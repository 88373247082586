import { Form, FormInstance, Upload } from 'antd';
import ModalCustom from 'components/Modal';
import { useCallback, useEffect, useState } from 'react';
import { ICardItem } from '..';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isKeepOpen: boolean;
  fileName?: string;
  form: FormInstance<any>;
  fileUrl: string;
  loading: boolean;
}

const ModalDowload = (props: IProps) => {
  const { visible, onCancel, onSubmit, isKeepOpen, fileName, form, fileUrl, loading } = props;
  const [urlContent, setUrlContent] = useState<string>('');

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [fileName]);

  useEffect(() => {
    if (
      fileUrl.toLocaleLowerCase().includes('.mp4') ||
      fileUrl.toLocaleLowerCase().includes('.m4v') ||
      fileUrl.toLocaleLowerCase().includes('.avi') ||
      fileUrl.toLocaleLowerCase().includes('.mpg')
    ) {
      setUrlContent(
        `<video playsinline style='width: 100%' height="500" controls src='${
          fileUrl || ''
        }'>Your browser does not support this video.</video>`,
      );
    } else if (fileUrl.toLocaleLowerCase().includes('.pdf')) {
      setUrlContent(`<iframe style='width: 100%' src='${fileUrl || ''}' height='623px'></iframe>`);
    } else {
      setUrlContent(
        `<iframe style='width: 100%' src='https://view.officeapps.live.com/op/embed.aspx?src=${
          fileUrl?.split('?')[0] || ''
        }' height='623px'></iframe>`,
      );
    }
  }, [fileUrl]);

  return (
    <div className="custom-modal-download1">
      <ModalCustom
        onCancel={onCancel}
        onSubmit={form.submit}
        visible={visible}
        title={`View File: ${fileName}`}
        cancelText="Back"
        okText={'Download'}
        isKeepOpen={isKeepOpen}
        buttonFloatRight
        styleCancel={{ width: '120px' }}
        styleOk={{ width: '120px' }}
        className="custom-modal-download"
        loading={loading}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          className="custom-upload text-center"
        >
          {fileUrl.toLocaleLowerCase().includes('svg') ||
          fileUrl.toLocaleLowerCase().includes('png') ||
          fileUrl.toLocaleLowerCase().includes('jpg') ? (
            <img className="rounded-2xl object-cover" src={fileUrl} />
          ) : (
            <div
              className="overflow-auto w-full text-base text-[#6E6B68]"
              dangerouslySetInnerHTML={{ __html: urlContent }}
            ></div>
          )}
        </Form>
      </ModalCustom>
    </div>
  );
};

export default ModalDowload;
