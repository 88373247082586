export const optionsLimit = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
];

export interface IMemberList {
  studentID: number;
  groupName: string;
  createdAt: string;
  updatedAt: string;
  userID: number;
  username: string;
  userFullName: string;
}
export interface ITeacherDetail {
  classID: number;
  createdAt: string;
  groupID: number;
  groupName: string;
  studentID: number | null;
  teacherID: number;
  updatedAt: string;
  userID: number;
  username: string;
  fullName: string;
}

interface IClassDetail {
  id: number;
  createdAt: string;
  updatedAt: string;
  className: string;
  classYear: number;
  classSemester: string;
  capacity: number;
  centreID: number;
  courseID: number;
  startDate: string;
  endDate: string;
  aimsID: number;
}

export interface IGroupMemberData {
  limit?: number | string;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: string;
  total?: number;
  studentList: IMemberList[];
  teacherDetail?: ITeacherDetail[];
  classDetail?: IClassDetail;
}
