import React from 'react'
import { useQuery } from 'react-query';
import { Skeleton } from 'antd';
import { getUnitsId } from 'api/unit';
import { getModuleById } from 'api/module';

interface ISecctionColumn {
    colValue: any;
    rowValue: any
}

function ModuleColumn(props: ISecctionColumn) {
    const { colValue, rowValue } = props
    const { data, isLoading } = useQuery(['getUnitsId', colValue], () =>
        getUnitsId(Number(colValue)), { enabled: !!colValue }
    );

    const { data: moduleData, isLoading: isLoadingModuleData } = useQuery(['getModuleById', data?.data?.session?.moduleID], () =>
        getModuleById(Number(data?.data?.session?.moduleID)), { enabled: !!data?.data?.session?.moduleID }
    );

    return (isLoading || isLoadingModuleData) ? <Skeleton.Input active /> : (
        <div>{moduleData?.data?.moduleName}</div>
    )
}

export default React.memo(ModuleColumn)