/**
 *
 * TopicDetails
 *
*/
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Layout, Breadcrumb, Avatar, Form, Row, Col, Input, Button, Upload } from 'antd';
import { ArrowRightOutlined, LinkOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from 'react-query';

import { ROUTES } from 'constants/constants';
import { createNewReply, getTopicDetails } from 'api/forum';
import { AppContext } from 'context';
import { TTopic } from '../../../types';
import '../../../style.css';

const { Header, Content } = Layout;

interface IState {
  className: string;
};

const StudentTopicDetails = () => {
  const navigate = useNavigate();
  const { forumId, topicId } = useParams();
  const location = useLocation();
  const state = location.state as IState;
  const [form] = Form.useForm();
  const [stateContext]: any = useContext(AppContext);
  const userInfo = stateContext?.user;

  const [details, setDetails] = useState<{ topic: TTopic } | null>(null);

  const { mutate: getDetails } = useMutation('getTopicDetails', getTopicDetails, {
    onSuccess: ({
      data
    }: {
      data: { topic: TTopic }
    }) => {
      if (data) {
        setDetails(data);
      }
    }
  });

  useEffect(() => {
    if (topicId && forumId) {
      getDetails({
        forumId,
        topicId,
      });
    }
  }, [, forumId, topicId]);

  const { mutate: mutateCreateNewReply } = useMutation('createNewReply', createNewReply, {
    onSuccess: () => {
      if (forumId && topicId) {
        getDetails({
          forumId,
          topicId,
        });
      }
    }
  });

  const onSubmit = useCallback((values: { replyText: string }) => {
    if (topicId && userInfo) {
      mutateCreateNewReply({
        userId: Number(userInfo?.id),
        forumId: Number(forumId),
        replyText: values.replyText,
        attachment: '',
        topicId: Number(topicId),
      })
    }
  }, [topicId, userInfo]);

  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      <Header className="flex justify-between items-center bg-transparent px-0">
        <Breadcrumb className="text-[1.75rem] leading-9 font-bold mb-0">
          <Breadcrumb.Item
            className="cursor-pointer font-fontFamily"
            onClick={() => navigate(ROUTES.class_management)}
          >
            Class Management
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily">
            {state && state.className ? state.className : ''}
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily">
            Class Forum
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily text-main-font-color">
            {details?.topic?.topicTitle ?? ''}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content className="rounded-3xl flex flex-col gap-y-6 bg-white">
        <div className="rounded-3xl bg-white p-8">
          <div className="rounded-3xl bg-[#FCECD9] p-8">
            <h2>Topic Discussion</h2>
            <p>{details?.topic?.topicText ?? ''}</p>
          </div>
          {
            details?.topic?.replies?.map((reply) => (
              <div className="flex justify-between mt-5" key={reply.id}>
                <div className="flex gap-3">
                  <Avatar size="large" src={reply?.author?.userProfile?.profilePhotoDestination} />
                  <div>
                    <h3 className="font-bold">{reply.author?.userProfile?.firstName} {reply.author?.userProfile?.lastName}</h3>
                    <p>{reply.replyText}</p>
                  </div>
                </div>
                <div>
                  <p>
                    {moment.utc(reply.createdAt).fromNow(true)}
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </Content>
      <div className="rounded-3xl bg-white p-8">
        <Form
          form={form}
          name="basic"
          initialValues={{ layout: 'inline' }}
          autoComplete="off"
          onFinish={onSubmit}
        >
          <Row gutter={12}>
            <Col xs={16}>
              <Form.Item
                name="replyText"
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Input
                  placeholder="Leave discussion here"
                  className="style_input_custom_login_page rounded-xl"
                />
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Upload>
                <Button block className="h-11 font-fontFamily rounded-xl font-semibold text-base text-[#32302D] font-semibold text-sm" icon={<LinkOutlined />}>Attachment</Button>
              </Upload>
            </Col>
            <Col xs={3}>
              <Button block className="h-11 font-fontFamily rounded-xl text-white !border-main-button-color !bg-main-button-color font-semibold text-sm" htmlType="submit">
                Send <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  );
};

export default StudentTopicDetails;