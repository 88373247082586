import { getAPI, postAPI, putAPI } from './axios';
import { getSearchParams } from 'constants/index';

export const getAssignmentById = async (id: any) => {
  try {
    const data = await getAPI(`api/assignments/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createAssignment = async (payload: any) => {
  try {
    const data = await postAPI(`api/assignments`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateAssignment = async (payload: any) => {
  try {
    const data = await putAPI(`api/assignments/${payload.id}`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllAssignments = async (params: {
  limit?: number;
  page?: number;
  search?: string;
  filters?: string;
  sort?: string;
  order?: string;
}) => {
  const paramsOption = getSearchParams(params);

  try {
    const data = await getAPI(`api/assignments/all/${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllAssignmentsByUnits = async (params: {
  limit?: number;
  page?: number;
  search?: string;
  filters?: string;
  sort?: string;
  order?: string;
  units?: any;
}) => {
  const paramsOption = getSearchParams(params);

  try {
    const data = await postAPI(`api/assignments/all/units/${paramsOption ? paramsOption : ''}`, {
      units: params?.units,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
