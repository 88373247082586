import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Spin, Typography } from 'antd';

import 'react-quill/dist/quill.snow.css';
import './style.css';
import { getUnitsId } from 'api/unit';

const { Title } = Typography;

import ContentByUnit from 'components/ContentByUnit';
import { useContext } from 'react';
import { AppContext } from 'context';
import { getAssignmentById } from 'api/assignment';
import { getAssignmentAttachmentsByAssignmentID } from 'api/assignment_attachments';

function MyAssignmentDetail() {
  const { unitId, assignmentId } = useParams();
  const [stateContext]: any = useContext(AppContext);

  const {
    data: dataUnit,
    isLoading,
    refetch: refetchGetUnitsId,
  } = useQuery(['getUnitsId', unitId], () => getUnitsId(Number(unitId)), {
    enabled: !!unitId,
  });

  const {
    data: dataAssignments,
    isLoading: isLoadingAssignments,
    refetch: refetchGetAllAssignments,
  } = useQuery(['getAssignmentById', assignmentId], () => getAssignmentById(Number(assignmentId)), {
    enabled: !!assignmentId,
  });

  const {
    data: dataAssignmentAttachmentsByAssignmentID,
    isLoading: isLoadingAssignmentAttachmentsByAssignmentID,
    refetch: refetchAssignmentAttachmentsByAssignmentID,
  } = useQuery(
    ['getAssignmentAttachmentsByAssignmentID', unitId],
    () => getAssignmentAttachmentsByAssignmentID(Number(assignmentId)),
    {
      enabled: !!unitId && !!assignmentId,
    },
  );

  return (
    <Spin
      spinning={isLoading || isLoadingAssignments || isLoadingAssignmentAttachmentsByAssignmentID}
      tip="Loading..."
    >
      <div className="pb-5">
        <Title level={3} className="flex items-center gap-3 flex-wrap">
          <div style={{ opacity: 0.5 }}>Assignments</div>
          <div style={{ opacity: 0.5 }}>/</div>
          <div style={{ opacity: 0.5 }}>{dataUnit?.data?.session?.sessionName}</div>
          <div style={{ opacity: 1 }}>/</div>
          <div style={{ opacity: 1 }}>{dataUnit?.data?.unitName}</div>
        </Title>
        {!!unitId && dataAssignments?.data && (
          <ContentByUnit
            isTeacher
            assignmentAttachments={dataAssignmentAttachmentsByAssignmentID?.data}
            refetchGetUnitsId={refetchGetUnitsId}
            refetchGetAllAssignments={refetchGetAllAssignments}
            assignment={dataAssignments?.data}
            stateContext={stateContext}
            unitId={unitId}
            content={dataUnit?.data?.content}
          />
        )}
      </div>
    </Spin>
  );
}

export default MyAssignmentDetail;
