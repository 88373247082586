import { deleteAPI, getAPI, postAPIS3 } from './axios';

export enum ETimeQuery {
  TODAY = 'Today',
  WEEK = 'Week',
  THIS_MONTH = 'This_month',
  LAST_MONTH = 'Last_month',
  OLDER = 'Older',
  YESTERDAY = 'Yesterday',
}

export enum TrashType {
  MY_DRIVE = 'my_drive',
  CLASS_LIBRARY = 'class_library',
}
export interface IParamsGetBin {
  fullTextSearch?: string;
  type: TrashType;
  classId?: string;
}

export interface IPayloadAction {
  binId: number;
  type: TrashType;
}

export interface IPayloadActionDeleteAll {
  idPath: number;
  type: TrashType;
}

export const deleteAllFileDrive = async (payload: IPayloadActionDeleteAll) => {
  try {
    const data = await postAPIS3(`api/trash-drive/delete-all-file-drive`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteFileDrive = async (payload: IPayloadAction) => {
  try {
    const data = await postAPIS3(`api/trash-drive/delete-file-drive`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFileDrive = async (params?: IParamsGetBin) => {
  try {
    const data = await getAPI(`api/trash-drive`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const restoreFileDrive = async (payload: IPayloadAction) => {
  try {
    const data = await postAPIS3(`api/trash-drive`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
