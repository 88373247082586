import { Form, Input, notification } from 'antd';
import { saveZoomAccount } from 'api/online_meetings';
import ModalCustom from 'components/Modal';
import { CLASS_NAME_FIELD } from 'constants/index';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

const ConfigureModal = (props: any) => {
  const { visible, onCancel, teacherId } = props;

  const [form] = Form.useForm();
  const [isKeepOpen, setIsKeepOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const accountId = Form.useWatch('accountId', form);
  const zoomKey = Form.useWatch('zoomKey', form);
  const zoomSecret = Form.useWatch('zoomSecret', form);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
    setIsKeepOpen(true);
    setLoading(false);
  };

  const { mutateAsync: mutateSaveZoomAccount } = useMutation('saveZoomAccount', saveZoomAccount, {
    onSuccess: () => {
      handleCancel();
      notification.success({message: 'Configure successfully!'})
    },
    onError: () => {
      setLoading(false);
      notification.error({message: 'Configure fail!'})
    },
  });

  const onFinish = useCallback(
    (formValues: {
      accountId: string;
      zoomKey: string;
      zoomSecret: string;
    }) => {
      setLoading(true);
      mutateSaveZoomAccount({
        teacherId: Number(teacherId),
        accountId: accountId || formValues.accountId,
        zoomKey: zoomKey || formValues.zoomKey,
        zoomSecret: zoomSecret || formValues.zoomSecret,
      })
      .then(() => {
        notification.success({message: 'Configure successfully!'})
      })
      .catch((error) => {
        notification.error({message: error})
      });
    },
    [accountId, zoomKey, zoomSecret],
  );

  return (
    <ModalCustom
      title='Zoom Login Information'
      okText="Save"
      cancelText="Cancel"
      visible={visible}
      onCancel={handleCancel}
      onSubmit={form.submit}
      isKeepOpen={isKeepOpen}
      titleCenter
      contentLeft
      styleCancel={{ border: '1px solid #D1CDCB' }}
      className="modal-event-modified-custom"
      loading={loading}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          required
          name="accountId"
          rules={[
            { required: true, message: 'Username is required!' },
          ]}
          label='Account ID'
        >
          <Input maxLength={200} className={`${CLASS_NAME_FIELD} rounded-xl`} />
        </Form.Item>
        <Form.Item
          required
          name="zoomKey"
          rules={[
            { required: true, message: 'Zoom key is required!' },
          ]}
          label='Zoom Key'
        >
          <Input maxLength={200} className={`${CLASS_NAME_FIELD} rounded-xl`} />
        </Form.Item>
        <Form.Item
          required
          name="zoomSecret"
          rules={[
            { required: true, message: 'Zoom secret is required!' },
          ]}
          label='Zoom Secret'
        >
          <Input maxLength={200} className={`${CLASS_NAME_FIELD} rounded-xl`} />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default ConfigureModal;
