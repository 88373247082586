export const optionsLimit = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
];

export const groupCardData = [
  {
    title: 'Group 1',
  },
  { title: 'Group 2' },
  { title: 'Group 3' },
  { title: 'Group 4' },
];
