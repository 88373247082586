import { Button, Form, Input, Select } from 'antd';
import { sendOtp, verifyUserForgotPassword } from 'api/user';
import BackToLogin from 'components/BackToLogin';
import HeaderLogin from 'components/HeaderLogin';
import { ACTION_TYPE, TwoFAMethod } from 'constants/constants';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const ForgoPassword = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  //const methodValue = Form.useWatch('method', form);
  const nricValue = Form.useWatch('nric', form);
  const usernameValue = Form.useWatch('username', form);
  const { mutate, isLoading } = useMutation('verify-user', verifyUserForgotPassword, {
    onSuccess: (data) => {
      if (data?.isSuccess) {
        history(`/create-new-password?token=${data.data.token}`);//nricValue !== TwoFAMethod.EMAIL ? '/two-factor-forgot-password' : '/email-sent'
      }
    },
    onError: () => {
      form.setFields([
        { name: 'username', errors: ['Invalid Username or NRIC. Please try again. '] },
        { name: 'method', errors: [''] },
      ]);
    },
  });

  const onFinish = ({ username, nric }: { username: string; nric: string }) => {
    mutate({ 
      nric: nric,
      username: username
    });
  };

  const isDisable = useMemo(() => {
    if (!usernameValue || !nricValue) {
      return true;
    }
    return false;
  }, [usernameValue, nricValue]);

  return (
    <>
      <HeaderLogin title="Forgot Password" />
      <span className="font-normal text-center text-sm mb-2">
        Please enter your registered ID and NRIC to reset password.
      </span>
      <Form
        className="w-full"
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
      >
        <span className="label_custom_login_page">Username :</span>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Username is required!' }]}
        >
          <Input placeholder="Username" className="style_input_custom_login_page" />
        </Form.Item>

        <span className="label_custom_login_page">NRIC :</span>
        <Form.Item
          name="nric"
          rules={[{ required: true, message: 'NRIC is required!' }]}
        >
          <Input placeholder="NRIC" className="style_input_custom_login_page" />
        </Form.Item>

        {/* <span className="label_custom_login_page">2FA Method:</span> */}
        {/* <Form.Item name="method" rules={[{ required: true, message: '2FA Method is required!' }]}>
          <Select
            placeholder="Please select"
            allowClear
            className="text-[#32302D] font-fontFamily text-sm"
          >
            <Select.Option value={TwoFAMethod.SMS}>SMS</Select.Option>  
            <Select.Option value={TwoFAMethod.EMAIL}>Email</Select.Option>
            <Select.Option value={TwoFAMethod.APP}>App</Select.Option>  
          </Select>
        </Form.Item> */}

        <Form.Item className="w-full">
          <Button
            loading={isLoading}
            htmlType="submit"
            className={`mb-3 styte_button_primary ${isDisable && 'text-white'}`}
            size="large"
            disabled={isDisable}
          >
            Submit
          </Button>
          <BackToLogin text="Back" />
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgoPassword;
