import { getAPI, postAPI, putAPI } from "./axios";

export interface IParamsSearch {
  limit: number;
  page: number;
  search?: string;
  filters?: string;
  sort?: string;
  order?: string;
  id?: number;
}

interface BodyEndAnnouncement {
  id: number[]
}

export interface BodyCreateAnnouncement {
  title?: string
  message?: string
  classes: string[];
  startDate: Date
  endDate: Date
  sendEmail: boolean
}

// Generated by https://quicktype.io

export interface ConfigBellNotification extends UserNotification {
  sortTime:        Date;
}
export interface UserNotification {
  id:             number;
  createdAt:      string;
  updatedAt:      string;
  userId:         number;
  notificationId: number;
  isRead:         boolean;
  notification:   Notification;
}
export interface Notification {
  id:              number;
  createdAt:       string;
  updatedAt:       string;
  createdByUserId: number;
  actionType:      string;
  nameType:        string;
  content:         string;
  createdByUser:   CreatedByUser;
}

export interface CreatedByUser {
  id:                 number;
  createdAt:          string;
  updatedAt:          string;
  username:           string;
  email:              string;
  googleLoginHash:    string;
  officeLoginHash:    string;
  is2FAEnabled:       number;
  password:           string;
  "2FAMethod":        string;
  lastLogin:          string;
  lastChangeUsername: string;
  isActive:           number;
  isDeleted:          number;
  deletedAt:          Date;
  userProfileID:      number;
  userRoleID:         number;
  themeID:            number;
  userProfile:        UserProfile;
}

export interface UserProfile {
  id:                      number;
  createdAt:               string;
  updatedAt:               string;
  firstName:               string;
  lastName:                string;
  ICNumber:                string;
  nationality:             string;
  address1:                string;
  address2:                string;
  country:                 string;
  postalCode:              string;
  dateOfBirth:             string;
  contactNumber:           string;
  mobileCountryCode:       string;
  salutation:              string;
  gender:                  string;
  designation:             any;
  profilePhotoDestination: string;
  languageID:              number;
}



export const getNotification = async (params: IParamsSearch) => {
  try {
    const data = await getAPI(`api/user-notification`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCountUnreadNotification = async () => {
  try {
    const data = await getAPI(`api/user-notification/count-unread-notification`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateNotificationStatus = async (id: number) => {
  try {
    const data = await putAPI(`api/user-notification/update-status-readed/${id}`, {});
    return data;
  } catch (error) {
    throw error;
  }
};
