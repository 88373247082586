import { getSearchParams } from '../constants/index';
import { deleteAPI, getAPI, postAPI, putAPI } from './axios';

export interface IOnlineMeeting {
  teacherId: number;
  classId: number;
  studentId: number;
  startTime: Date | string;
  endTime: Date | string;
  remarks?: string;
}

export interface IMeetingAccount {
  teacherId: number;
  accountId: string;
  zoomKey: string;
  zoomSecret?: string;
}

export const getMeetingsForUser = async (params: { userId: number }) => {
  const { userId } = params;
  try {
    const data = await getAPI(`api/online-meeting/user/${userId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export interface IParamsSearch {
  limit: number;
  page: number;
  sort?: string;
}

export const getMeetingsForAdmin = async (params: IParamsSearch) => {
  const paramsOption = getSearchParams(params);
  try {
    const data = await getAPI(`api/online-meeting${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createOnlineMeeting = async (payload: IOnlineMeeting) => {
  try {
    const data = await postAPI('api/online-meeting', payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveZoomAccount = async (payload: IMeetingAccount) => {
  try {
    const data = await postAPI('api/online-meeting/account', payload);
    return data;
  } catch (error) {
    throw error;
  }
};
