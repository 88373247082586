import React from 'react'
import { useQuery } from 'react-query';
import { Skeleton } from 'antd';
import { getUnitsId } from 'api/unit';

interface IModuleColumn {
    colValue: any;
    rowValue: any
}


function SecctionColumn(props: IModuleColumn) {
    const { colValue, rowValue } = props
    const { data, isLoading } = useQuery(['getUnitsId', colValue], () =>
        getUnitsId(Number(colValue)), { enabled: !!colValue }
    );

    return isLoading ? <Skeleton.Input active /> : (
        <div>{data?.data?.session?.sessionName}</div>
    )
}

export default React.memo(SecctionColumn)