import React from 'react';

import ModalCustom from 'components/Modal';
import { RoleName } from 'enum/role';
import { leaveFromGroup } from 'api/teacher_my_groups';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

interface Props {
  title: string;
  bodyText: string;
  removeModal: boolean;
  setRemoveModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveModal = ({ removeModal, setRemoveModal, bodyText, title }: Props) => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation('leaveFromGroup', leaveFromGroup, {
    onSuccess: (data) => {
      setRemoveModal?.(false);
      notification.success({ message: data.message, description: data.data });
      navigate('/courses/my-group');
    },
    // onError: (error: { response: { data: { message: string } } }) => {
    //   // console.log(error.response);
    //   // notification.error({ message: 'Error', description: `${error.response.data.message}` });
    // },
  });

  const handleSubmit = () => {
    mutate({ userType: RoleName.STUDENT, groupID: Number(groupId) });
  };

  return (
    <div>
      <ModalCustom
        cancelText="Cancel"
        visible={removeModal}
        onSubmit={handleSubmit}
        onCancel={() => setRemoveModal?.(false)}
        okText="Confirm"
        title={title}
        titleCenter
        loading={isLoading}
        isKeepOpen={true}
      >
        <p>{bodyText}</p>
      </ModalCustom>
    </div>
  );
};

export default RemoveModal;
