import { Button, Dropdown, Layout, Menu } from 'antd';
import { useForm } from 'antd/es/form/Form';
// import { readAnnouncement } from 'api/announcement';
import { checkStudentInClass, getAnnnoucementInClass, getClassById } from 'api/class';
import { getCourseDetail, getCourseModulesByStudent } from 'api/courses';
import { ConfigBellNotification } from 'api/notification';
import { DATE_FORMAT, ROUTES, Status } from 'constants/constants';
import { AppContext } from 'context';
import moment from 'moment';
import ModalDetail from 'pages/admin/notifications/component/ModalDetail';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './style.css';
import { routerUserAccess } from 'helper/function';
import { RoleName } from 'enum/role';

export interface ICourseDetail {
    id: number;
    courseName: string;
    catalogImageUrl?: string;
    description?: string;
    startDate?: string;
    endDate?: string;
    learningMethod?: string;
    programType?: string;
}

const MyCourseDetail = () => {
    const { id } = useParams();
    const routerUser = routerUserAccess();
    const search = useLocation().search;
    const classId = new URLSearchParams(search).get('classId');
    const history = useNavigate();
    const [dataCourses, setDataCourses] = useState<ICourseDetail>();
    const [dataModules, setDataModules] = useState<any>();
    const [className, setClassName] = useState('');
    const [form] = useForm();
    // const [announcementSelected, setAnnouncementSelected] = useState<ConfigBellNotification>();
    // const [listAnnouncement, setListAnnouncement] = useState<any>([]);
    const [state, setState]: any = useContext(AppContext);
    const [isStudentJoinClass, setIsStudentJoinClass] = useState<any>();

    const { mutate: getCourse } = useMutation('getCourseDetail', getCourseDetail, {
        onSuccess: ({ data }: { data: ICourseDetail }) => {
            const formData = {
                id: data?.id,
                courseName: data?.courseName,
                learningMethod: data?.learningMethod,
                programType: data?.programType,
                description: data?.description,
                catalogImageUrl: data?.catalogImageUrl,
                startDate: data?.startDate,
                endDate: data?.endDate,
            };
            setDataCourses(formData);
        },
    });

    // const announcements = useQuery(['listAnnoucement', { state: state?.countAnnouncement }], () =>
    //     getAnnnoucementInClass(classId as any),
    // );

    const checkStudentJoinClass: any = useQuery(['checkStudentInClass'], () =>
        checkStudentInClass(classId as any),
    );

    useEffect(() => {
        if (checkStudentJoinClass.status === 'success') {
            setIsStudentJoinClass(checkStudentJoinClass.data.data);
        }
    }, [checkStudentJoinClass]);

    // useEffect(() => {
    //     if (announcements?.data?.data) {
    //         const handleDataAnnouncement = announcements?.data.data.map((item: any) => {
    //             return {
    //                 ...item,
    //                 author: `${item.author?.userProfile?.firstName} ${item.author?.userProfile?.lastName}`,
    //             };
    //         });
    //     setListAnnouncement(handleDataAnnouncement);
    //     }
    // }, [announcements]);

    const { mutate: getCourseModule } = useMutation('getCourseModule', getCourseModulesByStudent, {
        onSuccess: ({ data }: { data: ICourseDetail }) => {
            setDataModules(data);
        },
    });

    // const { mutate: readAnnounce } = useMutation('readAnnoucement', readAnnouncement, {
    //     onSuccess(data, variables, context) {
    //         announcements.refetch();
    //     },
    // });

    const { mutate: getClass } = useMutation('getClass', getClassById, {
        onSuccess: ({ data }) => {
            setClassName(data.className);
        },
    });

    useEffect(() => {
        if (id) {
            getCourse(Number(id));
            getCourseModule({ 'courseId': Number(id)});
        }
    }, [id]);

    useEffect(() => {
        getClass(Number(classId));
    }, [classId]);

    const menu = (
        <Menu className="w-[116px] absolute left-[-15px]">
            <Menu.Item className="pr-3 btn-link">Withdraw</Menu.Item>
            <Menu.Item className="pr-3 btn-link">Transfer</Menu.Item>
        </Menu>
    );

    return (
        <>
        { 
            routerUser.userRole === RoleName.STUDENT && isStudentJoinClass != null ? (
                <Layout id="course-detail-student" className="bg-transparent flex flex-col gap-y-6">
                    <div className="flex justify-between items-center bg-transparent px-0 custom-class-info">
                        <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 custom-font-header">
                            <span className="text-[#AEA8A5] cursor-pointer" onClick={() => history(ROUTES.my_course)}>My Courses</span> / {dataCourses?.courseName} - {className}
                        </p>
                        <Dropdown trigger={['click']} overlay={menu} placement="bottomRight" className="h-full">
                            <div className="text-[#32302D] hover:text-[#BE5E2A] text-[16px] font-semibold border-solid border-[1px] border-[#D1CDCB] hover:border-[#ED7635] rounded-xl px-4 py-1.5 cursor-pointer">
                                Action
                            </div>
                        </Dropdown>
                    </div>
                    
                    <div className="flex items-start flex-row p-8 gap-10 bg-[#FFFFFF] rounded-3xl custom-class-info">
                        <img className="w-[320px] h-[240px] rounded-2xl" src={dataCourses?.catalogImageUrl} />
                        <div>
                            <p className="text-2xl font-bold text-[#32302D] custom-font-header">{dataCourses?.courseName}</p>
                            <p className="text-2xl font-bold text-[#32302D] custom-font-header">{className}</p>
                            <p className="font-semibold text-[#6E6B68] text-lg">{dataCourses?.description}</p>
                            <p className="font-semibold text-[#6E6B68] text-lg">Duration: {moment(dataCourses?.startDate).format(DATE_FORMAT)} to {moment(dataCourses?.endDate).format(DATE_FORMAT)}</p>
                            <p className="font-semibold text-[#6E6B68] text-lg">Learning Method: {dataCourses?.learningMethod}</p>
                            <p className="font-semibold text-[#6E6B68] text-lg">Program Type: {dataCourses?.programType}</p>
                        </div>
                    </div>
                    
                    <div className="p-8 bg-[#FFFFFF] rounded-3xl mt-4">
                        <p className="text-2xl font-bold text-[#32302D] custom-font-header">Class Progress information</p>
                        
                        <div className="flex flex-col gap-4">
                            <div className="font-semibold text-[#32302D] text-lg mt-4">Active Modules</div>
                            {
                                dataModules && dataModules?.length > 0 && dataModules?.map((module: any) => {
                                    var percent = 0
                                    var totalSessionsWithTest = module?.progresses[0]?.totalSessionsWithTest ?? 0
                                    var completedSessionsWithTest = module?.progresses[0]?.completedSessionsWithTest ?? 0

                                    if (totalSessionsWithTest > 0 && completedSessionsWithTest > 0)
                                        percent = Math.round((completedSessionsWithTest / totalSessionsWithTest) * 100)

                                    if (percent > 0 && percent < 100) {
                                        const codeMudule = module?.moduleCode && module?.moduleCode !== '' ? `(${module?.moduleCode})` : '';
                                        return (
                                            <div className="flex items-center" key={module?.id}>
                                                <div className="text-base text-[#6E6B68] w-[200px] cursor-pointer hover:text-[#ED7635]" onClick={() => history(`${ROUTES.my_course}/${id}/module/${module?.id}?classId=${classId}`, )}>
                                                    {module?.moduleName + ' ' + codeMudule}
                                                </div>
                                                <div id="myProgress" className="w-full bg-[#F7F5F4] h-[40px] rounded-xl relative overflow-hidden">
                                                    <div id="myBar" className={`bg-[#F8C8AE] text-[#32302D] text-center h-[40px] rounded-xl`} style={{ width: percent + '%' }}>
                                                        <span className="flex items-center h-full justify-center text-base font-semibold custom-percent">{percent}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                        
                        <div className="flex flex-col gap-4">
                            <div className="font-semibold text-[#32302D] text-lg mt-4">Upcoming Modules</div>
                            {
                                dataModules && dataModules?.length > 0 && dataModules?.map((module: any) => {
                                    var percent = 0
                                    var totalSessionsWithTest = module?.progresses[0]?.totalSessionsWithTest ?? 0
                                    var completedSessionsWithTest = module?.progresses[0]?.completedSessionsWithTest ?? 0

                                    if (totalSessionsWithTest > 0 && completedSessionsWithTest > 0)
                                        percent = Math.round((completedSessionsWithTest / totalSessionsWithTest) * 100)

                                    if (percent === 0) {
                                        const codeMudule = module?.moduleCode && module?.moduleCode !== '' ? `(${module?.moduleCode})` : '';
                                        return (
                                            <div className="flex items-center" key={module?.id}>
                                                <div className="text-base text-[#6E6B68] w-[200px] cursor-pointer hover:text-[#ED7635]" onClick={() => history(`${ROUTES.my_course}/${id}/module/${module?.id}?classId=${classId}`, )}>
                                                    {module?.moduleName + ' ' + codeMudule}
                                                </div>
                                                <div id="myProgress" className="w-full bg-[#F7F5F4] h-[40px] rounded-xl relative overflow-hidden">
                                                    <div id="myBar" className="text-[#32302D] text-center h-[40px] rounded-xl">
                                                        <span className="flex items-center h-full justify-center text-base font-semibold custom-percent">{percent}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                        
                        <div className="flex flex-col gap-4">
                            <div className="font-semibold text-[#32302D] text-lg mt-4">Completed Modules</div>
                            {
                                dataModules && dataModules?.length > 0 && dataModules?.map((module: any) => {
                                    var percent = 0
                                    var totalSessionsWithTest = module?.progresses[0]?.totalSessionsWithTest ?? 0
                                    var completedSessionsWithTest = module?.progresses[0]?.completedSessionsWithTest ?? 0

                                    if (totalSessionsWithTest > 0 && completedSessionsWithTest > 0)
                                        percent = Math.round((completedSessionsWithTest / totalSessionsWithTest) * 100)

                                    if (percent === 100) {
                                        const codeMudule = module?.moduleCode && module?.moduleCode !== '' ? `(${module?.moduleCode})` : '';
                                        return (
                                            <div className="flex items-center" key={module?.id}>
                                                <div className="text-base text-[#6E6B68] w-[200px] cursor-pointer hover:text-[#ED7635]" onClick={() => history(`${ROUTES.my_course}/${id}/module/${module?.id}?classId=${classId}`, )}>
                                                    {module?.moduleName + ' ' + codeMudule}
                                                </div>
                                                <div id="myProgress" className="w-full bg-[#F1915D] h-[40px] rounded-xl ml-2">
                                                    <div id="myBar" className="w-[100%] bg-[#F1915D] text-[#FFFFFF] text-center h-[40px] rounded-xl">
                                                        <span className="flex items-center h-full justify-center text-base font-semibold">{percent}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                        
                    {/* <div className="flex gap-4 mt-4 mb-4 h-full custom-class-info">
                        <div className="course-detail-announcement p-8 bg-[#FFFFFF] rounded-3xl basis-1/2 flex flex-col gap-2 lg:w-full">
                            <div className="course-detail-announcement-title text-2xl font-bold text-[#32302D] custom-font-header">Announcements</div>
                            {
                                announcements.status == 'success' && listAnnouncement.map((item: any, index: any) => {
                                    return (
                                        <div className={`flex justify-between items-center cursor-pointer  `} key={index} onClick={() => { setAnnouncementSelected(item) }}>
                                            <div className={`text-sm font-semibold text-[#32302D] title-announcement ${ item.isRead && 'text-[#AEA8A5]' } `}>{item.title}</div>
                                            <div className="w-[30%] text-[#AEA8A5] flex flex-col">{moment(item.createdAt).format('ddd YYYY/MM/DD, hh:mm a')}</div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        
                        <div className="course-detail-message p-8 bg-[#FFFFFF] rounded-3xl basis-1/2 flex flex-col gap-4 cus-my-course-w-full">
                            <div className="course-detail-message-title text-2xl font-bold text-[#32302D] custom-font-header">Messages (show latest 3)</div>
                            <div className="flex justify-between items-center">
                                <div className="text-sm font-semibold text-[#32302D]">Teacher 1</div>
                                <Button className="w-[30%] text-[#32302D] rounded-lg">Chat</Button>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-sm font-semibold text-[#32302D]">Teacher 2</div>
                                <Button className="w-[30%] text-[#32302D] rounded-lg">Chat</Button>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-sm font-semibold text-[#32302D]">Teacher 3</div>
                                <Button className="w-[30%] text-[#32302D] rounded-lg">Chat</Button>
                            </div>
                        </div>
                    </div> */}
            
                    {/* <ModalDetail 
                        data={announcementSelected} 
                        form={form} 
                        visible={announcementSelected ? true : false} 
                        onCancel={async () => {
                            if (announcementSelected) {
                                readAnnounce(announcementSelected.id);
                            }
                            setAnnouncementSelected(undefined);
                        }}
                    /> */}
                </Layout>
            ) : (
                <p className="font-bold text-2xl">You don&apos;t have the permission to access this resource</p>
            )
        }
        </>
    );
};

export default MyCourseDetail;
