import { Form, FormInstance, notification } from 'antd';
import CustomInput from 'components/Input';
import ModalCustom from 'components/Modal';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { useCallback, useEffect } from 'react';
import { ICardUploadFile } from '..';
import Dragger from 'antd/lib/upload/Dragger';
import ButtonCustom from 'components/Button';
import Upload, { RcFile, UploadFile } from 'antd/lib/upload';
import { ERROR_MESSAGE } from 'constants/index';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isKeepOpen: boolean;
  form: FormInstance<any>;
  onUploadFolder: (file: File) => void;
  onRemoveFolder: (uidFolder: string) => void;
  listFolder: string[];
  loading: boolean;
  limitSize?: number;
}

const ModalUploadFolder = (props: IProps) => {
  const {
    visible,
    onCancel,
    onSubmit,
    isKeepOpen,
    form,
    listFolder,
    onUploadFolder,
    onRemoveFolder,
    loading,
    limitSize,
  } = props;

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [listFolder]);

  const onBeforeUpload = (file: RcFile) => {
    if (file.webkitRelativePath === file.name) {
      notification.error({
        message: `Folder invalid!`,
      });
      return Upload.LIST_IGNORE;
    }

    if (limitSize && file?.size / 1024 / 1024 > limitSize) {
      notification.error({
        message: `The file ${file.name} limit size must be less than ${limitSize}MB`,
      });
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const onRemoveUpload = (file: UploadFile<any>) => {
    onRemoveFolder(file.uid);
  };

  const customRequest = async (options: any) => {
    const { onSuccess, file } = options;
    onSuccess('Ok');
    onUploadFolder(file);
  };

  useEffect(() => {
    if (listFolder?.length > 0) form.validateFields();
  }, [listFolder]);

  return (
    <ModalCustom
      onCancel={onCancel}
      onSubmit={form.submit}
      visible={visible}
      title={'Upload Folder'}
      cancelText="Cancel"
      okText={'Create'}
      isKeepOpen={isKeepOpen}
      loading={loading}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Upload Folder"
          name="thumbnailPath"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (listFolder?.length === 0) {
                  return Promise.reject('Folder upload is required!');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <div className="flex justify-center items-center rounded-xl bg-[#F4F4F4] text-center box-upload">
            <Dragger
              multiple
              beforeUpload={onBeforeUpload}
              customRequest={customRequest}
              onRemove={onRemoveUpload}
              className="py-12"
              fileList={listFolder.map((element: string) => ({
                uid: `${element}`,
                name: element as string,
              }))}
              directory
            >
              <div className="lg:max-w-fit m-auto">
                <ButtonCustom
                  color="outline"
                  className="m-auto"
                  icon={<UploadIcon className="icon-button" />}
                >
                  &nbsp; Click or drop folder
                </ButtonCustom>
              </div>
            </Dragger>
          </div>
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default ModalUploadFolder;
