import ButtonCustom from 'components/Button';
import CollapseModule, { ISessionsModule } from 'pages/admin/courses/component/CollapseModule';
import { useCallback, useEffect, useState } from 'react';
import { IForm } from '..';
import { ReactComponent as ShowIcon } from 'assets/icons/show_less.svg';
import { getCourseSessionsByModule } from 'api/courses';
import { useMutation } from 'react-query';
import { searchClassRoom } from 'api/class';
import { searchTeacher } from 'api/teacher';
import { PARAMS_SELECT_SEARCH } from 'constants/constants';
import { searchCentres } from 'api/centres';

interface IProps {
  forms: IForm;
  id?: string | number;
  returnSessionsModule: (module: ISessionsModule[], index: number) => void;
  onChangeModule: (index: number[], key: string | boolean[]) => (value: { label: string; value: string }) => void;
  validationSessionModule: (index: number[], key: string | boolean[]) => void;
  item: IForm;
  indexClass: number;
  isShowLessButton: boolean;
  total: number;
  isEdit: boolean;
  isShowError: boolean;
  setIsChangeField: (val: boolean) => void;
  returnCentre: (data: { label: string; value: string }, index: number) => void;
  classesList: IForm[];
  isChecked?: boolean;
  handleChooseAllClass: (e: any, indexModule: number) => void;
  getIndexClassToApplyAll: (indexClass: number) => void;
  handleSubmit: (indexModule: number) => void;
}

const FormModule = (props: IProps) => {
  const {
    forms,
    onChangeModule,
    returnSessionsModule,
    id,
    item,
    indexClass,
    isShowLessButton,
    total,
    isEdit,
    isShowError,
    setIsChangeField,
    returnCentre,
    validationSessionModule,
    classesList,
    isChecked,
    handleChooseAllClass,
    getIndexClassToApplyAll,
    handleSubmit
  } = props;
  const [isShowLess, setIsShowLess] = useState<boolean>(true);

  const [classRoomOptions, setClassRoomOptions] = useState<{ label: string; value: string }[]>([]);
  const [TeacherOptions, setTeacherOptions] = useState<{ label: string; value: string }[]>([]);
  const [centreOptions, setCentreOptions] = useState<{ label: string; value: string }[]>([]);
  const [limit] = useState(10);
  const [order] = useState('DESC');

  const { mutate: getSessionsModule } = useMutation(
    'getCourseSessionsByModule',
    getCourseSessionsByModule,
    {
      onSuccess: (res: { data: ISessionsModule[] }) => {
        console.log("hereasdas")
        const modules = res.data.map((module) => {
          return {
            ...module,
            sessions: module.sessions.map((session) => {
              return {
                ...session,
                teachers: [],
                centre: item?.formValue?.centre ? item?.formValue?.centre : '',
                classRoom: '',
                time: undefined,
                disabled: Number(id) ? false : true,
              };
            }),
          };
        });
        if (!Number(id)) {
          returnSessionsModule(modules, indexClass);
        }
      },
    },
  );

  const { mutate: searchClassRooms } = useMutation('searchClassRoom', searchClassRoom, {
    onSuccess: (res: { data: { listClassrooms: { roomName: string; id: number }[] } }) => {
      const optionDefault = [{ label: 'Virtual classroom', value: '' }];
      const options = res.data.listClassrooms.map((classRoom) => {
        return {
          label: classRoom.roomName,
          value: classRoom.id.toString(),
        };
      });
      const newOptions = optionDefault.concat(options);
      setClassRoomOptions(newOptions as { label: string; value: string }[]);
    },
  });

  const { mutate: searchTeachers } = useMutation('searchTeacher', searchTeacher, {
    onSuccess: (res: {
      data: {
        records: { user: { userProfile: { firstName: string; lastName: string } }; id: number }[];
      };
    }) => {
      const options = res.data.records.map((teacher) => {
        return {
          label: teacher?.user?.userProfile?.firstName + ' ' + teacher?.user?.userProfile?.lastName,
          value: teacher.id.toString(),
        };
      });
      setTeacherOptions(options as { label: string; value: string }[]);
    },
  });

  const { mutate: searchCentre } = useMutation('searchCentres', searchCentres, {
    onSuccess: (res: { data: { listCentres: { centreName: string; id: number }[] } }) => {
      const options = res.data.listCentres.map((centreItem) => {
        return {
          label: centreItem.centreName,
          value: centreItem.id.toString(),
        };
      });
      setCentreOptions(options as { label: string; value: string }[]);
    },
  });

  useEffect(() => {
    if (item?.formValue?.centre.value) {
      searchTeachers({
        ...PARAMS_SELECT_SEARCH.teacher,
        limit,
        filters: JSON.stringify([{ isActive: true, centreID: Number(item?.formValue?.centre) }]),
      });
      return;
    }
    setTeacherOptions([]);
  }, [item?.formValue?.centre]);

  useEffect(() => {
    searchClassRooms({
      limit,
      order,
      page: 1,
      search: '',
      filters: JSON.stringify([
        { centreID: item?.formValue?.centre?.value ? Number(item?.formValue?.centre?.value) : Number(item?.formValue?.centre?.value) },
      ]),
    });

    if (item?.formValue?.courseName?.value) {
      getSessionsModule(Number(item?.formValue?.courseName?.value)); //wrong
    }
  }, [total, item?.formValue?.courseName?.value])

  const renderModules = useCallback(
    (x: IForm, index: number) => {
      const newModule = x?.modules?.length > 0 ? x?.modules : [forms];
      return (
        Array.isArray(newModule) &&
        newModule.map((module, indexModule) => (
          <CollapseModule
            disable={isEdit}
            returnCentre={returnCentre}
            indexClass={index}
            indexModule={indexModule}
            returnSessionsModule={returnSessionsModule}
            courseName={x?.formValue?.courseName?.value}
            centre={x?.formValue?.centre}
            onChangeModule={onChangeModule}
            key={indexModule}
            validationSessionModule={validationSessionModule}
            data={module as { sessions: [] }}
            startDate={x.formValue.startDate}
            endDate={x.formValue.endDate}
            idClass={Number(id)}
            isShowError={isShowError}
            setIsChangeField={setIsChangeField}
            isShowLess={isShowLess && total - 1 !== index && newModule?.length > 1}
            classesList={classesList}
            handleChooseAllClass={handleChooseAllClass}
            getIndexClassToApplyAll={getIndexClassToApplyAll}
            handleSubmit={handleSubmit}
            classRoomOptionsMain={classRoomOptions}
            TeacherOptionsMain={TeacherOptions}
            CentreOptionsMain={centreOptions}
          />
        ))
      );
    },
    [classesList, isShowLess, onChangeModule, returnSessionsModule, total, isShowError, isEdit, id, item, forms],
  );

  const toggleModule = useCallback(() => {
    setIsShowLess(!isShowLess);
  }, [isShowLess]);

  useEffect(() => {
    if (total === 1) {
      setIsShowLess(false);
      return;
    }
    if (total > 1) {
      setIsShowLess(true);
    }

    if (item?.formValue?.courseName?.value) {
      getSessionsModule(Number(item?.formValue?.courseName?.value)); //wrong
    }
  }, [total, item?.formValue?.courseName?.value])

  return (
    <div className='responsive__form-module'>
      {renderModules(item, indexClass)}
      {item?.modules?.length > 1 && isShowLessButton && total - 1 !== indexClass && (
        <div className="text-right">
          <ButtonCustom
            color="outline"
            onClick={toggleModule}
            icon={<ShowIcon className="icon-button mr-1" />}
            className="mt-4"
            isWidthFitContent
          >
            {isShowLess ? 'Show more' : 'Show less'}
          </ButtonCustom>
        </div>
      )}
    </div>
  );
};

export default FormModule;
