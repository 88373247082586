import React from 'react';
import { MODULES } from './DefaultModule';

interface ILayout2 {
  slot1: string;
  slot2: string;
  slot3: string;
}

function Layout2(props: ILayout2) {
  const { slot1, slot2, slot3 } = props;

  const Slot1 = MODULES?.[slot1] || MODULES.default;
  const Slot2 = MODULES?.[slot2] || MODULES.default;
  const Slot3 = MODULES?.[slot3] || MODULES.default;
  return (
    <div className="flex flex-col gap-5">
      <Slot1 />
      <div className="flex gap-5">
        <Slot2 />
        <Slot3 />
      </div>
    </div>
  );
}

export default React.memo(Layout2);
