import { Col, Form, Row } from 'antd';
import ModalCustom from 'components/Modal';
import moment from 'moment';
import { useState } from 'react';

const ScheduledMeetingModal = (props: any) => {
  const { visible, onCancel, rowData = {} } = props;
  const [form] = Form.useForm();
  const [isKeepOpen, setIsKeepOpen] = useState(true);
  const [loading, setLoading] = useState(false);


  const handleCancel = () => {
    onCancel();
    form.resetFields();
    setIsKeepOpen(true);
    setLoading(false);
  };

  return (
    <ModalCustom
      title='Scheduled Online Meeting'
      okText="Start Meeting"
      cancelText="Cancel"
      visible={visible}
      onCancel={handleCancel}
      onSubmit={() => {
        window.open(rowData?.zoomLink)
      }}
      titleCenter
      contentLeft
      styleCancel={{ border: '1px solid #D1CDCB' }}
      className="modal-event-modified-custom"
      loading={loading}
    >
      <Row className='mt-10'>
        <Col span={10}>Start Date Time:</Col>
        <Col span={14}>
          {moment(rowData.startDate).format('YYYY/MM/DD - h:mm a')}
        </Col>
      </Row>
      <Row>
        <Col span={10}>End Date Time:</Col>
        <Col span={14}>
          {moment(rowData.endDate).format('YYYY/MM/DD - h:mm a')}
        </Col>
      </Row>
      <Row>
        <Col span={10}>Student Name:</Col>
        <Col span={14}>{rowData?.studentName}</Col>
      </Row>
      <Row>
        <Col span={10}>Remark:</Col>
        <Col span={14}>{rowData?.remarks}</Col>
      </Row>
      <Row className='mt-10'>
        <Col span={24}><i>Note: The Start Meeting button will only be active 10 mins prior to start of meeting</i></Col>
      </Row>

    </ModalCustom>
  );
};

export default ScheduledMeetingModal;
