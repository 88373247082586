import React, { useState } from 'react';
import { Button, Empty, Spin, Tag, Typography, notification } from 'antd';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

import Dragger from 'antd/lib/upload/Dragger';
import ReactQuill from 'react-quill';
import { isEmpty } from 'lodash';
import Upload, { RcFile } from 'antd/lib/upload';
import { ERROR_MESSAGE } from 'constants/index';
import { useMutation } from 'react-query';
import { uploadFile } from 'api/user';
import { Base64 } from 'js-base64';
import ModalCustom from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import { createAssignment, updateAssignment } from 'api/assignment';
const { Title } = Typography;

import { v1 } from 'uuid';
import { createAssignmentAttachments } from 'api/assignment_attachments';

export enum QuestionType {
  FREE_TEXT = 'Free text',
}

function ContentTitleByUnit(props: any) {
  const {
    content,
    unitId,
    stateContext,
    assignment,
    refetchGetAllAssignments,
    refetchGetUnitsId,
    assignmentAttachments,
    isTeacher,
  } = props;

  const [listFileUpload, setListFileUpload] = useState<File[]>([]);
  const listFolderSelected = ['assignments', unitId, v1()];
  const [listFileLinks, setListFileLinks] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);

  React.useEffect(() => {
    setListFileLinks(assignmentAttachments?.map((x: any) => x?.filePath));
  }, [assignmentAttachments]);

  const handleReload = () => {
    refetchGetAllAssignments();
    refetchGetUnitsId();
  };

  const { mutateAsync: mutateUploadFile } = useMutation('uploadFile', uploadFile, {});
  const { mutateAsync: mutateCreateAssignment, isLoading: isLoadingCreateAssignment } = useMutation(
    'createAssignment',
    createAssignment,
    {
      onSuccess: () => {
        handleReload();
      },
    },
  );
  const { mutateAsync: mutateUpdateAssignment, isLoading: isLoadingUpdateAssignment } = useMutation(
    'updateAssignment',
    updateAssignment,
    {
      onSuccess: () => {
        handleReload();
      },
    },
  );

  const {
    mutateAsync: mutateCreateAssignmentAttachments,
    isLoading: isCreateAssignmentAttachments,
  } = useMutation('createAssignmentAttachments', createAssignmentAttachments, {
    onSuccess: () => {
      setListFileUpload([]);
    },
  });

  const handleUploadFiletoS3 = (assignmentID: number) => {
    if (isEmpty(listFileUpload)) return;
    const fmData = new FormData();
    listFileUpload.forEach((element: File) => {
      fmData.append('file', element, Base64.encode(element.name, true));
    });
    fmData.append(
      'path',
      `${listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}` : ''}`,
    );
    return mutateUploadFile(fmData).then((res: any) => {
      setListFileLinks((s) => [...s, ...res.data]);
      mutateCreateAssignmentAttachments(
        res.data?.map((x: any) => ({
          filePath: x,
          assignmentID: assignmentID,
        })),
      );
    });
  };
  const onBeforeUpload = (file: RcFile) => {
    if (file?.size > 1048576 * 1048576) {
      notification.error({ message: ERROR_MESSAGE.LIMIT_SIZE });
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const onUploadFile = (file: File) => {
    setListFileUpload((prevState: File[]) => [...prevState, file]);
  };

  const customRequest = (options: any) => {
    const { onSuccess, file } = options;
    onSuccess('Ok');
    onUploadFile(file);
  };
  const onRemoveUpload = () => {};
  const [value, setValue] = useState(assignment?.summary);
  const navigate = useNavigate();

  const handleSaveAssignment = () => {
    if (!value) {
      setIsError(true);
      return;
    }
    if (isEmpty(assignment)) {
      mutateCreateAssignment({
        summary: value,
        unitID: +unitId,
        studentID: stateContext?.user?.student?.id,
        status: 1,
      })
        .then((res) => {
          handleUploadFiletoS3(res?.data?.id);
        })
        .then(() => {
          notification.success({ message: 'Save success!' });
        });
    } else {
      mutateUpdateAssignment({
        id: assignment.id,
        summary: value,
      })
        .then((res) => {
          handleUploadFiletoS3(res?.data?.id);
        })
        .then(() => {
          notification.success({ message: 'Save success!' });
        });
    }
  };

  const handleSubmitAssignment = () => {
    if (!value) {
      setIsError(true);
      return;
    }
    if (isEmpty(assignment)) {
      mutateCreateAssignment({
        summary: value,
        unitID: +unitId,
        studentID: stateContext?.user?.student?.id,
        status: 1,
        submissionDate: new Date(),
      })
        .then((res) => {
          handleUploadFiletoS3(res?.data?.id);
        })
        .then(() => {
          notification.success({ message: 'Submit success!' });
        });
    } else {
      mutateUpdateAssignment({
        id: assignment.id,
        summary: value,
        submissionDate: new Date(),
      })
        .then((res) => {
          handleUploadFiletoS3(res?.data?.id);
        })
        .then(() => {
          notification.success({ message: 'Submit success!' });
        });
    }
  };

  const handleMakeCompleted = () => {
    mutateUpdateAssignment({
      id: assignment.id,
      isMarked: true,
      markedDate: new Date(),
      markedByTeacherID: stateContext?.user?.teacher?.id,
    }).then(() => {
      notification.success({ message: 'Marke completed success!' });
    });
  };

  return isEmpty(content) ? (
    <Empty />
  ) : (
    <div className="flex flex-col gap-4">
      {content?.map((x: any) => (
        <div key={x.id}>
          <div className="mt-5 p-8 bg-white rounded-xl">
            <Title level={4}>Assignment instructions</Title>
            {!!x.contentTitle ? (
              <div dangerouslySetInnerHTML={{ __html: x.contentTitle }}></div>
            ) : (
              <Empty />
            )}
          </div>
          <div className="mt-5 p-8 bg-white rounded-xl">
            <div className="">
              <div>Summary</div>
              {isError && <div className="color-red-500">Complete this field</div>}
              {assignment?.submissionDate || isTeacher ? (
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{ __html: assignment?.summary }}
                ></div>
              ) : (
                <ReactQuill
                  className="mt-2"
                  style={{ height: 200 }}
                  theme="snow"
                  value={value}
                  onChange={setValue}
                />
              )}
            </div>

            <div className="mt-16">
              <div className="mb-2">Attachments</div>
              {!isTeacher && (
                <Dragger
                  multiple
                  beforeUpload={onBeforeUpload}
                  customRequest={customRequest}
                  onRemove={onRemoveUpload}
                  disabled={assignment?.submissionDate}
                  className="py-12"
                  fileList={listFileUpload.map((element: File, index: number) => ({
                    uid: `${index}_${element.name}`,
                    name: element.name as string,
                  }))}
                >
                  <div className="lg:max-w-fit m-auto">
                    <Button
                      style={{ minWidth: 200, maxWidth: 200 }}
                      icon={<UploadIcon className="icon-button" />}
                      className="styte_button_outline my-0"
                      size="middle"
                    >
                      &nbsp; Click or drop file
                    </Button>
                  </div>
                </Dragger>
              )}
              {!isEmpty(listFileLinks) && (
                <div className="flex gap-2 mt-5">
                  {listFileLinks?.map((y, i) => (
                    <Tag key={i}>
                      <a target="_bank" href={y}>
                        File {i + 1}
                      </a>
                    </Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="flex items-center justify-between mt-8">
              <div></div>
              <div className="flex gap-3">
                <Button
                  style={{ minWidth: 120 }}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="styte_button_outline_light"
                  size="middle"
                >
                  Cancel
                </Button>
                {!isTeacher && (
                  <Button
                    onClick={handleSaveAssignment}
                    className={
                      assignment?.submissionDate ? 'rounded-2xl' : 'styte_button_primary my-0'
                    }
                    style={{ minWidth: 120 }}
                    size="middle"
                    disabled={assignment?.submissionDate}
                    loading={
                      isLoadingCreateAssignment ||
                      isLoadingUpdateAssignment ||
                      isCreateAssignmentAttachments
                    }
                  >
                    Save
                  </Button>
                )}
                {!isTeacher && (
                  <ModalCustom
                    className="custom-width"
                    cancelText="Cancel"
                    viewComponent={
                      <Button
                        disabled={assignment?.submissionDate}
                        style={{ minWidth: 120 }}
                        className={
                          assignment?.submissionDate ? 'rounded-2xl' : 'styte_button_primary my-0'
                        }
                        size="middle"
                        loading={
                          isLoadingCreateAssignment ||
                          isLoadingUpdateAssignment ||
                          isCreateAssignmentAttachments
                        }
                      >
                        {assignment?.submissionDate ? 'Submited' : 'Submit'}
                      </Button>
                    }
                    titleCenter
                    okText="Confirm"
                    content={`Are you sure want to submit assignment?`}
                    title="Confirmation"
                    onSubmit={handleSubmitAssignment}
                  />
                )}
                {isTeacher && !assignment.isMarked && (
                  <Button
                    onClick={handleMakeCompleted}
                    className={
                      !assignment?.submissionDate ? 'rounded-2xl' : 'styte_button_primary my-0'
                    }
                    style={{ minWidth: 150 }}
                    size="middle"
                    disabled={!assignment?.submissionDate}
                    loading={
                      isLoadingCreateAssignment ||
                      isLoadingUpdateAssignment ||
                      isCreateAssignmentAttachments
                    }
                  >
                    Marke Completed
                  </Button>
                )}

                {isTeacher && assignment.isMarked && (
                  <Button
                    className={'styte_button_primary my-0'}
                    style={{ minWidth: 150 }}
                    size="middle"
                    loading={
                      isLoadingCreateAssignment ||
                      isLoadingUpdateAssignment ||
                      isCreateAssignmentAttachments
                    }
                  >
                    Completed
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default React.memo(ContentTitleByUnit);
