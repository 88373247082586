import { Spin, Typography, Empty } from 'antd';
import { getAllSessionByClassModule } from 'api/session';
import SecctionByModule from 'components/SecctionByModule';
import { useQuery } from 'react-query';
const { Title } = Typography;

import { sortBy } from 'lodash';
import { useRef } from 'react';
import { useIsInViewport } from 'hooks';

interface IModuleByCourse {
  id: number;
  moduleCode: string;
  moduleName: string;
  status: string;
  classId: number;
}

function ModuleByCourse(props: IModuleByCourse) {
  const { classId, moduleName, id } = props;
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const { data: dataSessions, isLoading } = useQuery({
    queryKey: ['getAllSessionByClassModule', id, classId],
    queryFn: () =>
      getAllSessionByClassModule({
        limit: 10,
        page: 1,
        filters: JSON.stringify([{ moduleID: id, classID: classId }]),
      }),
    enabled: isInViewport,
  });

  return (
    <div ref={ref} className="bg-white p-8 rounded-xl">
      <Title level={3}>{moduleName}</Title>
      <Spin spinning={isLoading} tip="Loading...">
        <div className="flex flex-col">
          {!!dataSessions?.data?.length ? (
            sortBy(dataSessions?.data, 'id')?.map((x: any) => (
              <SecctionByModule key={x.id} {...x} />
            ))
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </div>
  );
}

export default ModuleByCourse;
