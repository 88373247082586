import { Layout, Radio, Space, notification } from 'antd';
import ModalCustom from 'components/Modal';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import './custom-configrure.css';
import { useLocation } from 'react-router-dom';
import { routerUserAccess } from 'helper/function';
import ButtonCustom from 'components/Button';
import { useMutation, useQuery } from 'react-query';
import { getAllDasboards, getAllDashboardModules, updateDashBoardLayout, updateDashBoardModule } from 'api/dashboard_module_user';
import { AppContext } from 'context';
import { getDashboardModuleUser } from 'api/dashboard_module_user';

export default function Configure() {
  const [visibleHeader, setVisibleHeader] = useState(false);
  const [visibleDashboard, setVisibleDashboard] = useState(false);
  const [visibleDashboardLayout, setVisibleDashboardLayout] = useState(false);
  const [titleDashbord, setTitleDashboard] = useState<string>('');
  const [dashBoardData, setDashBoardData] = useState([]);
  const [dashBoardModules, setDashBoardModules] = useState([]);
  const [radioButtonValue, setRadioButtonValue] = useState(undefined);
  const [layoutZone, setLayoutZone] = useState(1);

  const [stateContext]: any = useContext(AppContext);
  const { data: userDashboards, isLoading, refetch } = useQuery(
    ['getDashboardModuleUser'],
    () => getDashboardModuleUser(stateContext?.user?.id),
    { enabled: !!stateContext?.user?.id },
  );

  const handleHeader = () => {
    setLayoutZone(1);
    setVisibleHeader(true);
  };
  const handleConfigure = () => {
    setVisibleDashboardLayout(true);
  }
  const location = useLocation();
  const routerUser = routerUserAccess();
  const [checkRoleUser, setCheckRoleUser] = useState(true);

  const { mutate: mutateGetDashboards } = useMutation('getAllDasboards', getAllDasboards, {
    onSuccess: ({ data }: { data: any }) => {
      setDashBoardData(data);
    },
  });

  const { mutate: mutateGetDashboardModules } = useMutation('getAllDasboardModules', getAllDashboardModules, {
    onSuccess: ({ data }: { data: any }) => {
      setDashBoardModules(data);
    },
  });

  const { mutateAsync: mutateUpdateDashBoardModule } = useMutation('updateDashBoardModule', updateDashBoardModule, {
    onSuccess: ({ data }: { data: any }) => {
      console.log('## mutateUpdateDashBoardModule', data);
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  const { mutateAsync: mutateUpdateDashBoardLayout } = useMutation('updateDashBoardLayout', updateDashBoardLayout, {
    onSuccess: ({ data }: { data: any }) => {
      console.log('## mutateUpdateDashBoardModule', data);
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  useEffect(() => {
    mutateGetDashboards();
    mutateGetDashboardModules();
  }, []);

  useEffect(() => {
    if (!routerUser.listRoute.length) setCheckRoleUser(true);
    else setCheckRoleUser(routerUser.listRoute.some((item: string) => location.pathname === item));
  }, [routerUser.listRoute.length]);

  const userDashboardId = userDashboards?.data?.[0]?.dashboardID;
  const userDashboardModuleID = userDashboards?.data?.[0]?.dashboardModuleID;
  const userId = stateContext?.user?.id;

  const ref = useRef(1);
  const configLayoutRef = useRef(1);

  const handleRadioButtonChange = (e: any) => {
    if (e.target.checked) {
      ref.current = e.target.value;
      setRadioButtonValue(e.target.name);
    } else {
      setRadioButtonValue(undefined);
    }
  };
  const handleRadioButtonLayoutChange = (e: any) => {
    if (e.target.checked) {
      configLayoutRef.current = e.target.value;
      setRadioButtonValue(e.target.name);
    } else {
      setRadioButtonValue(undefined);
    }
  };

  const handleUpdateDashBoardModule = () => {
    mutateUpdateDashBoardModule({
      userId,
      data: {
        dashboardID: userDashboardId,
        dashboardModuleID: ref.current,
        layoutZone,
      },
    }).then(() => {
      notification.success({ message: 'Save success!' })
    });
  };

  const handleUpdateDashBoardLayout = () => {
    mutateUpdateDashBoardLayout({
      userId,
      dashboardId: configLayoutRef.current,
    }).then(() => {
      refetch();
      notification.success({ message: 'Save success!' })
    });
  };

  const ModalHeader = () => {
    return (
      <ModalCustom
        visible={visibleHeader}
        cancelText="Cancel"
        onCancel={() => {
          setVisibleHeader(false);
        }}
        okText="Update"
        onSubmit={handleUpdateDashBoardModule}
        title="Header Dashboard"
        titleCenter
        contentLeft
        content={
          <Radio.Group>
            <Space direction="vertical">
              {dashBoardModules.length > 0 ? (
                dashBoardModules.map((item: any, key) => {
                  return (
                    <Radio
                      className="custom-radio py-[8px]"
                      key={key}
                      onChange={handleRadioButtonChange}
                      value={item.dashboardModuleCode}
                    >
                      {item.dashboardModuleName}
                    </Radio>
                  );
                })
              ) : (
                <></>
              )}
            </Space>
          </Radio.Group>
        }
      />
    );
  };

  const ModalDashboard = (props: any) => {
    const { title } = props;
    return (
      <ModalCustom
        visible={visibleDashboard}
        cancelText="Cancel"
        onCancel={() => {
          setVisibleDashboard(false);
        }}
        okText="Update"
        onSubmit={handleUpdateDashBoardModule}
        title={title}
        titleCenter
        contentLeft
        // styleCancel={"text-black"}
        content={
          <Radio.Group>
            <Space direction="vertical">
              {dashBoardModules.length > 0 ? (
                dashBoardModules.map((item: any, key) => {
                  return (
                    <Radio
                      className="custom-radio py-[8x]"
                      key={key}
                      onChange={handleRadioButtonChange}
                      value={item.dashboardModuleCode}
                    >
                      {item.dashboardModuleName}
                    </Radio>
                  );
                })
              ) : (
                <></>
              )}
            </Space>
          </Radio.Group>
        }
      />
    );
  };

  const ModalDashboardLayout = (props: any) => {
    return (
      <ModalCustom
        visible={visibleDashboardLayout}
        cancelText="Cancel"
        onCancel={() => {
          setVisibleDashboardLayout(false);
        }}
        okText="Save"
        onSubmit={handleUpdateDashBoardLayout}
        title="Choose Layout"
        titleCenter
        contentLeft
        // styleCancel={"text-black"}
        content={
          <Radio.Group>
            <Space direction="vertical">
              {dashBoardData.length > 0 ? (
                dashBoardData.map((item: any, key) => {
                  return (
                    <Radio
                      className="custom-radio py-[8x]"
                      key={key}
                      onChange={handleRadioButtonLayoutChange}
                      value={item.id}
                    >
                      <img className="w-full h-[162px] rounded-2xl object-cover" src={item.layoutImageUrl} />
                      {item.dashboardName}
                    </Radio>
                  );
                })
              ) : (
                <></>
              )}
            </Space>
          </Radio.Group>
        }
      />
    );
  };

  const renderFirstSlot: any = () => {
    return (
      <div
        className="w-full h-[320px] px-[72px] py-[64px] bg-[#FCECD9] rounded-3xl mt-8"
        onClick={handleHeader}
      >
        <p className="font-meriweather font-bold text-2xl leading-8">
          Press to select the dashboard you prefer
        </p>
        <p className="font-meriweather font-normal text-lg leading-7">
          Maybe default as Announcement for this
        </p>
      </div>
    );
  }
  const renderSecondSlot: any = () => {
    return (
      <div
        className="basis-1/3 rounded-3xl h-full bg-[#FBE4D7] px-[72px] py-[64px] font-bold text-2xl leading-8"
        onClick={() => {
          setLayoutZone(2);
          setVisibleDashboard(true);
          setTitleDashboard('Body 1 Dashboard');
        }}
      >
        Press to select the dashboard you prefer
      </div>
    );
  }
  const renderThirdSlot: any = () => {
    return (
      <div
        className="basis-1/3 rounded-3xl h-full bg-[#E6F2F2] px-[72px] py-[64px] font-bold text-2xl leading-8"
        onClick={() => {
          setLayoutZone(3);
          setVisibleDashboard(true);
          setTitleDashboard('Body 2 Dashboard');
        }}
      >
        Press to select the dashboard you prefer
      </div>
    );
  }
  const renderFourthSlot: any = () => {
    return (
      <div
        className="basis-1/3 rounded-3xl h-full bg-[#FCECD9] px-[72px] py-[64px] font-bold text-2xl leading-8"
        onClick={() => {
          setLayoutZone(4);
          setVisibleDashboard(true);
          setTitleDashboard('Body 3 Dashboard');
        }}
      >
        Press to select the dashboard you prefer
      </div>
    );
  }

  const renderLayoutOne = () => {
    return (
      <div className="flex flex-col gap-3">
        {renderFirstSlot()}
        <div className="flex gap-3 min-h-[240px] pb-[30px] font-meriweather custom-configrure">
          {renderSecondSlot()}
          {renderThirdSlot()}
          {renderFourthSlot()}
        </div>
      </div>
    );
  }

  const renderLayoutTwo = () => {
    return (
      <div className="flex flex-col gap-3">
        {renderFirstSlot()}
        <div className="flex gap-3 w-full">
          <div>
            {renderSecondSlot()}
          </div>
          <div>
            {renderThirdSlot()}
          </div>
        </div>
      </div>
    );
  }

  const renderLayoutThree = () => {
    return (
      <div className="flex flex-col gap-3">
        {renderFirstSlot()}
        {renderSecondSlot()}
        {renderThirdSlot()}
      </div>
    );
  }

  return (
    <>
      {checkRoleUser ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <div>
            <div className="font-meriweather font-bold text-3xl w-full h-[36px] m-[5px]">
              Configure Dashboard
            </div>
            {userDashboardId === 1 && renderLayoutOne()}
            {userDashboardId === 2 && renderLayoutTwo()}
            {userDashboardId === 3 && renderLayoutThree()}
            <div className="flex gap-x-3 justify-end mt-5">
              <ButtonCustom
                color="orange"
                onClick={handleConfigure}
              >
                Configure
              </ButtonCustom>
            </div>
          </div>
          <ModalHeader />
          <ModalDashboard title={titleDashbord} />
          <ModalDashboardLayout title={titleDashbord} />
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
}
