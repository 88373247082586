import { getCourseModules } from 'api/courses';
import { useQuery } from 'react-query';
import ModuleByCourse from 'components/ModuleByCourse';
import { sortBy } from 'lodash';

interface ICourseByStudent {
  courseId: number;
  classId: number;
}
function CourseByStudent(props: ICourseByStudent) {
  const { courseId, classId } = props;

  const { data: dataCourseModules } = useQuery(['getCourseModules', courseId], () =>
    getCourseModules(+courseId),
  );

  return (
    <div className="flex flex-col gap-5">
      {!!dataCourseModules?.data?.length &&
        sortBy(dataCourseModules?.data, 'id').map((x: any) => (
          <ModuleByCourse key={`module-${x.id}`} {...x} classId={classId} />
        ))}
    </div>
  );
}

export default CourseByStudent;
