import { Row, Col } from 'antd';
import CustomTooltip from 'components/Tooltip';
import './style.css';
import { ReactComponent as ImageIcon } from 'assets/images/imageIcon.svg';
import { ReactComponent as CsvIcon } from 'assets/images/CSV.svg';
import { ReactComponent as CsvImage } from 'assets/images/CSV 1.svg';
import { ReactComponent as Exel } from 'assets/images/Exel.svg';
import { ReactComponent as ExelImage } from 'assets/images/Exel1.svg';
import { ReactComponent as WordImage } from 'assets/images/Word 1.svg';
import { ReactComponent as PdfImage } from 'assets/images/Pdf 1.svg';
import { ReactComponent as Pdf } from 'assets/images/Pdf.svg';
import { ReactComponent as Word } from 'assets/images/Word.svg';
import { ReactComponent as Ai } from 'assets/images/Ai18.svg';
import { ReactComponent as Mp4 } from 'assets/images/Mp4 18.svg';
import { ReactComponent as AiImage } from 'assets/images/Ai64.svg';
import { ReactComponent as Mp4Image } from 'assets/images/Mp4 64.svg';
import { ICardFile } from 'pages/admin/my-drive/own-drive';

interface IProps {
  data: ICardFile;
  pathToModule?: string;
  isShowImage: boolean;
  onRedirect?: (id: number) => void;
}

const CardImage = (props: IProps) => {
  const { data, isShowImage, onRedirect } = props;
  const file = data.name.slice(data.name.lastIndexOf('.'));
  const defindIconFile = () => {
    switch (true) {
      case file.includes('xls'):
        return <Exel />;

      case file.includes('doc'):
        return <Word />;

      case file.includes('ai'):
        return <Ai />;

      case file.includes('mp4'):
        return <Mp4 />;

      case file.includes('ppt') || file.includes('pdf'):
        return <Pdf />;

      case file.includes('svg') || file.includes('png') || file.includes('jpg'):
        return <ImageIcon />;

      default:
        return <CsvIcon />;
    }
  };

  return (
    <Row
      className={`rounded-3xl p-4 bg-white shadow-[0px_8px_32px_rgba(0,0,0,0.04)] h-full border border-solid border-transparent hover:!border-main-button-color cursor-pointer`}
      onClick={() => onRedirect && onRedirect(Number(1))}
    >
      <Col className="w-full">
        {isShowImage && (
          <div className="box-privew flex justtify-center items-center">
            {file.includes('svg') || file.includes('png') || file.includes('jpg') ? (
              <img className="rounded-2xl object-cover" src={data.urlFile} />
            ) : file.includes('xlsx') ? (
              <ExelImage />
            ) : file.includes('doc') ? (
              <WordImage />
            ) : file.includes('ppt') || file.includes('pdf') ? (
              <PdfImage />
            ) : file.includes('ai') ? (
              <AiImage />
            ) : file.includes('mp4') ? (
              <Mp4Image />
            ) : (
              <CsvImage />
            )}
          </div>
        )}
        <div className="flex justify-between w-full mt-4">
          <div className="flex gap-2 w-[90%]">
            <div className="self-center">
              {/* <ImageIcon /> */}
              {defindIconFile()}
            </div>
            <div className="w-[20px] h-[20px]" />
            <div className="w-[80%]">
              <CustomTooltip title={data.name}>
                <h5 className="font-semibold  custom-font line-height-20 text-lg font-fontFamily overflow-hidden text-ellipsis whitespace-nowrap w-fit max-w-full">
                  {data.name}
                </h5>
              </CustomTooltip>
              {isShowImage && (
                <p className="m-0 line-height-18 custom-font font-weight-400">
                  {data.date} - {data.nameShare}
                </p>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CardImage;
