import { Layout } from 'antd';
import { getCourseDetail, getCourseModules, getCoursePrice } from 'api/courses';
import { DATE_FORMAT, DATE_FORMAT_TWO, ROUTES } from 'constants/constants';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonCustom from 'components/Button';

export interface ICourseDetail {
  id: number;
  courseName: string;
  catalogImageUrl?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  learningMethod?: string;
  programType?: string;
}

const SubscriptionDetail = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [dataCourses, setDataCourses] = useState<ICourseDetail>();
  const [dataModules, setDataModules] = useState<any>();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>();
  const [coursesPrice, setCoursesPrice] = useState<any>();

  const { mutate: getCourse } = useMutation('getCourseDetail', getCourseDetail, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      const formData = {
        id: data?.id,
        courseName: data?.courseName,
        learningMethod: data?.learningMethod,
        programType: data?.programType,
        description: data?.description,
        catalogImageUrl: data?.catalogImageUrl,
        startDate: data?.startDate,
        endDate: data?.endDate,
      };
      localStorage.setItem('currentCourse', JSON.stringify(formData));
      setDataCourses(formData);
    },
  });

  const { mutate: mutateCoursePrice } = useMutation('getCoursePrice', getCoursePrice, {
    onSuccess: ({ data }: { data: any }) => {
      console.log(data);
      const formData = {
        id: data?.coursePrice.id,
        coursePrice: data?.coursePrice.coursePrice,
        startDate: data?.coursePrice.startDate,
        endDate: data?.coursePrice.endDate,
      };
      localStorage.setItem("currentCoursePrice", JSON.stringify(formData));
      setCoursesPrice(formData);
    },
  });

  const { mutate: getCourseModule } = useMutation('getCourseModule', getCourseModules, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      console.log("Modules:", data)
      setDataModules(data);
    },
  });

  const setFormFields = () => {
    let subscriptionDetail: any = localStorage.getItem('subscriptionDetails');
    subscriptionDetail = JSON.parse(subscriptionDetail);
    console.log(subscriptionDetail);
    const formValue = {
      username: subscriptionDetail.username,
      fullName: subscriptionDetail.fullName,
      ICNumber: subscriptionDetail.ICNumber,
      gender: subscriptionDetail.gender,
      nationality: subscriptionDetail.nationality,
      address1: subscriptionDetail.address1,
      address2: subscriptionDetail.address2,
      country: subscriptionDetail.country,
      postalCode: subscriptionDetail.postalCode,
      dateOfBirth: moment(subscriptionDetail.dateOfBirth).format(DATE_FORMAT_TWO),
      preferredCenter: subscriptionDetail.preferredCenter,
      academicYear: subscriptionDetail.academicYear,
      studentDisease: subscriptionDetail.studentDisease,
      studentAllergy: subscriptionDetail.studentAllergy,
      coursesPrice: coursesPrice?.coursePrice
    };

    setSubscriptionDetails(formValue);
  }

  useEffect(() => {
    if (id) {
      getCourse(Number(id));
      getCourseModule(Number(id));
      mutateCoursePrice(Number(id));
      console.log("Details::", localStorage.getItem('subscriptionDetails'));
      setFormFields();
    }
  }, [id]);

  useEffect(() => {
    console.log(coursesPrice);
  }, [coursesPrice]);

  useEffect(() => {
    console.log(subscriptionDetails);
  }, [subscriptionDetails]);

  const handleOpenPayment = useCallback(() => {
    if (id) {
      history(`${ROUTES.available_course}/${id}/subscription-payment`);
    }
  }, [id]);

  const handleCancel = useCallback(() => {
    history(`${ROUTES.available_course}`);
    return;
  }, []);

  return (
    <Layout id="course-detail-student" className="bg-transparent flex flex-col gap-y-6">
      <div className="flex justify-between items-center bg-transparent px-0 custom-class-info">
        <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 custom-font-header">
          <span className="text-[#AEA8A5] cursor-pointer" onClick={() => history(ROUTES.available_course)}>
            Available Courses
          </span>{' '}
          / {dataCourses?.courseName}
          / Payment
        </p>
      </div>
      <div className="flex items-start flex-row p-8 gap-10 bg-[#FFFFFF] rounded-3xl custom-class-info">
        <div>
          <img className="w-[320px] h-[240px] rounded-2xl" src={dataCourses?.catalogImageUrl} />
        </div>
        <div>
          <p className="text-2xl font-bold text-[#32302D] custom-font-header">
            {dataCourses?.courseName}
          </p>
          <p className="font-semibold text-[#6E6B68] text-lg">{dataCourses?.description}</p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Duration: {moment(dataCourses?.startDate).format(DATE_FORMAT)} to{' '}
            {moment(dataCourses?.endDate).format(DATE_FORMAT)}
          </p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Learning Method: {dataCourses?.learningMethod}
          </p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Program Type: {dataCourses?.programType}
          </p>
          <p className="text-1xl font-bold text-[#32302D] custom-font-header">
            Brief Course Content/Modules
          </p>
          <div className='flex flex-wrap'>
            {dataModules?.length > 0 &&
              dataModules?.map((item: any) => (
                <p className='w-[40%] gap-1 module-box mr-3 text-center aqtique-bg' key={item.id}>{item.moduleName}</p>
              ))}
          </div>
          <div>
            <p className="text-1xl font-bold text-[#32302D] custom-font-header">
              Student Details
            </p>
            <table>
              <tr>
                <td className='w-[40%]'>
                  <p>Student Name</p>
                  <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.fullName}</p>
                </td>
                <td className='w-[40%]'>
                  <p>ICNumber</p>
                  <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.ICNumber}</p>
                </td>
              </tr>
            </table>
            <tr>
              <td className='w-[40%]'>
                <p>Gender</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.gender}</p>
              </td>
              <td className='w-[40%]'>
                <p>Nationality</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.nationality}</p>
              </td>
            </tr>
            <tr>
              <td className='w-[40%]'>
                <p>Address 2</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.address1}</p>
              </td>
              <td className='w-[40%]'>
                <p>Address 1</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.address2}</p>
              </td>
            </tr>
            <tr>
              <td className='w-[40%]'>
                <p>Country</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.country}</p>
              </td>
              <td className='w-[40%]'>
                <p>Postal Code</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.postalCode}</p>
              </td>
            </tr>
            <tr >
              <td className='w-[40%]'>
                <p>Date of Birth</p>
                <p className='gap-1 w-[100%] module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.dateOfBirth}</p>
              </td>
              <td className='w-[40%]'>
                <p>Preferred Center</p>
                <p className='gap-1 w-[100%] module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.preferredCenter}</p>
              </td>
            </tr>
            <tr>
              <td className='w-[40%]'>
                <p>Academic Year</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.academicYear}</p>
              </td>
            </tr>
            <tr>
              <td className='w-[40%]'>
                <p>Does student have a disease that require the teachers attention?</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.studentDisease}</p>
              </td>
              <td className='w-[40%]'>
                <p>Does student suffer from any allergies to any type of medication?</p>
                <p className='gap-1 module-box mr-3 text-center aqtique-bg'>{subscriptionDetails?.studentAllergy}</p>
              </td>
            </tr>
          </div>
          <div>
            <p className="text-1xl font-bold text-[#32302D] custom-font-header">
              Total Payment: ${coursesPrice?.coursePrice}
            </p>

          </div>
          <div className="gap-[10px] flex justify-end flex-wrap cus-width-my-info">
            <>
              <ButtonCustom color="outline" onClick={handleCancel}>
                Cancel
              </ButtonCustom>
              <ButtonCustom onClick={handleOpenPayment} color="orange">
                Proceed
              </ButtonCustom>
            </>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubscriptionDetail;
