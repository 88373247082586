import { Button, Layout } from 'antd';
import { IUserUnit, searchUnits, UnitType } from 'api/content_management';
import ButtonCustom from 'components/Button';
import Loading from 'components/Loading';
import { ROUTES } from 'constants/constants';
import { RoleName } from 'enum/role';
import { routerUserAccess } from 'helper/function';
import { ContentType, IUnit } from 'pages/admin/content-management/session/add-content-session';
import {
  initialUnitOptions,
  IUnitOptions,
  shuffle,
} from 'pages/admin/content-management/session/add-content-session/CreateUnit/Test/TestConstantsAndInterface';
import TestPreview from 'pages/admin/content-management/session/add-content-session/CreateUnit/Test/TestPreview';
import MyCoursePreviewCheckbox from 'pages/admin/courses/my-course/my-course-detail/my-course-session/content/Checkbox';
import MyCoursePreviewQuestion from 'pages/admin/courses/my-course/my-course-detail/my-course-session/content/Question';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ScormPreview from 'pages/admin/courses/my-course/my-course-detail/my-course-session/content/SCORM';

interface IProps {
  topicId?: string;
  sessionId?: string;
  moduleId?: string;
}

const PreviewContentCreationComponent = (props: IProps) => {
  const history = useNavigate();
  const { topicId, sessionId, moduleId } = props;
  const routerUser = routerUserAccess();

  const [listUnits, setListUnits] = useState<IUnit[]>([]);
  const [unitChoose, setUnitChoose] = useState<IUnit>();

  const { mutate: getUnits, isLoading: isSearchingUnits } = useMutation(
    'searchUnits',
    searchUnits,
    {
      onSuccess: ({
        data,
      }: {
        data: { records: IUnit[]; total: number; page: number; limit: number };
      }) => {
        const tempUnits =
          data.records?.map((x, index) => ({
            ...x,
            userUnits: [],
          })) || [];
        if (tempUnits && tempUnits.length > 0) {
          setListUnits(tempUnits);
          setUnitChoose(tempUnits[0]);
        } else {
          setListUnits([]);
          setUnitChoose(undefined);
        }
      },
    },
  );
  const handleExit = () => {
    history(-1);
  };

  const handleClickContinue = useCallback(
    (result: boolean, studentAnswers?: any, correctAnswers?: any, savedAnswers?: any) => {
      if (unitChoose) {
        if (listUnits?.length > 0) {
          const currentIndex = listUnits.findIndex((x) => x.id === unitChoose.id);

          if (unitChoose.unitType !== UnitType.TEST) {
            const tempUserUnit: IUserUnit = {
              isCompleted: true,
              isPassed: true,
            };
            const fakeArr: IUserUnit[] = [];
            fakeArr.push(tempUserUnit);
            const tempListUnits = [...listUnits];
            tempListUnits[currentIndex].userUnits = fakeArr;
            setListUnits(tempListUnits);
          }

          if (currentIndex < listUnits.length - 1) {
            const temp = listUnits[currentIndex + 1];
            setUnitChoose(temp);
          } else {
            handleExit();
          }
        }
      }
    },
    [listUnits, unitChoose],
  );

  const handleClickBack = useCallback(
    (result: boolean, studentAnswers?: any, correctAnswers?: any, savedAnswers?: any) => {
      if (unitChoose) {
        if (unitChoose.unitType !== UnitType.TEST) {
        }

        if (listUnits?.length > 0) {
          const currentIndex = listUnits.findIndex((x) => x.id === unitChoose.id);
          if (currentIndex < listUnits.length && currentIndex > 0) {
            const temp = listUnits[currentIndex - 1];
            setUnitChoose(temp);
          }
        }
      }
    },
    [listUnits, unitChoose],
  );

  const handleSaveTestResultTemp = (data: IUserUnit) => {
    if (unitChoose && listUnits?.length > 0 && unitChoose.unitType === UnitType.TEST) {
      const currentIndex = listUnits.findIndex((x) => x.id === unitChoose.id);
      const tempListUnits = [...listUnits];
      const fakeArr: IUserUnit[] = [];
      const fakeExistUserUnit = tempListUnits[currentIndex].userUnits;
      fakeArr.push({
        ...(fakeExistUserUnit && fakeExistUserUnit[0] ? fakeExistUserUnit[0] : undefined),
        ...data,
      });
      tempListUnits[currentIndex].userUnits = fakeArr;
      setListUnits(tempListUnits);
    }
  };

  const renderPreview = useCallback(() => {
    if (unitChoose) {
      const currentIndex = listUnits.findIndex((x) => x.id === unitChoose.id);
      const savedContent: IUserUnit =
        unitChoose.userUnits && unitChoose.userUnits.length > 0 ? unitChoose.userUnits[0] : null;
      switch (unitChoose.unitType) {
        case UnitType.SCORM_XAPI_CMI5: {
          return (
            <ScormPreview
              currentIndex={currentIndex}
              handleExit={handleExit}
              handleClickContinue={handleClickContinue}
              handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
              isSpecialContent={unitChoose?.isUploadedFile ? true : false}
              {...unitChoose}
            ></ScormPreview>
          );
        }
        case UnitType.CONTENT:
          if (unitChoose.content && unitChoose.content.length > 0) {
            const content = {
              ...unitChoose.content[0],
              isCompleted: savedContent?.isCompleted || false,
              isPassed: savedContent?.isPassed || false,
            };
            if (content.contentType === ContentType.QUESTION) {
              return (
                <MyCoursePreviewQuestion
                  unit={unitChoose}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={unitChoose?.isUploadedFile ? true : false}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            } else {
              return (
                <MyCoursePreviewCheckbox
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={unitChoose?.isUploadedFile ? true : false}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            }
          } else {
            if (unitChoose?.isUploadedFile) {
              return (
                <MyCoursePreviewCheckbox
                  unit={unitChoose}
                  content={undefined}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={unitChoose?.isUploadedFile ? true : false}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            }
            return (
              <>
                <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                  <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div>Class content</div>
                      <div>Video / Quic / Short answers / presentation etc</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center gap-3">
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                    onClick={handleExit}
                  >
                    Exit
                  </Button>
                  {currentIndex > 0 && (
                    <Button
                      className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                      onClick={() => handleClickBack(true)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickContinue(true)}
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }
        case UnitType.WEB_CONTENT:
          if (unitChoose.content && unitChoose.content.length > 0) {
            const content = {
              ...unitChoose.content[0],
              isCompleted: savedContent?.isCompleted || false,
              isPassed: savedContent?.isPassed || false,
            };
            if (content.contentType === ContentType.QUESTION) {
              return (
                <MyCoursePreviewQuestion
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            } else {
              return (
                <MyCoursePreviewCheckbox
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            }
          } else {
            return (
              <>
                <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                  <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div>Class content</div>
                      <div>Video / Quic / Short answers / presentation etc</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center gap-3">
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                    onClick={handleExit}
                  >
                    Exit
                  </Button>
                  {currentIndex > 0 && (
                    <Button
                      className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                      onClick={() => handleClickBack(true)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickContinue(true)}
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }
        case UnitType.VIDEO:
          if (unitChoose.content && unitChoose.content.length > 0) {
            const content = {
              ...unitChoose.content[0],
              isCompleted: savedContent?.isCompleted || false,
              isPassed: savedContent?.isPassed || false,
            };
            if (content.contentType === ContentType.QUESTION) {
              return (
                <MyCoursePreviewQuestion
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            } else {
              return (
                <MyCoursePreviewCheckbox
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            }
          } else {
            return (
              <>
                <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                  <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div>Class content</div>
                      <div>Video / Quic / Short answers / presentation etc</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center gap-3">
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                    onClick={handleExit}
                  >
                    Exit
                  </Button>
                  {currentIndex > 0 && (
                    <Button
                      className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                      onClick={() => handleClickBack(true)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickContinue(true)}
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }
        case UnitType.AUDIO:
          if (unitChoose.content && unitChoose.content.length > 0) {
            const content = {
              ...unitChoose.content[0],
              isCompleted: savedContent?.isCompleted || false,
              isPassed: savedContent?.isPassed || false,
            };
            if (content.contentType === ContentType.QUESTION) {
              return (
                <MyCoursePreviewQuestion
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            } else {
              return (
                <MyCoursePreviewCheckbox
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            }
          } else {
            return (
              <>
                <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                  <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div>Class content</div>
                      <div>Video / Quic / Short answers / presentation etc</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center gap-3">
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                    onClick={handleExit}
                  >
                    Exit
                  </Button>
                  {currentIndex > 0 && (
                    <Button
                      className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                      onClick={() => handleClickBack(true)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickContinue(true)}
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }
        case UnitType.DOCUMENT:
          if (unitChoose.content && unitChoose.content.length > 0) {
            const content = {
              ...unitChoose.content[0],
              isCompleted: savedContent?.isCompleted || false,
              isPassed: savedContent?.isPassed || false,
            };
            if (content.contentType === ContentType.QUESTION) {
              return (
                <MyCoursePreviewQuestion
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            } else {
              return (
                <MyCoursePreviewCheckbox
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  isSpecialContent={true}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            }
          } else {
            return (
              <>
                <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                  <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div>Class content</div>
                      <div>Video / Quic / Short answers / presentation etc</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center gap-3">
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                    onClick={handleExit}
                  >
                    Exit
                  </Button>
                  {currentIndex > 0 && (
                    <Button
                      className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                      onClick={() => handleClickBack(true)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickContinue(true)}
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }
        case UnitType.ASSIGNMENT:
          if (unitChoose.content && unitChoose.content.length > 0) {
            const content = {
              ...unitChoose.content[0],
              isCompleted: savedContent?.isCompleted || false,
              isPassed: savedContent?.isPassed || false,
            };
            if (content.contentType === ContentType.QUESTION) {
              return (
                <MyCoursePreviewQuestion
                  unit={unitChoose}
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            } else {
              return (
                <MyCoursePreviewCheckbox
                  // content={{
                  //   ...listContentsAnswered[currentIndex],
                  //   ...content,
                  // }}
                  content={{
                    ...(savedContent && savedContent.savedData
                      ? JSON.parse(savedContent.savedData)
                      : {}),
                    ...content,
                  }}
                  handleClickContinue={handleClickContinue}
                  handleClickBack={currentIndex > 0 ? handleClickBack : undefined}
                  // shufflePossibleAnswers={true}
                  listTestOptions={{
                    ...initialUnitOptions,
                    shufflePossibleAnswers: true,
                    checkNotContinueUntilCorrectAnswer: true,
                  }}
                />
              );
            }
          } else {
            return (
              <>
                <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                  <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
                    <div className="text-center">
                      <div>Class content</div>
                      <div>Video / Quic / Short answers / presentation etc</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center gap-3">
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                    onClick={handleExit}
                  >
                    Exit
                  </Button>
                  {currentIndex > 0 && (
                    <Button
                      className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                      onClick={() => handleClickBack(true)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickContinue(true)}
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }
        case UnitType.TEST:
          const listTestOptions: IUnitOptions =
            unitChoose.unitOptions && unitChoose.unitOptions.length > 0
              ? unitChoose.unitOptions[0]
              : initialUnitOptions;
          console.log('listTestOptions choose', listTestOptions);
          return (
            <>
              <div className="w-full bg-white border-solid border-transparent rounded-2xl min-height-60vh p-8">
                <TestPreview
                  listQuestionsAdded={
                    unitChoose.unitContents
                      ? listTestOptions && listTestOptions.shuffleQuestions
                        ? shuffle(unitChoose.unitContents)
                        : unitChoose.unitContents.sort((a, b) => {
                            if (a.order && b.order && a.order > b.order) {
                              return 1;
                            }
                            if (a.order && b.order && a.order < b.order) {
                              return -1;
                            }
                            return 0;
                          })
                      : []
                  }
                  isPreviewQuestion={false}
                  setIsPreviewQuestion={() => {}}
                  listTestOptions={listTestOptions}
                  isModal={false}
                  handleClickContinueCustom={() => handleClickContinue(true)}
                  selectedUnit={unitChoose}
                  handleSetListUnitsAfterSaveResultTest={() => {}}
                  handleSaveTestViewAsStudent={handleSaveTestResultTemp}
                />
              </div>
              <div className="flex justify-end items-center gap-3">
                <Button
                  className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                  onClick={handleExit}
                >
                  Exit
                </Button>
                {currentIndex > 0 && (
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickBack(true)}
                  >
                    Back
                  </Button>
                )}
                <Button
                  className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                  onClick={() => handleClickContinue(true)}
                >
                  Continue
                </Button>
              </div>
            </>
          );
        case UnitType.IFRAME:
          return (
            <>
              <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                <div className="text-base text-[#6E6B68] flex w-full flex-col justify-center items-center h-full">
                  <div className="text-center w-full">
                    {
                      unitChoose.urlContent == 'embed' ? <iframe
                        style={{ width: '100%' }}
                        src={unitChoose.url}
                        className='h-[60vh] w-full'
                      ></iframe> : <Button onClick={() => { window.open(`${unitChoose.url}`, '_blank', 'width=600,height=400') }}>Open Popup</Button>

                    }


                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center gap-3">
                <Button
                  className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                  onClick={handleExit}
                >
                  Exit
                </Button>
                {currentIndex > 0 && (
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickBack(true)}
                  >
                    Back
                  </Button>
                )}
                <Button
                  className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                  onClick={() => handleClickContinue(true)}
                >
                  Continue
                </Button>
              </div>
            </>
          );
        default:
          return (
            <>
              <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
                <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
                  <div className="text-center">
                    <div>Class content</div>
                    <div>Video / Quic / Short answers / presentation etc</div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center gap-3">
                <Button
                  className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
                  onClick={handleExit}
                >
                  Exit
                </Button>
                {currentIndex > 0 && (
                  <Button
                    className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                    onClick={() => handleClickBack(true)}
                  >
                    Back
                  </Button>
                )}
                <Button
                  className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
                  onClick={() => handleClickContinue(true)}
                >
                  Continue
                </Button>
              </div>
            </>
          );
      }
    } else {
      return (
        <>
          <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
            <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
              <div className="text-center">
                <div>Class content</div>
                <div>Video / Quic / Short answers / presentation etc</div>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center gap-3">
            <Button
              className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
              onClick={handleExit}
            >
              Exit
            </Button>
          </div>
        </>
      );
    }
  }, [unitChoose, listUnits]);

  useEffect(() => {
    if (sessionId && !sessionId.includes('create-new')) {
      getUnits({
        limit: 9999,
        order: 'ASC',
        sort: 'order',
        filters: JSON.stringify([
          Object.fromEntries(
            Object.entries({
              sessionID: Number(sessionId),
            }).filter(([, v]) => (v as any)?.toString() !== ''),
          ),
        ]),
      });
    }
  }, [sessionId]);

  return (
    <>
      {listUnits.length === 0 ? (
        <>
          <div className="flex justify-between items-center bg-transparent px-0">
            <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0">
              Content Creation
            </p>

            {/* <ButtonCustom
          color="outline"
          onClick={() => {
            history(
              ROUTES.community_library +
                `/topic/${topicId}/module/${moduleId}/session/${sessionId}/overview`,
            );
          }}
        >
          Exit
        </ButtonCustom> */}
          </div>
          <div className="w-full border-solid border-[#D1CDCB] rounded-2xl h-[60vh]">
            <div className="text-base text-[#6E6B68] flex flex-col justify-center items-center h-full">
              <div className="text-center">
                <div>Class content</div>
                <div>Video / Quic / Short answers / presentation etc</div>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center gap-3">
            <Button
              className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
              onClick={handleExit}
            >
              Exit
            </Button>
            <Button
              className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
              onClick={() => handleClickContinue(true)}
            >
              Continue
            </Button>
          </div>
        </>
      ) : routerUser.userRole !== RoleName.STUDENT &&
        (listUnits.length > 0
          ? routerUser.userRole === RoleName.ADMIN || routerUser.userRole === RoleName.SUPER_ADMIN
            ? true
            : listUnits[0]?.session?.authorID !== routerUser.id
            ? listUnits[0]?.session?.authorization === 'Public'
            : true
          : false) ? (
        <>
          <div className="flex justify-between items-center bg-transparent px-0">
            <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0">
              Content Creation
            </p>

            {/* <ButtonCustom
          color="outline"
          onClick={() => {
            history(
              ROUTES.community_library +
                `/topic/${topicId}/module/${moduleId}/session/${sessionId}/overview`,
            );
          }}
        >
          Exit
        </ButtonCustom> */}
          </div>
          <div className="flex flex-col gap-4">
            <Loading isLoading={false}>{renderPreview()}</Loading>
          </div>
        </>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default PreviewContentCreationComponent;
