import { Col, Dropdown, Form, Input, Layout, Menu, Row, Select, notification } from 'antd';
import images from 'assets/images';
import ButtonCustom from 'components/Button';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
const { Header, Content } = Layout;
import Loading from 'components/Loading';

import { IListCourse, InfoType } from 'constants/types';
import CertificateCardItem from 'components/CertificateCardItem';
import { getCertByStudentId } from 'api/class';
import { AppContext } from 'context';

export interface ICertificateCardItem {
  id: number;
  title: string;
  thumbnail?: string;
  thumbnailPath?: string;
  quantity: number;
}

const Certificate = () => {
  const [form] = Form.useForm();
  const [listCertificates, setListCertificates] = useState<ICertificateCardItem[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<InfoType[]>([]);
  const [stateContext]: any = useContext(AppContext);
  const studentId = stateContext?.user?.student?.id;
  console.log(stateContext);
  console.log('studentId', studentId);

  const { mutate: mutateGetCertByStudentId, isLoading: isLoadingStudents } = useMutation('getCertByStudentId', getCertByStudentId, {
    onSuccess: ({ data }: { data: any }) => {
      console.log('## list cert', data);
      // const { students: fetchedStudents } = data;
      // console.log('data oagij', data);
      // console.log('## fetchedStudents', fetchedStudents);
      // setListCertificates(fetchedStudents);
      // console.log('mutateGetStudentsByClassId time')
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  useEffect(() => {
    if (studentId) {
      mutateGetCertByStudentId({
        studentId: Number(studentId),
      });
    }
  }, [studentId]);

  useEffect(() => {
    console.log('run useEffect');
   
  }, []);


  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      {/* <Header className="flex items-center bg-transparent px-0 header-trash">
        <p
          onClick={() => {
            setLoading(true);
            form.resetFields();
            setListFolderSelected([]);
          }}
          className={`custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 ${listFolderSelected?.length > 0 ? 'color-Earth-5 point' : ''
            }`}
        >
          Certificate
        </p>
        
        {!!listFolderSelected?.length &&
          listFolderSelected.map((element: string, index: number) => (
            <p
              onClick={() => {
                rollBackFolder(index);
              }}
              key={index}
              className={`custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 ${listFolderSelected?.length - 1 !== index ? 'color-Earth-5 point' : ''
                }`}
            >
              &nbsp;/ {element}
            </p>
          ))}
      </Header> */}
      <Row className="flex justify-between items-center bg-transparent px-0 ">
        <Col className="mb-2 mr-2">
          <p className="custom-font-header text-[1.75rem] font-fontFamily custom-font-header leading-9 font-bold mb-0">
            Certificate
          </p>
        </Col>
        <Col className="mb-2 flex">
          <ButtonCustom
            className="custom-pading"
            color="orange"
          >
            Download
          </ButtonCustom>
          <ButtonCustom
            className="custom-pading ml-3"
            color="orange"
          >
            Download All
          </ButtonCustom>
        </Col>
      </Row>
      <Content className="flex flex-col gap-y-6">
        <div className="filter-card">
          <Form
            className="w-full"
            name="basic"
            autoComplete="off"
            // onFinish={handleSearch}
            form={form}
          >
            <Row className="flex gap-4 items-center">
              {/* <Col className="w-[calc(60%_-_0.67rem)]" xs={24} sm={17} md={17} lg={17} xl={17}> */}
              <Col className="w-[calc(50%_-_0.5rem)]">
                <Dropdown
                  trigger={['click']}
                  // overlay={searchResult}
                  placement="bottomRight"
                  className="w-full h-full"
                >
                  <Form.Item name="search" className="flex-1 mb-0">
                    <Input
                      placeholder="Search"
                      suffix={<img src={images.search} alt="search" />}
                      className="style_input_custom_login_page"
                    // onChange={(value: ChangeEvent<HTMLInputElement>) =>
                    //    handleChangeSearch(value)
                    //   }
                    />
                  </Form.Item>
                </Dropdown>
              </Col>
              <Col className="w-[calc(20%_-_0.67rem)]">
                <Form.Item className="mb-0" name="courseId">
                  <Select
                    className="w-fit-content h-full relative "
                    placeholder="All Courses"
                    allowClear
                  >
                    {(courses || [])?.map((course) => (
                      <Select.Option value={course.id} key={course.id}>
                        {course.courseName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="w-[calc(20%_-_0.67rem)]">
                <Form.Item className="mb-0">
                  <ButtonCustom className="h-12" htmlType="submit" color="orange">
                    Search
                  </ButtonCustom>
                </Form.Item>
              </Col>
              <ButtonCustom
                isWidthFitContent
                className="h-12"
                color="outline"
              >
                Reset
              </ButtonCustom>
            </Row>
          </Form>
        </div>

        <div className="cus-mt-1_25rem">
          <Loading isLoading={isLoading}>
            <Row className="gap-4">
              {listCertificates?.map((element: ICertificateCardItem) => (
                <Col
                  className="w-336"
                  key={element.id}
                  onClick={() => {

                  }}
                >
                  <CertificateCardItem
                    onChangeSelect={() => { }}
                    data={element}
                    checkAll={true}
                    onRedirect={() => { }}
                    setMessageWarning={() => { }}
                  />
                </Col>
              ))}
            </Row>
          </Loading>
        </div>
      </Content>
    </Layout>
  );
};

export default Certificate;
