import { Form, Input, Select, notification } from 'antd';
import { createOnlineMeeting } from 'api/online_meetings';
import ModalCustom from 'components/Modal';
import RangePickerCustom from 'components/RangePicker';
import { CLASS_NAME_FIELD } from 'constants/index';
import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

const ScheduleMeetingModal = (props: any) => {
  const { visible, onCancel, onReload, optionsStudent, teacherId, classId } = props;
  const [form] = Form.useForm();
  const [duration, setDuration] = useState<[Moment, Moment]>();
  const [loading, setLoading] = useState(false);
  const studentId = Form.useWatch('studentId', form);
  const remark = Form.useWatch('remark', form);

  const handleCancel = () => {
    onReload();
    onCancel();
    form.resetFields();
    setDuration(undefined);
    setLoading(false);
  };

  const { mutateAsync: mutateCreateOnlineMeeting } = useMutation('createOnlineMeeting', createOnlineMeeting);

  const onFinish = useCallback(
    (formValues: {
      studentId: string;
      remark: string;
      duration: [Moment, Moment];
      guests: string[];
    }) => {
      if (!duration) {
        form.setFields([
          {
            name: 'duration',
            errors: ['Duration is required!'],
          },
        ]);

        return;
      }

      setLoading(true);
      mutateCreateOnlineMeeting({
        teacherId: Number(teacherId),
        classId: Number(classId),
        studentId: Number(studentId) || Number(formValues.studentId),
        startTime: moment(duration?.[0], 'YYYY-MM-DD HH:mm A').toDate(),
        endTime: moment(duration?.[1], 'YYYY-MM-DD HH:mm A').toDate(),
        remarks: remark || formValues.remark,
      })
        .then((response) => {
          handleCancel();
          onReload();
          notification.success({ message: 'Create the meeting successfully!' })
        })
        .catch((error) => {
          notification.error({ message: 'Please configure your zoom link before scheduling a zoom meetup! ' })
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [studentId, remark, duration],
  );



  const handleChangeDuration = (newDuration: [Moment, Moment]) => {
    setDuration(newDuration);
  };

  return (
    <ModalCustom
      title='New Online Meeting'
      okText="Save"
      cancelText="Cancel"
      visible={visible}
      onCancel={handleCancel}
      onSubmit={form.submit}
      titleCenter
      contentLeft
      styleCancel={{ border: '1px solid #D1CDCB' }}
      className="modal-event-modified-custom"
      loading={loading}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Duration"
          required
          name="duration"
          rules={[{ required: !!!duration, message: 'Duration is required' }]}
        >
          <RangePickerCustom
            form={form}
            placeholder={['Start date time', 'End date time']}
            dateFormat="YYYY/MM/DD hh:mm a"
            timeFormat="hh:mm A"
            duration={duration}
            onChangeDuration={handleChangeDuration}
            dropdownClassname="sm:top-[10%] sm:fixed"
          />
        </Form.Item>
        <Form.Item
          name="studentId"
          label="Student Name"
          required
          className="Student Name"
          rules={[{ required: true, message: 'Student name is required!' }]}
        >
          <Select
            options={optionsStudent}
            className="new_announcement_select_class popup__guest rounded-xl"
            filterOption={(input, option: any = {}) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          ></Select>
        </Form.Item>
        <Form.Item name="remark" label="Remark " required className="mb-0" rules={[{ required: true, message: 'Student name is required!' }]}>
          <Input maxLength={200} className={`${CLASS_NAME_FIELD} rounded-xl`} />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default ScheduleMeetingModal;
