import { Dropdown, Form, Input, Layout, Select, TablePaginationConfig } from 'antd';
import { getAllAvailableCourses, getAllCoursesStudent, getCourseProgramType, getLearningMode, getListCategories } from 'api/courses';
import images from 'assets/images';
import TopicDefault from 'assets/images/topic-default.jpg';
import ButtonCustom from 'components/Button';
import CardResult from './component/CardResult';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import { ICategory, PARAMS_SELECT_SEARCH, ROUTES, TEXT_SELECT_SEARCH } from 'constants/index';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import './cus-available-course-style.css';
export interface ICardItemCourse {
  id: number;
  key?: string;
  title: string;
  categoryTitle: string;
  learningMethod: string;
  programType: string;
  thumbnail?: string;
}

interface ICourse {
  id: number;
  courseName: string;
  learningMethod: string;
  programType: string;
  courseCategory: any;
  categoryId: number;
  catalogImageUrl: string;
}

const AvailableCourse = () => {
  const history = useNavigate();
  const timeout: any = useRef(null);
  const [form] = Form.useForm();
  const [listCourses, setListCourses] = useState<ICardItemCourse[]>([]);
  // const [searchResult, setSearchResult] = useState<{ id: number; courseName: string }[]>([]);
  const [limit, setLimit] = useState('9');
  const [filters, setFilters] = useState({});
  const [searchValue, setSearchValue] = useState('');
 // const [statusChoose, setStatus] = useState('');
  const [selectedCategory, setCategory] = useState('');
  const [selectedProgramType, setProgramType] = useState('');
  const [selectedLearningMethod, setLearningMethods] = useState('');

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 9,
  });
  const [searchResults, setSearchResults] = useState<
    Array<{ id: number; courseName: string; }>
  >([]);
  const keySearch = Form.useWatch('search', form);
  const [learningMethods, setLearningMethod] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const [categories, setCategories] = useState<
    { label: string; value: string; isDisabled?: boolean }[]
  >([]);

  const { mutate: searchListCourses } = useMutation('searchTopics', getAllAvailableCourses, {
    onSuccess: ({
      data: coursesData,
    }: {
      data: {
        listCourses: ICourse[];
      };
    }) => {
      const newData = coursesData.listCourses.map((item) => {
        return {
          id: item.id,
          courseName: item.courseName,
          categoryTitle: item.courseCategory?.categoryName,
          learningMethod: item.learningMethod,
          programType: item.programType,
          // categoryId: item.categoryId,
          //className: item.className,
          // classId: item.class_id,
          title: item.courseName
        };
      });
      setSearchResults(newData);
    },
  });

  const { mutate: getLearningMethod } = useMutation('getLearningMode', getLearningMode, {
    onSuccess: ({ data }) => {
      const newOptions = data.map((el: string) => {
        return { label: el, value: el };
      });
      setLearningMethod(newOptions);
    },
  });

  const { mutate: getCategories } = useMutation('getListCategories', getListCategories, {
    onSuccess: ({ data }: { data: { listCategories: ICategory[] } }) => {
      const newOptions = data.listCategories
        .map((el) => {
          return { label: el.categoryName.toString(), value: el.id.toString(), isDisabled: false };
        })
        .concat([{ label: TEXT_SELECT_SEARCH.category, value: '', isDisabled: true }]);
      setCategories(newOptions);
    },
  });

  const { mutate: getCourseProgramTypes } = useMutation(
    'getCourseProgramType',
    getCourseProgramType,
    {
      onSuccess: ({ data }) => {
        const newOptions = data.map((el: string) => {
          return { label: el, value: el };
        });

        setProgramTypes(newOptions);
      },
    },
  );

  const { mutate: getCourses, isLoading } = useMutation('getCourses', getAllAvailableCourses, {
    onSuccess: async ({
      data: courses,
    }: {
      data: { listCourses: ICourse[]; total: number; page: number; limit: number };
    }) => {
      if (courses?.listCourses?.length > 0) {
        const coursesData = await Promise.all(
          courses.listCourses.map(async (item: ICourse) => {
            return {
              id: Number(item.id),
              key: item.id?.toString(),
              title: item.courseName,
              categoryTitle: item.courseCategory?.categoryName,
              learningMethod: item.learningMethod,
              programType: item.programType,
              thumbnail: item?.catalogImageUrl ? item?.catalogImageUrl : TopicDefault,
            };
          }),
        );
        setListCourses(coursesData);
        setPagination({
          ...pagination,
          current: courses.page,
          pageSize: Number(courses.limit),
          total: courses?.total || 0,
        });
        return;
      }
      setListCourses([]);
    },
  });

  // const handleGetListTopics = useCallback(
  //   (page?: number) => {
  //     getCourses({
  //       limit: Number(limit),
  //       page: page ? page : Number(pagination.current),
  //       search: searchValue,
  //       order: renderOrder(),
  //       sort: renderSort(),
  //       filters: JSON.stringify([
  //         Object.fromEntries(
  //           Object.entries({ ...filters, topicType: TopicType.HQ_LIBRARY }).filter(
  //             ([, v]) => (v as string)?.toString() !== '',
  //           ),
  //         ),
  //       ]),
  //     });
  //   },
  //   [limit, pagination.current, searchValue, renderOrder, renderSort, filters],
  // );

  const searchResult = useMemo(
    () => (
      <>
        {keySearch && keySearch !== '' ? (
          <>
            <div className="bg-white rounded-2xl p-4 min-h-[100px]">
              {searchResults?.length > 0 ? (
                searchResults?.map(
                  (role: {
                    id: number;
                    courseName: string;
                  }) => (
                    <div
                      className="py-2 font-fontFamily font-normal cursor-pointer"
                      onClick={() =>
                        history(`${ROUTES.available_course}/${role.id}`)
                      }
                      key={role.id}
                    >
                      {role.courseName}
                    </div>
                  ),
                )
              ) : keySearch ? (
                <div className="text-center font-fontFamily font-normal pt-4 word-break">
                  No results found for “{keySearch}”
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div />
        )}
      </>
    ),
    [searchResults, keySearch],
  ) as ReactElement<string>;

  // const optionsFilter = useMemo(() => {
  //   return [
  //     {
  //       name: 'category',
  //       placeholder: 'All Categories',
  //       className: 'basis-[calc(28.3%_-_0.75rem)] lg:w-[calc(50%_-_0.5rem)] lg:basis-auto',
  //       options: categories,
  //       type: 'select-search',
  //     },
  //     {
  //       name: 'learningMethod',
  //       className: 'basis-[calc(28.3%_-_0.75rem)] lg:w-[calc(50%_-_0.5rem)] lg:basis-auto',
  //       placeholder: 'All Learning Methods',
  //       options: learningMethod,
  //     },
  //     {
  //       name: 'programType',
  //       className: 'basis-[calc(28.3%_-_0.75rem)] lg:w-[calc(50%_-_0.5rem)] lg:basis-auto',
  //       placeholder: 'All Program Types',
  //       options: programTypes,
  //     },
  //   ];
  // }, [learningMethod, programTypes, categories]);
  useEffect(() => {
    getLearningMethod();
    getCourseProgramTypes();
    getCategories(PARAMS_SELECT_SEARCH.category);
  }, []);
  // const onFinish = useCallback(
  //   (values: IFields) => {
  //     setPagination({ ...pagination, pageSize: Number(limit), current: 1 });
  //     setSearchValue(values.search);
  //     setFilters({

  //     });
  //   },
  //   [limit, pagination, order],
  // );

  const debounceSearch = useCallback(
    (value: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        setSearchValue(value);
        searchListCourses({
          limit: Number(limit),
          page: Number(pagination.current),
          search: value,
          filters: JSON.stringify([
            Object.fromEntries(
              //Object.entries({ ...filters, status: statusChoose, category: selectedCategory, programType: selectedProgramType, learningMethod: selectedLearningMethod }).filter(
              Object.entries({ ...filters, category: selectedCategory, programType: selectedProgramType, learningMethod: selectedLearningMethod }).filter(
                ([, v]) => (v as string)?.toString() !== '',
              ),
            ),
          ]),
        });
      }, 100);
    },
    [limit, timeout],
  );

  const handleChangeSearch = useCallback((value: ChangeEvent<HTMLInputElement>) => {
    debounceSearch(value.target.value);
  }, []);

  const onChangeLimit = (value: string) => {
    const total = pagination.total;
    const maxPage = Math.ceil(Number(total) / Number(value));
    setLimit(String(value));
    if (Number(pagination.current) > maxPage) setPagination({ ...pagination, current: maxPage });
    else setPagination(pagination);
  };

  const pageSize = useMemo(() => {
    return Math.ceil(Number(pagination.total) / Number(pagination.pageSize));
  }, [pagination]);

  const handleResetData = useCallback(() => {
    //form.setFieldsValue({ search: '', status: undefined,category: undefined, programType: undefined, learningMethod: undefined });
    form.setFieldsValue({ search: '', category: undefined, programType: undefined, learningMethod: undefined });
    setLimit('9');
    setPagination({
      current: 1,
      pageSize: 9,
    });
    setFilters({});
    setSearchValue('');
   // setStatus('');
    setCategory('');
    setProgramType('');
    setLearningMethods('');
  }, [limit]);

  useEffect(() => {
    getCourses({
      limit: Number(limit),
      page: Number(pagination.current),
      order: 'DESC',
      sort: 'createdAt',
      search: searchValue,
      filters: JSON.stringify([
        Object.fromEntries(
          Object.entries({ ...filters, category: selectedCategory, programType: selectedProgramType, learningMethod: selectedLearningMethod }).filter(
            ([, v]) => (v as string)?.toString() !== '',
          ),
        ),
      ]),
    });
  }, [limit, pagination.current, filters]);

  const handleSearch = useCallback(
    //({ search, status, category, learningMode, programType }: { search: string; status: string, category: string, learningMode: string, programType: string }) => {
    ({ search, category, learningMethod, programType }: { search: string; category: string; learningMethod: string; programType: string; }) => {
      setSearchValue(search);
      //setStatus(status);
      setCategory(category);
      setProgramType(programType);
      setLearningMethods(learningMethod);

      setPagination({
        ...pagination,
        current: 1,
      });
      getCourses({
        limit: Number(limit),
        page: Number(pagination.current),
        order: 'DESC',
        sort: 'createdAt',
        search: search,
        filters: JSON.stringify([
          Object.fromEntries(
            //Object.entries({ ...filters, status, category, programType, learningMode }).filter(
            Object.entries({ ...filters, category, programType, learningMethod }).filter(
              ([, v]) => (v as string)?.toString() !== '',
            ),
          ),
        ]),
      });
    },
    [pagination, limit],
  );

  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      <div className="flex justify-between items-center bg-transparent px-0">
        <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 custom-font-header">
          Available Courses
        </p>
      </div>

      <div className="filter-card">
        <Form
          className="w-full"
          name="basic"
          autoComplete="off"
          onFinish={handleSearch}
          form={form}
        >
          <div className="ant-form ant-form-horizontal w-full flex flex-wrap gap-4 custom-available-course">
            <div className='flex w-full gap-4 relative custom-gap'>
            
            <Dropdown
              trigger={['click']}
              overlay={searchResult}
              placement="bottomRight"
              className="h-full w-[50%] "
            >
              <Form.Item name="search" className="flex-1 mb-0">
                <Input
                  placeholder="Search"
                  suffix={<img src={images.search} alt="search" />}
                  className="style_input_custom_login_page"
                  onChange={(value: ChangeEvent<HTMLInputElement>) => handleChangeSearch(value)}
                />
              </Form.Item>
            </Dropdown>
            <ButtonCustom
              onClick={handleResetData}
              isWidthFitContent
              className="h-12 sm:w-[calc(50%_-_0.5rem)]"
              color="outline"
            >
              Reset
            </ButtonCustom>
            </div>
            {/* <Form.Item className="w-[20%] mb-0 lg:w-full" name="status">
              <Select
                className="input-field"
                // defaultValue=""
                placeholder="Status"
                allowClear
                options={[
                  //   {
                  //     value: '',
                  //     label: 'All Courses',
                  //   },
                  {
                    value: 'Ongoing',
                    label: 'Ongoing',
                  },
                  {
                    value: 'Completed',
                    label: 'Completed',
                  },
                  {
                    value: 'Pending Withdraw',
                    label: 'Pending Withdraw',
                  },
                  {
                    value: 'Pending Transfer',
                    label: 'Pending Transfer',
                  },
                ]}
              />
            </Form.Item> */}
            <div  className='flex w-full gap-4 relative custom-gap'>
            <Form.Item className="w-[25%] mb-0 lg:w-full" name="category">
              <Select
                className="input-field"
                // defaultValue=""
                placeholder="Categories"
                allowClear
                options={categories}
              />
            </Form.Item>
            <Form.Item className="w-[25%] mb-0 lg:w-full" name="learningMethod">
              <Select
                className="input-field"
                // defaultValue=""
                placeholder="Learning Methods"
                allowClear
                options={learningMethods}
              />
            </Form.Item>
            <Form.Item className="w-[25%] mb-0 lg:w-full" name="programType">
              <Select
                className="input-field"
                // defaultValue=""
                placeholder="Program Types"
                allowClear
                options={programTypes}
              />
            </Form.Item>
            <Form.Item className="w-[25%] min-w-fit mb-0 sm:w-[calc(50%_-_0.5rem)]">
              <ButtonCustom className="h-12 min-w-fit w-full" htmlType="submit" color="orange">
                Search
              </ButtonCustom>
            </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      {/* <div className="font-bold text-2xl text-[#32302D]">
        {statusChoose && statusChoose !== '' ? statusChoose : 'All'} Courses
      </div> */}
      <div className="relative top-[-45px]">
        <CardResult
          onRedirect={(id) => {
            history(`${ROUTES.available_course}/${id}?classId=${id}`);
          }}
          data={listCourses}
          pagination={pagination}
          onChangePagination={(page) => {
            setPagination({ ...pagination, current: Number(page) });
          }}
          searchNotFound={
            listCourses?.length > 0 ? undefined : (
              <SearchNotFound isBackgroundWhite text={searchValue} />
            )
          }
          isLoading={isLoading}
          onLastPage={() => {
            setPagination({
              ...pagination,
              current: pageSize,
            });
          }}
          onFirstPage={() => {
            setPagination({ ...pagination, current: 1 });
          }}
          viewItem={{
            onChange: onChangeLimit,
            value: String(limit),
          }}
        />
      </div>
    </Layout>
  );
};

export default AvailableCourse;
