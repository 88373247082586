import React, { useMemo, useState } from 'react';

import { ReactComponent as IconDashOutLine } from 'assets/icons/dash_out_lined.svg';

import style from './style.module.scss';
import { Dropdown, Menu } from 'antd';
import RemoveModal from '../RemoveModal';
import AddEditModal from '../AddEditModal';
import { useNavigate, useParams } from 'react-router-dom';
import { background } from './helper';

interface IProps {
  groupName: string;
  onClick?: any;
  groupId: number;
  handleRefetchingGroups: () => void;
}

const GroupCard = ({ groupName, groupId, handleRefetchingGroups }: IProps) => {
  const [removeModal, setRemoveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editGroup, setEditGroup] = useState({ groupId: -1, groupName: '' });

  const { classId } = useParams();
  const navigate = useNavigate();

  const menu = (
    <Menu
      items={[
        {
          label: 'Edit',
          key: '1',
          onClick: () => {
            setShowModal(true);
            setEditGroup({ groupId, groupName });
          },
        },

        {
          label: 'Delete',
          key: '2',
          onClick: () => setRemoveModal(true),
        },
      ]}
    />
  );

  const randomBackgroundColorMemo = useMemo(
    () => background[Math.floor(Math.random() * background.length)],
    [],
  );

  return (
    <>
      <div
        onClick={() =>
          navigate(`/class-management/${classId}/my-group/${groupId}`, {
            state: {
              groupName: groupName,
            },
          })
        }
        className={`${style.cardWrapper} flex justify-center items-center relative border border-red-800 p-4  ${randomBackgroundColorMemo}`}
      >
        <div
          className={style.icon}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="absolute w-12 h-12 rounded-2xl hover:bg-main-button-color top-3 right-2 dash-theme flex items-center cursor-pointer">
            <Dropdown
              trigger={['click']}
              overlay={menu}
              placement="bottomRight"
              className="w-full h-full"
            >
              <div className="w-full h-full flex justify-center items-center">
                <IconDashOutLine />
                {removeModal && (
                  <RemoveModal
                    removeModal={removeModal}
                    setRemoveModal={setRemoveModal}
                    handleRefetching={handleRefetchingGroups}
                    isRefetching={true}
                    title="Delete Group"
                    groupId={groupId}
                    bodyText=" Are you sure you want to delete this group."
                  />
                )}
                {showModal && (
                  <AddEditModal
                    showModal={showModal}
                    editGroup={editGroup}
                    setEditGroup={setEditGroup}
                    setShowModal={setShowModal}
                    handleRefetchingGroups={handleRefetchingGroups}
                    title={groupName}
                    okButtonText="Update"
                  />
                )}
              </div>
            </Dropdown>
          </div>
        </div>

        <h1 className="font-bold text-2xl text-[#32302D]">{groupName ?? 'Group 1'}</h1>
      </div>
    </>
  );
};

export default GroupCard;
