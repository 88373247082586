import { Layout } from 'antd';
import { ROUTES } from 'constants/index';
import { RoleName } from 'enum/role';

import { routerUserAccess } from 'helper/function';
import PreviewContentCreationComponent from 'pages/admin/hq-library/teacher/session/overview/create-new-content/PreviewContentCreation';
import React from 'react';
import { useParams } from 'react-router-dom';

const CommunityLibraryContentCreation = () => {
  const routerUser = routerUserAccess();
  const { topicId, sessionId, moduleId } = useParams();

  return (
    <React.Fragment>
      {routerUser?.listRoute?.some(
        (route) =>
          (route === ROUTES.community_library_session &&
            location.pathname.includes(ROUTES.community_library_session)) ||
          (route.includes(ROUTES.community_library) &&
            routerUser.userRole === RoleName.SUPER_ADMIN) ||
          (route.includes(ROUTES.community_library) && routerUser.userRole === RoleName.ADMIN) ||
          (location.pathname.includes(ROUTES.community_library_topic) &&
            route === ROUTES.community_library_topic) ||
          (route === ROUTES.community_library_module &&
            location.pathname.includes(ROUTES.community_library_module)),
      ) ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <PreviewContentCreationComponent
            moduleId={moduleId}
            sessionId={sessionId}
            topicId={topicId}
          />
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </React.Fragment>
  );
};

export default CommunityLibraryContentCreation;
