import { Form, FormInstance } from 'antd';
import CustomInput from 'components/Input';
import ModalCustom from 'components/Modal';
import UploadFile from 'components/UploadFile';
import { useCallback, useEffect } from 'react';
import { ICardItem } from '..';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isKeepOpen: boolean;
  form: FormInstance<any>;
  loading: boolean;
  fileName: string;
  type: string;
  timeout?: number;
  isDelete?: boolean;
}

const ModalRemove = (props: IProps) => {
  const {
    visible,
    onCancel,
    onSubmit,
    isKeepOpen,
    form,
    loading,
    fileName,
    type,
    timeout,
    isDelete,
  } = props;

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, []);

  return (
    <ModalCustom
      onCancel={onCancel}
      onSubmit={form.submit}
      visible={visible}
      title={isDelete ? 'Delete' : 'Move To Trash'}
      cancelText="Cancel"
      okText={isDelete ? 'Delete' : 'Move to trash'}
      isKeepOpen={isKeepOpen}
      loading={loading}
      className="text-center"
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <span className="text-center">{`<${type} ${fileName}> will be permanently deleted ${
          isDelete ? '' : `after ${timeout} days.`
        }`}</span>
      </Form>
    </ModalCustom>
  );
};

export default ModalRemove;
