import React, { useCallback, useEffect, useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "../available-course-stripe-card-section";
import ButtonCustom from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { DATE_FORMAT_TWO, ROUTES } from "constants/constants";
import { useMutation } from "react-query";
import { createCoursePayment, createHitpayPaymentRequest } from "api/courses";
import { createCourseRegistration } from "api/courses_by_admission";
import { ICourseRegistration, ICourseRegistrationPayment, IHitpayPayment } from "constants/types";
import moment from "moment";
import { config } from "config";


function PaynowElementsComponent(props: any) {
  const { id } = useParams();
  const history = useNavigate();
  const [stripeData, setStripeData] = useState<any>();
  const [isSubmit, setIsSubmit] = useState(false);

  const handleCancelBtn = useCallback(() => {
    history(`${ROUTES.available_course}`);
  }, []);


  const { mutate: mutateRequestPayment } = useMutation('createHitpayPaymentRequest', createHitpayPaymentRequest, {
    onSuccess: ({ data }: { data: any }) => {
      console.log("mutateRequestPayment:", data);
      const redirectUrl = data.url;
      window.location.href = redirectUrl;
      setIsSubmit(true);
    },
    onError: () => {
      setIsSubmit(false);
    }
  });

  const handlePaynowSubmit = (courseRegistrationId: any) => {
    let subscriptionDetail: any = localStorage.getItem('subscriptionDetails');
    subscriptionDetail = JSON.parse(subscriptionDetail);

    console.log(subscriptionDetail);

    let currentCoursePrice: any = localStorage.getItem('currentCoursePrice');
    currentCoursePrice = JSON.parse(currentCoursePrice);
    const HITPAY_REDIRECT_URL = config.HITPAY_REDIRECT_URL
    const HITPAY_WEBHOOK = config.HITPAY_WEBHOOK
    const requestData: IHitpayPayment = {
      amount: currentCoursePrice.coursePrice,
      payment_methods: ['paynow_online'],
      currency: "SGD",
      email: subscriptionDetail.studentEmail,
      purpose: "Course Registration Payment",
      name: subscriptionDetail.fullName,
      reference_number: courseRegistrationId,
      redirect_url: HITPAY_REDIRECT_URL!,
      webhook: HITPAY_WEBHOOK!
    };

    mutateRequestPayment(requestData);
  };
  const { mutate: handleCreateCourseRegistration } = useMutation('createCourseRegistration', createCourseRegistration, {
    onSuccess: ({ data }: { data: any }) => {
      handlePaynowSubmit(data.id);
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      setIsSubmit(false);
      if (error.response.status == 403) {
        alert("Registration not successful");
      } else {
      }
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmit(true);

    //createCoursePayment 
    let subscriptionDetail: any = localStorage.getItem('subscriptionDetails');
    subscriptionDetail = JSON.parse(subscriptionDetail);
    const dataRequests: ICourseRegistration = {
      firstName: subscriptionDetail.fullName,
      ICNumber: subscriptionDetail.ICNumber,
      gender: subscriptionDetail.gender,
      nationality: subscriptionDetail.nationality,
      address1: subscriptionDetail.address1,
      address2: subscriptionDetail.address2,
      country: subscriptionDetail.country,
      postalCode: subscriptionDetail.postalCode,
      dateOfBirth: moment(subscriptionDetail.dateOfBirth).format(DATE_FORMAT_TWO),
      preferredCenter: subscriptionDetail.preferredCenter,
      academicYear: subscriptionDetail.academicYear,
      studentDisease: subscriptionDetail.studentDisease,
      studentAllergy: subscriptionDetail.studentAllergy,
      additionalNotes: '',
      allergies: Boolean(subscriptionDetail.studentAllergy),
      allergiesDescription: "",
      centreID: Number(subscriptionDetail.preferredCenter),
      classID: 0,
      contactNumber: subscriptionDetail.contactNumber,
      courseID: Number(id),
      disabilities: Boolean(subscriptionDetail.studentDisease),
      disabilitiesDescription: "",
      lastName: subscriptionDetail.fullName.split(" ")[1],
      mobileCountryCode: "65",
      salutation: "Mr",
      status: "Pending",
      studentID: subscriptionDetail.studentID,
      sessionID: 1
    }
    handleCreateCourseRegistration(dataRequests);

  }


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mt-6 mb-6 gap-[10px] flex justify-end flex-wrap cus-width-my-info">
          <>
            <ButtonCustom color="outline" onClick={handleCancelBtn} disabled={isSubmit}>
              Cancel
            </ButtonCustom>
            <ButtonCustom htmlType="submit" color="orange" >
              {!isSubmit ? "Proceed" : "Processing"}
            </ButtonCustom>
          </>
        </div>
      </form>
    </div>
  );
}

export default PaynowElementsComponent;
