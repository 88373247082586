import { Layout, Row, Col, notification } from 'antd';
import TableCustom from 'components/SearchBar/Table';
import CustomTooltip from 'components/Tooltip';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';

import ScheduledMeetingModal from './modals/ScheduledMeetingModal';
import { getMeetingsForUser } from 'api/online_meetings';
import { AppContext } from 'context';
import moment from 'moment';

const MyOnlineMeetings = () => {
  const [visibleScheduledModal, setVisibleScheduledModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [stateContext]: any = useContext(AppContext);
  const userId = stateContext?.user?.id;

  const {
    data: fetchedData,
    isLoading,
    refetch: refetchGetMeetingsForUser,
  } = useQuery(['getMeetingsForUser', userId], () => getMeetingsForUser({ userId: Number(userId) }), {
    enabled: !!userId,
  });

  if (!userId) {
    refetchGetMeetingsForUser();
  }

  const handleScheduledModal = () => {
    setVisibleScheduledModal(true);
  };

  const handleCancelScheduledModal = () => {
    setVisibleScheduledModal(false);
  };

  const columns = [
    {
      title: 'Start Date Time',
      dataIndex: 'startDate',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.startDate}>
              <div className="custom-text-ellipsis">
                {moment(record.startDate).format('YYYY/MM/DD - h:mm a')}
              </div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'End Date Time',
      dataIndex: 'endDate',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.endDate}>
              <div className="custom-text-ellipsis">
                {moment(record.endDate).format('YYYY/MM/DD - h:mm a')}
              </div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.studentName}>
              <div className="custom-text-ellipsis">{record.studentName}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
  ];

  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      <Row className="flex justify-between items-center bg-transparent px-0 mt-5 ">
        <Col className="mb-2 mr-2">
          <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0">
            Online Meetings
          </p>
        </Col>
      </Row>

      <TableCustom
        enableRowSelection={false}
        enableViewItemFilter={false}
        columns={columns}
        data={fetchedData?.data}
        isLoading={isLoading}
        onRow={(record) => ({
          onClick: () => {
            console.log('## row record', record);
            setRowData(record);
            handleScheduledModal();
          },
        })}
      />
      <ScheduledMeetingModal
        visible={visibleScheduledModal}
        onCancel={handleCancelScheduledModal}
        rowData={rowData}
      />
    </Layout>
  );
};

export default MyOnlineMeetings;
