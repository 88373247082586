export const optionsLimit = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
];

export const groupCardData = [
  {
    groupID: 9,
    groupName: 'Class A Group',
    classID: 1,
    updatedAt: '2023-05-30T02:53:31.057Z',
    createdAt: '2023-05-30T02:53:31.057Z',
    userID: 175,
  },
  {
    groupID: 9,
    groupName: 'Class A Group',
    classID: 1,
    updatedAt: '2023-05-30T02:53:31.057Z',
    createdAt: '2023-05-30T02:53:31.057Z',
    userID: 175,
  },
  {
    groupID: 9,
    groupName: 'Class A Group',
    classID: 1,
    updatedAt: '2023-05-30T02:53:31.057Z',
    createdAt: '2023-05-30T02:53:31.057Z',
    userID: 175,
  },
  {
    groupID: 9,
    groupName: 'Class A Group',
    classID: 1,
    updatedAt: '2023-05-30T02:53:31.057Z',
    createdAt: '2023-05-30T02:53:31.057Z',
    userID: 175,
  },
  {
    groupID: 9,
    groupName: 'Class A Group',
    classID: 1,
    updatedAt: '2023-05-30T02:53:31.057Z',
    createdAt: '2023-05-30T02:53:31.057Z',
    userID: 175,
  },
];

interface IGroupsList {
  groupID: number;
  groupName: string;
  classID: number;
  updatedAt: string;
  createdAt: string;
  userID: number;
}
interface IClassDetail {
  id: number;
  createdAt: string;
  updatedAt: string;
  className: string;
  classYear: number;
  classSemester: string;
  capacity: number;
  centreID: number;
  courseID: number;
  startDate: string;
  endDate: string;
  aimsID: number;
}

export interface IGroupListData {
  limit?: number | string;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: string;
  total?: number;
  groups: IGroupsList[];
  classDetails: IClassDetail;
}
