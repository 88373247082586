/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Form, Input, notification } from 'antd';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { IParamsSearch } from 'api/courses';
import ModalCustom from 'components/Modal';
import CustomSelect from 'components/multi-select';
import { searchStudentClassManagement } from 'api/student';
import { createGroup, updateGroup } from 'api/teacher_my_groups';
import { routerUserAccess } from 'helper/function';

import style from './modal.module.scss';

interface Props {
  title: string;
  showModal: boolean;
  okButtonText: string;
  editGroup?: {
    groupId: number;
    groupName: string;
  };
  setEditGroup?: React.Dispatch<
    React.SetStateAction<{
      groupId: number;
      groupName: string;
    }>
  >;
  handleRefetchingGroups?: () => void;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddEditModal = ({
  title,
  showModal,
  editGroup,
  okButtonText,
  setShowModal,
  setEditGroup,
  handleRefetchingGroups,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [studentList, setStudentList] = useState({
    page: 1,
    limit: 5,
    sort: 'createdAt',
    order: 'ASC',
    total: null,
    records: [],
  });
  const [errors, setErrors] = useState({
    isGroupNameError: false,
    isStudentListError: false,
  });
  const [filter, setFilter] = useState<IParamsSearch>({
    page: 1,
    limit: 30,
    sort: 'createdAt',
    order: 'ASC',
    search: '',
  });
  const { classId } = useParams();
  const { teacherData } = routerUserAccess();

  const [form] = Form.useForm();

  const handleChangeSelect = (selectValue: any[] = []): void => {
    setSelectedValue([...selectValue]);
    errors.isStudentListError && setErrors((prev) => ({ ...prev, isStudentListError: false }));
  };

  const handleSearch = (value: string): void => {
    setFilter((prev) => ({ ...prev, search: value }));
  };

  const handleResetEditGroupValue = () => {
    if (setEditGroup) {
      setEditGroup({
        groupId: -1,
        groupName: '',
      });
    }
  };

  const { mutate: mutateGetStudentList, isLoading: selectIsLoading } = useMutation(
    'searchStudentClassManagement',
    searchStudentClassManagement,
    {
      onSuccess: ({ data }: any) => {
        setStudentList({
          page: data.page,
          limit: data.limit,
          sort: data.sort,
          order: data.order,
          total: data.total,
          records: data.records,
        });
      },
    },
  );

  const { mutate, isLoading } = useMutation('createGroup', createGroup, {
    onSuccess: (data) => {
      setShowModal?.(false);
      handleRefetchingGroups?.();
      notification.success({ message: data.message, description: data.data });
    },
    // onError: (error: { response: { data: { message: string } } }) => {

    // },
  });
  const { mutate: updateMutate, isLoading: updateIsLoading } = useMutation(
    'updateGroup',
    updateGroup,
    {
      onSuccess: (data) => {
        setShowModal?.(false);
        handleRefetchingGroups?.();
        handleResetEditGroupValue();
        notification.success({ message: data.message, description: data.data });
      },
      onError: (error: { response: { data: { message: string } } }) => {
        notification.error({ message: 'Error', description: error?.response.data.message });
      },
    },
  );

  const handleSubmit = () => {
    const groupNameValue = form.getFieldValue('groupName');
    const tempError = { ...errors };
    if (!groupNameValue) {
      tempError.isGroupNameError = true;
    } else if (!selectedValue?.length && !editGroup?.groupName) {
      tempError.isStudentListError = true;
    } else if (!tempError.isGroupNameError && !tempError.isStudentListError) {
      if (editGroup && editGroup.groupName) {
        updateMutate({
          groupName: groupNameValue,
          classID: Number(classId),
          teacherID: teacherData?.id,
          groupID: Number(editGroup.groupId),
          studentIDs: selectedValue.map((ele: any) => ele.id),
        });
      } else {
        mutate({
          groupName: groupNameValue,
          classID: Number(classId),
          teacherID: teacherData?.id,
          studentIDs: selectedValue.map((ele: any) => ele.id),
        });
      }
    }
    setErrors(tempError);
  };

  useEffect(() => {
    if (editGroup && editGroup.groupName) {
      form.setFieldValue('groupName', editGroup.groupName);
    }
  }, [editGroup]);

  useEffect(() => {
    let timeout: any = null;
    timeout = setTimeout(() => {
      mutateGetStudentList({
        limit: Number(filter.limit),
        page: filter.page,
        search: filter.search,
        order: filter.order,
        filters: JSON.stringify([{ classID: classId }]),
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [classId, filter.search]);

  return (
    <div>
      <ModalCustom
        cancelText="Cancel"
        visible={showModal}
        onCancel={() => {
          setShowModal?.(false);
          form.setFieldValue('groupName', '');
          setSelectedValue([]);
          handleResetEditGroupValue();
        }}
        okText={okButtonText}
        onSubmit={handleSubmit}
        title={title}
        titleCenter
        loading={isLoading || updateIsLoading}
        isKeepOpen={true}
      >
        <Form form={form} className={style.width}>
          <div className={style.flex}>
            <label className={style.label}>Group Name</label>
            <Form.Item
              name="groupName"
              className="flex-1 mb-0"
              // help="Group name is required"
              // validateStatus=""
              style={{ textAlign: 'left' }}
            >
              <Input
                placeholder="Placeholder"
                onChange={(e) => {
                  errors.isGroupNameError === true &&
                    setErrors((prev) => {
                      return { ...prev, isGroupNameError: false };
                    });
                }}
                // suffix={<img src={images.search} alt="search" />}
                className={`style_input_custom_login_page ${
                  errors.isGroupNameError && style.hasError
                }`}
                // onChange={(value: ChangeEvent<HTMLInputElement>) => handleChangeSearch(value)}
              />
            </Form.Item>
          </div>
          <CustomSelect
            displayKeyValue="fullName"
            options={studentList.records}
            selectedValue={selectedValue}
            selectIsLoading={selectIsLoading}
            className={errors.isStudentListError ? 'hasError' : ''}
            handleSearch={handleSearch}
            setSelectedValue={setSelectedValue}
            handleChangeSelect={handleChangeSelect}
          />
        </Form>
      </ModalCustom>
    </div>
  );
};

export default AddEditModal;
