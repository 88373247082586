import { Form, FormInstance } from 'antd';
import CustomInput from 'components/Input';
import ModalCustom from 'components/Modal';
import { useCallback, useEffect } from 'react';
import { ICardItem } from '..';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: { folderName: string }) => void;
  isKeepOpen: boolean;
  form: FormInstance<any>;
  folder?: ICardItem;
  loading: boolean;
}

const ModalCreateNewFolder = (props: IProps) => {
  const { visible, onCancel, onSubmit, isKeepOpen, form, folder, loading } = props;

  const handleSubmit = useCallback(
    (values: { folderName: string }) => {
      onSubmit(values);
    },
    [folder],
  );

  useEffect(() => {
    if (folder) {
      form.setFieldsValue({ folderName: folder?.folderName });
    }
  }, [folder]);

  return (
    <ModalCustom
      onCancel={onCancel}
      onSubmit={form.submit}
      visible={visible}
      title={'Create New Folder'}
      cancelText="Cancel"
      okText={'Create'}
      isKeepOpen={isKeepOpen}
      loading={loading}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Folder name"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                const v = value && value.trim();
                if (!v) {
                  return Promise.reject('Folder name is required!');
                }
                return Promise.resolve();
              },
            },
          ]}
          name="folderName"
        >
          <CustomInput />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default ModalCreateNewFolder;
