import { Col, Dropdown, Form, Input, Layout, Menu, Row, Select, notification } from 'antd';
import { ListObjectsCommandOutput } from '@aws-sdk/client-s3';
import images from 'assets/images';
import ButtonCustom from 'components/Button';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
const { Header, Content } = Layout;
import './style.css';
import SelectCustom from 'components/Select';
import CardFolder from 'components/CardFolder/CardFolder';
import CardImage from 'components/CardImage';
import { routerUserAccess } from 'helper/function';
import moment from 'moment';
import { AppContext } from 'context';
import Loading from 'components/Loading';
import { Buffer } from 'buffer';
import {
  dowloadFile,
  getListSharedPeople,
  getListUsersShare,
  getObject,
  shareForUser,
} from 'api/own_drive';
import { saveAs } from 'file-saver';
import { SharedWithMeType } from 'constants/index';
import ModalShare from '../own-drive/share';
import ModalDowload from '../own-drive/dowload';
import ModalRename from '../own-drive/rename';
import {
  IParamsListFileAndFolder,
  getListFileAndFolder,
  moveFile,
  moveFolder,
  renameFile,
} from 'api/shared_with_me';

export interface ICardFile {
  name: string;
  date: string;
  nameShare?: string;
  urlFile: string;
  sharerId?: number;
  path?: string;
}

export interface ICardFolder {
  name: string;
  date?: string;
  nameShare?: string;
  sharerId?: number;
  path?: string;
}

export interface ICardUploadFile {
  thumbnailPath: string;
}

export interface IUserShare {
  id: number;
  username: string;
  email: string;
  userProfile: IUserProfileShare;
}

export interface IUserProfileShare {
  firstName: string;
  lastName: string;
  contactNumber: string;
  mobileCountryCode: string;
  profilePhotoDestination: string;
}

export interface ICardItem {
  folderName: string;
}

export interface IReponseSharedWithMe {
  id: number;
  createdAt: string;
  updatedAt: string;
  userID: number;
  path: string;
  sharerId: number;
  dateShare: string;
  sharedType: string;
  folderName?: string;
  fileName?: string;
  sharer: {
    id: number;
    email: string;
    userProfile: {
      firstName: string;
      lastName: string;
      contactNumber: string;
      mobileCountryCode: string;
      profilePhotoDestination: string;
    };
  };
  fileUrl?: string;
}

const SharedWithMe = () => {
  const [form] = Form.useForm();

  const [formRenameFolder] = Form.useForm();
  const [formPreview] = Form.useForm();
  const [formShare] = Form.useForm();
  const routerUser = routerUserAccess();
  const [stateContext]: any = useContext(AppContext);
  const keySearch = Form.useWatch('search', form);

  const [listFolder, setListFolder] = useState<ICardFolder[]>([]);
  const [listFile, setListFile] = useState<ICardFile[]>([]);
  const [sortCondtion, setSortCondition] = useState<string>('A-Z');
  const [urlPreview, setUrlPreview] = useState<string>('');
  const [pathFolder, setPathFoler] = useState<string>();
  const [folderClick, setFolderClick] = useState<ICardFolder>();
  const [fileClick, setFileClick] = useState<ICardFile>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [listFolderSelected, setListFolderSelected] = useState<string[]>([]);

  const [isShowModalRename, setShowModalRename] = useState<boolean>(false);
  const [isShowModalDowload, setShowModalDowload] = useState<boolean>(false);
  const [isShowModalShare, setShowModalShare] = useState<boolean>(false);
  const [listUsersShare, setListUsersShare] = useState<IUserShare[]>([]);

  console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', pathFolder);

  const [searchResults, setSearchResults] = useState<{
    folders: ICardFolder[];
    files: ICardFile[];
  }>();
  const timeout: any = useRef(null);

  const { mutate: searchFileAndFolder } = useMutation('searchRole', getListFileAndFolder, {
    onSuccess: async ({
      data,
    }: {
      data: { listFolders: IReponseSharedWithMe[]; listFiles: IReponseSharedWithMe[] };
    }) => {
      const tempListFoler: ICardFolder[] = [];
      if (data?.listFolders) {
        data.listFolders.forEach((element) => {
          if (element?.folderName) {
            tempListFoler.push({
              name: element.folderName,
              date: element.dateShare,
              nameShare: `${element.sharer.userProfile.firstName} ${element.sharer.userProfile.lastName}`,
              path: element.path,
              sharerId: element.sharerId,
            });
          }
        });
      }

      const tempListFile: ICardFile[] = [];

      if (data?.listFiles) {
        data?.listFiles.forEach((element) => {
          tempListFile.push({
            date: moment(element.dateShare).format('YYYY/MM/DD'),
            name: element.fileName || '',
            nameShare: `${element.sharer.userProfile.firstName} ${element.sharer.userProfile.lastName}`,
            urlFile: element.fileUrl || '',
            sharerId: element.sharerId,
            path: element.path,
          });
        });
      }

      setSearchResults({ files: tempListFile, folders: tempListFoler });
      setLoadingSearch(false);
    },
  });

  const debounceSetSearchFor = useCallback(
    (value: string, listSelect: string[], currentPath: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        const params: IParamsListFileAndFolder = {};
        if (listSelect?.length > 1)
          params.prefix = `${listSelect.filter((_, index: number) => index !== 0).join('/')}`;

        if (currentPath) params.pathFolder = `${currentPath}`;

        if (value) params.fullTextSearch = value;

        searchFileAndFolder(params);
      }, 500);
    },
    [5, timeout],
  );

  const handleChangeSearch = useCallback(
    (value: ChangeEvent<HTMLInputElement>) => {
      setLoadingSearch(true);
      debounceSetSearchFor(value.target.value, listFolderSelected, pathFolder || '');
    },
    [listFolderSelected, pathFolder],
  );

  const downloadBlob = (url: string, name: string) => {
    // // Create a link element
    // const link = document.createElement('a');
    // // Set link's href to point to the Blob URL
    // link.href = url;
    // link.download = name;
    // // Append link to the body
    // // document.body.appendChild(link);
    // // Dispatch click event on the link
    // // This is necessary as link.click() does not work on the latest firefox
    // // link.dispatchEvent(
    // //   new MouseEvent('click', {
    // //     bubbles: true,
    // //     cancelable: true,
    // //     view: window,
    // //   }),
    // // );
    // link.click();
    // // Remove link from body
    // // document.body.removeChild(link);
    saveAs(url, name);
  };

  const { mutate: muteListFileAndFolder } = useMutation(
    'getListFileAndFolder',
    getListFileAndFolder,
    {
      onSuccess: async ({
        data,
      }: {
        data: { listFolders: IReponseSharedWithMe[]; listFiles: IReponseSharedWithMe[] };
      }) => {
        try {
          if (!isLoading) setLoading(true);
          if (data?.listFolders) {
            const tempListFoler: ICardFolder[] = [];

            data.listFolders.forEach((element) => {
              if (element?.folderName) {
                tempListFoler.push({
                  name: element.folderName,
                  date: element.dateShare,
                  nameShare: `${element.sharer.userProfile.firstName} ${element.sharer.userProfile.lastName}`,
                  path: element.path,
                  sharerId: element.sharerId,
                });
              }
            });

            if (sortCondtion === 'Z-A') {
              tempListFoler.sort((a, b) => b.name.localeCompare(a.name));
            } else {
              tempListFoler.sort((a, b) => a.name.localeCompare(b.name));
            }

            setListFolder(tempListFoler);
          } else {
            setListFolder([]);
          }

          if (data?.listFiles) {
            const tempListFile: ICardFile[] = [];
            data?.listFiles.forEach((element) => {
              tempListFile.push({
                date: moment(element.dateShare).format('YYYY/MM/DD'),
                name: element.fileName || '',
                nameShare: `${element.sharer.userProfile.firstName} ${element.sharer.userProfile.lastName}`,
                urlFile: element.fileUrl || '',
                sharerId: element.sharerId,
                path: element.path,
              });
            });
            if (sortCondtion === 'Z-A') {
              tempListFile.sort((a, b) => b.name.localeCompare(a.name));
            } else {
              tempListFile.sort((a, b) => a.name.localeCompare(b.name));
            }
            setListFile(tempListFile);
          } else {
            setListFile([]);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      },
    },
  );

  const fetchS3Infomation = async () => {
    const params: IParamsListFileAndFolder = {};

    if (listFolderSelected?.length > 1)
      params.prefix = `${listFolderSelected.filter((_, index: number) => index !== 0).join('/')}`;

    if (pathFolder) params.pathFolder = `${pathFolder}`;

    // if (sortCondtion) params.filterConditon = `${sortCondtion}`;

    if (keySearch) params.fullTextSearch = `${keySearch}`;

    muteListFileAndFolder(params);
  };

  const { mutate: mutateDowloadFile } = useMutation('uploadFileImage', dowloadFile, {
    onSuccess: async ({ data }: { data: any }) => {
      const buffer = Buffer.from(data);
      const blob = new Blob([buffer]);
      saveAs(blob, `${fileClick?.name}`);
      // downloadBlob(data.fileUrl, `${fileClick}`);
      setFileClick(undefined);
      if (isShowModalDowload) setShowModalDowload(false);
      setLoading(false);
    },
  });

  const { mutate: mutateGetListUsersShare } = useMutation('getListUsersShare', getListUsersShare, {
    onSuccess: async ({
      data,
    }: {
      data: { content: IUserShare[]; total: number; page: number; size: number };
    }) => {
      setListUsersShare(data.content);
    },
  });

  const { mutate: mutatePreviewFile } = useMutation('getObject', getObject, {
    onSuccess: async ({ data }: { data: { fileUrl: string } }) => {
      setUrlPreview(data.fileUrl);
      setShowModalDowload(true);
    },
  });

  const { mutate: moveFolderToNewPath } = useMutation('moveFolder', moveFolder, {
    onSuccess: async () => {
      fetchS3Infomation();
    },
    onError: async (error: { response: { data: { message: string } } }) => {
      setLoading(false);
      notification.error({ message: error.response.data.message });
    },
  });
  const { mutate: moveFileToNewPath } = useMutation('moveFile', moveFile, {
    onSuccess: async () => {
      fetchS3Infomation();
    },
    onError: async (error: { response: { data: { message: string } } }) => {
      setLoading(false);
      notification.error({ message: error.response.data.message });
    },
  });

  const onSearchUser = useCallback(
    (valueSearch: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        mutateGetListUsersShare({ page: 1, limit: 300, search: valueSearch });
      }, 500);
    },
    [5, timeout],
  );

  const handleMoveFolder = (folderName: string) => {
    setLoading(true);
    if (folderClick)
      moveFolderToNewPath({
        sourcePath: `${folderClick.sharerId}/${folderClick.path}${
          listFolderSelected?.length > 1
            ? `/${listFolderSelected.filter((_, index: number) => index !== 0).join('/')}/${
                folderClick?.name
              }`
            : ''
        }`,
        sourceMove: folderName,
        sharerId: folderClick.sharerId || 0,
        isRemove: listFolderSelected?.length > 0,
      });

    if (fileClick)
      moveFileToNewPath({
        sourcePath: `${fileClick.sharerId}/${
          listFolderSelected?.length > 0
            ? `${pathFolder}/${
                listFolderSelected?.length > 1
                  ? `${listFolderSelected.filter((_, index: number) => index !== 0).join('/')}/`
                  : ''
              }${fileClick?.name}`
            : fileClick?.path
        }`.replace('//', '/'),
        sourceMove: `${folderName}/${fileClick.name}`,
        sharerId: fileClick.sharerId || 0,
        isRemove: listFolderSelected?.length > 0,
      });
  };

  const handleDowload = () => {
    setLoading(true);
    mutateDowloadFile({
      key: `${fileClick?.sharerId}/${
        listFolderSelected?.length > 0
          ? `${pathFolder}/${
              listFolderSelected?.length > 1
                ? `${listFolderSelected.filter((_, index: number) => index !== 0).join('/')}/`
                : ''
            }${folderClick?.name || fileClick?.name}`
          : fileClick?.path || folderClick?.path
      }`,
    });
    // const params = {
    //   Bucket: `${process.env.REACT_APP_AWS_BUCKET_NAME}`,
    // Key: `${routerUser.id}${
    //   listFolderSelected?.length > 0 ? `/${listFolderSelected.join('/')}` : ''
    // }/${folderClick}`,
    // };
    // s3Client.getObject(params, (err, data: GetObjectOutput) => {
    //   if (!err) {
    //     const csvBlob = new Blob([data.Body as any]);
    //     downloadBlob(csvBlob, `${folderClick}`);
    //     setFolderClick('');
    //   }
    // });
  };

  const { mutate: mutateGetListSharedPeople } = useMutation(
    'getListSharedPeople',
    getListSharedPeople,
    {
      onSuccess: async ({ data }: { data: number[] }) => {
        formShare.setFieldsValue({ user: data });
      },
    },
  );

  const actionButtonOnChange = (value: string) => {
    switch (true) {
      case value === 'RENAME':
        setShowModalRename(true);
        break;
      case value === 'DOWNLOAD_FILE':
        handleDowload();
        break;
      case value === 'RENAME_FILE':
        formRenameFolder.setFieldsValue({
          folderName: fileClick?.name.replace(
            fileClick?.name.slice(fileClick?.name.lastIndexOf('.')),
            '',
          ),
        });
        setShowModalRename(true);
        break;
      case value === 'SHARE':
        setShowModalShare(true);
        mutateGetListUsersShare({ page: 1, limit: 99 });
        mutateGetListSharedPeople({
          path: `${
            listFolderSelected?.length > 0
              ? `${pathFolder}/${
                  listFolderSelected?.length > 1
                    ? `${listFolderSelected.filter((_, index: number) => index !== 0).join('/')}/`
                    : ''
                }${folderClick?.name || fileClick?.name}`
              : fileClick?.path || folderClick?.path || 0
          }`,
          sharerId: fileClick?.sharerId || folderClick?.sharerId || 0,
        });
        break;

      default:
        break;
    }
  };

  const rollBackFolder = (index: number) => {
    setLoading(true);
    form.resetFields();
    setListFolderSelected((prevState: string[]) =>
      prevState.filter((_, indexState: number) => indexState <= index),
    );
  };

  const { mutate: mutateShareForUser } = useMutation('shareForUser', shareForUser, {
    onSuccess: async () => {
      formShare.resetFields();
      notification.success({ message: 'Share success' });
      setShowModalShare(false);
      fetchS3Infomation();
    },
  });

  const handleShareForUser = (values: { user: number[]; notify: boolean; message: string }) => {
    setLoading(true);
    mutateShareForUser({
      path: `${
        listFolderSelected?.length > 0
          ? `${pathFolder}/${
              listFolderSelected?.length > 1
                ? `${listFolderSelected.filter((_, index: number) => index !== 0).join('/')}/`
                : ''
            }${folderClick?.name || fileClick?.name}`
          : fileClick?.path || folderClick?.path
      }`,
      sharedType: fileClick ? SharedWithMeType.FILE : SharedWithMeType.FOLDER,
      userId: values.user,
      sharerId: fileClick?.sharerId || folderClick?.sharerId || 0,
      message: values.message,
      notify: values.notify,
    });
  };

  const { mutate: mutateRenameFile } = useMutation('renameFile', renameFile, {
    onSuccess: async () => {
      setShowModalRename(false);
      setFileClick(undefined);
      fetchS3Infomation();
      formRenameFolder.resetFields();
    },
    onError: async (error: { response: { data: { message: string } } }) => {
      setLoading(false);
      notification.error({ message: error.response.data.message });
    },
  });

  const handleRenameFile = async (values: { folderName: string }) => {
    if (
      listFile.some(
        (e) =>
          e.name ===
            `${values.folderName}${fileClick?.name?.slice(fileClick?.name?.lastIndexOf('.'))}` &&
          e.name !== fileClick?.name,
      ) &&
      listFolderSelected?.length > 0
    ) {
      return notification.error({
        message: `File ${values.folderName} is already existed`,
      });
    }
    setLoading(true);
    mutateRenameFile({
      newFileName: `${values.folderName}${fileClick?.name.slice(fileClick?.name.lastIndexOf('.'))}`,
      oldPathFile: `${
        listFolderSelected?.length > 0
          ? `${pathFolder}/${
              listFolderSelected?.length > 1
                ? `${listFolderSelected.filter((_, index: number) => index !== 0).join('/')}/`
                : ''
            }${fileClick?.name || folderClick?.name}`
          : fileClick?.path || folderClick?.path
      }`,
      sharerId: fileClick?.sharerId || 0,
    });
  };

  const handleSearch = () => {
    setLoading(true);
    fetchS3Infomation();
  };

  const onClickFolder = (folderName: string) => {
    setListFolderSelected((prevState: string[]) => [...prevState, folderName]);
  };

  const handleFilterByName = (value: string) => {
    setSortCondition(value);
    if (value === 'A-Z') {
      setListFolder((prevState) => prevState.sort((a, b) => a.name.localeCompare(b.name)));
      setListFile((prevState) => prevState.sort((a, b) => a.name.localeCompare(b.name)));
    }

    if (value === 'Z-A') {
      setListFolder((prevState) => prevState.sort((a, b) => b.name.localeCompare(a.name)));
      setListFile((prevState) => prevState.sort((a, b) => b.name.localeCompare(a.name)));
    }
  };

  const searchResult = useMemo(
    () => (
      <>
        {keySearch ? (
          <Loading isLoading={isLoadingSearch}>
            <div className="bg-white rounded-2xl p-t-b-0  p-4 min-h-[100px]">
              {(searchResults?.files && searchResults?.files?.length > 0) ||
              (searchResults?.folders && searchResults?.folders?.length > 0) ? (
                <>
                  {searchResults?.folders.map((element: ICardFolder) => (
                    <CardFolder
                      key={element.name}
                      data={element}
                      onRedirect={() => {
                        form.resetFields();
                        setLoading(true);
                        onClickFolder(element.name);
                        setPathFoler(element.path);
                      }}
                    />
                  ))}
                  {searchResults?.files.map((element: ICardFile) => (
                    <CardImage
                      key={element.name}
                      data={element}
                      isShowImage={false}
                      onRedirect={() => {
                        form.resetFields();
                        // setLoading(false);
                        mutatePreviewFile({
                          key: `${element.sharerId}/${
                            listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''
                          }${element.name}`,
                        });
                      }}
                    />
                  ))}
                </>
              ) : keySearch ? (
                <div className="text-center font-fontFamily font-normal pt-4 word-break">
                  No results found for “{keySearch}”
                </div>
              ) : null}
            </div>
          </Loading>
        ) : (
          <div />
        )}
      </>
    ),
    [searchResults, keySearch],
  ) as ReactElement<string>;

  useEffect(() => {
    if (routerUser?.id) {
      fetchS3Infomation();
    }
  }, [routerUser?.id, listFolderSelected]);

  const [checkRoleUser, setCheckRoleUser] = useState(true);
  useEffect(() => {
    if (routerUser.listRoute.length === 0) setCheckRoleUser(true);
    else setCheckRoleUser(routerUser.listRoute.some((item: string) => location.pathname === item));
  }, [routerUser.listRoute.length]);

  return (
    <>
      {checkRoleUser ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <Header className="flex flex-wrap items-center bg-transparent px-0 header-trash">
            <p
              onClick={() => {
                setLoading(true);
                form.resetFields();
                setListFolderSelected([]);
                setPathFoler(undefined);
              }}
              className={`custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 ${
                listFolderSelected?.length > 0 ? 'color-Earth-5 point' : ''
              }`}
            >
              Shared with me
            </p>
            {!!listFolderSelected?.length &&
              listFolderSelected.map((element: string, index: number) => (
                <p
                  onClick={() => {
                    rollBackFolder(index);
                  }}
                  key={index}
                  className={`custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold  mb-0 ${
                    listFolderSelected?.length - 1 !== index ? 'color-Earth-5 point' : ''
                  }`}
                >
                  &nbsp;/ {element}
                </p>
              ))}
          </Header>
          <Content className="flex flex-col gap-y-6">
            <div className="filter-card">
              <Form
                className="w-full"
                name="basic"
                autoComplete="off"
                onFinish={handleSearch}
                form={form}
              >
                <Row className="flex gap-4 items-center">
                  {/* <Col className="w-[calc(60%_-_0.67rem)]" xs={24} sm={17} md={17} lg={17} xl={17}> */}
                  <Col className="w-[calc(60%_-_0.67rem)]">
                    <Dropdown
                      trigger={['click']}
                      overlay={searchResult}
                      placement="bottomRight"
                      className="w-full h-full"
                    >
                      <Form.Item name="search" className="flex-1 mb-0">
                        <Input
                          placeholder="Search"
                          suffix={<img src={images.search} alt="search" />}
                          className="style_input_custom_login_page"
                          onChange={(value: ChangeEvent<HTMLInputElement>) =>
                            handleChangeSearch(value)
                          }
                        />
                      </Form.Item>
                    </Dropdown>
                  </Col>
                  {/* <Col className="w-[calc(20%_-_0.67rem)]" xs={24} sm={4} md={4} lg={4} xl={4}> */}
                  <Col className="w-[calc(20%_-_0.67rem)]">
                    <Form.Item className="mb-0">
                      <ButtonCustom
                        className="h-12 unset-min-width w-full"
                        htmlType="submit"
                        color="orange"
                      >
                        Search
                      </ButtonCustom>
                    </Form.Item>
                  </Col>

                  {/* <Col className="w-[calc(10%_-_0.7rem)]" xs={24} sm={3} md={3} lg={3} xl={3}> */}
                  <Col className="w-[calc(20%_-_0.67rem)]">
                    <Form.Item className="mb-0 ">
                      <ButtonCustom
                        className="h-12 unset-min-width w-full"
                        htmlType="button"
                        color="outline"
                        onClick={() => {
                          form.resetFields();
                          fetchS3Infomation();
                        }}
                      >
                        Reset
                      </ButtonCustom>
                    </Form.Item>
                  </Col>
                  {/* <ButtonCustom
                onClick={handleResetData}
                isWidthFitContent
                className="h-12"
                color="outline"
              >
                Reset
              </ButtonCustom> */}
                </Row>
              </Form>
            </div>
            <div className="filter-content w-full">
              <Row className="items-center justify-between">
                <Col>
                  <div className="box-items flex">
                    <div className="item-grid-layout mr-5 point">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.25 0.375H1.25C1.01794 0.375 0.795376 0.467187 0.631282 0.631282C0.467187 0.795376 0.375 1.01794 0.375 1.25V8.25C0.375 8.48206 0.467187 8.70462 0.631282 8.86872C0.795376 9.03281 1.01794 9.125 1.25 9.125H8.25C8.48206 9.125 8.70462 9.03281 8.86872 8.86872C9.03281 8.70462 9.125 8.48206 9.125 8.25V1.25C9.125 1.01794 9.03281 0.795376 8.86872 0.631282C8.70462 0.467187 8.48206 0.375 8.25 0.375ZM7.375 7.375H2.125V2.125H7.375V7.375ZM18.75 0.375H11.75C11.5179 0.375 11.2954 0.467187 11.1313 0.631282C10.9672 0.795376 10.875 1.01794 10.875 1.25V8.25C10.875 8.48206 10.9672 8.70462 11.1313 8.86872C11.2954 9.03281 11.5179 9.125 11.75 9.125H18.75C18.9821 9.125 19.2046 9.03281 19.3687 8.86872C19.5328 8.70462 19.625 8.48206 19.625 8.25V1.25C19.625 1.01794 19.5328 0.795376 19.3687 0.631282C19.2046 0.467187 18.9821 0.375 18.75 0.375ZM17.875 7.375H12.625V2.125H17.875V7.375ZM8.25 10.875H1.25C1.01794 10.875 0.795376 10.9672 0.631282 11.1313C0.467187 11.2954 0.375 11.5179 0.375 11.75V18.75C0.375 18.9821 0.467187 19.2046 0.631282 19.3687C0.795376 19.5328 1.01794 19.625 1.25 19.625H8.25C8.48206 19.625 8.70462 19.5328 8.86872 19.3687C9.03281 19.2046 9.125 18.9821 9.125 18.75V11.75C9.125 11.5179 9.03281 11.2954 8.86872 11.1313C8.70462 10.9672 8.48206 10.875 8.25 10.875ZM7.375 17.875H2.125V12.625H7.375V17.875ZM18.75 10.875H11.75C11.5179 10.875 11.2954 10.9672 11.1313 11.1313C10.9672 11.2954 10.875 11.5179 10.875 11.75V18.75C10.875 18.9821 10.9672 19.2046 11.1313 19.3687C11.2954 19.5328 11.5179 19.625 11.75 19.625H18.75C18.9821 19.625 19.2046 19.5328 19.3687 19.3687C19.5328 19.2046 19.625 18.9821 19.625 18.75V11.75C19.625 11.5179 19.5328 11.2954 19.3687 11.1313C19.2046 10.9672 18.9821 10.875 18.75 10.875ZM17.875 17.875H12.625V12.625H17.875V17.875Z"
                          fill="#ED7635"
                        />
                      </svg>
                    </div>
                    <div className="item-list-layout point">
                      <svg
                        width="22"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.5 0.125H3.125V2.75H0.5V0.125ZM0.5 6.6875H3.125V9.3125H0.5V6.6875ZM0.5 13.25H3.125V15.875H0.5V13.25ZM21.5 2.75V0.125H5.78019V2.75H19.925H21.5ZM5.75 6.6875H21.5V9.3125H5.75V6.6875ZM5.75 13.25H21.5V15.875H5.75V13.25Z"
                          fill="#AEA8A5"
                        />
                      </svg>
                    </div>
                  </div>
                </Col>
                <Col>
                  {/* <div className="flex flex-wrap gap-4"> */}
                  <div className="custom-flex-filter">
                    <div className="flex gap-2 items-center m-r-12">
                      <p className="font-fontFamily font-bold mb-0">Filter</p>
                      <SelectCustom
                        placeholder="Select"
                        color="transparent"
                        className="min-w-[144px]"
                        onChange={handleFilterByName}
                        options={[
                          { label: 'Name (A - Z)', value: 'A-Z' },
                          { label: 'Name (Z - A)', value: 'Z-A' },
                        ]}
                        value={sortCondtion || ''}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="cus-mt-1_25rem">
              <div className="flex justify-between items-center mt-4 mb-4">
                <div className="text-lg font-semibold text-[#000000]">Folder</div>
              </div>
              <Loading isLoading={isLoading}>
                <Row className="gap-3">
                  {listFolder?.map((element: ICardFolder) => (
                    <React.Fragment key={element.name}>
                      <Dropdown
                        key={element.name}
                        overlay={
                          <Menu
                            className="menu-folder"
                            onClick={(e) => actionButtonOnChange(e.key)}
                          >
                            <Menu.Item key={'SHARE'}>Share</Menu.Item>
                            {(listFolder.length > 1 || listFolderSelected.length > 1) && (
                              <Menu.SubMenu key={'MOVE_TO'} title="Move To">
                                {listFolderSelected?.length > 1 && (
                                  <Menu.Item
                                    key={`MOVE_TO_${listFolderSelected.at(-2) || ''}`}
                                    onClick={() =>
                                      handleMoveFolder(
                                        `${folderClick?.sharerId}/${folderClick?.path}${
                                          listFolderSelected?.length > 0
                                            ? `/${listFolderSelected
                                                .filter(
                                                  (_, index: number) =>
                                                    index > 0 && index < listFolderSelected.length,
                                                )
                                                .join('/')}`
                                            : ''
                                        }`,
                                      )
                                    }
                                  >
                                    ..
                                  </Menu.Item>
                                )}
                                {listFolder
                                  .filter((e: ICardFolder) => e.name !== element.name)
                                  .map((e: ICardFolder) => (
                                    <Menu.Item
                                      key={`MOVE_TO_${
                                        listFolderSelected?.length > 0
                                          ? `${listFolderSelected.join('/')}/`
                                          : ''
                                      }${e.name}`}
                                      onClick={() =>
                                        handleMoveFolder(
                                          `${e.sharerId}/${e.path}${
                                            listFolderSelected?.length > 0
                                              ? `/${listFolderSelected
                                                  .filter((_, index: number) => index > 0)
                                                  .join('/')}/${e.name}`
                                              : ''
                                          }`,
                                        )
                                      }
                                    >
                                      {e.name}
                                    </Menu.Item>
                                  ))}
                              </Menu.SubMenu>
                            )}
                          </Menu>
                        }
                        onVisibleChange={(visible: boolean) => {
                          if (visible) {
                            setFolderClick(element);
                            setFileClick(undefined);
                          }
                        }}
                        trigger={['contextMenu']}
                        className="xl-min:w-[calc(33.33%_-_0.5rem)] xl:w-[calc(50%_-_0.375rem)] sm:w-full"
                      >
                        <Col
                          key={element.name}
                          onClick={() => {
                            setLoading(true);
                            onClickFolder(element.name);
                            setPathFoler(element.path);
                          }}
                        >
                          <CardFolder data={element} onRedirect={() => {}} />
                        </Col>
                      </Dropdown>
                    </React.Fragment>
                  ))}
                </Row>
              </Loading>
            </div>
            <div className="cus-mt-1_25rem">
              <div className="flex justify-between items-center mt-4 mb-4">
                <div className="text-lg font-semibold text-[#000000]">Files</div>
              </div>
              <Loading isLoading={isLoading}>
                <Row className="gap-3">
                  {listFile?.map((element: ICardFile) => (
                    <React.Fragment key={element.name}>
                      <Dropdown
                        key={element.name}
                        overlay={
                          <Menu className="" onClick={(e) => actionButtonOnChange(e.key)}>
                            <Menu.Item key={'SHARE'}>Share</Menu.Item>
                            {(listFolder.length > 0 || listFolderSelected.length > 1) && (
                              <Menu.SubMenu key={'MOVE_TO'} title="Move To">
                                {listFolderSelected?.length > 1 && (
                                  <Menu.Item
                                    key={`MOVE_TO_${listFolderSelected.at(-2) || ''}`}
                                    onClick={() => {
                                      handleMoveFolder(
                                        `${fileClick?.sharerId}/${pathFolder}${
                                          listFolderSelected?.length > 2
                                            ? `/${listFolderSelected
                                                .filter(
                                                  (_, index: number) =>
                                                    index > 0 &&
                                                    index < listFolderSelected.length - 1,
                                                )
                                                .join('/')}`
                                            : ''
                                        }`,
                                      );
                                    }}
                                  >
                                    ..
                                  </Menu.Item>
                                )}
                                {listFolder
                                  .filter((e: ICardFolder) => e.name !== element.name)
                                  .map((e: ICardFolder) => (
                                    <Menu.Item
                                      key={`MOVE_TO_${
                                        listFolderSelected?.length > 0
                                          ? `${listFolderSelected.join('/')}/`
                                          : ''
                                      }${e.name}`}
                                      onClick={() => {
                                        handleMoveFolder(
                                          `${e.sharerId}/${e.path}${
                                            listFolderSelected?.length > 0
                                              ? `/${listFolderSelected
                                                  .filter((_, index: number) => index > 0)
                                                  .join('/')}/${e.name}`
                                              : ''
                                          }`.replace('//', '/'),
                                        );
                                      }}
                                    >
                                      {e.name}
                                    </Menu.Item>
                                  ))}
                              </Menu.SubMenu>
                            )}
                            <Menu.Item key={'DOWNLOAD_FILE'}>Download</Menu.Item>
                            <Menu.Item key={'RENAME_FILE'}>Rename</Menu.Item>
                          </Menu>
                        }
                        onVisibleChange={(visible: boolean) => {
                          if (visible) {
                            setFileClick(element);
                            setFolderClick(undefined);
                          }
                        }}
                        trigger={['contextMenu']}
                        className="xl-min:w-[calc(33.33%_-_1rem)] xl:w-[calc(50%_-_0.5rem)] lg:w-[calc(50%_-_0.5rem)] sm:w-full"
                      >
                        <Col
                          key={element.name}
                          onClick={() => {
                            setFileClick(element);
                            mutatePreviewFile({
                              key: `${element.sharerId}/${
                                listFolderSelected?.length > 0
                                  ? `${listFolderSelected.join('/')}/`
                                  : ''
                              }${element.name}`,
                            });
                          }}
                        >
                          <CardImage data={element} isShowImage={true} />
                        </Col>
                      </Dropdown>
                    </React.Fragment>
                  ))}
                </Row>
              </Loading>
            </div>
          </Content>

          {isShowModalRename && (
            <ModalRename
              visible={true}
              onCancel={() => {
                setShowModalRename(false);
                formRenameFolder.resetFields();
              }}
              onSubmit={handleRenameFile}
              isKeepOpen={true}
              form={formRenameFolder}
              loading={isLoading}
            />
          )}

          {isShowModalDowload && (
            <ModalDowload
              visible={true}
              onCancel={() => {
                setShowModalDowload(false);
              }}
              onSubmit={() => {
                handleDowload();
              }}
              isKeepOpen={true}
              fileUrl={urlPreview}
              fileName={fileClick?.name}
              form={formPreview}
              loading={isLoading}
            />
          )}

          {isShowModalShare && (
            <ModalShare
              visible={true}
              onCancel={() => {
                setShowModalShare(false);
                formShare.resetFields();
              }}
              onSubmit={handleShareForUser}
              isKeepOpen={true}
              form={formShare}
              listUsers={listUsersShare}
              loading={isLoading}
              nameShare={fileClick?.name || folderClick?.name || ''}
              onSearch={onSearchUser}
            />
          )}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default SharedWithMe;
