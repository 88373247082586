import React, { useContext } from 'react';
import { ROUTES } from 'constants/constants';
import { Typography, Button } from 'antd';
const { Title } = Typography;
import { useNavigate } from 'react-router-dom';
import './style.css';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { getAllAssignments } from 'api/assignment';
import { AppContext } from 'context';

interface IAssignmentsBySecction {
  unitName?: string;
  isUploadedFile?: boolean;
  id?: number;
  isDisabled?: boolean;
  content?: any;
}

function AssignmentsBySecction(props: IAssignmentsBySecction) {
  const { unitName, isUploadedFile, id, isDisabled, content } = props;

  const navigate = useNavigate();

  const [stateContext]: any = useContext(AppContext);




  const { data: dataAssignments, isLoading: isLoadingAssignments } = useQuery(['getAllAssignments', id], () => getAllAssignments({ limit: 1, page: 1, filters: JSON.stringify([{ unitID: Number(id), studentID: stateContext?.user?.student?.id }]) }), {
    enabled: !!id && !!stateContext?.user?.student?.id,
  });

  const isSubmited = !!dataAssignments?.data?.records?.[0]?.submissionDate;
  const isMarked = !!dataAssignments?.data?.records?.[0]?.isMarked;
  const isEmptyAssignments = dataAssignments?.data?.records?.length === 0

  const renderColor = () => {
    if (isEmpty(content)) return '#f7f5f4'
    if (isMarked) return 'var(--main-color)';
    if (isSubmited && !isMarked) return 'var(--main-color-light)';
    if (isEmptyAssignments && !isMarked) return 'var(--main-color-light)';
    if (!isEmptyAssignments && !isSubmited) return 'var(--main-color-light)';

  }

  return (
    <div className="mt-6 flex items-center gap-4 w-full">
      <Title style={{ margin: 0, minWidth: 300, maxWidth: 300 }} level={5}>
        {unitName}
      </Title>
      <div
        style={{
          backgroundColor: renderColor(),
        }}
        className="flex-1 h-10 rounded-2xl flex items-center justify-center text-white text-lg"
      >
        {!isEmpty(content) && <span>{isMarked ? 'Completed' : isSubmited ? 'Submited' : 'Started'}</span>}
      </div>
      <div style={{ minWidth: 150 }}>
        {!isEmpty(content) && <div>
          <Button
            disabled={isSubmited}
            onClick={() => {
              navigate(ROUTES.my_assignments + '/' + id);
            }}
            className="styte_button_outline_light"
            size="large"
            style={{ minWidth: 150 }}
          >
            {isSubmited ? 'Submitted' : 'Continue'}
          </Button>
        </div>}
      </div>
    </div>
  );
}

export default AssignmentsBySecction;
