import { Form, Layout, Row, Col } from 'antd';
import ButtonCustom from 'components/Button';
import CalendarList from './component/CalendarList';
import CalendarFilter from './component/CalendarFilter';
import EventModified from './component/CalendarModal/EventModified';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ICalendarListFilter, IEvent } from './constants';
import moment from 'moment';
import { Views } from 'react-big-calendar';
import { useMutation } from 'react-query';
import { getAllEvent } from 'api/event';
import EventDelete from './component/CalendarModal/EventDelete';
import { getDaysArray, routerUserAccess } from 'helper/function';
import { useLocation } from 'react-router-dom';
import { getAllSessionByStudent, getAllSessionByTeacher, searchClass } from 'api/class';
import { PARAMS_SELECT_SEARCH, TEXT_SELECT_SEARCH } from 'constants/constants';
import { RoleName } from 'enum/role';
import { getStudentByTeacher } from 'api/student';
import { IUser, getProfileMe } from 'api/user';

const CalendarTab = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const routerUser = routerUserAccess();
  const [checkRoleUser, setCheckRoleUser] = useState(true);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [form] = Form.useForm();
  const [eventDetail, setEventDetail] = useState<IEvent>();
  const timeout: any = useRef(null);
  const [eventSearch, setEventSearch] = useState<IEvent[]>([]);
  const [eventSearched, setEventSearched] = useState<IEvent>();
  const [events, setEvents] = useState<IEvent[]>([]);
  const [agendaEvents, setAgendaEvents] = useState<IEvent[]>([]);
  const [dayEvents, setDayEvents] = useState<IEvent[]>([]);
  const [filter, setFilter] = useState<ICalendarListFilter>({
    date: moment(),
    amOrPm: 'AM',
    sort: Views.DAY,
  });
  const [idEventFocused, setIdEventFocused] = useState<{ day?: string; agenda?: string }>({
    day: '',
    agenda: '',
  });
  const [querySearch, setQuerySearch] = useState({
    classes: '',
    course: '',
    centre: '',
  });
  const [nameUser, setNameUser] = useState<string | null>(null);

  const [classOptions, setClassOptions] = useState<
    { label: string; value: string; isDisabled?: boolean }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [listSession, setListSession] = useState<any[]>([]);
  useEffect(() => {
    if (routerUser.listRoute.length === 0) setCheckRoleUser(true);
    else setCheckRoleUser(routerUser.listRoute.some((item: string) => location.pathname === item));
  }, [routerUser.listRoute.length]);

  const handleOpenAdd = () => {
    setVisible(true);
    setIdEventFocused({ day: '', agenda: '' });
  };

  const { mutate: getDetail } = useMutation('getListEvent', getAllEvent, {
    onSuccess: ({ data }: { data: IEvent[] }) => {
      setEventDetail(data[0]);
      setVisible(true);
    },
  });

  const handleOpenEdit = (event?: IEvent) => {
    getDetail({ id: event?.id?.toString() });
  };

  const handleOpenDelete = (event?: IEvent) => {
    setVisibleModalDelete(true);
    setEventDetail(event);
  };

  const convertTimeDataSession = (data: any[]) => {
    const listSessions: any[] = [];
    data.map((elm: any) => {
      const startDateTime = new Date(elm.startTime as string);
      const endDateTime = new Date(elm.endTime as string);
      const dates = getDaysArray(startDateTime, endDateTime);
      // if (dates.length == 1) {
      //   listSessions.push({
      //     start: startDateTime,
      //     end: endDateTime,
      //     title: elm?.session?.sessionName ?? 'Session Name',
      //     resource: {
      //       ...elm,
      //       id: Math.random(),
      //     },
      //     isSession: true,
      //   });
      // }

      for (const date of dates) {
        if (startDateTime.toLocaleDateString() == date.toLocaleDateString()) {
          listSessions.push({
            start: moment(elm.startTime).toDate(),
            end: moment(elm.startTime).endOf('date').toDate(),
            title: elm?.session?.sessionName ?? 'Session Name',
            resource: {
              ...elm,
              id: Math.random(),
            },

            isSession: true,
          });
        } else if (endDateTime.toLocaleDateString() == date.toLocaleDateString()) {
          listSessions.push({
            start: moment(elm.endTime).startOf('date').toDate(),
            end: moment(elm.endTime).toDate(),
            title: elm?.session?.sessionName ?? 'Session Name',
            resource: {
              ...elm,
              id: Math.random(),
            },
            isSession: true,
          });
        } else {
          listSessions.push({
            start: moment(date).startOf('date').toDate(),
            end: moment(date).endOf('date').toDate(),
            title: elm?.session?.sessionName ?? 'Session Name',
            resource: {
              ...elm,
              id: Math.random(),
            },
            isSession: true,
          });
        }
      }
    });
    return listSessions;
  };

  const dataEvents = (newEvents: IEvent[]) => {
    const result: any = [];
    const listTimeSession: any = [];
    try {
      for (const event of newEvents) {
        const startDateTime = new Date(event.startDateTime as string);
        const endDateTime = new Date(event.endDateTime as string);
        const dates = getDaysArray(startDateTime, endDateTime);
        // listTimeSession.push(...convertSession(event.classes));

        if (dates.length == 1) {
          result.push({
            start: startDateTime,
            end: endDateTime,
            title: event.title,
            resource: {
              ...event,
            },
          });
          continue;
        }
        console.log('run to here========================================> 1');

        for (const date of dates) {
          if (startDateTime.toLocaleDateString() == date.toLocaleDateString()) {
            result.push({
              start: moment(event.startDateTime).toDate(),
              end: moment(event.startDateTime).endOf('date').toDate(),
              title: event.title,
              resource: {
                ...event,
              },
            });
          } else if (endDateTime.toLocaleDateString() == date.toLocaleDateString()) {
            result.push({
              start: moment(event.endDateTime).startOf('date').toDate(),
              end: moment(event.endDateTime).toDate(),
              title: event.title,
              resource: {
                ...event,
              },
            });
          } else {
            result.push({
              start: moment(date).startOf('date').toDate(),
              end: moment(date).endOf('date').toDate(),
              title: event.title,
              resource: {
                ...event,
              },
            });
          }
        }
      }

      return [...result, ...listTimeSession];
    } catch (error) {
      throw error;
    }
  };

  const { mutate: getListEvent } = useMutation('getListEvent', getAllEvent, {
    onSuccess: ({ data }: { data: IEvent[] }) => {
      console.log('run to get list event', data);
      setEvents(dataEvents(data));
      setAgendaEvents(dataEvents(data));
      console.log('filter?.id========================>', filter?.id);
      if (filter?.id) {
        const date =
          moment(data[0].startDateTime).format('YYYY-MM-DD') ===
          moment(new Date()).format('YYYY-MM-DD')
            ? moment(data[0].startDateTime)
            : moment(data[0].startDateTime).startOf('day');

        //if want to display one event
        // setFilter((prev) => ({ sort: prev.sort, date, id: filter?.id }));
        console.log('run to her===================>', date);

        setFilter((prev) => ({ sort: prev.sort, date, idSearched: filter?.id }));
        setEventSearched(data[0]);
      }
    },
  });

  const { mutate: getListSession } = useMutation('getListSession', getAllSessionByTeacher, {
    onSuccess: ({ data }: { data: any[] }) => {
      console.log('run to get getListSession ====================>', data);
      setListSession(convertTimeDataSession(data));
      if (filter?.id) {
        const date =
          moment(data[0].startTime).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')
            ? moment(data[0].startTime)
            : moment(data[0].startTime).startOf('day');

        //if want to display one event
        // setFilter((prev) => ({ sort: prev.sort, date, id: filter?.id }));
        console.log('run to her===================>', date);

        setFilter((prev) => ({ sort: prev.sort, date, idSearched: filter?.id }));
        setEventSearched(data[0]);
      }
    },
  });

  const { mutate: getListSessionByStudent } = useMutation(
    'getListSession',
    getAllSessionByStudent,
    {
      onSuccess: ({ data }: { data: any[] }) => {
        console.log('run to get getListSession ====================>', data);
        setListSession(convertTimeDataSession(data));
        if (filter?.id) {
          const date =
            moment(data[0].startTime).format('YYYY-MM-DD') ===
            moment(new Date()).format('YYYY-MM-DD')
              ? moment(data[0].startTime)
              : moment(data[0].startTime).startOf('day');

          //if want to display one event
          // setFilter((prev) => ({ sort: prev.sort, date, id: filter?.id }));
          console.log('run to her===================>', date);

          setFilter((prev) => ({ sort: prev.sort, date, idSearched: filter?.id }));
          setEventSearched(data[0]);
        }
      },
    },
  );

  const { mutate: getClasses } = useMutation('searchClass', searchClass, {
    onSuccess: ({
      data,
    }: {
      data: { listClasses: { id: number; className: string; course: { courseName: string } }[] };
    }) => {
      let newOptions = data.listClasses.map((el) => {
        return {
          label: el.className + ' - ' + el.course.courseName,
          value: el.id.toString(),
          disabled: false,
        };
      });
      newOptions = newOptions.concat([
        { label: TEXT_SELECT_SEARCH.class, value: '', disabled: true },
      ]);
      setClassOptions(newOptions);
    },
  });

  const { mutate: searchListEvent } = useMutation('getListEvent', getAllEvent, {
    onSuccess: ({ data }: { data: IEvent[] }) => {
      const listEvent = data.map((event) => ({
        start: new Date(event.startDateTime || ''),
        end: new Date(event.endDateTime || ''),
        title: event.title,
        resource: {
          ...event,
        },
      }));
      if (isSearch) {
        if (listEvent.length > 0) {
          const date = moment(listEvent[0]?.resource?.startDateTime);
          setFilter({ date });
        }
        setIsSearch(false);
      }
      setEvents(listEvent);
      setEventSearch(listEvent);
      console.log('mutate==========================>');
    },
  });

  const { mutate: getProfile } = useMutation('getProfileMe', getProfileMe, {
    onSuccess: ({ data }: { data: IUser }) => {
      setNameUser(
        `${data.userProfile?.firstName} ${data.userProfile?.lastName}` ?? "Teacher Name's",
      );
    },
  });

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (isLoading && routerUser.userRole) {
      getProfile();
      getClasses(PARAMS_SELECT_SEARCH.class);
      getListEvent({
        search: '',
      });
      if (routerUser.userRole !== RoleName.STUDENT) {
        getListSession({ search: '' });
      } else {
        getListSessionByStudent({ search: '' });
      }
    }
  }, [isLoading, routerUser.userRole]);

  useEffect(() => {
    if (filter?.search) {
      const param = {
        search: filter.search,
        centre: querySearch.centre.trim() === '' ? undefined : querySearch.centre,
        classes: querySearch.classes.trim() === '' ? undefined : querySearch.classes,
        course: querySearch.course.trim() === '' ? undefined : querySearch.course,
      };
      getListEvent(param);
      if (routerUser.userRole !== RoleName.STUDENT) {
        getListSession(param);
      } else {
        getListSessionByStudent(param);
      }
    } else if (filter?.id) {
      console.log('call API ======>', filter?.id);

      // if (filter?.sort === 'day') {
      const callApi =
        filter?.id?.toString() !==
        (filter?.sort === 'day'
          ? idEventFocused.day?.split('_')[0]
          : idEventFocused.agenda?.split('_')[0]);
      console.log('call API ======>', callApi);

      if (callApi) {
        const param = { id: filter?.id };
        getListEvent(param);
        if (routerUser.userRole !== RoleName.STUDENT) {
          getListSession(param);
        } else {
          getListSessionByStudent(param);
        }
      }
      // } else if (filter?.sort === 'agenda') {
      //   const findEvent = agendaEvents.find((event) => event?.resource?.id === filter?.id);
      //   const date =
      //     moment(findEvent?.resource?.startDateTime).format('YYYY-MM-DD') ===
      //     moment(new Date()).format('YYYY-MM-DD')
      //       ? moment(findEvent?.resource?.startDateTime)
      //       : moment(findEvent?.resource?.startDateTime).startOf('day');

      //   setEventSearched(findEvent?.resource);
      //   setFilter({ sort: 'agenda', date, idSearched: filter?.id, id: filter?.id });
      // }
    } else {
      // getListEvent({
      //   search: '',
      //   id: '',
      //   centre: querySearch.centre.trim() === '' ? undefined : querySearch.centre,
      //   classes: querySearch.classes.trim() === '' ? undefined : querySearch.classes,
      //   course: querySearch.course.trim() === '' ? undefined : querySearch.course,
      // });
    }
  }, [filter?.search, filter?.id, filter?.sort]);

  useEffect(() => {
    // if (filter?.date && filter?.sort === 'day') {
    const eventsFilter =
      events?.filter(
        (event: IEvent) =>
          moment(event?.start, 'YYYY-MM-DD').format('YYYY-MM-DD') ===
          moment(filter?.date ? filter?.date : new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      ) || [];
    setDayEvents(eventsFilter);
    // }
  }, [filter?.date, filter?.sort, events, eventSearch]);

  const handleChangeFilter = (newFilter?: ICalendarListFilter) => {
    if (eventSearched?.id && newFilter?.sort && newFilter?.sort !== filter.sort) {
      setFilter((prev) => ({
        ...prev,
        ...newFilter,
        date: moment(eventSearched.startDateTime),
        time: moment(eventSearched.startDateTime),
      }));

      return;
    }

    setFilter((prev) => ({ ...prev, ...newFilter }));
  };

  const handleReload = (type: 'modified' | 'delete') => {
    if (type === 'delete' && filter?.idSearched && filter?.idSearched === eventDetail?.id) {
      const newEventsSearch = eventSearch.filter((x) => x?.resource?.id !== filter?.idSearched);
      setEventSearch([...newEventsSearch]);
    }
    const param = filter?.search
      ? { search: filter?.search, id: '' }
      : filter?.id
      ? { search: '', id: filter?.id }
      : { search: '', id: '' };
    // if (filter?.search) {
    //   getListEvent({ search: filter?.search, id: '' });
    // } else if (filter?.id) {
    //   getListEvent({ search: '', id: filter?.id });
    // } else {
    //   getListEvent({ search: '', id: '' });
    // }
    getListEvent(param);
    if (routerUser.userRole !== RoleName.STUDENT) {
      getListSession(param);
    } else {
      getListSessionByStudent(param);
    }
  };

  const handleCancelEventModified = () => {
    setEventDetail(undefined);
    setVisible(false);
    console.log('filter?.id========================>', filter?.id);
    const param = {
      search: form.getFieldValue('search') ?? '',
      id: '',
      centre: querySearch.centre.trim() === '' ? undefined : querySearch.centre,
      classes: querySearch.classes.trim() === '' ? undefined : querySearch.classes,
      course: querySearch.course.trim() === '' ? undefined : querySearch.course,
    };
    getListEvent(param);
    if (routerUser.userRole !== RoleName.STUDENT) {
      getListSession(param);
    } else {
      getListSessionByStudent(param);
    }
  };

  const handleCancelEventDelete = () => {
    setEventDetail(undefined);
    setVisibleModalDelete(false);
    const param = {
      search: form.getFieldValue('search') ?? '',
      id: '',
      centre: querySearch.centre.trim() === '' ? undefined : querySearch.centre,
      classes: querySearch.classes.trim() === '' ? undefined : querySearch.classes,
      course: querySearch.course.trim() === '' ? undefined : querySearch.course,
    };
    getListEvent(param);
    if (routerUser.userRole !== RoleName.STUDENT) {
      getListSession(param);
    } else {
      getListSessionByStudent(param);
    }
  };

  const onFinish = (formValues: { search: string; id?: string }) => {
    if (formValues?.search) {
      setFilter((prev) => ({ ...prev, search: formValues.search, id: '' }));
    } else if (formValues?.id) {
      // const needToSetFilter =
      //   formValues?.id?.toString() !==
      //   (filter?.sort === 'day'
      //     ? idEventFocused.day?.split('_')[0]
      //     : idEventFocused.agenda?.split('_')[0]);

      // console.log('==================================>', formValues.id);

      // if (needToSetFilter) {
      setFilter((prev) => ({ ...prev, id: formValues.id, search: '' }));
      // }
    } else {
      setFilter((prev) => ({
        sort: prev.sort,
        id: '',
        search: '',
        date: moment(),
        time: prev.sort === 'day' ? null : moment(),
      }));

      setEventSearched(undefined);
    }
  };

  const handleReset = (isClick: boolean) => {
    setFilter((prev) => ({
      ...prev,
      search: '',
      id: '',
      date: moment(new Date()),
      amOrPm: 'AM',
      sort: Views.DAY,
    }));
    setIsSearch(false);
    if (isClick)
      setQuerySearch({
        centre: '',
        classes: '',
        course: '',
      });
    // searchListEvent({ search: '', id: '' });
    setTimeout(() => {
      const param = { search: '', id: '' };
      getListEvent(param);
      if (routerUser.userRole !== RoleName.STUDENT) {
        getListSession(param);
      } else {
        getListSessionByStudent(param);
      }
    }, 500);

    setEventSearched(undefined);
  };

  const debounceSearch = useCallback(
    (value: string, classes?: string, centre?: string, course?: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        if (centre || classes || course) {
          setQuerySearch({
            centre: centre ?? '',
            classes: classes ?? '',
            course: course ?? '',
          });
          if ((classes && classes?.trim() !== '') || (course && course?.trim() !== '')) {
            const param = { centre: centre, classes: classes, course: course, search: value };
            getListEvent(param);
            if (routerUser.userRole !== RoleName.STUDENT) {
              getListSession(param);
            } else {
              getListSessionByStudent(param);
            }
          } else {
            setIsSearch(true);
            const param = {
              search: value,
              id: '',
              centre: querySearch.centre.trim() === '' ? undefined : querySearch.centre,
              classes: querySearch.classes.trim() === '' ? undefined : querySearch.classes,
              course: querySearch.course.trim() === '' ? undefined : querySearch.course,
            };
            getListEvent(param);
            if (routerUser.userRole !== RoleName.STUDENT) {
              getListSession(param);
            } else {
              getListSessionByStudent(param);
            }
          }
        } else {
          setQuerySearch({
            centre: '',
            classes: '',
            course: '',
          });
          if (value.trim() === '') {
            const param = {
              search: '',
              id: '',
              centre: querySearch.centre.trim() === '' ? undefined : querySearch.centre,
              classes: querySearch.classes.trim() === '' ? undefined : querySearch.classes,
              course: querySearch.course.trim() === '' ? undefined : querySearch.course,
            };
            getListEvent(param);
            if (routerUser.userRole !== RoleName.STUDENT) {
              getListSession(param);
            } else {
              getListSessionByStudent(param);
            }
          } else {
            setIsSearch(true);
            const param = {
              search: value,
              id: '',
              centre: querySearch.centre.trim() === '' ? undefined : querySearch.centre,
              classes: querySearch.classes.trim() === '' ? undefined : querySearch.classes,
              course: querySearch.course.trim() === '' ? undefined : querySearch.course,
            };
            getListEvent(param);
            if (routerUser.userRole !== RoleName.STUDENT) {
              getListSession(param);
            } else {
              getListSessionByStudent(param);
            }
            // searchListEvent({ search: value });
          }
        }
      }, 500);
    },
    [timeout],
  );

  // const handleChangeSearch = useCallback(
  //   (value: string) => {
  //     debounceSearch(value);
  //   },
  //   [filter.search],
  // );

  const handleSearchCourses = useCallback(
    (value: string, classes?: string, centre?: string, course?: string) => {
      debounceSearch(value, classes, centre, course);
      // setIsSearch(false);
      setEventSearched(undefined);
    },
    [filter.search],
  );

  return (
    <>
      {checkRoleUser ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <Row className="flex justify-between items-center bg-transparent px-0 ">
            <Col>
              <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 mr-2 custom-font-header">
                {routerUser.userRole === RoleName.ADMIN ||
                routerUser.userRole === RoleName.SUPER_ADMIN
                  ? 'Admin'
                  : routerUser.userRole === RoleName.TEACHER
                  ? nameUser ??
                    `${
                      routerUser.userRole === RoleName.TEACHER ? "Teacher Name's" : "Student Name's"
                    } `
                  : ''}
                {' Calendar'}
              </p>
            </Col>
            {routerUser.userRole !== RoleName.STUDENT && (
              <Col className="">
                <ButtonCustom color="orange" onClick={handleOpenAdd}>
                  Create new event
                </ButtonCustom>
              </Col>
            )}
          </Row>

          <CalendarFilter
            form={form}
            keyResult="title"
            onFinish={onFinish}
            // searchResults={eventSearch}
            // handleChangeSearch={handleChangeSearch}
            handleSearchCourses={handleSearchCourses}
            handleReset={handleReset}
          />

          <CalendarList
            onOpenEdit={handleOpenEdit}
            events={dayEvents}
            filter={filter}
            onChangeFilter={handleChangeFilter}
            onOpenDelete={handleOpenDelete}
            agendarEvents={agendaEvents}
            eventSearched={eventSearched}
            idEventFocused={idEventFocused}
            listSession={listSession}
            onChangeIdEventFocused={(value: { day?: string; agenda?: string }) =>
              setIdEventFocused((prev) => ({ ...prev, ...value }))
            }
          />

          {routerUser.userRole !== RoleName.STUDENT && (
            <EventModified
              visible={visible}
              onCancel={handleCancelEventModified}
              eventDetail={eventDetail}
              onReload={() => handleReload('modified')}
              optionsGuest={classOptions}
              searchOptionGuest={(value:string)=>getClasses({...PARAMS_SELECT_SEARCH.class,search:value})}

            />
          )}

          {routerUser.userRole !== RoleName.STUDENT && (
            <EventDelete
              visible={visibleModalDelete}
              onCancel={handleCancelEventDelete}
              eventDetail={eventDetail}
              onReload={() => handleReload('delete')}
            />
          )}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default CalendarTab;
