import { Row, Col } from 'antd';
import CustomTooltip from 'components/Tooltip';
import './style.css';
import { ReactComponent as FolderSVG } from 'assets/images/folder.svg';
import { ICardFolder } from 'pages/admin/my-drive/own-drive';
import moment from 'moment';

interface IProps {
  data: ICardFolder;
  pathToModule?: string;
  onRedirect?: () => void;
}

const CardFolder = (props: IProps) => {
  const { data, onRedirect } = props;

  return (
    <Row
      className={`rounded-3xl p-4 bg-white shadow-[0px_8px_32px_rgba(0,0,0,0.04)] h-full border border-solid border-transparent hover:!border-main-button-color cursor-pointer`}
      onClick={() => onRedirect && onRedirect()}
    >
      <Col className="w-full">
        <div className="flex justify-between w-full">
          <div className="flex gap-2 w-[90%]">
            <div className="self-center">
              <FolderSVG />
            </div>
            <div className="w-[20px] h-[20px]" />
            <div className="w-[80%]">
              <CustomTooltip title={data.name}>
                <h5 className="font-weight-400 line-height-18 custom-font font-fontFamily overflow-hidden text-ellipsis whitespace-nowrap w-fit max-w-full">
                  {data.name}
                </h5>
              </CustomTooltip>
              {data.date && data.nameShare && (
                <p className="m-0">
                  {moment(data.date).format('YYYY/MM/DD')} - {data.nameShare}
                </p>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CardFolder;
