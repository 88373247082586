import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumb, Form, Layout, Spin, Table } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { routerUserAccess } from 'helper/function';
import { RoleName } from 'enum/role';
import { useMutation, useQuery } from 'react-query';
import { ROUTES } from 'constants/constants';
import { checkTeachManagerClass } from 'api/module';
import { IParamsSearch } from 'api/courses';
import ButtonCustom from 'components/Button';
import { ReactComponent as SortSVG } from 'assets/images/sort.svg';

import style from './style.module.scss';
import PaginationCustom from 'components/Pagination';
import GroupDetailFilter from './Filter';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import SelectCustom from 'components/Select';
import CustomTooltip from 'components/Tooltip';
import { EnterOutlined } from '@ant-design/icons';
import RemoveModal from '../MyGroups/component/RemoveModal';

import OwnDrive from 'pages/admin/my-drive/own-drive';

const { Header, Content } = Layout;

import './myGroup.css';
import { getGroupMember } from 'api/teacher_my_groups';
import { IGroupMemberData, IMemberList, optionsLimit } from './helper';

const GroupDetail = () => {
  const [active, setActive] = useState(2);
  const [removeTable, setRemoveTable] = useState(false);
  const [studentID, setStudentID] = useState<number | null>(null);
  const [listFolderSelected, setListFolderSelected] = useState<string[]>([]);
  const [dataList, setDataList] = useState<IGroupMemberData>();
  const [searchResults, setSearchResults] = useState<IMemberList[]>([]);
  const [isTeacherManagerClass, setIsTeacherManagerClass] = useState<any>();
  const [filter, setFilter] = useState<IParamsSearch>({
    page: 1,
    limit: 5,
    sort: 'createdAt',
    order: 'ASC',
    search: '',
  });
  const timeout: any = useRef(null);

  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { classId, groupId } = useParams();
  const [form] = Form.useForm();
  const routerUser = routerUserAccess();

  const { mutate: getGroupMemberList, isLoading } = useMutation('getGroupMember', getGroupMember, {
    onSuccess: ({ data }: { data: IGroupMemberData }) => {
      setDataList({
        page: data.page,
        limit: data.limit,
        sort: data.sort,
        order: data.order,
        total: data.total,
        studentList: data.studentList,
        teacherDetail: data.teacherDetail,
        classDetail: data.classDetail,
      });
    },
  });

  const checkTeacherManagerClass = useQuery(['checkTeachManagerClass'], () =>
    checkTeachManagerClass(classId as any),
  );

  useEffect(() => {
    if (checkTeacherManagerClass.status === 'success') {
      setIsTeacherManagerClass(checkTeacherManagerClass.data.data);
    }
  }, [checkTeacherManagerClass, classId]);

  const { mutate: searchModules } = useMutation('searchGetGroupMember', getGroupMember, {
    onSuccess: ({ data }: { data: IGroupMemberData }) => {
      setSearchResults(data.studentList);
    },
  });

  const startPageSize = useMemo(() => {
    const startSize = Number(filter.page) * Number(filter?.limit) - (Number(filter?.limit) - 1);

    return startSize;
  }, [filter.page, filter?.limit]);

  const endPageSize = useMemo(() => {
    let endSize = Number(filter.page) * Number(filter?.limit);
    endSize = dataList?.total && endSize < dataList?.total ? endSize : (dataList?.total as number);

    return endSize;
  }, [filter.page, filter?.limit, dataList?.total]);

  const onChangeLimit = useCallback(
    (value: string) => {
      const total = dataList?.total;
      const maxPage = Math.ceil(Number(total) / Number(value));
      if (filter.page > maxPage) setFilter((prev) => ({ ...prev, page: maxPage, limit: +value }));
      else setFilter({ ...filter, limit: +value });
    },
    [filter.page, dataList?.total],
  );

  const columns = [
    {
      title: (
        <span
          className="font-fontFamily"
          onClick={() => {
            setFilter({
              ...filter,
              order: filter.order === 'ASC' ? 'DESC' : 'ASC',
            });
          }}
        >
          Name <SortSVG />
        </span>
      ),
      dataIndex: 'userFullName',
      key: 'userFullName',
      render: (value: string) => {
        return <span className="font-fontFamily font-semibold">{value}</span>;
      },
      width: '80%',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      // fixed: true,
      width: 100,
      center: true,
      render: (text: string, record: IMemberList) => (
        <>
          <CustomTooltip title="Remove" placement="topLeft">
            <div
              className="flex gap-2 icon-hover"
              onClick={(e) => {
                setRemoveTable(true);
                setStudentID(record.studentID);
                e.stopPropagation();
              }}
            >
              <div className="cursor-pointer">
                <EnterOutlined className="icon-hover" />
              </div>
              <p
                className="font-weight-600"
                style={{
                  fontWeight: 800,
                }}
              >
                Remove
              </p>
            </div>
          </CustomTooltip>
        </>
      ),
    },
  ];

  const debounceSearch = useCallback(
    (value: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        searchModules({
          search: value,
          page: 1,
          limit: 300,
          sort: 'createdAt',
          order: 'ASC',
          filters: JSON.stringify([{ groupID: groupId }]),
        });
      }, 500);
    },
    [timeout],
  );

  const handleChangeSearch = useCallback(
    (value: string) => {
      debounceSearch(value);
    },
    [filter?.search],
  );

  const handleReset = () => {
    setFilter((prev) => ({ ...prev, search: '', filters: undefined }));
    searchModules({
      search: '',
      page: 1,
      limit: 300,
      sort: 'createdAt',
      order: 'ASC',
      filters: JSON.stringify([{ groupID: groupId }]),
    });
  };

  const onFinish = (values: { search: string; moduleId?: string | number }) => {
    setFilter((prev) => ({
      ...prev,
      search: values.search,
      filters: JSON.stringify([{ groupID: values.moduleId }]),
      page: 1,
    }));
  };

  const handleRefetching = () => {
    getGroupMemberList({ ...filter, filters: JSON.stringify([{ groupID: groupId }]) });
  };

  useEffect(() => {
    if (groupId) {
      getGroupMemberList({ ...filter, filters: JSON.stringify([{ groupID: groupId }]) });
    }
  }, [groupId, filter]);

  return (
    <div>
      {routerUser.userRole === RoleName.TEACHER && isTeacherManagerClass != null ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <div className="flex">
            <>
              <Header className="flex justify-between items-center bg-transparent px-0 h-auto flex-1">
                <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
                  <Breadcrumb.Item
                    className="cursor-pointer font-fontFamily"
                    onClick={() => navigate(ROUTES.class_management)}
                  >
                    Class Management
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font-fontFamily">
                    {dataList?.classDetail?.className} My Groups
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className={`font-fontFamily ${
                      listFolderSelected?.length === 0 ? 'text-main-font-color' : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (listFolderSelected?.length > 0) setListFolderSelected([]);
                    }}
                  >
                    {state?.groupName}
                  </Breadcrumb.Item>

                  {!!listFolderSelected?.length &&
                    listFolderSelected.map((element, index: number) => (
                      <Breadcrumb.Item
                        className={`font-fontFamily ${
                          listFolderSelected?.length === index + 1
                            ? 'text-main-font-color'
                            : 'cursor-pointer'
                        }`}
                        key={`${index}`}
                        onClick={() => {
                          setListFolderSelected((prevState: string[]) =>
                            prevState.filter((_, indexState: number) => indexState <= index),
                          );
                        }}
                      >
                        {element}
                      </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
              </Header>
              <div>
                <ButtonCustom
                  color="orange"
                  className={style.new_group_btn}
                  onClick={() => {
                    setRemoveTable(true);
                  }}
                >
                  Leave Group
                </ButtonCustom>
              </div>
            </>
          </div>

          <div className={style.tabs}>
            <button
              className={style.tab}
              onClick={() => {
                setActive(1);
                setListFolderSelected([]);
              }}
              style={{
                backgroundColor: active === 1 ? '#ed7635' : '',
                color: active === 1 ? '#ffff' : '',
              }}
            >
              Library
            </button>
            <button
              className={style.tab}
              onClick={() => {
                setActive(2);
                setListFolderSelected([]);
              }}
              style={{
                backgroundColor: active === 2 ? '#ed7635' : '',
                color: active === 2 ? '#ffff' : '',
              }}
            >
              Members
            </button>
          </div>

          {active === 1 && (
            <OwnDrive
              onChangeFolder={(folderName: string) => {
                setListFolderSelected((prevState: string[]) => [...prevState, folderName]);
              }}
              propsListSelected={listFolderSelected}
            />
          )}

          {active === 2 && (
            <>
              <Content className="flex flex-col gap-y-6">
                <GroupDetailFilter
                  form={form}
                  searchResults={searchResults ?? []}
                  keyResult="searchGroupMember"
                  handleChangeSearch={handleChangeSearch}
                  onFinish={onFinish}
                  handleReset={handleReset}
                  handleRefetching={handleRefetching}
                  classId={Number(classId)}
                />

                <div className="flex justify-between items-center">
                  <p className="text-2xl font-fontFamily leading-9 font-bold mb-0"></p>
                  <div className="flex gap-4 items-center flex-wrap">
                    <p className="font-fontFamily font-bold mb-0">View Item</p>
                    <SelectCustom
                      color="transparent"
                      value={filter.limit.toString()}
                      onChange={onChangeLimit}
                      options={optionsLimit}
                    />
                  </div>
                </div>
              </Content>

              {isLoading && (
                <div className="flex justify-center">
                  <Spin size="large" />
                </div>
              )}

              {!isLoading && (
                <>
                  {dataList?.total ? (
                    <>
                      <Table
                        pagination={false}
                        columns={columns}
                        dataSource={dataList?.studentList}
                        className="bg-transparent table-component cursor-pointer"
                        rowKey="studentID"
                      />
                      <div className="flex justify-between items-center my-4 footer-course-sp sm:gap-3">
                        <span className="font-fontFamily text-sm text-main-font-color bottom-8">
                          {startPageSize} - {endPageSize} of {dataList?.total}
                        </span>
                        <PaginationCustom
                          total={dataList?.total}
                          pageSize={Number(filter.limit)}
                          onChange={(page) => {
                            setFilter((prev) => ({ ...prev, page }));
                          }}
                          current={filter.page}
                          onLastPage={() => {
                            setFilter((prev) => ({
                              ...prev,
                              page: Math.ceil(Number(dataList?.total) / Number(filter.limit)),
                            }));
                          }}
                          onFirstPage={() => {
                            setFilter((prev) => ({ ...prev, page: 1 }));
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <SearchNotFound isBackgroundWhite text={filter.search} />
                  )}
                </>
              )}
            </>
          )}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}

      <RemoveModal
        removeModal={removeTable}
        studentID={studentID}
        setStudentID={setStudentID}
        setRemoveModal={setRemoveTable}
        handleRefetching={handleRefetching}
        title="Leave Group"
        groupId={Number(groupId)}
        bodyText={
          studentID !== null
            ? 'Are you sure you want to Remove this student from this group ?'
            : 'Are you sure you want to Leave this Group ?'
        }
      />
    </div>
  );
};

export default GroupDetail;
