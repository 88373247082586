import React, { useCallback, useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "../available-course-stripe-card-section";
import ButtonCustom from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { DATE_FORMAT_TWO, ROUTES } from "constants/constants";
import { useMutation } from "react-query";
import { createCoursePayment } from "api/courses";
import { createCourseRegistration } from "api/courses_by_admission";
import { ICourseRegistration, ICourseRegistrationPayment } from "constants/types";
import moment from "moment";


function StripeElementsComponent(props: any) {
  const { id } = useParams();
  const history = useNavigate();
  const [stripeData, setStripeData] = useState<any>();
  const [isSubmit, setIsSubmit] = useState(false);
  const handleCancelBtn = useCallback(() => {  
    history(`${ROUTES.available_course}`); 
  },[]);

  const { mutate: handleCreateCoursePayment } = useMutation('createCoursePayment', createCoursePayment, {
    onSuccess: ({ data }: { data: any }) => {
      alert("Registration successful");
      setIsSubmit(false);
      handleCancelBtn(); 
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      alert("Payment not successful");
      if (error.response.status == 403) {
        alert("Payment not successful");
      } else {

      }
    },
  });

  const { mutate: handleCreateCourseRegistration } = useMutation('createCourseRegistration', createCourseRegistration, {
    onSuccess: ({ data }: { data: any }) => {
      let subscriptionDetail: any = localStorage.getItem('subscriptionDetails');
      console.log(subscriptionDetail);
      subscriptionDetail = JSON.parse(subscriptionDetail);
      let courseDetail: any = localStorage.getItem('currentCourse');
      courseDetail = JSON.parse(courseDetail);
      let currentCoursePrice: any = localStorage.getItem('currentCoursePrice');
      currentCoursePrice = JSON.parse(currentCoursePrice);
      let stripeDatals: any = localStorage.getItem('stripeData');
      stripeDatals = JSON.parse(stripeDatals);
      const dataRequests: ICourseRegistrationPayment = {
        studentName: subscriptionDetail.fullName,
        studentID: subscriptionDetail.studentID,
        courseID: Number(id),
        courseRegistrationId: data.id,
        courseName: courseDetail.courseName,
        stripeTransactionId: stripeDatals.id,
        coursePrice: Number(currentCoursePrice.coursePrice).toFixed(2),
        paymentType: "registration_fee",
        stripeMetaData: JSON.stringify(stripeDatals),
        studentEmail: subscriptionDetail.studentEmail,
        paymentStatus: "pending",
        paymentDate: new Date(),
        centreID: Number(subscriptionDetail.preferredCenter)
      }
      handleCreateCoursePayment(dataRequests);
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      setIsSubmit(false);
      if (error.response.status == 403) {
        alert("Registration not successful");
      } else {
      }
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmit(true);
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setIsSubmit(false);
      alert(result.error.message);
      console.log(result.error.message);
    } else { 
      setStripeData(result.token)
      //createCoursePayment
      localStorage.setItem('stripeData', JSON.stringify(result.token));
      let subscriptionDetail: any = localStorage.getItem('subscriptionDetails');
      subscriptionDetail = JSON.parse(subscriptionDetail);
      const dataRequests: ICourseRegistration = {

        firstName: subscriptionDetail.fullName,
        ICNumber: subscriptionDetail.ICNumber,
        gender: subscriptionDetail.gender,
        nationality: subscriptionDetail.nationality,
        address1: subscriptionDetail.address1,
        address2: subscriptionDetail.address2,
        country: subscriptionDetail.country,
        postalCode: subscriptionDetail.postalCode,
        dateOfBirth: moment(subscriptionDetail.dateOfBirth).format(DATE_FORMAT_TWO),
        preferredCenter: subscriptionDetail.preferredCenter,
        academicYear: subscriptionDetail.academicYear,
        studentDisease: subscriptionDetail.studentDisease,
        studentAllergy: subscriptionDetail.studentAllergy,
        additionalNotes: '',
        allergies: Boolean(subscriptionDetail.studentAllergy),
        allergiesDescription: "",
        centreID: Number(subscriptionDetail.preferredCenter),
        classID: 0,
        contactNumber: subscriptionDetail.contactNumber,
        courseID: Number(id),
        disabilities: Boolean(subscriptionDetail.studentDisease),
        disabilitiesDescription: "",
        lastName: subscriptionDetail.fullName.split(" ")[1],
        mobileCountryCode: "65",
        salutation: "Mr",
        status: "Pending",
        studentID: subscriptionDetail.studentID,
        sessionID: 1
      }
      if(result.token.id){
        handleCreateCourseRegistration(dataRequests);
      } else {
        alert("Something wrong with stripe! Please try again");
        setIsSubmit(false);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <div className="mt-6 mb-6 gap-[10px] flex justify-end flex-wrap cus-width-my-info">
          <>
          <ButtonCustom color="outline" onClick={handleCancelBtn} disabled={isSubmit}>
              Cancel
            </ButtonCustom>
            <ButtonCustom htmlType="submit" color="orange" disabled={!props.stripe || isSubmit} >
              {!isSubmit ? "Proceed" :"Processing"}
            </ButtonCustom>
          </>
        </div>
      </form>
    </div>
  );
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <StripeElementsComponent stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}