import { Calendar } from 'antd';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import type { Moment } from 'moment';
import React from 'react';
import { Card, Empty, Typography } from 'antd';

const { Title } = Typography;

const CalendarApp: React.FC = () => {
  const onPanelChange = (value: Moment, mode: CalendarMode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  return (
    <Card
      bordered={false}
      title={<Title level={4}>Calendar</Title>}
    //   extra={<a href="*">More</a>}
      className="w-full rounded-2xl"
    >
      <Calendar fullscreen={false} onPanelChange={onPanelChange} />
    </Card>
  );
};

export default CalendarApp;
