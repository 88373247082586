import { Breadcrumb, Layout, Tabs } from 'antd';
import { getStudentDetailById } from 'api/student';
import ModalCustom from 'components/Modal';
import { ROUTES } from 'constants/constants';
import usePrompt from 'constants/function';
import { WARNING_MESSAGE } from 'constants/messages';
import { IStudentProfile } from 'constants/types';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import StudentInformation from './student-information/index';

import './custom-student-detail.css';
import { routerUserAccess } from 'helper/function';
import { RoleName } from 'enum/role';

const StudentDetail = () => {
  const history = useNavigate();
  const routerUser = routerUserAccess();
  const { studentId } = useParams();
  const [activeKey, setActiveKey] = useState('1');
  const [isChanging, setIsChanging] = useState(false);
  const [isModalNotice, setIsModalNotice] = useState(false);
  const [tabNavigate, setTabNavigate] = useState('');
  const [profile, setProfile] = useState<IStudentProfile>();
  const firstName = profile?.user?.userProfile?.firstName || '';
  const lastName = profile?.user?.userProfile?.lastName || '';

  const handleChangeTab = (key: string) => {
    setTabNavigate(key);
    if (isChanging) {
      setIsModalNotice(true);
      return;
    }
    setActiveKey(key);
  };

  const { mutate: mutateGetStudentDetail } = useMutation(
    'getStudentDetailById',
    getStudentDetailById,
    {
      onSuccess: ({ data }) => {
        setProfile(data);
      },
    },
  );

  usePrompt(WARNING_MESSAGE.LEAVE_MANAGE_ADMIN_INFO, isChanging);

  useEffect(() => {
    if (studentId) {
      mutateGetStudentDetail({ id: Number(studentId) });
    }
  }, [studentId]);

  return (
    <>
    {
    routerUser.userRole === RoleName.TEACHER
    ?
    <Layout className="bg-transparent gap-y-6">
      <div className="flex justify-between items-center custom-student-detail">
        <Breadcrumb
          style={{
            color: '#AEA8A5',
            fontWeight: '700',
            lineHeight: '36px',
            fontSize: '28px',
          }}
          className="font-fontFamily text-main-font-color custom-sp-mb"
        >
          <Breadcrumb.Item
            className="opacity-50 cursor-pointer"
            onClick={() => {
              history(ROUTES.manage_student);
            }}
          >
            Students
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily text-main-font-color">
            {firstName + ' ' + lastName}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Tabs
        className="custom-tab"
        onChange={(key) => handleChangeTab(key)}
        activeKey={activeKey}
        size={'small'}
        style={{ marginBottom: 32 }}
      >
        <Tabs.TabPane tab="Information" key="1" style={{ outline: 'none' }}>
          <StudentInformation
            profile={profile}
            mutateGetStudentDetail={mutateGetStudentDetail}
          />
        </Tabs.TabPane>
      </Tabs>

      {isModalNotice && (
        <ModalCustom
          visible={true}
          cancelText="Cancel"
          onCancel={() => {
            setIsModalNotice(false);
          }}
          okText="Leave"
          onSubmit={() => {
            setIsChanging(false);
            setActiveKey(tabNavigate);
          }}
          title="Notice"
          titleCenter
          content={WARNING_MESSAGE.LEAVE_MANAGE_ADMIN_INFO}
        />
      )}
    </Layout>
    :
    <p className='font-bold text-2xl'>You don&apos;t have the permission to access this resource</p>
    }
    </>
  );
};

export default StudentDetail;
