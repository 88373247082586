import { getAllTemplate } from 'api/theme';
import Loading from 'components/Loading';
import { routerUserAccess } from 'helper/function';
import CardTheme from 'pages/admin/setting/component/CardTheme';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

const Setting = () => {
  const history = useNavigate();
  const location = useLocation()
  const routerUser = routerUserAccess()
  const [checkRoleUser, setCheckRoleUser] = useState(true);
  const { data, mutate, isLoading } = useMutation('getAllTemplate', getAllTemplate);

  useEffect(()=> {
    if(!routerUser.listRoute.length) setCheckRoleUser(true)
    else
      setCheckRoleUser(routerUser.listRoute.some((item: string) => location.pathname === item))
  }, [routerUser.listRoute.length])

  useEffect(() => {
    mutate();
  }, []);
  return (
    <>
    {
      checkRoleUser ? 
    <Loading isLoading={isLoading}>
      <div className="content-title">Themes</div>
      <div className="flex gap-6 justify-start flex-wrap f-justify-center">
        {data?.data.templates.map((theme: { id: number; templateName: string }) => {
          return (
            <>
              <CardTheme
                onClick={() => {
                  history(`/settings/templates/${theme.id}/themes`);
                }}
              >
                {theme.templateName}
              </CardTheme>
            </>
          );
        })}
      </div>
    </Loading>
    :
    <p className='font-bold text-2xl'>You don&apos;t have the permission to access this resource</p>
  }</>
  );
};

export default Setting;
