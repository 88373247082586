import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Dropdown, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import images from 'assets/images';
import ButtonCustom from 'components/Button';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { IEvent } from '../../constants';

import './cus-style-calendar.css';
import { useMutation } from 'react-query';
import { searchCourses } from 'api/courses';
import { searchCentres, searchCentresOfAdmin } from 'api/centres';
import { PARAMS_SELECT_SEARCH, TEXT_SELECT_SEARCH } from 'constants/constants';
import { getAllSessionByStudent, getAllSessionByTeacher, searchClass } from 'api/class';
import { routerUserAccess } from 'helper/function';
import { RoleName } from 'enum/role';
import SelectSearch from 'components/SelectSearch';
import { getAllEvent } from 'api/event';

interface ICalendarFilter {
  handleSearchCourses?: (value: string, classes?: string, centre?: string, course?: string) => void;
  handleReset?: (e: boolean) => void;
  onFinish?: (values: any) => void;
  onValuesChange?: (values: any) => void;
  dropdown?: {
    overlay: React.ReactElement;
    visible?: boolean;
  };
  form?: FormInstance<any>;
  handleChangeSearch?: (value: string) => void;
  searchResults?: IEvent[];
  keyResult?: string;
  dependencyFiled?: string;
  fieldNameReset?: string;
  handleSearchDependency?: (val: string | number) => void;
}

const CalendarFilter = (props: ICalendarFilter) => {
  const {
    handleSearchCourses,
    onFinish,
    onValuesChange,
    handleReset,
    handleChangeSearch,
    searchResults,
    keyResult,
    form,
    dependencyFiled,
    fieldNameReset,
    handleSearchDependency,
  } = props;
  const [valueSearch, setValueSearch] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [optionCentre, setOptionCentre] = useState<
    { label: string; value: string; isDisabled?: boolean }[]
  >([]);
  const [optionCourse, setOptionCourse] = useState<
    { label: string; value: string; isDisabled?: boolean }[]
  >([]);
  const [optionClass, setOptionClass] = useState<
    { label: string; value: string; isDisabled?: boolean; idCourse?: string }[]
  >([]);
  const [listClassByCourse, setListClassByCourse] = useState<
    { label: string; value: string; isDisabled?: boolean }[]
  >([]);
  const [listCourse, setListCourse] = useState<
    { label: string; value: string; isDisabled?: boolean }[]
  >([]);
  const [positionLimitByIcons, setPositionLimitByIcons] = useState<'UP' | 'DOWN'>('DOWN');
  const [optionFilterValue, setOptionFilterValue] = useState<IEvent[]>([]);
  const [optionFilterValueSession,setOptionFilterValureSession] = useState<IEvent[]>([]);

  const { mutate: searchListEvent } = useMutation('getListEvent', getAllEvent, {
    onSuccess: ({ data }: { data: IEvent[] }) => {
      console.log('run to get searchListEvent ====================>', data);

      const listEvent = data.map((event) => ({
        start: new Date(event.startDateTime || ''),
        end: new Date(event.endDateTime || ''),
        title: event.title,
        resource: {
          ...event,
        },
      }));
      setOptionFilterValue(listEvent);
    },
  });

  const { mutate: getListSession } = useMutation('getListSession', getAllSessionByTeacher, {
    onSuccess: ({ data }: { data: any[] }) => {
      const listEvent = data.map((event) => ({
        start: new Date(event.startTime || ''),
        end: new Date(event.endTime || ''),
        title: event.session.sessionName,
        resource: {
          ...event,
        },
        isSession:true
      }));
      setOptionFilterValureSession(listEvent) 
    },
  });

  const { mutate: getListSessionByStudent } = useMutation('getListSession', getAllSessionByStudent, {
    onSuccess: ({ data }: { data: any[] }) => {
      console.log('run to get getListSession ====================>', data);
      const listEvent = data.map((event) => ({
        start: new Date(event.startTime || ''),
        end: new Date(event.endTime || ''),
        title: event.session.sessionName,
        resource: {
          ...event,
        },
        isSession:true
      }));
      setOptionFilterValureSession(listEvent) 
    },
  });


  const [center, setCentre] = useState<string | null>(null);
  const [course, setCourse] = useState<string | null>(null);
  const [classes, setClasses] = useState<string | null>(null);
  const [session, setSession] = useState<string | null>(null);
  const [isFilterCourse, setIsFilterCourse] = useState<boolean>(false);
  const [isFilterClasses, setIsFilterClasses] = useState<boolean>(false);
  const routerUser = routerUserAccess();

  const { mutate: getCourses } = useMutation('searchCourses', searchCourses, {
    onSuccess: ({ data }: { data: { listCourses: Array<{ id: number; courseName: string }> } }) => {
      const newData = data.listCourses
        .map((item) => {
          return {
            value: item.id.toString(),
            label: item.courseName,
            isDisabled: false,
          };
        })
        .concat([{ label: TEXT_SELECT_SEARCH.course, value: '', isDisabled: true }]);
      setOptionCourse(newData);
    },
  });

  useEffect(() => {
    if (course) {
      if (classes) setClasses(null);
      const listClassConvert = [...optionClass].filter((e) => e.idCourse === course);
      setListClassByCourse(listClassConvert);
    } else {
      setListClassByCourse([]);
    }
  }, [course]);

  const { mutate: getCentres } = useMutation('searchCentres', searchCentres, {
    onSuccess: ({ data }: { data: { listCentres: { centreName: string; id: number }[] } }) => {
      const options = data.listCentres
        .map((item) => {
          return { label: item.centreName, value: item.id.toString(), isDisabled: false };
        })
        .concat([{ label: TEXT_SELECT_SEARCH.centre, value: '', isDisabled: true }]);
      setOptionCentre(options);
    },
  });

  const { mutate: searchClasses } = useMutation('searchClass', searchClass, {
    onSuccess: ({
      data,
    }: {
      data: {
        listClasses: {
          id: number;
          className: string;
          course: { id: number; courseName: string };
        }[];
      };
    }) => {
      const newOptions = data.listClasses
        .map((el) => {
          return {
            label: el.className + ' - ' + el.course.courseName,
            value: el.id.toString(),
            isDisabled: false,
            idCourse: el.course.id.toString(),
          };
        })
        .concat([{ label: TEXT_SELECT_SEARCH.class, value: '', isDisabled: true, idCourse: '' }]);
      setOptionClass(newOptions);
    },
  });

  const getData = () => {
    Promise.all([
      getCourses({ ...PARAMS_SELECT_SEARCH.course }),
      getCentres({ ...PARAMS_SELECT_SEARCH.centre }),
      searchClasses({ ...PARAMS_SELECT_SEARCH.class }),
    ]);
  };

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (isLoading) {
      getData();
    }
  }, [isLoading]);

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
    const param = {
      search: event.target.value.trim() === '' ? valueSearch : event.target.value.trim(),
      centre: center ?? undefined,
      classes: classes ?? undefined,
      course: course ?? undefined,
    }
    searchListEvent(param);
    if(routerUser.userRole !== RoleName.STUDENT){
      getListSession(param)
    }else{
      getListSessionByStudent(param);
    }
  };

  const dependency = Form.useWatch(dependencyFiled ? dependencyFiled : '', form);
  useEffect(() => {
    if (fieldNameReset) {
      form?.setFieldsValue({ [fieldNameReset]: '' });
      if (handleSearchDependency instanceof Function) {
        handleSearchDependency(dependency?.value);
      }
    }
  }, [dependency, fieldNameReset]);

  const onSearch = (title: string, id: string) => {
    setValueSearch(title);
    form?.setFieldsValue({ search: title });
    if (onFinish instanceof Function) {
      onFinish({ id });
    }
  };

  const searchResult = useMemo(
    () => (
      <>
        {valueSearch && keyResult ? (
          <div className="bg-white rounded-2xl p-4 min-h-[100px] custom-dropdown-search">
            {optionFilterValue && optionFilterValue?.length > 0 || optionFilterValueSession && optionFilterValueSession.length > 0 ? (
              [...optionFilterValue,...optionFilterValueSession]?.map((event: IEvent) => (
                <div
                  className="py-2 font-fontFamily font-normal cursor-pointer text-truncate"
                  key={event?.resource?.id}
                  onClick={() => onSearch(event?.title || '', event?.resource?.id || '')}
                >
                  {/* {event.isSession && 'Session : '} */}
                   {event.title} 
                </div>
              ))
            ) : valueSearch ? (
              <div className="text-center font-fontFamily font-normal pt-4 word-break">
                No results found for “{valueSearch}”
              </div>
            ) : null}
          </div>
        ) : (
          <div />
        )}
      </>
    ),
    [searchResults, valueSearch, keyResult],
  ) as ReactElement<string>;

  const dropdowOptionFilter = (
    listOption: any[],
    name: 'centre' | 'course' | 'classes' | 'session',
  ) => (
    <Form.Item name={name} className={'mb-0 custom-width-announcement-center-dropDown'}>
      <SelectSearch
        onChange={(e) => {
          switch (name) {
            case 'centre':
              setCentre(e?.value?.toString() ?? null);
              break;
            case 'course':
              setCourse(e?.value?.toString() ?? null);
              if (classes && e?.value) {
                const index = optionClass.findIndex((elm) => elm.value === classes);
                if (
                  index !== -1 &&
                  optionClass[index].idCourse &&
                  +optionClass[index].idCourse! !== e?.value
                ) {
                  form?.setFieldValue('classes', null);
                  setClasses(null);
                }
              }
              break;
            case 'classes':
              setClasses(e?.value?.toString() ?? null);
              break;
            case 'session':
              setSession(e?.value?.toString() ?? null);
              break;
            default:
              break;
          }
        }}
        handleSearchOptions={(e: string) => {
          switch (name) {
            case 'centre':
              break;
            case 'course':
              if (e.trim() !== '') {
                const listCourseConvert = [...optionCourse].filter((elm) =>
                  elm.label.toLocaleLowerCase().includes(`${e}`.toLocaleLowerCase()),
                );
                setListCourse(listCourseConvert);
                setIsFilterCourse(true);
              } else {
                setIsFilterCourse(false);
                setListCourse([]);
              }
              break;
            case 'classes':
              if (e.trim() !== '') {
                const listClass = (course ? [...listClassByCourse] : [...optionClass]).filter(
                  (elm) => elm.label.toLocaleLowerCase().includes(`${e}`.toLocaleLowerCase()),
                );
                setListClassByCourse(listClass);
                setIsFilterClasses(true);
              } else {
                setIsFilterClasses(false);
                if (course) {
                  const listClassConvert = [...optionClass].filter(
                    (elm) => elm.idCourse === course,
                  );
                  setListClassByCourse(listClassConvert);
                } else {
                  setListClassByCourse([]);
                }
              }
              break;
            // case 'session':
            //   setSession(e?.value?.toString() ?? null);
            //   break;
            default:
              break;
          }
        }}
        // value={
        //   name === 'centre'
        //     ? center
        //     : name === 'classes'
        //     ? classes
        //     : name === 'course'
        //     ? course
        //     : session
        // }
        className={`${name}__option__selected`}
        options={listOption}
        placeholder={`All ${name.charAt(0).toUpperCase() + name.slice(1)}`}
        disable={name === 'centre' || name === 'session'}
      />
    </Form.Item>
  );

  const optionFilter = () => (
    <div
      className="option__filter max-w-full"
      id="option__filter"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSearchCourses?.(
            valueSearch,
            classes ?? undefined,
            center ?? undefined,
            course ?? undefined,
          );
        }
      }}
    >
      <Row className="option__filter_container">
        {(routerUser.userRole === RoleName.ADMIN ||
          routerUser.userRole === RoleName.SUPER_ADMIN) && (
          <Col span={8} className="option__filter__col">
            <div className="title__option__filter">Centre</div>
            <div className="dropdow__option__filter">
              {dropdowOptionFilter(optionCentre, 'centre')}
            </div>
          </Col>
        )}

        <Col span={8} className="option__filter__col">
          <div className="title__option__filter">Course</div>
          <div className="dropdow__option__filter">
            {dropdowOptionFilter(isFilterCourse ? listCourse : optionCourse, 'course')}
          </div>
        </Col>
        <Col span={8} className="option__filter__col">
          <div className="title__option__filter">
            Class
            {(routerUser.userRole === RoleName.ADMIN ||
              routerUser.userRole === RoleName.SUPER_ADMIN) &&
              '(Optional)'}
          </div>
          <div className="dropdow__option__filter">
            {dropdowOptionFilter(
              isFilterClasses || course ? listClassByCourse : optionClass,
              'classes',
            )}
          </div>
        </Col>
        {routerUser.userRole === RoleName.TEACHER && (
          <Col span={8} className="option__filter__col">
            <div className="title__option__filter">Session</div>
            <div className="dropdow__option__filter">
              {dropdowOptionFilter(optionCentre, 'centre')}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );

  return (
    <div className="filter-card">
      <div className="filter-content w-full">
        <Form
          form={form}
          className="w-full flex flex-wrap gap-4"
          name="basic"
          initialValues={{ layout: 'inline' }}
          autoComplete="off"
          // onFinish={() => {
          //   handleSearchCourses?.(valueSearch, classes ?? '', center ?? '', course ?? '');
          // }}
          // onValuesChange={onValuesChange}
        >
          <div className="flex w-full gap-4 relative cus-flex">
            <div className="custom-width-col-search cus-flex-item">
              <Dropdown
                trigger={['click']}
                overlay={searchResult}
                placement="bottom"
                className="w-full h-full relative cus-width-min-180"
                getPopupContainer={(trigger: any) => trigger?.parentNode}
              >
                <div className="relative" onClick={(e) => e.preventDefault()}>
                  <Form.Item name="search" className="w-full mb-0">
                    <Input
                      value={valueSearch}
                      id="input__option"
                      placeholder="Search"
                      onPaste={(e)=>{
                        const mess = `${e.clipboardData.getData('Text')} `
                        setTimeout(() => {
                          setValueSearch(mess);
                          const param ={
                            search: mess.trim() === '' ? valueSearch : mess,
                            centre: center ?? undefined,
                            classes: classes ?? undefined,
                            course: course ?? undefined,
                          }
                          searchListEvent(param);
                          if(routerUser.userRole !== RoleName.STUDENT){
                            getListSession(param)
                          }else{
                            getListSessionByStudent(param);
                          }
                        }, 500);
                      }}
                      onChange={onChangeSearch}
                      suffix={<img src={images.search} alt="search" />}
                      onPressEnter={(e) => {
                        document.getElementById('input__option')?.click();
                        handleSearchCourses?.(
                          valueSearch,
                          classes ?? '',
                          center ?? '',
                          course ?? '',
                        );
                      }}
                      className="style_input_custom_login_page rounded-xl"
                    />
                  </Form.Item>
                </div>
              </Dropdown>
            </div>
            <div className="custom-width-col-limit">
              <Dropdown
                trigger={['click']}
                overlay={<div className="box__dropdown"></div>}
                placement="bottom"
                className="max-w-full h-full relative cus-flex-item asd"
                getPopupContainer={(trigger: any) => trigger?.parentNode}
                // onVisibleChange={() => {
                //   // if (positionLimitByIcon === 'DOWN') setPositionLimitByIcon('UP');
                // }}
              >
                <div
                  className="flex items-center justify-between border border-[#E9E6E5] border-solid rounded-xl px-4 bg-white custom-width cus-width-min-180 cus-height-min-50"
                  onClick={() => setPositionLimitByIcons((prev) => (prev === 'UP' ? 'DOWN' : 'UP'))}
                >
                  <p className="mb-0 font-fontFamily text-[#32302D]">Limit by </p>
                  {positionLimitByIcons === 'DOWN' ? (
                    <DownOutlined className="dropdown-icon" />
                  ) : (
                    <UpOutlined className="text-[10px]" />
                  )}
                </div>
              </Dropdown>
              {positionLimitByIcons === 'UP' && optionFilter()}
            </div>
            <div className="custom-width-col-button-search">
              <Form.Item
                className="basis-[15%] mb-0 cus-width-min-180 cus-flex-item"
                id="outSizeClick"
              >
                <ButtonCustom
                  className="h-12"
                  // htmlType="submit"
                  color="orange"
                  onClick={() => {
                    handleSearchCourses?.(valueSearch, classes ?? '', center ?? '', course ?? '');
                  }}
                >
                  Search
                </ButtonCustom>
              </Form.Item>
            </div>
            <div className="custom-width-col-button-search">
              <Form.Item className="mb-0 cus-flex-item">
                <ButtonCustom
                  className="h-12"
                  htmlType="button"
                  color="outline"
                  onClick={() => {
                    form?.resetFields();
                    setValueSearch('');
                    setClasses(null);
                    setCourse(null);
                    setCentre(null);
                    if (handleReset instanceof Function) {
                      handleReset(true);
                    }
                  }}
                  isWidthFitContent
                >
                  Reset
                </ButtonCustom>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CalendarFilter;
