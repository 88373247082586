import { Breadcrumb, Dropdown, Form, Layout, Menu, Table } from 'antd';
import { IParamsSearch } from 'api/courses';
import { checkTeachManagerClass, getModuleByClass } from 'api/module';
import PaginationCustom from 'components/Pagination';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import SelectCustom from 'components/Select';
import { ROUTES } from 'constants/constants';
import { IListModule, IModule } from 'constants/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ClassAttendanceFilter from './component/Filter';
import { ReactComponent as SortSVG } from 'assets/images/sort.svg';
import { routerUserAccess } from 'helper/function';
import { Buffer } from 'buffer';
import { RoleName } from 'enum/role';
import './style.css';
import { exportStudents } from 'api/student';
import { saveAs } from 'file-saver';
import Loading from 'components/Loading';
import ButtonCustom from 'components/Button';
const { Header, Content } = Layout;

const optionsLimit = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
];

export enum ExportType {
  ATTENDANCE = 'Attendance',
  SUMMARY = 'Summary',
}

const optionsExport = [
  { label: 'Export Attendance', value: ExportType.ATTENDANCE },
  { label: 'Export Summary', value: ExportType.SUMMARY },
];

const ClassAttendance = () => {
  const [form] = Form.useForm();
  const routerUser = routerUserAccess();
  const navigate = useNavigate();
  const { classId } = useParams();
  const [dataList, setDataList] = useState<IListModule>();
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [filter, setFilter] = useState<IParamsSearch>({
    page: 1,
    limit: 30,
    sort: 'moduleName',
    order: 'ASC',
    search: '',
  });
  const [searchResults, setSearchResults] = useState<IModule[]>([]);
  const [isTeacherManagerClass, setIsTeacherManagerClass] = useState<any>();
  const timeout: any = useRef(null);

  const { mutate: getModules } = useMutation('getModuleByClass', getModuleByClass, {
    onSuccess: ({ data }: { data: IListModule }) => {
      setDataList({
        page: data.page,
        limit: data.limit,
        sort: data.sort,
        order: data.order,
        total: data.total,
        listModules: data.listModules,
        students: data.students,
        classDetail: data.classDetail,
      });
    },
  });

  const { mutate: mutateExportStudents } = useMutation('exportStudents', exportStudents, {
    onSuccess: async ({ data }: { data: any }) => {
      const buffer = Buffer.from(data.buffer);
      const blob = new Blob([buffer]);
      saveAs(blob, data.fileName);
      setLoadingExport(false);
    },
  });

  const checkTeacherManagerClass = useQuery(['checkTeachManagerClass'], () =>
    checkTeachManagerClass(classId as any),
  );

  useEffect(() => {
    if (checkTeacherManagerClass.status === 'success') {
      setIsTeacherManagerClass(checkTeacherManagerClass.data.data);
    }
  }, [checkTeacherManagerClass]);

  const { mutate: searchModules } = useMutation('searchModuleByClass', getModuleByClass, {
    onSuccess: ({ data }: { data: IListModule }) => {
      setSearchResults(data.listModules);
    },
  });

  useEffect(() => {
    if (classId) {
      getModules({ ...filter, id: +classId });
    }
  }, [classId, filter]);

  const startPageSize = useMemo(() => {
    const startSize = Number(filter.page) * Number(filter?.limit) - (Number(filter?.limit) - 1);

    return startSize;
  }, [filter.page, filter?.limit]);

  const endPageSize = useMemo(() => {
    let endSize = Number(filter.page) * Number(filter?.limit);
    endSize = dataList?.total && endSize < dataList?.total ? endSize : (dataList?.total as number);

    return endSize;
  }, [filter.page, filter?.limit, dataList?.total]);

  const onChangeLimit = useCallback(
    (value: string) => {
      const total = dataList?.total;
      const maxPage = Math.ceil(Number(total) / Number(value));
      if (filter.page > maxPage) setFilter((prev) => ({ ...prev, page: maxPage, limit: +value }));
      else setFilter({ ...filter, limit: +value });
    },
    [filter.page, dataList?.total],
  );

  const onExport = (value: string) => {
    if (value) {
      setLoadingExport(true);

      mutateExportStudents({ classId: parseInt(classId || '0'), typeExport: value as ExportType });
    }
  };

  const columns: any = [
    {
      title: (
        <span
          className="font-fontFamily"
          onClick={() => {
            setFilter({
              ...filter,
              order: filter.order === 'ASC' ? 'DESC' : 'ASC',
            });
          }}
        >
          Module <SortSVG />
        </span>
      ),
      dataIndex: 'moduleName',
      key: 'moduleName',
      render: (value: string, record: any) => {
        const codeMudule =
          record?.moduleCode && record?.moduleCode !== '' ? `(${record?.moduleCode})` : '';
        return <span className="font-fontFamily font-semibold">{value + ' ' + codeMudule}</span>;
      },
      width: '80%',
    },
    {
      title: <span className="font-fontFamily">No. of Students</span>,
      dataIndex: 'students',
      key: 'students',
      render: (value: string, record: IListModule) => (
        <span className="font-fontFamily font-semibold text-left">{dataList?.students || 0}</span>
      ),
    },
  ];

  const debounceSearch = useCallback(
    (value: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        searchModules({
          search: value,
          page: 1,
          limit: 300,
          sort: 'moduleName',
          order: 'ASC',
          id: Number(classId),
        });
      }, 500);
    },
    [timeout],
  );

  const handleChangeSearch = useCallback(
    (value: string) => {
      debounceSearch(value);
    },
    [filter?.search],
  );

  const handleReset = () => {
    setFilter((prev) => ({ ...prev, search: '', filters: undefined }));
    searchModules({
      search: '',
      page: 1,
      limit: 300,
      sort: 'moduleName',
      order: 'ASC',
      id: Number(classId),
    });
  };

  const onFinish = (values: { search: string; moduleId?: string | number }) => {
    setFilter((prev) => ({
      ...prev,
      search: values.search,
      filters: JSON.stringify([{ id: values.moduleId }]),
      page: 1,
    }));
  };

  const redirectToListSession = (id?: number, moduleName?: string) => {
    navigate(`${ROUTES.class_management}/${classId}${ROUTES.attendance}/module/${id}`, {
      state: {
        moduleName,
        className: dataList?.classDetail?.className,
      },
    });
  };

  const onRow = (record?: IModule, rowIndex?: number) => {
    const codeMudule =
      record?.moduleCode && record?.moduleCode !== '' ? `(${record?.moduleCode})` : '';
    return {
      onClick: () => redirectToListSession(record?.id, record?.moduleName + ' ' + codeMudule),
    };
  };

  return (
    <>
      {routerUser.userRole === RoleName.TEACHER && isTeacherManagerClass != null ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <Header className="flex justify-between items-center bg-transparent px-0 h-auto">
            <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
              <Breadcrumb.Item
                className="cursor-pointer font-fontFamily"
                onClick={() => navigate(ROUTES.class_management)}
              >
                Class Management
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-fontFamily">
                {dataList?.classDetail?.className}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-fontFamily text-main-font-color">
                Attendance
              </Breadcrumb.Item>
            </Breadcrumb>
          </Header>

          <Content className="flex flex-col gap-y-6">
            <ClassAttendanceFilter
              form={form}
              searchResults={searchResults}
              keyResult="searchCourses"
              handleChangeSearch={handleChangeSearch}
              onFinish={onFinish}
              handleReset={handleReset}
              classId={Number(classId)}
              redirectToListSession={redirectToListSession}
            />

            <div className="flex justify-between items-center">
              <div>
                <p className="text-2xl font-fontFamily leading-9 font-bold mb-0"></p>
                <div className="flex gap-4 items-center flex-wrap">
                  <Loading isLoading={loadingExport}>
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <Menu className="menu-folder">
                          {optionsExport.map((element) => (
                            <Menu.Item onClick={() => onExport(element.value)} key={element.value}>
                              {element.label}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                    >
                      <ButtonCustom
                        className="h-12 unset-min-width w-full w-200"
                        htmlType="submit"
                        color="orange"
                      >
                        Export
                      </ButtonCustom>
                    </Dropdown>
                    {/* <Select
                      value={null}
                      getPopupContainer={(triggerNode) => triggerNode}
                      onChange={onExport}
                      placeholder={'Export'}
                      allowClear
                      options={optionsExport}
                      className="text-main-font-color font-fontFamily text-sm min-w-200"
                    /> */}
                  </Loading>
                  {/* <SelectCustom
                    placeholder="Export"
                    color="transparent"
                    className="w-200"
                    value={null}
                    onChange={onExport}
                    options={optionsExport}
                  /> */}
                </div>
              </div>
              <div>
                <p className="text-2xl font-fontFamily leading-9 font-bold mb-0"></p>
                <div className="flex gap-4 items-center flex-wrap">
                  <p className="font-fontFamily font-bold mb-0">View Item</p>
                  <SelectCustom
                    color="transparent"
                    value={filter.limit.toString()}
                    onChange={onChangeLimit}
                    options={optionsLimit}
                  />
                </div>
              </div>
            </div>
          </Content>

          {dataList?.total ? (
            <>
              <Table
                pagination={false}
                columns={columns}
                dataSource={dataList?.listModules}
                className="bg-transparent table-component cursor-pointer"
                rowKey="id"
                onRow={onRow}
              />
              <div className="flex justify-between items-center my-4 footer-course-sp sm:gap-3">
                <span className="font-fontFamily text-sm text-main-font-color bottom-8">
                  {startPageSize} - {endPageSize} of {dataList?.total}
                </span>
                <PaginationCustom
                  total={dataList?.total}
                  pageSize={Number(filter.limit)}
                  onChange={(page) => {
                    setFilter((prev) => ({ ...prev, page }));
                  }}
                  current={filter.page}
                  onLastPage={() => {
                    setFilter((prev) => ({
                      ...prev,
                      page: Math.ceil(Number(dataList?.total) / Number(filter.limit)),
                    }));
                  }}
                  onFirstPage={() => {
                    setFilter((prev) => ({ ...prev, page: 1 }));
                  }}
                />
              </div>
            </>
          ) : (
            <SearchNotFound isBackgroundWhite text={filter.search} />
          )}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default ClassAttendance;
