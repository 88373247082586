import { Checkbox, Form, FormInstance, Input, Select } from 'antd';
import CustomInput from 'components/Input';
import ModalCustom from 'components/Modal';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { IUserShare } from '..';
import TextArea from 'antd/lib/input/TextArea';
import ButtonCustom from 'components/Button';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: { user: number[]; notify: boolean; message: string }) => void;
  isKeepOpen: boolean;
  form: FormInstance<any>;
  listUsers: IUserShare[];
  loading: boolean;
  nameShare: string;
  onSearch: (value: string) => void;
}

const ModalShare = (props: IProps) => {
  const { visible, onCancel, onSubmit, isKeepOpen, form, listUsers, loading, nameShare, onSearch } =
    props;

  const notify = Form.useWatch('notify', form);

  const handleSubmit = useCallback(
    (values: { user: number[]; notify: boolean; message: string }) => {
      onSubmit(values);
    },
    [listUsers],
  );

  const handleSearch = useCallback((value: string) => {
    if (value) {
      onSearch(value);
    }
  }, []);

  const listUserElement = useMemo(() => {
    return (
      <>
        {listUsers.map((element) => (
          <Select.Option
            key={element.email}
            value={element.id}
            placeholder="Insert Email Address/Group of people"
          >
            <p className="m-b-0">
              {element.userProfile.firstName || ''} {element.userProfile.lastName}
            </p>
            <p className="m-b-0">{element.email}</p>
          </Select.Option>
        ))}
        <Select.Option key={'type_to_search'} value={''} disabled>
          Type to search for more users
        </Select.Option>
      </>
    );
  }, [listUsers]) as ReactElement<string>;

  return (
    <ModalCustom
      onCancel={onCancel}
      loading={loading}
      onSubmit={form.submit}
      visible={visible}
      title={'Share with people'}
      cancelText="Cancel"
      okText={'Share'}
      isKeepOpen={isKeepOpen}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item name="user" rules={[{ required: true, message: 'People is required!' }]}>
          <Select
            placeholder={'Insert Email Address/Group of people'}
            allowClear
            mode="multiple"
            showSearch
            className="text-main-font-color font-fontFamily text-sm min-w-200 h-100-pc custom-select-share"
            filterOption={false}
            onSearch={(value) => handleSearch(value)}
          >
            {listUserElement}
          </Select>
        </Form.Item>
        <Form.Item name="notify" valuePropName="checked">
          <Checkbox className="custom-checkbox">Notify People</Checkbox>
        </Form.Item>
        <Form.Item label="Message" name="message">
          <TextArea
            disabled={!notify}
            placeholder="Message"
            className="h-140 custom-area"
          ></TextArea>
        </Form.Item>

        <Form.Item className="mb-0">
          <div className=" unset-min-width w-full custom-name-share">{`${nameShare}`}</div>
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default ModalShare;
