import { AppContext } from 'context';
import { useContext } from 'react';

export const isEmptyObject = (obj: any) => {
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
  }
  return true;
};

export const truncateString = (str: string, stringLength: number) => {
  if (str.length > stringLength) {
    return str.slice(0, stringLength) + '...';
  } else {
    return str;
  }
};

export const getDaysArray = (start: Date, end: Date) => {
  const arr = [];
  for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    const newDate = new Date(dt);
    arr.push(new Date(newDate.setHours(23, 59, 59, 0)));
  }
  return arr;
};

export const routerUserAccess = () => {
  const [state]: any = useContext(AppContext);
  const teacherData = state?.user?.teacher || null;
  const studentPayload = state?.user?.student || null;

  const userRoute: [] = state?.user?.userRole?.sidebarMenus;
  const userRole = state?.user?.userRole?.roleName;

  const id = state?.user?.id;
  const listRoute: any[] = [];
  if (userRoute) {
    userRoute.forEach((item: any) => {
      listRoute.push(item.resourcePath);

      if (item.menuChildren.length) {
        item.menuChildren.forEach((element: any) => {
          listRoute.push(element.resourcePath);

          if (element.menuChildren.length) {
            element.menuChildren.forEach((e: any) => {
              listRoute.push(e.resourcePath);
            });
          }
        });
      }
    });
  }
  // console.log(listRoute);

  return { listRoute, userRole, id, teacherData, studentPayload };
};
