import {
  Breadcrumb,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  message,
  notification,
  Row,
  Table,
} from 'antd';
import { getListRoles } from 'api/access_control';
import { ReactComponent as SortSVG } from 'assets/images/sort.svg';
import {
  deleteAllFileDrive,
  deleteFileDrive,
  ETimeQuery,
  getFileDrive,
  restoreFileDrive,
  TrashType,
} from 'api/trash-drive';
import { ReactComponent as CsvIcon } from 'assets/images/CSV.svg';
import images from 'assets/images';
import { ReactComponent as FolderSVG } from 'assets/images/folder.svg';
import { ReactComponent as TrashSVG } from 'assets/images/trash.svg';
import ButtonCustom from 'components/Button';
import Loading from 'components/Loading';
import ModalCustom from 'components/Modal';
import SelectCustom from 'components/Select';
import { REGEX_IMAGE, ROUTES } from 'constants/constants';
import moment from 'moment';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routerUserAccess } from 'helper/function';
import { checkTeachManagerClass } from 'api/module';
import { RoleName } from 'enum/role';
import { getAllGlobalSetting } from 'api/global_setting';
import { keyBy } from 'lodash';
import { getClassById } from 'api/class';

const { Header, Content } = Layout;

const optionsFilter = [
  { label: 'Name (A-Z)', value: 'A-Z' },
  { label: 'Name (Z-A)', value: 'Z-A' },
  { label: 'Trash Date (Ascending)', value: 'ASC' },
  { label: 'Trash Date (Descending)', value: 'DESC' },
];

export interface IDriveInfo {
  id: number;
  deleteBy: number;
  deleteType: string;
  size: string;
  pathInTrash: string;
  pathToRestore: string;
  deleteAt: string;
  url: string;
  deleter: {
    id: number;
    email: string;
    userProfile: {
      contactNumber: string;
      firstName: string;
      lastName: string;
      mobileCountryCode: string;
      profilePhotoDestination: string;
    };
  };
}

const MyDriveTrashDrive = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const paramsQuery = useParams();
  const routerUser = routerUserAccess();
  const [classId, setClassId] = useState<string | undefined>(undefined);
  const [className, setClassName] = useState<string | null>(null);
  const [filterToday, setFilterToday] = useState<string>('A-Z');
  const [filterThisMonth, setFilterThisMonth] = useState<string>('A-Z');
  const [filterWeek, setFilterWeek] = useState<string>('A-Z');
  const [filterLastMonth, setFilterLastMonth] = useState<string>('A-Z');
  const [filterOlder, setFilterOlder] = useState<string>('A-Z');
  const [filterYesterday, setFilterYesterday] = useState<string>('A-Z');
  const [dataListToday, setDataListToday] = useState<IDriveInfo[]>([]);
  const [dataListYesterday, setDataListYesterday] = useState<IDriveInfo[]>([]);
  const [dataListWeek, setDataListWeek] = useState<IDriveInfo[]>([]);
  const [dataListThisMonth, setDataListThisMonth] = useState<IDriveInfo[]>([]);
  const [dataListLastMonth, setDataListLastMonth] = useState<IDriveInfo[]>([]);
  const [dataListOlder, setDataListOlder] = useState<IDriveInfo[]>([]);
  const [isShowRestore, setIsShowRestore] = useState<IDriveInfo>();
  const [isShowDelete, setIsShowDelete] = useState<IDriveInfo>();
  const [isShowDeleteAll, setIsShowDeleteAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isTeacherManagerClass, setIsTeacherManagerClass] = useState<any>();
  const [MYDRIVE_USER_TRASH, setMYDRIVE_USER_TRASH] = useState<number>();
  const [MYDRIVE_ADMIN_TRASH, setMYDRIVE_ADMIN_TRASH] = useState<number>(90);

  const [checkRoleUser, setCheckRoleUser] = useState(true);

  const menu = (data: IDriveInfo) => (
    <Menu>
      <Menu.Item onClick={() => setIsShowRestore(data)}>Restore</Menu.Item>
      <Menu.Item onClick={() => setIsShowDelete(data)}>Delete Permanently</Menu.Item>
    </Menu>
  );

  const handleChangeSearch = useCallback((value: ChangeEvent<HTMLInputElement>) => {
    // debounceSetSearchFor(value.target.value);
  }, []);

  const { data: dataGlobalSetting, refetch } = useQuery(['getAllGlobalSetting'], () =>
    getAllGlobalSetting(),
  );

  // const searchResult = useMemo(
  //   () => (
  //     <>
  //       {keySearch ? (
  //         <div className="bg-white rounded-2xl p-4 min-h-[100px]">
  //           {searchResults?.length > 0 ? (
  //             searchResults?.map((data) => (
  //               <div
  //                 className="py-2 font-fontFamily font-normal cursor-pointer"
  //                 onClick={() => form.setFieldValue('search', data.pathInTrash.split('/')[1])}
  //                 key={data.id}
  //               >
  //                 {data.pathInTrash.split('/')[1]}
  //               </div>
  //             ))
  //           ) : keySearch ? (
  //             <div className="text-center font-fontFamily font-normal pt-4">
  //               No results found for “{keySearch}”
  //             </div>
  //           ) : null}
  //         </div>
  //       ) : (
  //         <div />
  //       )}
  //     </>
  //   ),
  //   [searchResults],
  // ) as ReactElement<string>;

  const { mutate: mutateDataListToday, isLoading } = useMutation(
    'getListTrashDrive',
    getFileDrive,
    {
      onSuccess: ({
        data,
      }: {
        data: {
          binToday: IDriveInfo[];
          binWeek: IDriveInfo[];
          binThisMonth: IDriveInfo[];
          binLastMonth: IDriveInfo[];
          binOlder: IDriveInfo[];
          yesterday: IDriveInfo[];
        };
      }) => {
        if (data.binLastMonth) {
          if (filterLastMonth === 'A-Z') {
            setDataListLastMonth([
              ...data.binLastMonth.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
            ]);
          }

          if (filterLastMonth === 'Z-A') {
            setDataListLastMonth([
              ...data.binLastMonth.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
            ]);
          }

          if (filterLastMonth === 'DESC') {
            setDataListLastMonth([
              ...data.binLastMonth.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
            ]);
          }

          if (filterLastMonth === 'ASC') {
            setDataListLastMonth([
              ...data.binLastMonth.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
            ]);
          }
        } else {
          setDataListLastMonth([]);
        }

        if (data.yesterday) {
          if (filterYesterday === 'A-Z') {
            setDataListYesterday([
              ...data.yesterday.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
            ]);
          }

          if (filterYesterday === 'Z-A') {
            setDataListYesterday([
              ...data.yesterday.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
            ]);
          }

          if (filterYesterday === 'DESC') {
            setDataListYesterday([
              ...data.yesterday.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
            ]);
          }

          if (filterYesterday === 'ASC') {
            setDataListYesterday([
              ...data.yesterday.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
            ]);
          }
        } else {
          setDataListYesterday([]);
        }

        if (data.binThisMonth) {
          if (filterThisMonth === 'A-Z') {
            setDataListThisMonth([
              ...data.binThisMonth.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
            ]);
          }

          if (filterThisMonth === 'Z-A') {
            setDataListThisMonth([
              ...data.binThisMonth.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
            ]);
          }

          if (filterThisMonth === 'DESC') {
            setDataListThisMonth([
              ...data.binThisMonth.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
            ]);
          }

          if (filterThisMonth === 'ASC') {
            setDataListThisMonth([
              ...data.binThisMonth.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
            ]);
          }
        } else {
          setDataListThisMonth([]);
        }

        if (data.binOlder) {
          if (filterOlder === 'A-Z') {
            setDataListOlder([
              ...data.binOlder.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
            ]);
          }

          if (filterOlder === 'Z-A') {
            setDataListOlder([
              ...data.binOlder.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
            ]);
          }

          if (filterOlder === 'DESC') {
            setDataListOlder([
              ...data.binOlder.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
            ]);
          }

          if (filterOlder === 'ASC') {
            setDataListOlder([
              ...data.binOlder.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
            ]);
          }
        } else {
          setDataListOlder([]);
        }

        if (data.binWeek) {
          if (filterWeek === 'A-Z') {
            setDataListWeek([
              ...data.binWeek.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
            ]);
          }

          if (filterWeek === 'Z-A') {
            setDataListWeek([
              ...data.binWeek.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
            ]);
          }

          if (filterWeek === 'DESC') {
            setDataListWeek([...data.binWeek.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt))]);
          }

          if (filterWeek === 'ASC') {
            setDataListWeek([...data.binWeek.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt))]);
          }
        } else {
          setDataListWeek([]);
        }

        if (data.binToday) {
          if (filterToday === 'A-Z') {
            setDataListToday([
              ...data.binToday.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
            ]);
          }

          if (filterToday === 'Z-A') {
            setDataListToday([
              ...data.binToday.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
            ]);
          }

          if (filterToday === 'DESC') {
            setDataListToday([
              ...data.binToday.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
            ]);
          }

          if (filterToday === 'ASC') {
            setDataListToday([
              ...data.binToday.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
            ]);
          }
        } else {
          setDataListToday([]);
        }
        setLoading(false);
      },
    },
  );

  const onSearch = (values: { search: string }) => {
    setLoading(true);
    mutateDataListToday({
      fullTextSearch: values.search?.trim() ? values.search.trim() : undefined,
      type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
      classId: classId,
    });
  };

  const columns = [
    {
      title: <></>,
      dataIndex: 'Key',
      key: 'Type',
      render: (text: string, record: IDriveInfo) => {
        return (
          <div>
            {record?.deleteType === 'Folder' && <FolderSVG />}
            {record?.deleteType === 'File' && (
              <React.Fragment key={record.id}>
                {record.pathInTrash && REGEX_IMAGE.test(record.pathInTrash.toLowerCase()) ? (
                  <img className="w-full h-full" src={record?.url} alt="image" />
                ) : (
                  <CsvIcon />
                )}
              </React.Fragment>
            )}
          </div>
        );
      },
      width: '5%',
    },
    {
      title: <div className="flex items-center">Name</div>,
      dataIndex: 'Key',
      key: 'Name',
      width: '45%',
      render: (text: string, record: IDriveInfo) => {
        return <div>{record?.pathInTrash || ''}</div>;
      },
    },
    {
      title: <div className="flex items-center">Trashed Date</div>,
      dataIndex: 'deleteAt',
      key: 'deleteAt',
      width: '15%',
      render: (text: string, record: IDriveInfo) => (
        <div>{moment.utc(text).local().format('YYYY/MM/DD')}</div>
      ),
    },
    {
      title: <div className="flex items-center">Size</div>,
      dataIndex: 'size',
      key: 'size',
      width: '15%',
      render: (text: string, record: IDriveInfo) => <div>{text}</div>,
    },
    {
      title: <div className="flex items-center">Owner</div>,
      dataIndex: 'Owner',
      key: 'Owner',
      width: '15%',
      render: (text: any, record: IDriveInfo) => (
        <div>
          {record?.deleter?.userProfile?.firstName || ''}{' '}
          {record?.deleter?.userProfile?.lastName || ''}
        </div>
      ),
    },
    {
      title: <></>,
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: IDriveInfo) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <a>...</a>
        </Dropdown>
      ),
      width: '5%',
    },
  ];

  const onChangeFilter = (value: string, type: ETimeQuery) => {
    switch (type) {
      case ETimeQuery.TODAY:
        setFilterToday(value);

        if (value === 'A-Z') {
          setDataListToday((prevState) => [
            ...prevState.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
          ]);
        }

        if (value === 'Z-A') {
          setDataListToday((prevState) => [
            ...prevState.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
          ]);
        }

        if (value === 'DESC') {
          setDataListToday((prevState) => [
            ...prevState.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
          ]);
        }

        if (value === 'ASC') {
          setDataListToday((prevState) => [
            ...prevState.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
          ]);
        }

        break;

      case ETimeQuery.YESTERDAY:
        setFilterYesterday(value);

        if (value === 'A-Z') {
          setDataListYesterday((prevState) => [
            ...prevState.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
          ]);
        }

        if (value === 'Z-A') {
          setDataListYesterday((prevState) => [
            ...prevState.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
          ]);
        }

        if (value === 'DESC') {
          setDataListYesterday((prevState) => [
            ...prevState.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
          ]);
        }

        if (value === 'ASC') {
          setDataListYesterday((prevState) => [
            ...prevState.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
          ]);
        }

        break;
      case ETimeQuery.LAST_MONTH:
        setFilterLastMonth(value);
        if (value === 'A-Z') {
          setDataListLastMonth((prevState) => [
            ...prevState.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
          ]);
        }

        if (value === 'Z-A') {
          setDataListLastMonth((prevState) => [
            ...prevState.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
          ]);
        }

        if (value === 'DESC') {
          setDataListLastMonth((prevState) => [
            ...prevState.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
          ]);
        }

        if (value === 'ASC') {
          setDataListLastMonth((prevState) => [
            ...prevState.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
          ]);
        }

        break;
      case ETimeQuery.OLDER:
        setFilterOlder(value);
        if (value === 'A-Z') {
          setDataListOlder((prevState) => [
            ...prevState.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
          ]);
        }

        if (value === 'Z-A') {
          setDataListOlder((prevState) => [
            ...prevState.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
          ]);
        }

        if (value === 'DESC') {
          setDataListOlder((prevState) => [
            ...prevState.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
          ]);
        }

        if (value === 'ASC') {
          setDataListOlder((prevState) => [
            ...prevState.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
          ]);
        }

        break;
      case ETimeQuery.THIS_MONTH:
        setFilterThisMonth(value);
        if (value === 'A-Z') {
          setDataListThisMonth((prevState) => [
            ...prevState.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
          ]);
        }

        if (value === 'Z-A') {
          setDataListThisMonth((prevState) => [
            ...prevState.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
          ]);
        }

        if (value === 'DESC') {
          setDataListThisMonth((prevState) => [
            ...prevState.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
          ]);
        }

        if (value === 'ASC') {
          setDataListThisMonth((prevState) => [
            ...prevState.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
          ]);
        }

        break;
      case ETimeQuery.WEEK:
        setFilterWeek(value);
        if (value === 'A-Z') {
          setDataListWeek((prevState) => [
            ...prevState.sort((a, b) => a.pathInTrash.localeCompare(b.pathInTrash)),
          ]);
        }

        if (value === 'Z-A') {
          setDataListWeek((prevState) => [
            ...prevState.sort((a, b) => b.pathInTrash.localeCompare(a.pathInTrash)),
          ]);
        }

        if (value === 'DESC') {
          setDataListWeek((prevState) => [
            ...prevState.sort((a, b) => b.deleteAt.localeCompare(a.deleteAt)),
          ]);
        }

        if (value === 'ASC') {
          setDataListWeek((prevState) => [
            ...prevState.sort((a, b) => a.deleteAt.localeCompare(b.deleteAt)),
          ]);
        }
        break;
      default:
        break;
    }
  };

  const { mutate: mutateDeleteDrive } = useMutation('deleteDrive', deleteFileDrive, {
    onSuccess: () => {
      setIsShowDelete(undefined);
      mutateDataListToday({
        fullTextSearch: form.getFieldValue(['search']) ? form.getFieldValue(['search']) : undefined,
        type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
        classId: classId,
      });
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      const response = error.response;
      if (response.status === 400) {
        message.error(response.data.message);
      }
    },
  });

  const { mutate: mutateRestoreDrive } = useMutation('deleteDrive', restoreFileDrive, {
    onSuccess: ({ data }: { data: { message: string } }) => {
      setIsShowRestore(undefined);
      notification.success({ message: data.message });
      mutateDataListToday({
        fullTextSearch: form.getFieldValue(['search']) ? form.getFieldValue(['search']) : undefined,
        type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
        classId: classId,
      });
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      const response = error.response;
      if (response.status === 400) {
        message.error(response.data.message);
      }
    },
  });

  const { mutate: mutateDeleteAllDrive } = useMutation('deleteAllDrive', deleteAllFileDrive, {
    onSuccess: () => {
      setIsShowDelete(undefined);
      mutateDataListToday({
        fullTextSearch: form.getFieldValue(['search']) ? form.getFieldValue(['search']) : undefined,
        type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
        classId: classId,
      });
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      const response = error.response;
      if (response.status === 400) {
        message.error(response.data.message);
      }
    },
  });

  const { mutate: checkTeacherManagerClass } = useMutation(
    'deleteAllDrive',
    checkTeachManagerClass,
    {
      onSuccess: (data) => {
        setIsTeacherManagerClass(data.data);
      },
    },
  );

  const onDelete = (data: IDriveInfo) => {
    setLoading(true);
    mutateDeleteDrive({
      binId: data.id,
      type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
    });
  };

  const onRestore = (data: IDriveInfo) => {
    setLoading(true);
    mutateRestoreDrive({
      binId: data.id,
      type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
    });
  };

  // useEffect(() => {
  //   const dataListSort =
  //     filter == 'Name'
  //       ? dataList.sort(function (a, b) {
  //           return ('' + a.Key).localeCompare(b.Key);
  //         })
  //       : dataList.sort(function (a, b) {
  //           return moment(b.LastModified).unix() - moment(a.LastModified).unix();
  //         });
  //   setDataList([...dataListSort]);
  // }, [filter]);

  // useEffect(() => {
  //   setSearchResults(dataList.filter((data) => data.Key.split('/')[1].includes(keySearch)));
  // }, [keySearch]);

  const { mutate: getClass } = useMutation('getClass', getClassById, {
    onSuccess: ({ data }) => {
      setClassName(data.className);
    },
  });

  useEffect(() => {
    if (classId) {
      getClass(+parseInt(classId));
    }
  }, [classId]);

  useEffect(() => {
    if (dataGlobalSetting?.data) {
      const _globalSetting = keyBy(dataGlobalSetting?.data, 'settingKey');

      setMYDRIVE_ADMIN_TRASH(parseInt(_globalSetting['MYDRIVE_ADMIN_TRASH']?.settingValue));
      setMYDRIVE_USER_TRASH(parseInt(_globalSetting['MYDRIVE_USER_TRASH']?.settingValue));
    }
  }, [dataGlobalSetting]);

  useEffect(() => {
    if (paramsQuery?.classId) setClassId(paramsQuery.classId);
    else {
      const search = window.location.search;
      const paramsSearch = new URLSearchParams(search);
      const classIdSearch = paramsSearch.get('classId');
      if (classIdSearch) {
        setClassId(classIdSearch);
      }
    }
  }, [paramsQuery]);

  useEffect(() => {
    if (routerUser.id) {
      let classIdQuery = paramsQuery.classId;

      if (!paramsQuery.classId) {
        const search = window.location.search;
        const paramsSearch = new URLSearchParams(search);
        const classIdSearch = paramsSearch.get('classId');
        if (classIdSearch) {
          classIdQuery = classIdSearch;
        }
      }
      mutateDataListToday({
        type: classIdQuery ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
        classId: classIdQuery,
      });
    }
  }, [routerUser.id]);

  useEffect(() => {
    if (classId) checkTeacherManagerClass(classId as any);
  }, [classId]);

  useEffect(() => {
    if (routerUser.listRoute.length === 0) setCheckRoleUser(true);
    else
      setCheckRoleUser(
        routerUser.listRoute.some((item: string) => location.pathname === item) ||
          (routerUser.userRole === RoleName.TEACHER && isTeacherManagerClass != null) ||
          routerUser.userRole === RoleName.STUDENT,
      );
  }, [routerUser.listRoute.length, isTeacherManagerClass]);

  return (
    <>
      {checkRoleUser ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <Header className="flex justify-between items-center bg-transparent px-0 header-trash">
            <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
              {!!classId ? (
                <>
                  <Breadcrumb.Item
                    className="cursor-pointer font-fontFamily"
                    onClick={() => navigate(ROUTES.class_management)}
                  >
                    Class Management
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font-fontFamily">
                    {className ? className : ''}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font-fontFamily text-main-font-color">
                    Trash Drive
                  </Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item className="font-fontFamily text-main-font-color">
                  Trash for Own Drive
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </Header>
          <Content className="flex flex-col gap-y-6">
            <div className="filter-card">
              <Form
                className="w-full"
                name="basic"
                autoComplete="off"
                form={form}
                onFinish={onSearch}
              >
                <Row className="flex gap-4 items-center">
                  {/* <Col className="w-[calc(60%_-_0.67rem)]" xs={24} sm={17} md={17} lg={17} xl={17}> */}
                  <Col className="w-[calc(60%_-_0.67rem)]">
                    <Form.Item name="search" className="flex-1 mb-0">
                      <Input
                        placeholder="Search"
                        suffix={<img src={images.search} alt="search" />}
                        className="style_input_custom_login_page"
                        onChange={(value: ChangeEvent<HTMLInputElement>) =>
                          handleChangeSearch(value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col className="w-[calc(20%_-_0.67rem)]" xs={24} sm={4} md={4} lg={4} xl={4}> */}
                  <Col className="w-[calc(20%_-_0.67rem)]">
                    <Form.Item className="mb-0">
                      <ButtonCustom
                        className="h-12 unset-min-width w-full"
                        htmlType="submit"
                        color="orange"
                      >
                        Search
                      </ButtonCustom>
                    </Form.Item>
                  </Col>

                  {/* <Col className="w-[calc(10%_-_0.7rem)]" xs={24} sm={3} md={3} lg={3} xl={3}> */}
                  <Col className="w-[calc(20%_-_0.67rem)]">
                    <Form.Item className="mb-0 ">
                      <ButtonCustom
                        className="h-12 "
                        htmlType="button"
                        color="outline"
                        onClick={() => {
                          form.resetFields();
                          mutateDataListToday({
                            type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
                            classId: classId,
                          });
                        }}
                      >
                        Reset
                      </ButtonCustom>
                    </Form.Item>
                  </Col>
                  {/* <ButtonCustom
                onClick={handleResetData}
                isWidthFitContent
                className="h-12"
                color="outline"
              >
                Reset
              </ButtonCustom> */}
                </Row>
              </Form>
            </div>

            <Loading isLoading={isLoading || loading}>
              {dataListLastMonth?.length > 0 ||
              dataListOlder?.length > 0 ||
              dataListThisMonth?.length > 0 ||
              dataListToday?.length > 0 ||
              dataListWeek?.length > 0 ? (
                <>
                  <div className="flex justify-between items-center bg-white shadow-[0px 8px 32px rgba(0, 0, 0, 0.04)] rounded-3xl px-10 py-6">
                    <div className="font-normal text-lg">
                      Items in trash will be permanently deleted after{' '}
                      {routerUser.userRole === RoleName.ADMIN ||
                      routerUser.userRole === RoleName.SUPER_ADMIN
                        ? MYDRIVE_ADMIN_TRASH
                        : MYDRIVE_USER_TRASH}{' '}
                      days
                    </div>
                    <div
                      className="font-bold text-2xl text-[#ED7635] cursor-pointer"
                      onClick={() => setIsShowDeleteAll(true)}
                    >
                      Empty Trash
                    </div>
                  </div>
                  {dataListToday && dataListToday?.length > 0 && (
                    <div className="mt-5">
                      <div className="flex justify-between items-center">
                        <div className="text-lg font-semibold text-[#000000]">Today</div>
                        <div className="flex gap-4 items-center flex-wrap">
                          <p className="font-fontFamily font-semibold mb-0">Filter</p>
                          <SelectCustom
                            color="transparent"
                            className="min-w-[120px]"
                            value={filterToday}
                            onChange={(e) => onChangeFilter(e, ETimeQuery.TODAY)}
                            options={optionsFilter}
                          />
                        </div>
                      </div>

                      <div className="">
                        <Table
                          rowKey="Key"
                          columns={columns}
                          dataSource={dataListToday}
                          className="bg-transparent table-component"
                          pagination={false}
                        />
                      </div>
                    </div>
                  )}

                  {dataListYesterday && dataListYesterday?.length > 0 && (
                    <div className="">
                      <div className="mt-5">
                        <div className="flex justify-between items-center">
                          <div className="text-lg font-semibold text-[#000000]">Yesterday</div>
                          <div className="flex gap-4 items-center flex-wrap">
                            <p className="font-fontFamily font-semibold mb-0">Filter</p>
                            <SelectCustom
                              placeholder="Select"
                              color="transparent"
                              className="min-w-[120px]"
                              value={filterYesterday}
                              onChange={(e) => onChangeFilter(e, ETimeQuery.YESTERDAY)}
                              options={optionsFilter}
                            />
                          </div>
                        </div>
                        <div className="">
                          <Table
                            rowKey="Key"
                            columns={columns}
                            dataSource={dataListYesterday}
                            className="bg-transparent table-component"
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {dataListWeek && dataListWeek?.length > 0 && (
                    <div className="">
                      <div className="mt-5">
                        <div className="flex justify-between items-center">
                          <div className="text-lg font-semibold text-[#000000]">This week</div>
                          <div className="flex gap-4 items-center flex-wrap">
                            <p className="font-fontFamily font-semibold mb-0">Filter</p>
                            <SelectCustom
                              placeholder="Select"
                              color="transparent"
                              className="min-w-[120px]"
                              value={filterWeek}
                              onChange={(e) => onChangeFilter(e, ETimeQuery.WEEK)}
                              options={optionsFilter}
                            />
                          </div>
                        </div>
                        <div className="">
                          <Table
                            rowKey="Key"
                            columns={columns}
                            dataSource={dataListWeek}
                            className="bg-transparent table-component"
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {dataListThisMonth && dataListThisMonth?.length > 0 && (
                    <div className="">
                      <div className="mt-5">
                        <div className="flex justify-between items-center">
                          <div className="text-lg font-semibold text-[#000000]">This Month</div>
                          <div className="flex gap-4 items-center flex-wrap">
                            <p className="font-fontFamily font-semibold mb-0">Filter</p>
                            <SelectCustom
                              placeholder="Select"
                              color="transparent"
                              className="min-w-[120px]"
                              value={filterThisMonth}
                              onChange={(e) => onChangeFilter(e, ETimeQuery.THIS_MONTH)}
                              options={optionsFilter}
                            />
                          </div>
                        </div>
                        <div className="">
                          <Table
                            rowKey="Key"
                            columns={columns}
                            dataSource={dataListThisMonth}
                            className="bg-transparent table-component"
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {dataListLastMonth && dataListLastMonth?.length > 0 && (
                    <div className="">
                      <div className="mt-5">
                        <div className="flex justify-between items-center">
                          <div className="text-lg font-semibold text-[#000000]">Last Month</div>
                          <div className="flex gap-4 items-center flex-wrap">
                            <p className="font-fontFamily font-semibold mb-0">Filter</p>
                            <SelectCustom
                              placeholder="Select"
                              color="transparent"
                              className="min-w-[120px]"
                              value={filterLastMonth}
                              onChange={(e) => onChangeFilter(e, ETimeQuery.LAST_MONTH)}
                              options={optionsFilter}
                            />
                          </div>
                        </div>
                        <div className="">
                          <Table
                            rowKey="Key"
                            columns={columns}
                            dataSource={dataListLastMonth}
                            className="bg-transparent table-component"
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {dataListOlder && dataListOlder?.length > 0 && (
                    <div className="">
                      <div className="mt-5">
                        <div className="flex justify-between items-center">
                          <div className="text-lg font-semibold text-[#000000]">Older</div>
                          <div className="flex gap-4 items-center flex-wrap">
                            <p className="font-fontFamily font-semibold mb-0">Filter</p>
                            <SelectCustom
                              placeholder="Select"
                              color="transparent"
                              className="min-w-[120px]"
                              value={filterOlder}
                              onChange={(e) => onChangeFilter(e, ETimeQuery.OLDER)}
                              options={optionsFilter}
                            />
                          </div>
                        </div>
                        <div className="">
                          <Table
                            rowKey="Key"
                            columns={columns}
                            dataSource={dataListOlder}
                            className="bg-transparent table-component"
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="px-16 flex justify-between items-center bg-white shadow-[0px 8px 32px rgba(0, 0, 0, 0.04)] rounded-3xl">
                  <div className="">
                    <h3 className="font-bold text-2xl mb-0">Nothing in Trash</h3>
                    <p className="font-normal text-lg mb-0">
                      Items in trash will be permanently deleted after{' '}
                      {classId ? MYDRIVE_USER_TRASH : MYDRIVE_ADMIN_TRASH} days
                    </p>
                  </div>
                  <TrashSVG className="mr-3" />
                </div>
              )}
            </Loading>
          </Content>
          {isShowRestore && (
            <ModalCustom
              onSubmit={() => onRestore(isShowRestore)}
              visible={true}
              content={`Are you sure you want to restore ${isShowRestore.pathInTrash}?`}
              title="Restore"
              okText="Confirm"
              onCancel={() => setIsShowRestore(undefined)}
              cancelText="Cancel"
              titleCenter
            />
          )}
          {isShowDelete && (
            <ModalCustom
              onSubmit={() => onDelete(isShowDelete)}
              visible={true}
              content={`Are you sure you want to permanently delete ${isShowDelete?.pathInTrash}? You will not be able to restore it once confirmed.`}
              title="Delete"
              okText="Confirm"
              onCancel={() => setIsShowDelete(undefined)}
              cancelText="Cancel"
              titleCenter
            />
          )}
          <ModalCustom
            onSubmit={() => {
              setLoading(true);
              mutateDeleteAllDrive({
                idPath: classId ? parseInt(classId) : routerUser.id,
                type: classId ? TrashType.CLASS_LIBRARY : TrashType.MY_DRIVE,
              });
            }}
            visible={isShowDeleteAll}
            content={`Are you sure you want to empty your trash? You will not be able to restore your folders or files once confirmed.`}
            title="Empty Trash"
            okText="Confirm"
            onCancel={() => setIsShowDeleteAll(false)}
            cancelText="Cancel"
            titleCenter
          />
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default MyDriveTrashDrive;
