/**
 *
 * TopicDetails
 *
*/
import React, { useCallback, useContext } from 'react';
import { Layout, Breadcrumb, Form, Row, Col, Input, Upload, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { ROUTES } from 'constants/constants';
import { createNewTopic } from 'api/forum';
import { AppContext } from 'context';
import '../../../style.css';

const { Header } = Layout;

interface IState {
  className: string;
  forumId: string;
};

const CreateUpdateTopic = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as IState;
  const { forumId } = useParams();
  const [form] = Form.useForm();
  const [stateContext]: any = useContext(AppContext);
  const userInfo = stateContext?.user;

  const { mutate: mutateCreateNewTopic } = useMutation('createNewTopic', createNewTopic, {
    onSuccess: ({
      data
    }) => {
      console.log(data);
      navigate(-1);
    }
  });


  const onSubmit = useCallback((values: { topicTitle: string; topicText: string; }) => {
    if (userInfo && forumId) {
      mutateCreateNewTopic({
        ...values,
        authorID: Number(userInfo.id),
        forumID: Number(forumId)
      })
    }

  }, [userInfo, forumId]);

  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      <Header className="flex justify-between items-center bg-transparent px-0">
        <Breadcrumb className="text-[1.75rem] leading-9 font-bold mb-0">
          <Breadcrumb.Item
            className="cursor-pointer font-fontFamily"
            onClick={() => navigate(ROUTES.class_management)}
          >
            Class Management
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily">
            {state && state.className ? state.className : ''}
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily">
            Class Forum
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily text-main-font-color">
            Create New Topic
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <div className="rounded-3xl bg-white p-8">
        <Form
          form={form}
          name="basic"
          initialValues={{ layout: 'inline' }}
          autoComplete="off"
          onFinish={onSubmit}
        >
          <Row>
            <Col xs={24}>
              <span className="label_custom_login_page">Topic Title</span>
              <Form.Item
                name="topicTitle"
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Input
                  placeholder="Topic Title"
                  className="style_input_custom_login_page rounded-xl"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24}>
              <span className="label_custom_login_page">Content</span>
              <Form.Item
                name="topicText"
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Input.TextArea
                  placeholder="Content"
                  className="style_input_custom_login_page rounded-xl"
                  rows={6}
                />
              </Form.Item>
            </Col>
          </Row>
          
          <Row justify={'end'} style={{marginTop: '50px'}} gutter={24}>
            <Col xs={12} />
            <Col xs={6}>
              <Button block className="h-11 font-fontFamily rounded-xl font-semibold text-base text-[#32302D] font-semibold text-sm">
                Cancel
              </Button>
            </Col>
            <Col xs={6}>
              <Button block className="h-11 font-fontFamily rounded-xl text-white !border-main-button-color !bg-main-button-color font-semibold text-sm" htmlType="submit">
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  );
};

export default CreateUpdateTopic;