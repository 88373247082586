import { DatePicker, Form, Input, Layout, notification, Select } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'; 
import { getAllTemplateThemes } from 'api/theme';
import {
  getFileUrl,
  getProfileMe,
  IUser,
  uploadAvatar,
} from 'api/user';
import { ReactComponent as EditSVG } from 'assets/icons/edit_icon.svg';
import ButtonCustom from 'components/Button'; 
import CustomInput from 'components/Input'; 
import {
  DATE_FORMAT,
  DATE_FORMAT_TWO,
  FIELDS,
  REGEX_EMAIL,
  REGEX_LOGIN_ID,
  REGEX_NUMBER,
  ROUTES,
} from 'constants/constants';
import usePrompt from 'constants/function';
import { ERROR_MESSAGE, IFieldListForm, WARNING_MESSAGE } from 'constants/index';
import { AppContext } from 'context';
import moment from 'moment';
import { codesPhoneNumber } from 'pages/auth/register';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Area } from 'react-easy-crop';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import "./custom-style-my-info.css";
import { getAllCenters } from 'api/centres';

const { Content } = Layout;

export const fieldsValidation = ['contactNumber', 'ICNumber', 'username', 'Login ID'];

const MyInfo = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [formChangePassword] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [state, setState]: any = useContext(AppContext);
  const isAdmin = !!state?.user?.centreAdmin;
  const isStudent = !!state?.user?.student;
  const adminId = state?.user?.centreAdmin?.id;
  const [isOpenTwoFA, setIsOpenTwoFA] = useState<boolean>(false);
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [isOpenConfirmCancelEditProfile, setIsOpenConfirmCancelEditProfile] =
    useState<boolean>(false);
  const [isOpenConfirmUpdateProfile, setIsOpenConfirmUpdateProfile] = useState<boolean>(false);
  const [dataRequest, setDataRequest] = useState({});
  const [filePath, setFilePath] = useState<string>('');
  const [profilePhotoDestination, setProfilePhotoDestination] = useState('');
  const [formChangeContactNumber] = Form.useForm();
  const [isOpenModalChangeEmail, setIsOpenModalChangeEmail] = useState(false);
  const [formChangeEmail] = Form.useForm();
  const [isCentreOfAdmin, setIsCentreOfAdmin] = useState(false);
  const [preferredCenters, setPreferredCenters] = useState([{ label: "Please Select", value: "" }]);
  const [studentID, setStudentID] = useState(0);
  const isViewAsTeacher = !!(
    id &&
    pathname.includes(ROUTES.view_as) &&
    pathname.includes(ROUTES.teacher_detail)
  );
  const isViewAsStudent = !!(
    id &&
    pathname.includes(ROUTES.student_detail) &&
    pathname.includes(ROUTES.view_as)
  );

  const { mutate: mutateTheme, data: dataTheme } = useMutation(
    'getAllTemplateThemes',
    getAllTemplateThemes,
  );

  const { mutate: getAvatar } = useMutation('getFileUrl', getFileUrl, {
    onSuccess: ({ data }: { data: { fileUrl: string } }) => {
      setProfilePhotoDestination(data?.fileUrl || '');
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  const { mutate: uploadAvatarProfile } = useMutation('uploadAvatar', uploadAvatar, {
    onSuccess: ({ data }: { data: { filePath: string } }) => {
      getAvatar(data.filePath);
      setFilePath(data.filePath);
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });
  const { mutate: mutategetCenters } = useMutation('getAllCenters', getAllCenters, {
    onSuccess: ({ data }: { data: any }) => {
      console.log(typeof data);
      const centers = Array.isArray(data)
        ? data?.map((centre) => ({ label: centre.centreName, value: centre.id }))
        : [{ label: "Please Select", value: "" }];
      //centers.unshift({ label: "Please Select", value: "" });
      console.log(typeof centers);
      setPreferredCenters(centers);
      //form.setFieldsValue({ preferredCenter : centers[0] });
    },
  });

  const { mutate: getProfile } = useMutation('getProfileMe', getProfileMe, {
    onSuccess: ({ data }: { data: IUser }) => {
      setState({ ...state, user: data });
      setStudentID(Number(data?.id));
      const formValue = {
        fullName: data?.userProfile?.firstName + ' ' + data?.userProfile?.lastName,
        username: data?.username,
        email: data?.email,
        contactNumber: data?.userProfile?.contactNumber,
        dateOfBirth: data?.userProfile?.dateOfBirth ? moment(data?.userProfile?.dateOfBirth) : '',
        designation: data?.userProfile?.designation,
        role: data?.userRole?.roleName,
        theme: data?.theme?.id?.toString(),
        gender: data?.userProfile?.gender,
        ICNumber: data?.userProfile?.ICNumber,
        nationality: data?.userProfile?.nationality,
        postalCode: data?.userProfile?.postalCode,
        address1: data?.userProfile?.address1,
        address2: data?.userProfile?.address2,
        country: data?.userProfile?.country,
        mobileCountryCode: data?.userProfile?.mobileCountryCode,
        centre: Array.isArray(data?.centreAdmin?.centres)
          ? data?.centreAdmin?.centres
            ?.map((centre: { centreName: string }) => centre.centreName)
            .join('; ')
          : '',
      };
      form.setFieldsValue({ ...formValue });
      formChangeContactNumber.setFieldsValue({
        currentContactNumber: data?.userProfile?.contactNumber,
      });
      formChangeEmail.setFieldsValue({ currentEmail: data?.email });
      mutateTheme({ templateID: Number(data?.userRole?.templateID) });
      if (data?.userProfile?.profilePhotoDestination && !pathname.includes(ROUTES.view_as)) {
        setFilePath(data.userProfile.profilePhotoDestination);
        getAvatar(data.userProfile.profilePhotoDestination);
      }
    },
  });

  usePrompt(WARNING_MESSAGE.LEAVE_MANAGE_ADMIN_INFO, isChanging);

  const onFinish = (values: any) => {
    localStorage.removeItem('subscriptionDetails');
    const registrationInfo = {
      fullName: values.fullName,
      username: values.username,
      ICNumber: values.ICNumber,
      gender: values.gender,
      nationality: values.nationality,
      address1: values.address1,
      address2: values.address2,
      country: values.country,
      postalCode: values.postalCode,
      dateOfBirth: moment(values.dateOfBirth).format(DATE_FORMAT_TWO),
      preferredCenter: values.preferredCenter,
      academicYear: values.academicYear,
      studentDisease: values.studentDisease,
      studentAllergy: values.studentAllergy,
      contactNumber: values.contactNumber,
      studentID: studentID,
      studentEmail: values.email
    };
    setDataRequest(registrationInfo);
    localStorage.setItem('subscriptionDetails', JSON.stringify(registrationInfo));
    history(`${ROUTES.available_course}/${id}/subscription-details`);
    //setIsOpenConfirmUpdateProfile(true);
  };

  useEffect(() => {
    mutategetCenters();
    getProfile();
  }, [mutategetCenters]);


  const fieldList = [
    {
      label: 'Full Name',
      name: 'fullName',
      type: 'string',
      disabled: false,
    },
    {
      label: 'IC Number',
      name: 'ICNumber',
      type: 'string',
      rules: [
        { required: true, message: 'Please input your IC Number!' },
        {
          min: 5,
          message: 'IC Number should consist of at least 5 characters',
        },
      ],
    },
    {
      label: 'Gender',
      name: 'gender',
      type: 'select',
      options: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
      ],
      rules: [{ required: true, message: 'Please input your Gender!' }],
    },

    {
      label: 'Email Address',
      name: 'email',
      type: 'string',
      disabled: false,
      icon: (
        <EditSVG
          className="icon-hover cursor-pointer"
          onClick={() => {
            setIsOpenModalChangeEmail(true);
          }}
        />
      ),
      rules: [
        { required: true, message: 'Please input your email address!' },
        {
          validator(_: RuleObject, value: string) {
            const regex = new RegExp(REGEX_EMAIL);
            if (regex.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(ERROR_MESSAGE.EMAIL);
          },
        },
      ],
    },
    {
      label: 'Contact Number',
      name: 'contactNumber',
      nameChild: ['mobileCountryCode', 'contactNumber'],
      type: 'phoneNumber',
      rules: [
        { required: true, message: 'Contact Number is required!' },
        {
          validator(_: RuleObject, value: string) {
            const regex = new RegExp(REGEX_NUMBER);
            if (regex.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(ERROR_MESSAGE.CONTACT_NUMBER);
          },
        },
      ],
      options: codesPhoneNumber.map((item) => {
        return { label: `+ ${item.code}`, value: item.code.toString() };
      }),
    },
    {
      label: 'Date Of Birth',
      name: 'dateOfBirth',
      type: 'date',
      rules: [{ required: true, message: 'Date Of Birth is required!' }],
    },
    {
      label: 'Nationality',
      name: 'nationality',
      type: 'string',
      rules: [{ required: true, message: 'Nationality is required!' }],
    },
    {
      label: 'Preferred Center',
      name: 'preferredCenter',
      type: 'select',
      options: preferredCenters,
      rules: [{ required: true, message: 'Please input your Preferred Center!' }],
    },
    {
      label: 'Academic Year',
      name: 'academicYear',
      type: 'select',
      options: [
        { label: 'Select Academic Year', value: '' },
        { label: '2023', value: '2023' },
      ],
      rules: [{ required: true, message: 'Please input your Academic Year!' }],
    },
    {
      label: 'Does student have a disease that require the teachers attention?',
      name: 'studentDisease',
      type: 'select',
      options: [
        { label: 'Please Select', value: '' },
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      rules: [{ required: true, message: 'Please input an option!' }],
    },
    {
      label: 'Does student suffer from any allergies to any type of medication?',
      name: 'studentAllergy',
      type: 'select',
      options: [
        { label: 'Please Select', value: '' },
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      rules: [{ required: true, message: 'Please input an option!' }],
    },
    {
      label: 'Address Line 1',
      name: 'address1',
      type: 'string',
      rules: [{ required: true, message: 'Address is required!' }],
    },
    {
      label: 'Address Line 2',
      name: 'address2',
      type: 'string',
    },
    {
      label: 'Country',
      name: 'country',
      type: 'select',
      options: [{ label: 'Singapore', value: 'Singapore' }],
      rules: [{ required: true, message: 'Country is required!' }],
    },
    {
      label: 'Postal Code',
      name: 'postalCode',
      type: 'string',
      rules: [
        { required: true, message: 'Postal Code is required!' },
        {
          validator(_: RuleObject, value: string) {
            const regex = new RegExp(REGEX_NUMBER);
            if (regex.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(ERROR_MESSAGE.POSTAL_CODE);
          },
        },
      ],
    },
    // {
    //   label: 'Designation',
    //   name: 'designation',
    //   disabled: false,
    //   type: 'string',
    //   isHidden: isStudent || isViewAsStudent
    // }, 
    // {
    //   label: 'Centre',
    //   name: 'centre',
    //   type: 'string',
    //   disabled: false,
    //   //isHidden: !Array.isArray(state?.user?.centreAdmin?.centres),
    // },
  ];

  const renderField = useCallback(
    (field: IFieldListForm) => {
      switch (field.type) {
        case FIELDS.STRING:
          return field.icon ? (
            <Input
              className="style_input_custom_login_page"
              suffix={!isEdit && field.icon ? field.icon : <span />}
            />
          ) : (
            <CustomInput type={field.type} />
          );
        case FIELDS.NUMBER:
          return <CustomInput disabled={isEdit} type={field.type} />;
        case FIELDS.DATE:
          return (
            <DatePicker
              format={DATE_FORMAT}
              className="style_input_custom_login_page"
              inputReadOnly
            />
          );
        case FIELDS.GROUP_NAME:
          return (
            <div className="flex gap-x-3">
              <Form.Item
                noStyle
                rules={[{ required: true, message: 'First Name is required!' }]}
                name={field.nameChild ? field.nameChild[0] : ''}
              >
                <CustomInput placeholder="First Name" type="string" />
              </Form.Item>
              <Form.Item
                noStyle
                rules={[{ required: true, message: 'Last Name is required!' }]}
                name={field.nameChild ? field.nameChild[1] : ''}
              >
                <CustomInput placeholder="Last Name" type="string" />
              </Form.Item>
            </div>
          );
        case FIELDS.SELECT:
          return (
            <Select
              getPopupContainer={(node) => node}
              options={field.options as (BaseOptionType | DefaultOptionType)[]}

            />
          );

        case FIELDS.PHONE_NUMBER:
          return (
            <Input.Group compact>
              <Form.Item
                noStyle
                name={field?.nameChild ? field?.nameChild[0] : ('' as string)}
                rules={[{ required: true, message: 'Prefix is required!' }]}
              >
                <Select
                  options={field.options}
                  getPopupContainer={(node) => node}
                  className="w-[25%]"
                />
              </Form.Item>
              <Form.Item
                noStyle
                validateFirst
                name={field?.nameChild ? field?.nameChild[1] : ('' as string)}
                rules={field.rules}
              >
                <Input
                  className="style_input_custom_login_page w-[75%] rounded-r-2xl"
                />
              </Form.Item>
            </Input.Group>
          );
        case '':
          return <div></div>;
        default:
          return <CustomInput type={field.type} />;
      }
    },
    [DATE_FORMAT, isEdit],
  );

  const renderFieldList = useCallback(() => {
    return fieldList?.map(
      (field, index) =>
        <Form.Item
          className={'w-full sm:w-full md:w-full lg:w-[47%] custom-w-form-input'}
          key={index}
          validateFirst
          name={field.name}
          label={field.label}
          rules={field.rules}
        >
          {renderField(field)}
        </Form.Item>

    );
  }, [fieldList, isEdit]);

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>, croppedAreaPixels: Area) => {
    if (event && event.target.files && event.target.files[0]) {
      const imgUrl = event.target.files[0];
      const formData = new FormData();
      formData.append('file', imgUrl);
      formData.append('width', croppedAreaPixels.width.toString());
      formData.append('height', croppedAreaPixels.height.toString());
      formData.append('x', croppedAreaPixels.x.toString());
      formData.append('y', croppedAreaPixels.y.toString());
      uploadAvatarProfile({ formData, userId: Number(state?.user?.id) });
    }
  };

   const handleCancel = useCallback(() => {
      history(`${ROUTES.available_course}`);
      return; 
  }, []);

  const renderContent = useCallback(() => {
    return (
      <Content className="mt-8 p-8 bg-white rounded-3xl shadow[#0000000a]">
        <div className="md:flex lg:flex xl:flex 2xl:flex gap-x-6 form-edit custom-my-info">
          <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            onFieldsChange={() => {
              setIsChanging(true);
            }}
            colon={false}
            autoComplete="off"
            className="flex flex-wrap gap-x-6"
            initialValues={{
              mobileCountryCode: '65',
            }}
          >
            {renderFieldList()}
          </Form>
          {/* <CropUploadFile
            isEdit={!isEdit}
            avatarUrl={profilePhotoDestination}
            onChangeFile={onChangeFile}
          /> */}
        </div>
        {id && (
          <div className="gap-[10px] flex justify-end flex-wrap cus-width-my-info">
            <>
              <ButtonCustom color="outline" onClick={handleCancel}>
                Cancel
              </ButtonCustom>
              <ButtonCustom htmlType="submit" onClick={form.submit} color="orange">
              Enrol
              </ButtonCustom>
            </>
          </div>
        )}
      </Content>
    );
  }, [
    isEdit,
    form,
    setIsEdit,
    isOpenTwoFA,
    setIsOpenTwoFA,
    dataTheme,
    isChanging,
    profilePhotoDestination,
  ]);

  return (
    <>
      {renderContent()}
    </>
  );
};

export default MyInfo;
