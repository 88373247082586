import { Layout, Radio, RadioChangeEvent, Space } from 'antd';
import { createHitpayPaymentRequest, getCourseDetail, getCourseModules, getCoursePrice } from 'api/courses';
import { DATE_FORMAT, DATE_FORMAT_TWO, PaymentMethod, ROUTES } from 'constants/constants';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonCustom from 'components/Button';
import { config } from 'config';
import {
  Elements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import StripeElementsComponent from './available-course-stripe-elements';
import Checkbox from 'components/Checkbox';
import CheckboxCustom from 'components/Checkbox';
import PaynowElementsComponent from './available-course-paynow-elements';
export interface ICourseDetail {
  id: number;
  courseName: string;
  catalogImageUrl?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  learningMethod?: string;
  programType?: string;
}

const SubscriptionPayment = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [dataCourses, setDataCourses] = useState<ICourseDetail>();
  const [dataModules, setDataModules] = useState<any>();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>();
  const [coursesPrice, setCoursesPrice] = useState<any>();
  const [checkboxPayment, setcheckboxPayment] = useState("Paynow");
  const [stripePayment, setStripePayment] = useState(false);
  const [paynowPayment, setPaynowPayment] = useState(false);
  //Stripe Hooks
  const stripeKey = "pk_test_yCxAMS624YjngHg7ejqy7o6w00b6eSci67" || config.STRIPE_PUBLIC_KEY;
  const stripeObject = loadStripe(stripeKey);

  const { mutate: getCourse } = useMutation('getCourseDetail', getCourseDetail, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      const formData = {
        id: data?.id,
        courseName: data?.courseName,
        learningMethod: data?.learningMethod,
        programType: data?.programType,
        description: data?.description,
        catalogImageUrl: data?.catalogImageUrl,
        startDate: data?.startDate,
        endDate: data?.endDate,
      };
      setDataCourses(formData);
    },
  });

  const { mutate: mutateCoursePrice } = useMutation('getCoursePrice', getCoursePrice, {
    onSuccess: ({ data }: { data: any }) => {
      console.log(data);
      const formData = {
        id: data?.coursePrice.id,
        coursePrice: data?.coursePrice.coursePrice,
        startDate: data?.coursePrice.startDate,
        endDate: data?.coursePrice.endDate,
      };
      setCoursesPrice(formData);
    },
  });

  const { mutate: getCourseModule } = useMutation('getCourseModule', getCourseModules, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      console.log("Modules:", data)
      setDataModules(data);
    },
  });

  const setFormFields = () => {
    let subscriptionDetail: any = localStorage.getItem('subscriptionDetails');
    subscriptionDetail = JSON.parse(subscriptionDetail);
    console.log(subscriptionDetail);
    const formValue = {
      username: subscriptionDetail.username,
      fullName: subscriptionDetail.fullName,
      ICNumber: subscriptionDetail.ICNumber,
      gender: subscriptionDetail.gender,
      nationality: subscriptionDetail.nationality,
      address1: subscriptionDetail.address1,
      address2: subscriptionDetail.address2,
      country: subscriptionDetail.country,
      postalCode: subscriptionDetail.postalCode,
      dateOfBirth: moment(subscriptionDetail.dateOfBirth).format(DATE_FORMAT_TWO),
      preferredCenter: subscriptionDetail.preferredCenter,
      academicYear: subscriptionDetail.academicYear,
      studentDisease: subscriptionDetail.studentDisease,
      studentAllergy: subscriptionDetail.studentAllergy
    };
    setSubscriptionDetails(formValue);
  }

  useEffect(() => {
    if (id) {
      getCourse(Number(id));
      getCourseModule(Number(id));
      mutateCoursePrice(Number(id));
      console.log("Details::", localStorage.getItem('subscriptionDetails'));
      setFormFields();
    }
  }, [id]);

  useEffect(() => {
    console.log(coursesPrice);
  }, [coursesPrice]);

  useEffect(() => {
    console.log(subscriptionDetails);
  }, [subscriptionDetails]);

  const handleOpenPayment = useCallback(() => {
    if (id) {
      history(`${ROUTES.available_course}/${id}/payment`);
    }
  }, [id]);

  const onChange = useCallback((e: RadioChangeEvent) => {
    console.log(" e.target.value:::" + e.target.value)
    const value = e.target.value;
    setcheckboxPayment(value);
    if (value === PaymentMethod.STRIPE) {
      setStripePayment(true);
    } else {
      setStripePayment(false);
    }
  }, [stripePayment]);
  return (
    <Layout id="course-detail-student" className="bg-transparent flex flex-col gap-y-6">
      <div className="flex justify-between items-center bg-transparent px-0 custom-class-info">
        <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 custom-font-header">
          <span className="text-[#AEA8A5] cursor-pointer" onClick={() => history(ROUTES.available_course)}>
            Available Courses
          </span>
          / {dataCourses?.courseName}
          / Payment
        </p>
      </div>
      <div className="flex items-start flex-row p-8 gap-10 bg-[#FFFFFF] rounded-3xl custom-class-info">
        <div>
          <p className="text-2xl font-bold text-[#32302D] custom-font-header">
            Payment Content
          </p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </p>
          <div>
            <p className="text-1xl font-bold text-[#32302D] custom-font-header">
              Total Payment: ${coursesPrice?.coursePrice}
            </p>
            <Radio.Group className="font-fontFamily" onChange={onChange} value={checkboxPayment}>
              <Space direction="horizontal">
                <Radio value={PaymentMethod.STRIPE}>Payment Method (Debit/Credit)</Radio>
                <Radio value={PaymentMethod.PAYNOW}>Paynow</Radio>
              </Space>
            </Radio.Group>
          </div>
          <div>
            {
              stripePayment &&
              <Elements stripe={stripeObject}>
                <StripeElementsComponent />
              </Elements>
            }

          </div>
          <div>
            {
              !stripePayment &&
              <div className="paynow">
                <PaynowElementsComponent />
              </div>
            }

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubscriptionPayment;
