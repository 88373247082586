import { Card, Col, Dropdown, Empty, Form, Input, Layout, Menu, Row, Select, notification } from 'antd';
import images from 'assets/images';
import ButtonCustom from 'components/Button';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
const { Header, Content } = Layout;
import Loading from 'components/Loading';

import { IListCourse, InfoType } from 'constants/types';
import CertificateCardItem from 'components/CertificateCardItem';
import { getCertByStudentId } from 'api/class';
import { AppContext } from 'context';
import { isEmpty } from 'lodash';
import Meta from 'antd/lib/card/Meta';
import pdfThumbnail from 'assets/images/Pdf.svg';

export interface ICertificateCardItem {
  id: number;
  title: string;
  thumbnail?: string;
  thumbnailPath?: string;
  quantity: number;
}

const MyCertificate = () => {
  const [form] = Form.useForm();
  const [listCertificates, setListCertificates] = useState<ICertificateCardItem[]>([]);
  // const [isLoading, setLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<InfoType[]>([]);
  const [stateContext]: any = useContext(AppContext);
  const studentId = stateContext?.user?.student?.id;
  console.log(stateContext);
  console.log('studentId', studentId);

  const { mutate: mutateGetCertByStudentId, isLoading: isLoadingStudents } = useMutation('getCertByStudentId', getCertByStudentId, {
    onSuccess: ({ data }: { data: any }) => {
      console.log('## list cert', data);
      setListCertificates(data);
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  useEffect(() => {
    if (studentId) {
      mutateGetCertByStudentId({
        studentId: Number(studentId),
      });
    }
  }, [studentId]);

  useEffect(() => {
    console.log('run useEffect');

  }, []);


  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      <Row className="flex justify-between items-center bg-transparent px-0 ">
        <Col className="mb-2 mr-2">
          <p className="custom-font-header text-[1.75rem] font-fontFamily custom-font-header leading-9 font-bold mb-0">
            Certificate
          </p>
        </Col>
      </Row>
      <Content className="flex flex-col gap-y-6">
        <div className="cus-mt-1_25rem">
          <Loading isLoading={isLoadingStudents}>
            <div className="site-card-wrapper">
              <Row gutter={16}>
                {
                  isEmpty(listCertificates) ?
                    <Empty /> :
                    (listCertificates?.map((element: any, index) => {
                      console.log('listCertificates');
                      return (
                        <Col span={8} key={index} className='flex gap-6'>
                          <a href={element?.certUrl} target='_blank' rel="noreferrer">
                            <Card
                              style={{ width: 300 }}
                              cover={
                                <img
                                  alt="example"
                                  src={pdfThumbnail}
                                />
                              }
                              actions={[
                                <a href={element?.certUrl} target='_blank' rel="noreferrer" key="download" style={{ color: "blue" }}>View Certificate</a>
                              ]}
                            >
                              <Meta
                                title={element?.courseName}
                                description={`Serial Number: ${element?.serialNumber}`}
                              />
                            </Card>
                          </a>
                        </Col>
                      )
                    }))
                }
              </Row>
            </div>
          </Loading>
        </div>
      </Content>
    </Layout>
  );
};

export default MyCertificate;
