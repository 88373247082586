import { Button, Dropdown, Layout, Menu } from 'antd'; 
import { getCourseDetail, getCourseModules, getCoursePrice } from 'api/courses'; 
import { DATE_FORMAT, ROUTES, Status } from 'constants/constants'; 
import moment from 'moment'; 
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import ButtonCustom from 'components/Button';

export interface ICourseDetail {
  id: number;
  courseName: string;
  catalogImageUrl?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  learningMethod?: string;
  programType?: string;
}

const AvailableCourseDetail = () => {
  const { id } = useParams(); 
  const history = useNavigate();
  const [dataCourses, setDataCourses] = useState<ICourseDetail>();
  const [dataModules, setDataModules] = useState<any>();   
  const [coursesPrice, setCoursesPrice] = useState<any>();   

  const { mutate: getCourse } = useMutation('getCourseDetail', getCourseDetail, {
    onSuccess: ({ data }: { data: ICourseDetail }) => { 
      const formData = {
        id: data?.id,
        courseName: data?.courseName,
        learningMethod: data?.learningMethod,
        programType: data?.programType,
        description: data?.description,
        catalogImageUrl: data?.catalogImageUrl,
        startDate: data?.startDate,
        endDate: data?.endDate,
      };
      setDataCourses(formData);
    },
  });
  
  const { mutate: mutateCoursePrice } = useMutation('getCoursePrice', getCoursePrice, {
    onSuccess: ({ data }: { data: any }) => {
      console.log(data);
      const formData = {
        id: data?.coursePrice.id,
        coursePrice: data?.coursePrice.coursePrice, 
        startDate: data?.coursePrice.startDate,
        endDate: data?.coursePrice.endDate,
      };
      setCoursesPrice(formData);
    },
  });

 
  const { mutate: getCourseModule } = useMutation('getCourseModule', getCourseModules, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      console.log("Modules:", data)
      setDataModules(data); 
    },
  }); 

  useEffect(() => {
    if (id) {
      getCourse(Number(id));
      getCourseModule(Number(id)); 
      mutateCoursePrice(Number(id)); 
    }
  }, [id]);

  useEffect(() => {
    console.log(coursesPrice);
  }, [coursesPrice]);

  const handleOpenRegistration = useCallback(() => {
    if(id) {
      history(`${ROUTES.available_course}/${id}/subscription`);
    }
  },[id]);
  
  const menu = (
    <Menu className="w-[116px] absolute left-[-15px]">
      {/* {
        dataList.map((recordValue: UserNotification) => <Menu.Item onClick={() => !recordValue.isRead && updateStatusNotification(recordValue.id)} key={recordValue.id} className={`pr-3 header-noti-item ${recordValue.isRead ? 'header-noti-readed' : 'cursor-pointer'}`}>
          {`${recordValue.notification.actionType} ${recordValue.notification.nameType} ${recordValue.notification.content.includes('by') ? 'by ' + recordValue.notification.createdByUser.userProfile.firstName + ' ' + recordValue.notification.createdByUser.userProfile.lastName : ''} at ${moment(recordValue.notification.createdAt).format('YYYY/MM/DD hh:mm a')}`}
        </Menu.Item>)
      } */}
      <Menu.Item className="pr-3 btn-link">Withdraw</Menu.Item>
      <Menu.Item className="pr-3 btn-link">Transfer</Menu.Item>
    </Menu>
  );  

  return (
    <Layout id="course-detail-student" className="bg-transparent flex flex-col gap-y-6">
      <div className="flex justify-between items-center bg-transparent px-0 custom-class-info">
        <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0 custom-font-header">
          <span className="text-[#AEA8A5] cursor-pointer" onClick={() => history(ROUTES.my_course)}>
            Available Courses
          </span>{' '}
          / {dataCourses?.courseName}
        </p>
      </div>
      <div className="flex items-start flex-row p-8 gap-10 bg-[#FFFFFF] rounded-3xl custom-class-info">
        <img className="w-[320px] h-[240px] rounded-2xl" src={dataCourses?.catalogImageUrl} />
        <div>
          <p className="text-2xl font-bold text-[#32302D] custom-font-header">
            {dataCourses?.courseName}
          </p> 
          <p className="font-semibold text-[#6E6B68] text-lg">{dataCourses?.description}</p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Duration: {moment(dataCourses?.startDate).format(DATE_FORMAT)} to{' '}
            {moment(dataCourses?.endDate).format(DATE_FORMAT)}
          </p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Learning Method: {dataCourses?.learningMethod}
          </p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Program Type: {dataCourses?.programType}
          </p>
          <p className="font-semibold text-[#6E6B68] text-lg">
            Course Amount: {coursesPrice?.coursePrice}
          </p>
          <p className="text-2xl font-bold text-[#32302D] custom-font-header">
            Brief Course Content/Modules
          </p>
          <div className='flex flex-wrap'>
            {dataModules?.length > 0 &&
              dataModules?.map((item: any) => (
                <p className='w-[40%] gap-1 module-box mr-3 text-center aqtique-bg' key={item.id}>{item.moduleName}</p>
              ))}
          </div>
          <div>
          <ButtonCustom
            color="orange"
            className='bg-[#ED7635]'
            onClick={handleOpenRegistration}
           
          >
            Enrol
          </ButtonCustom>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default AvailableCourseDetail;
