import { uploadFile } from 'api/user';
import { Base64 } from 'js-base64';
import { isEmpty, values } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { generate } from 'short-uuid';
import './styles.css';
import { UploadOutlined } from '@ant-design/icons';
import JSZip from 'jszip';
import { AppContext } from 'context';

function UploadFolder(props: any) {
  const [files, setFiles] = React.useState([]);
  const [link, setLink] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [stateContext]: any = useContext(AppContext);

  const { mutateAsync: mutateUploadFile } = useMutation('uploadFile', uploadFile, {});

  const listFolderSelected = ['SCORM', generate()];

  const handleUploadFiletoS3 = (file: any) => {
    const fmData = new FormData();
    let fileType = '';
    const fileName = file.name.split('/').pop();

    if (fileName.includes('.js')) {
      fileType = 'text/javascript';
    }
    if (fileName.includes('.html')) {
      fileType = 'text/html';
    }
    if (fileName.includes('.jpeg')) {
      fileType = 'image/jpeg';
    }

    if (fileName.includes('.jpg')) {
      fileType = 'image/jpg';
    }

    if (fileName.includes('.gif')) {
      fileType = 'image/gif';
    }

    if (fileName.includes('.png')) {
      fileType = 'image/png';
    }

    if (fileName.includes('.svg')) {
      fileType = 'image/svg+xml';
    }

    if (fileName.includes('.pdf')) {
      fileType = 'application/pdf';
    }

    const renamedFile = new File([file], fileName, { type: fileType });

    fmData.append('file', renamedFile, Base64.encode(renamedFile.name, true));
    fmData.append(
      'path',
      `${listFolderSelected.join('/')}/${file.name.slice(0, file.name.lastIndexOf('/'))}`,
    );
    return mutateUploadFile(fmData);
  };

  const handleInputChange = async (e: any) => {
    const file = e?.target?.files?.[0];
    if (!file) return;
    setIsLoading(true);
    const zip = new JSZip();
    await zip.loadAsync(file);
    const extractedFiles: any = [];
    zip.forEach(async (relativePath, zipEntry) => {
      if (zipEntry.name.endsWith('/') || zipEntry.name.startsWith('__')) return;
      const fileContent = await zipEntry.async('uint8array');
      const extractedFile = new File([fileContent], zipEntry.name);
      extractedFiles.push(extractedFile);
    });
    setTimeout(() => {
      if (!isEmpty(extractedFiles)) setFiles(extractedFiles);
    }, 1000);
  };

  useEffect(() => {
    if (!isEmpty(files)) {
      const process = [];
      for (let index = 0; index < files.length; index++) {
        const item = handleUploadFiletoS3(files[index]);
        process.push(item);
      }
      Promise.all(process)
        .then((res) => {
          res.forEach((x) => {
            if (x.data?.[0].includes('/course/index.html')) {
              setLink(x.data?.[0]);
            }
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [files]);

  useEffect(() => {
    if (link) {
      const htmlContent = `<!DOCTYPE html><html lang="en"><head> <meta charset="UTF-8"> <meta name="viewport" content="width=device-width, initial-scale=1.0"> <title>Test-Package</title></head><body> <iframe style="width: 100vw;height: 100vh;" src="${link}" frameborder="0"></iframe> <script>const params=new URLSearchParams(location.search); const student_name=params.get("student_name") || ''; const student_id=params.get("student_id") || ''; const lesson_location=params.get("lesson_location") || ''; const lesson_status=params.get("lesson_status") || ''; const suspend_data=params.get("suspend_data") || ''; window.API=(function (){var data={"cmi.core.student_id": student_id, "cmi.core.student_name": student_name, "cmi.core.lesson_location": lesson_location, "cmi.core.lesson_status": lesson_status, "cmi.suspend_data": suspend_data}; return{LMSInitialize: function (){return "true";}, LMSCommit: function (){return "true";}, LMSFinish: function (){return "true";}, LMSGetValue: function (model){return data[model] || "";}, LMSSetValue: function (model, value){data[model]=value; window.parent.postMessage({name: "andalus-LMSSetValue", data: data}, "*" ); return "true";}, LMSGetLastError: function (){return "0";}, LMSGetErrorString: function (errorCode){return "No error";}, LMSGetDiagnostic: function (errorCode){return "No error";}};})(); </script></body></html>`;
      const fileName = 'new-website.html';
      const fileType = 'text/html';
      const _modifiedURL = link.substring(0, link.lastIndexOf('/course/index.html'));
      const modifiedURL = _modifiedURL.replace(
        `https://dev-andalus.s3.ap-southeast-1.amazonaws.com/${stateContext?.user?.id}/`,
        '',
      );
      console.log('modifiedURL', modifiedURL);

      const fmData = new FormData();
      const file = new File([htmlContent], fileName, { type: fileType });
      fmData.append('file', file, Base64.encode('index.html', true));
      fmData.append('path', modifiedURL);
      mutateUploadFile(fmData).then((res) => {
        props?.getFilePath({ filePath: res?.data?.[0], type: 'SCORM' });
      });
    }
  }, [link]);

  return (
    <div>
      <form id="file-upload-form" className="uploader">
        <input onChange={handleInputChange} id="file-upload" type="file" name="fileUpload" />

        <label htmlFor="file-upload" id="file-drag">
          <img id="file-image" src="#" alt="Preview" className="hidden" />
          <div id="start">
            <i className="fa fa-download" aria-hidden="true"></i>
            <div>Select a file or drag here</div>
            <div id="notimage" className="hidden">
              Please select zip file
            </div>
            {isLoading ? (
              <span>Upload...</span>
            ) : (
              <span id="file-upload-btn" className="btn btn-primary">
                <UploadOutlined size={20} /> <span className="ml-1">Select a zip file</span>
              </span>
            )}
          </div>
        </label>
      </form>
    </div>
  );
}

export default UploadFolder;
