/**
 *
 * TopicManagement
 *
*/
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Layout, Breadcrumb, Form, Button } from 'antd';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import SearchNotFound from 'components/SearchBar/SearchNotFound';
import Loading from 'components/Loading';
import PaginationCustom from 'components/Pagination';
import { ROUTES } from 'constants/constants';
import { searchForums } from 'api/forum';

import ForumSearch from '../Filter';
import TopicItem from '../TopicItem';
import { TForumResponse } from '../../types';
import { IParamsSearch } from 'api/courses';

const { Header, Content } = Layout;

interface IFields {
  search: string;
};

const StudentTopicManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const timeout: any = useRef(null);
  const [searchParams] = useSearchParams();
  const classId = searchParams.get('classId');

  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<IParamsSearch>({
    limit: 3,
    page: 1,
    search: ''
  });
  const [dataTopics, setDataTopics] = useState<TForumResponse>();

  const { mutate: getTopics, isLoading } = useMutation('searchTopics', searchForums, {
    onSuccess: ({
      data,
    }: {
      data: TForumResponse
    }) => {
      if (data) {
        const mappedData = data?.topics?.data?.map(topic => {
          const { id, topicText, topicTitle, createdAt, replyCount, author } = topic;
          return {
            id,
            topicText,
            topicTitle,
            createdAt,
            replyCount,
            author,
          }
        });
        setDataTopics({
          id: data?.id,
          topics: {
            data: mappedData,
            limit: data?.topics?.limit,
            page: data?.topics?.page,
            total: data?.topics?.total,
            
          }
        });
      }
    },
  });

  useEffect(() => {
    if (classId) {
      getTopics({
        classId: Number(classId),
        search: filter
      });
    }
  }, [classId, filter.limit, filter.page, filter.search]);

  const debounceSearch = useCallback(
    (value: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        setFilter((prev) => ({...prev, search: value}));
      }, 500);
    },
    [filter.page, timeout],
  );

  const handleChangeSearch = useCallback((value: string) => {
    debounceSearch(value);
  }, []);

  const onFinish = (values: IFields) => {
    setSearchValue(values?.search as string);
  };

  const onChangeLimit = (value: string) => {
    setFilter((prev) => ({...prev, limit: Number(value)}));
  };

  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      <Header className="flex justify-between items-center bg-transparent px-0">
        <Breadcrumb className="text-[1.75rem] leading-9 font-bold mb-0">
          <Breadcrumb.Item
            className="cursor-pointer font-fontFamily"
            onClick={() => navigate(ROUTES.my_course)}
          >
            My Course
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily text-main-font-color">
            Class Forum
          </Breadcrumb.Item>
        </Breadcrumb>
        <Button className="h-11 font-fontFamily rounded-xl text-white !border-main-button-color !bg-main-button-color font-semibold text-sm" onClick={() => navigate(`${ROUTES.class_forum_student}/${dataTopics?.id}/topics/new-topic`)}>
          New Topic
        </Button>
      </Header>

      <Content className="rounded-3xl flex flex-col gap-y-6 bg-white">
        <ForumSearch
          form={form}
          keyResult="searchForums"
          handleChangeSearch={handleChangeSearch}
          onFinish={onFinish}
          onChangeLimit={onChangeLimit}
          limit={filter.limit}
        />
        <Loading isLoading={isLoading}>
          <TopicItem forumId={dataTopics?.id} topics={dataTopics?.topics?.data ?? []} />
        </Loading>
      </Content>

      {dataTopics?.topics?.data?.length ? (
        <div className="flex justify-between items-center my-4 footer-course-sp sm:gap-3">
          <span className="font-fontFamily text-sm text-main-font-color bottom-8">
            Showing {filter?.limit}/{dataTopics?.topics?.total}
          </span>
          <PaginationCustom
            total={dataTopics?.topics?.total ?? 0}
            pageSize={filter.limit}
            onChange={(page) => {
              setFilter((prev) => ({ ...prev, page }));
            }}
            current={filter.page}
            onLastPage={() => {
              setFilter((prev) => ({
                ...prev,
                page: Math.ceil(Number(dataTopics?.topics?.total) / Number(filter?.limit)),
              }));
            }}
            onFirstPage={() => {
              setFilter((prev) => ({ ...prev, page: 1 }));
            }}
          />
        </div>
      ) : (
        <SearchNotFound isBackgroundWhite text={searchValue} />
      )}
    </Layout>
  )
}

export default StudentTopicManagement;