import { CalendarOutlined, SwapRightOutlined } from '@ant-design/icons';
import {
  Dropdown,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  TablePaginationConfig,
  Tooltip,
  Row,
  Col,
  Breadcrumb,
} from 'antd';
import { endAnnouncement, getAnnouncement } from 'api/announcement';
import images from 'assets/images';
import ButtonCustom from 'components/Button';
import CustomInput from 'components/Input';
import ModalCustom from 'components/Modal';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import TableCustom from 'components/SearchBar/Table';
import { useDebounce } from 'hooks';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { EDITOR_CONFIG, ROUTES } from 'constants/constants';
import { routerUserAccess, truncateString } from 'helper/function';
import './style.css';
import { RoleName } from 'enum/role';
import { IAnnouncementInfo } from 'pages/admin/notifications/announcement';
import { Header } from 'antd/lib/layout/layout';
import { getCourseDetail } from 'api/courses';
import { ICourseDetail } from '../my-course-detail';
import { getClassById } from 'api/class';

const optionsOrder = [
  { label: 'Author Name A-Z', value: 'author-ASC' },
  { label: 'Author Name Z-A', value: 'author-DESC' },
  { label: 'Posted Date & Time (Ascending)', value: 'createdAt-ASC' },
  { label: 'Posted Date & Time (Descending)', value: 'createdAt-DESC' },
];

const optionsCentre = [
  { label: 'All Centres', value: 'All Centres' },
  { label: 'Centre A', value: 'Centre A' },
  { label: 'Centre B', value: 'Centre B' },
  { label: 'Centre C', value: 'Centre C' },
];

const AnnoucementCourse = () => {
  const [formModal] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { courseId } = useParams();
  const [courseName, setCourseName] = useState<string | null>(null);
  const [className, setClassName] = useState<string | null>(null);

  const routerUser = routerUserAccess();
  const [checkRoleUser, setCheckRoleUser] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState<IAnnouncementInfo>({ listAnnouncement: [] });
  const [announcementDetail, setAnnouncementDetail] = useState<any>();
  const [isShowRestore, setIsShowRestore] = useState('');
  const [current, setCurrent] = useState(1);
  const [order, setOrder] = useState<string>('author-ASC');

  const [selection, setSelection] = useState<any>([]);
  const [tab, setTab] = useState<number>(1);
  const [isModelConfirm, setIsModalConfirm] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
  });

  useEffect(() => {
    console.log('courseId=============================>', courseId);

    if (routerUser.listRoute.length === 0) setCheckRoleUser(true);
    else
      setCheckRoleUser(
        routerUser.listRoute.some((item: string) => location.pathname.includes(item)),
      );
  }, [routerUser.listRoute.length]);

  const { mutate: listAnnouncement } = useMutation('listAnnouncement', getAnnouncement, {
    onSuccess(data, variables, context) {
      setIsLoading(false);
      const addKeyList = data.data.listAnnouncement.map((item: any) => {
        return {
          ...item,
          key: item.id,
          author: `${item.author?.userProfile?.firstName} ${item.author?.userProfile?.lastName}`,
        };
      });
      setDataList({ ...data.data, listAnnouncement: addKeyList });
      setPagination({
        ...pagination,
        total: data.data.total,
        current: data.data.page,
      });
    },
  });

  const fetchAnnouncement = async (filter?: {
    limit?: number;
    page?: number;
    search?: string;
    order?: string;
    sort?: string;
    status?: 'Ongoing' | 'End';
  }) => {
    const orderAndSort = order?.split('-') || [undefined, undefined];
    setIsLoading(true);
    setSelection([]);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const classId = params.get('classId');
    listAnnouncement({
      limit: filter?.limit || Number(pagination.pageSize),
      page: filter?.page || Number(current),
      search: '',
      order: filter?.order || orderAndSort[1],
      sort: filter?.sort || orderAndSort[0],
      filters: `[{"status": "Ongoing","classId":"${classId}"}]`,
    });
  };

  const onChangeLimit = useCallback(
    (value: string) => {
      const total = dataList.total;
      const maxPage = Math.ceil(Number(total) / Number(value));
      setPagination({ ...pagination, pageSize: Number(value) });
      if (current > maxPage) setCurrent(maxPage);
      else setCurrent(current);
    },
    [current, dataList.total],
  );

  const onFilter = useCallback(
    (value: string) => {
      setPagination({ ...pagination, current: 1 });
      setOrder(value);
    },
    [pagination],
  );

  const onChangeAction = useCallback(
    (value: string) => {
      if (value === 'selection') {
        if (selection.length > 1) setIsShowRestore('announcements');
        else setIsShowRestore('announcement');
        setIsModalConfirm(true);
      }
    },
    [selection],
  );

  const onChangeSelect = (selectedRowKeys: React.Key[]) => {
    setSelection(selectedRowKeys.map((item) => Number(item)));
  };

  const { mutate: getCourse } = useMutation('getCourseDetail', getCourseDetail, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      setCourseName(data?.courseName ?? null);
    },
  });

  const { mutate: getClass } = useMutation('getClass', getClassById, {
    onSuccess: ({ data }) => {
      setClassName(data?.className ?? null);
    },
  });

  const getData = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const classId = params.get('classId');
    Promise.all([getCourse(+courseId!), getClass(+classId!)]);
  };

  useEffect(() => {
    console.log('courseId================>', courseId);

    if (courseId && window) getData();
  }, [courseId]);

  useEffect(() => {
    if (order) {
      const orderAndSort = order.split('-');
      fetchAnnouncement({ sort: orderAndSort[0] || '', order: orderAndSort[1] || '' });
    }
  }, [order]);

  useEffect(() => {
    fetchAnnouncement({ limit: Number(pagination.pageSize) });
  }, [pagination.pageSize]);

  const columns = [
    {
      title: <>Announcement</>,
      dataIndex: 'title',
      key: 'title',
      width: '50%',
      render: (text: string) => (
        <Tooltip title={text}>
          <span className="title_annoucement_fix_width" style={{ width: 400 }}>
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <>Author</>,
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: <>Date</>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => {
        return <div>{moment(text).format('YYYY/MM/DD, h:mm a')}</div>;
      },
      width: '25%',
    },
  ];

  const ModalDetail = (data: any) => {
    const classes = data?.data?.classes.map((item: any) => item.className).join(', ');

    formModal.setFieldsValue({
      ...data.data,
      classes,
      message: data?.data?.message,
      duration: [moment(data?.data?.startDate), moment(data?.data?.endDate)],
    });
    return (
      <Modal
        className={`rounded-3xl p-0 `}
        width={600}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        style={{ zIndex: 100 }}
      >
        <span
          className={`font-fontFamily flex justify-left text-left
        font-bold text-2xl leading-8 tracking-tight mt-6 normal pb-[16px]`}
        >
          Announcement Details
        </span>

        <div className={`font-fontFamily font-normal text-sm mt-3}`}>
          <Form layout="vertical" form={formModal}>
            <div className="flex  justify-between gap-5 hide-search display-none">
              <Form.Item className="w-full" name="classes" label={'To'}>
                <Select
                  mode="multiple"
                  placeholder="Placeholder"
                  maxTagCount={2}
                  className={'announcement_select_multi'}
                  tagRender={(dataSelect) => {
                    return <div className="text-black classes_overflow">{dataSelect?.value}</div>;
                  }}
                  disabled
                />
              </Form.Item>
              <Form.Item name="author" label={'From'} className="w-full">
                <CustomInput
                  placeholder="Placeholder"
                  type="string"
                  disabled={true}
                  classNameCustom="text-black"
                />
              </Form.Item>
            </div>
            <Form.Item name="title" label={'Title'} className="w-full">
              <CustomInput
                disabled
                placeholder="Placeholder"
                type="string"
                classNameCustom="text-black title_annoucement_fix_width"
              />
            </Form.Item>
            <Form.Item name="message" label={'Message'} className="w-full">
              <CKEditor
                editor={ClassicEditor}
                config={{ ...EDITOR_CONFIG }}
                data={formModal.getFieldValue('message')}
              />
            </Form.Item>
            <div className="flex items-center justify-between gap-5">
              <div className="w-full flex flex-col">
                <div>
                  <span>Duration</span>
                </div>
                <div className="range-picker-announcement justify-between rounded-xl w-full items-center mt-2">
                  <span>{moment(announcementDetail?.startDate).format('YYYY/MM/DD hh:mm a')}</span>
                  <SwapRightOutlined />
                  <span>{moment(announcementDetail?.endDate).format('YYYY/MM/DD hh:mm a')}</span>
                  <CalendarOutlined />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {checkRoleUser ? (
        <Layout className="bg-transparent flex flex-col gap-y-6  ">
          <ModalDetail data={announcementDetail} />
          <ModalCustom
            onSubmit={() => {
              //   setIsShowRestore('');
              //   setIsModalConfirm(false);
              //   endAnnouncementApi({ id: selection });
              //   fetchAnnouncement();
              //   setSelection([]);
            }}
            visible={isModelConfirm}
            content={`Are you sure you want to end the selected ${isShowRestore}?`}
            title="End Announcement"
            okText="Confirm"
            onCancel={() => setIsModalConfirm(false)}
            cancelText="Cancel"
            titleCenter
          ></ModalCustom>
          <Header className="flex justify-between items-center bg-transparent px-0 h-auto">
            <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
              <Breadcrumb.Item
                className="cursor-pointer font-fontFamily"
                onClick={() => navigate(ROUTES.my_course)}
              >
                My Course
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-fontFamily">
                {`${courseName ?? 'Course Name'} - ${className ?? 'Class '}`}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-fontFamily text-main-font-color">
                Annoucement
              </Breadcrumb.Item>
            </Breadcrumb>
          </Header>

          <div className="relative xl:top-[0]">
            <TableCustom
              classNameFilter="w-full justify-end xl:justify-start"
              isRowSelect={false}
              columns={columns}
              isLoading={isLoading}
              data={dataList?.listAnnouncement || []}
              pagination={
                dataList?.listAnnouncement && dataList?.listAnnouncement?.length > 0
                  ? pagination
                  : undefined
              }
              onChangeSelect={onChangeSelect}
              onChangePagination={(page) => {
                setCurrent(page);
                setPagination({
                  ...pagination,
                  current: page,
                });
                const orderAndSort = order?.split('-') || [undefined, undefined];
                if (tab === 2) {
                  fetchAnnouncement({ page, sort: orderAndSort[0], order: orderAndSort[1] });
                } else fetchAnnouncement({ page });
              }}
              searchNotFound={
                dataList?.listAnnouncement && dataList?.listAnnouncement?.length > 0 ? undefined : (
                  <SearchNotFound isBackgroundWhite text={''} />
                )
              }
              onLastPage={() => {
                const currentPage = Math.ceil(
                  Number(pagination.total) / Number(pagination.pageSize),
                );
                setPagination({
                  ...pagination,
                  current: currentPage,
                });
                setCurrent(currentPage);
                const orderAndSort = order?.split('-') || [undefined, undefined];
                if (tab === 2) {
                  fetchAnnouncement({
                    page: currentPage,
                    sort: orderAndSort[0],
                    order: orderAndSort[1],
                  });
                } else fetchAnnouncement({ page: currentPage });
              }}
              onFirstPage={() => {
                setPagination({ ...pagination, current: 1 });
                setCurrent(1);
                const orderAndSort = order?.split('-') || [undefined, undefined];
                if (tab === 2) {
                  fetchAnnouncement({ page: 1, sort: orderAndSort[0], order: orderAndSort[1] });
                } else fetchAnnouncement({ page: 1 });
              }}
              viewItem={{
                onChange: onChangeLimit,
                value: pagination?.pageSize?.toString(),
              }}
              filters={{
                show: true,
                options: optionsOrder,
                onChange: onFilter,
                value: order,
                minWidth: 'min-w-[270px]',
              }}
              action={{
                show: selection.length > 0 && tab === 1 ? true : false,
                onSelect: onChangeAction,
                options: [{ value: 'selection', label: 'End Announcement' }],
              }}
              onRow={(record: any) => ({
                onClick: () => {
                  setAnnouncementDetail(record);
                  setVisible(true);
                },
              })}
            />
          </div>

          {/* {renderModalWarning()}
      {modalConfirmDeleteAdmin()}
      {renderModalConfirm()} */}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};
export default AnnoucementCourse;
