import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Layout, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { routerUserAccess } from 'helper/function';
import { RoleName } from 'enum/role';
import { useMutation, useQuery } from 'react-query';
import { ROUTES } from 'constants/constants';
import { checkTeachManagerClass } from 'api/module';
import { IParamsSearch } from 'api/courses';
import ButtonCustom from 'components/Button';
import SelectCustom from 'components/Select';
import { IGroupListData, groupCardData, optionsLimit } from './helper';
import GroupCard from './component/GroupCard';

import PaginationCustom from 'components/Pagination';
import AddEditModal from './component/AddEditModal';
import { getGroupList } from 'api/teacher_my_groups';
import style from './style.module.scss';
import SearchNotFound from 'components/SearchBar/SearchNotFound';

const { Header } = Layout;

const MyGroupList = () => {
  const [isTeacherManagerClass, setIsTeacherManagerClass] = useState();
  const [dataList, setDataList] = useState<IGroupListData>();
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState<IParamsSearch>({
    page: 1,
    limit: 5,
    sort: 'createdAt',
    order: 'ASC',
    search: '',
  });

  const routerUser = routerUserAccess();
  const navigate = useNavigate();
  const { classId } = useParams();

  const checkTeacherManagerClass = useQuery(['checkTeachManagerClass'], () =>
    checkTeachManagerClass(classId as any),
  );

  const { mutate: getGroupsData, isLoading } = useMutation('getGroupList', getGroupList, {
    onSuccess: ({ data }: { data: IGroupListData }) => {
      setDataList({
        page: data.page,
        limit: data.limit,
        sort: data.sort,
        order: data.order,
        total: data.total,
        groups: data.groups,
        classDetails: data.classDetails,
      });
    },
  });

  const onChangeLimit = useCallback(
    (value: string) => {
      const total = dataList?.total;
      const maxPage = Math.ceil(Number(total) / Number(value));
      if (filter.page > maxPage) setFilter((prev) => ({ ...prev, page: maxPage, limit: +value }));
      else setFilter({ ...filter, limit: +value });
    },
    [filter.page, dataList?.total],
  );

  const startPageSize = useMemo(() => {
    const startSize = Number(filter.page) * Number(filter?.limit) - (Number(filter?.limit) - 1);

    return startSize;
  }, [filter.page, filter?.limit]);

  const endPageSize = useMemo(() => {
    let endSize = Number(filter.page) * Number(filter?.limit);
    endSize = dataList?.total && endSize < dataList?.total ? endSize : (dataList?.total as number);

    return endSize;
  }, [filter.page, filter?.limit, dataList?.total]);

  useEffect(() => {
    if (checkTeacherManagerClass.status === 'success') {
      setIsTeacherManagerClass(checkTeacherManagerClass.data.data);
    }
  }, [checkTeacherManagerClass]);

  const handleRefetchingGroups = () => {
    getGroupsData({
      ...filter,
      filters: JSON.stringify([{ classID: classId, userType: RoleName.TEACHER }]),
    });
  };

  useEffect(() => {
    if (classId) {
      getGroupsData({
        ...filter,
        filters: JSON.stringify([{ classID: classId, userType: RoleName.TEACHER }]),
      });
    }
  }, [classId, filter]);

  const MyGroupCardList = useMemo(() => {
    return (
      <div className={style.groupList}>
        {dataList?.groups?.map((ele, index) => (
          <GroupCard
            groupName={ele.groupName}
            key={index}
            groupId={ele.groupID}
            handleRefetchingGroups={handleRefetchingGroups}
          />
        ))}
      </div>
    );
  }, [dataList]);

  if (checkTeacherManagerClass.isFetching) {
    return (
      <div className="flex justify-center">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      {routerUser.userRole === RoleName.TEACHER && isTeacherManagerClass != null ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <div className="flex">
            <>
              <Header className="flex justify-between items-center bg-transparent px-0 h-auto flex-1">
                <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
                  <Breadcrumb.Item
                    className="cursor-pointer font-fontFamily"
                    onClick={() => navigate(ROUTES.class_management)}
                  >
                    Class Management
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font-fontFamily">
                    {/* {dataList?.classDetail?.className} */}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font-fontFamily text-main-font-color">
                    My Group
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Header>
              <div>
                <ButtonCustom
                  color="orange"
                  className={style.new_group_btn}
                  onClick={() => setShowModal(true)}
                >
                  New Group
                </ButtonCustom>
              </div>
            </>
          </div>

          <div className="flex justify-end items-center mt-5">
            <div className="flex gap-4 items-center flex-wrap">
              <p className="font-fontFamily font-bold mb-0">View Item</p>
              <SelectCustom
                color="transparent"
                value={filter.limit.toString()}
                onChange={onChangeLimit}
                options={optionsLimit}
              />
            </div>
          </div>
          {isLoading && <Spin size="large" />}
          {MyGroupCardList}
          {!isLoading && (
            <>
              {dataList?.total ? (
                <div className="flex justify-between items-center my-4 footer-course-sp sm:gap-3">
                  <span className="font-fontFamily text-sm text-main-font-color bottom-8">
                    {startPageSize} - {endPageSize} of {dataList?.total}
                  </span>
                  <PaginationCustom
                    total={dataList?.total}
                    pageSize={Number(filter.limit)}
                    onChange={(page) => {
                      setFilter((prev) => ({ ...prev, page }));
                    }}
                    current={filter.page}
                    onLastPage={() => {
                      setFilter((prev) => ({
                        ...prev,
                        page: Math.ceil(Number(dataList?.total) / Number(filter.limit)),
                      }));
                    }}
                    onFirstPage={() => {
                      setFilter((prev) => ({ ...prev, page: 1 }));
                    }}
                  />
                </div>
              ) : (
                <SearchNotFound isBackgroundWhite text={filter.search} />
              )}
            </>
          )}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
      <AddEditModal
        okButtonText="Create"
        showModal={showModal}
        setShowModal={setShowModal}
        title="Create New Group"
        handleRefetchingGroups={handleRefetchingGroups}
      />
    </>
  );
};

export default MyGroupList;
