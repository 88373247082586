/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';

import ModalCustom from 'components/Modal';
import { useMutation } from 'react-query';
import { leaveFromGroup, removeStudentFromGroup } from 'api/teacher_my_groups';
import { RoleName } from 'enum/role';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  title: string;
  groupId: number;
  bodyText: string;
  removeModal: boolean;
  studentID?: number | null;
  isStudentRemove?: boolean;
  isRefetching?: boolean;
  handleRefetching?: () => void;
  setStudentID?: React.Dispatch<React.SetStateAction<number | null>>;
  setRemoveModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStudentRemove?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveModal = ({
  title,
  groupId,
  bodyText,
  studentID,
  removeModal,
  isRefetching,
  setStudentID,
  setRemoveModal,
  handleRefetching,
}: IProps) => {
  const navigate = useNavigate();
  const { classId } = useParams();
  const { mutate, isLoading } = useMutation('leaveFromGroup', leaveFromGroup, {
    onSuccess: (data) => {
      setRemoveModal?.(false);
      isRefetching === true && handleRefetching?.();
      navigate(`/class-management/${classId}/my-group`);

      // console.log(data);
      // notification.success({ message: 'Success', description: 'Successfully Leave group' });
    },
    // onError: (error: { response: { data: { message: string } } }) => {
    //   console.log(error.response);
    // },
  });

  const { mutate: removeMutate, isLoading: removeLoading } = useMutation(
    'removeStudentFromGroup',
    removeStudentFromGroup,
    {
      onSuccess: (data) => {
        setRemoveModal?.(false);
        studentID && setStudentID?.(null);
        handleRefetching?.();
        notification.success({ message: data.message, description: data.data });
        // console.log(data);
        // notification.success({ message: 'Success', description: 'Successfully Leave group' });
      },
      // onError: (error: { response: { data: { message: string } } }) => {
      //   console.log(error.response);
      // },
    },
  );

  const handleSubmit = () => {
    if (studentID !== null && studentID !== undefined) {
      removeMutate({ studentID: studentID, groupID: groupId });
    } else {
      mutate({ userType: RoleName.TEACHER, groupID: groupId });
    }
  };

  return (
    <div>
      <ModalCustom
        cancelText="Cancel"
        visible={removeModal}
        onCancel={() => {
          setRemoveModal?.(false);
          studentID && setStudentID?.(null);
        }}
        okText="Confirm"
        title={title || 'Remove Member'}
        titleCenter
        onSubmit={handleSubmit}
        loading={isLoading || removeLoading}
        isKeepOpen={true}
      >
        <p>{bodyText}</p>
      </ModalCustom>
    </div>
  );
};

export default RemoveModal;
