import { Empty, Spin } from 'antd';
import { getDashboardModuleUser } from 'api/dashboard_module_user';
import Layout1 from 'components/DashboardModule/Layout1';
import Layout2 from 'components/DashboardModule/Layout2';
import Layout3 from 'components/DashboardModule/Layout3';
import { AppContext } from 'context';
import { isEmpty, keyBy } from 'lodash';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import LogRocket from 'logrocket';

const MODULE_MAP = {
  1: 'Calendar',
  2: 'Notifications',
  3: 'Announcements',
  4: 'Assignments',
} as any;

const DASHBOARDS = {
  'Layout 1': Layout1,
  'Layout 2': Layout2,
  'Layout 3': Layout3,
} as any;

const Dashboard = () => {
  const [stateContext]: any = useContext(AppContext);

  LogRocket.identify(stateContext?.user?.username, {
    name: stateContext?.user?.fullName,
    email: stateContext?.user?.email,
  });

  const { data, isLoading } = useQuery(
    ['getDashboardModuleUser'],
    () => getDashboardModuleUser(stateContext?.user?.id),
    { enabled: !!stateContext?.user?.id },
  );

  const DashboardComponent = DASHBOARDS?.[data?.data?.[0]?.dashboard?.dashboardName] || Layout1;
  const keyZoneData = keyBy(data?.data, 'layoutZone');

  return (
    <Spin spinning={isLoading} tip="Loading...">
      {isEmpty(data?.data) ? (
        <Empty />
      ) : (
        <div className="flex flex-col gap-6">
          {data?.data?.[0]?.dashboard?.dashboardName && DashboardComponent && keyZoneData && (
            <DashboardComponent
              slot1={
                MODULE_MAP?.[keyZoneData?.[1]?.dashboardModule?.dashboardModuleCode] || 'Calendar'
              }
              slot2={
                MODULE_MAP?.[keyZoneData?.[2]?.dashboardModule?.dashboardModuleCode] || 'Notifications'
              }
              slot3={
                MODULE_MAP?.[keyZoneData?.[3]?.dashboardModule?.dashboardModuleCode] || 'Announcements'
              }
              slot4={
                MODULE_MAP?.[keyZoneData?.[4]?.dashboardModule?.dashboardModuleCode] || 'Assignments'
              }
            />
          )}
        </div>
      )}
    </Spin>
  );
};

export default Dashboard;
