import { Layout, TablePaginationConfig, Row, Col, notification, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import ButtonCustom from 'components/Button';
import ModalCustom from 'components/Modal';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import TableCustom from 'components/SearchBar/Table';
import CustomTooltip from 'components/Tooltip';
import { ROUTES } from 'constants/constants';
import { useDebounce } from 'hooks';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import FilterCard from './components/FilterCard';
import CustomInput from 'components/Input';

// import './custom_style.css';
import { routerUserAccess } from 'helper/function';
import { IStudentInfo } from 'pages/admin/manage-student';
import { addStudent, getStudentsByClassId, getStudentByICNumber } from 'api/class';
import ScheduleMeetingModal from './modals/ScheduleMeetingModal';
import ScheduledMeetingModal from './modals/ScheduledMeetingModal';
import ConfigureModal from './modals/ConfigureModal';
import { getMeetingsForUser } from 'api/online_meetings';
import { AppContext } from 'context';
import moment from 'moment';

interface IFields {
  search?: string;
  category?: string;
  leaningMethod?: string;
  programType?: string;
}

const OnlineMeetings = () => {
  const history = useNavigate();
  const [form] = useForm();

  // const urlParent = '/online-meetings'
  const routerUser = routerUserAccess();
  const [checkRoleUser, setCheckRoleUser] = useState(true);
  const [messageWarning, setMessageWarning] = useState('');
  const [messageConfirmDelete, setMessageConfirmDelete] = useState<string | null>('');
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [dataDropdown, setDataDropdown] = useState([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchDropdown, setSearchDropdown] = useState<string>('');
  const [selection, setSelection] = useState<React.Key[]>([]);
  const [limit, setLimit] = useState<string>('5');
  const [order, setOrder] = useState<string>('ASC');
  const debounceValue = useDebounce(searchDropdown, 300);
  const [categoryId, setCategoryId] = useState<number | string>();
  const [isModalDeleteCategory, setIsModalDeleteCategory] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: Number(limit),
    position: [],
  });

  const [isModalCreateModule, setIsModalCreateModule] = useState<boolean>(false);
  const [topicOptions, setTopicOptions] = useState<any>([]);




  const [visibleConfigureModal, setVisibleConfigureModal] = useState(false);
  const [visibleScheduleModal, setVisibleScheduleModal] = useState(false);
  const [visibleScheduledModal, setVisibleScheduledModal] = useState(false);
  const [reload, setReload] = useState(false);
  // const [meetings, setMeetings] = useState([]);
  const [rowData, setRowData] = useState({});
  const [stateContext]: any = useContext(AppContext);
  // TODO:
  const userId = stateContext?.user?.id;
  const teacherId = stateContext?.user?.teacher?.id;
  const [studentOptions, setStudentOptions] = useState<any>([]);
  const { classId } = useParams();

  const { mutate: mutateGetStudentsByClassId } = useMutation('getStudentsByClassId', getStudentsByClassId, {
    onSuccess: ({ data }: { data: any }) => {
      let newOptions = data.students.map((student: any) => {
        return {
          label: student.studentName,
          value: student.id.toString(),
        };
      });
      newOptions = [
        { label: 'Type to search for more categories', value: '', disabled: true },
      ].concat(newOptions);
      setStudentOptions(newOptions);
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });



  // const { mutate: mutateGetMeetingsForUser, isLoading: isLoadingStudents } = useMutation('getMeetingsForUser', getMeetingsForUser, {
  //   onSuccess: ({ data }: { data: any }) => {
  //     setMeetings(data);
  //   },
  //   onError: ({ response }: { response: { data: { message: string } } }) => {
  //     notification.error({ message: response.data.message });
  //   },
  // });

  const {
    data: fetchedData,
    isLoading,
    refetch: refetchGetMeetingsForUser,
  } = useQuery(['getMeetingsForUser', userId], () => getMeetingsForUser({ userId: Number(userId) }), {
    enabled: !!userId,
  });

  if (!userId) {
    refetchGetMeetingsForUser();
  }

  useEffect(() => {
    mutateGetStudentsByClassId({ classId: classId, params: {} });
  }, []);

  // useEffect(() => {
  //   if (userId) {
  //     mutateGetMeetingsForUser({
  //       userId: Number(userId),
  //     });
  //   }
  // }, [userId]);

  // useEffect(() => {
  //   if (routerUser.listRoute.length === 0) setCheckRoleUser(true)
  //   else
  //     setCheckRoleUser(routerUser.listRoute.some((item: string) => urlParent === item))
  // }, [routerUser.listRoute.length])

  const onValuesChange = (values: IFields) => {
    const search = values.search || '';
    setSearchDropdown(search);
  };

  const handleTableChange = (paginate: TablePaginationConfig) => {
    setPagination({ ...pagination, ...paginate });
  };

  const handleAddStudent = () => {
    setIsModalCreateModule(true);
  };



  const handleScheduleModal = () => {
    setVisibleScheduleModal(true);
  };

  const handleScheduledModal = () => {
    setVisibleScheduledModal(true);
  };

  const handleConfigureModal = () => {
    setVisibleConfigureModal(true);
  };

  const handleCancelScheduleModal = () => {
    setVisibleScheduleModal(false);
  };

  const handleCancelScheduledModal = () => {
    setVisibleScheduledModal(false);
  };

  const handleCancelConfigureModal = () => {
    setVisibleConfigureModal(false);
  };

  const onRow = (record?: any, rowIndex?: number) => {
    const codeMudule =
      record?.moduleCode && record?.moduleCode !== '' ? `(${record?.moduleCode})` : '';
    return {
      // onClick: () => redirectToListSession(record?.id, record?.moduleName + ' ' + codeMudule),
    };
  };

  // const handleAddStudentModalSubmit = (studentId: number) => {
  //   mutateAddStudent({ classId: Number(classId), studentId: studentId })
  //     .then(() => {
  //     })
  //     .catch((error) => {
  //       notification.error({ message: 'IC Number is incorrect!' })
  //     });
  // }

  const onChangeSelect = (selectedRowKeys: React.Key[]) => {
    setSelection(selectedRowKeys);
  };

  const onChangeLimit = (value: string) => {
    const total = pagination.total;
    const maxPage = Math.ceil(Number(total) / Number(value));
    setLimit(String(value));
    if (Number(pagination.current) > maxPage) setPagination({ ...pagination, current: maxPage });
    else setPagination(pagination);
  };

  const onFilter = (value: string) => {
    setOrder(value);
    setPagination({ ...pagination, current: 1 });
  };

  const onChangeAction = (value: string) => {
    if (value === 'selection') {
      setIsModalConfirm(true);
      setMessageConfirmDelete(
        selection?.length > 1
          ? 'Are you sure you want to delete the selected categories? This action cannot be undone.'
          : 'Are you sure you want to delete this category? This action cannot be undone.',
      );
    }
    // else if (value === 'all') {
    //   setMessageWarning(
    //     `Can't delete all categories. There are still courses in these categories!`,
    //   );
    // }
  };

  const onReload = () => {
    console.log('called');
  }

  // const handleReset = useCallback(() => {
  //   setSearchDropdown('');
  //   setSearchValue('');
  //   setOrder('ASC');
  //   setLimit('5');
  //   setSelection([]);
  //   form.resetFields();
  //   mutateGetStudentsByClassId({
  //     classId: Number(classId),
  //     params: {
  //       limit: Number(limit),
  //       page: pagination?.current as number,
  //       search: searchValue,
  //       sort: 'studentName',
  //       order: order,
  //     }
  //   });
  // }, [limit]);

  const columns = [
    {
      title: 'Start Date Time',
      dataIndex: 'startDate',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.startDate}>
              <div className="custom-text-ellipsis">
                {moment(record.startDate).format('YYYY/MM/DD - h:mm a')}
              </div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'End Date Time',
      dataIndex: 'endDate',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.endDate}>
              <div className="custom-text-ellipsis">
                {moment(record.endDate).format('YYYY/MM/DD - h:mm a')}
              </div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.studentName}>
              <div className="custom-text-ellipsis">{record.studentName}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {
        checkRoleUser
          ?
          <Layout className="bg-transparent flex flex-col gap-y-6">
            <Row className="flex justify-between items-center bg-transparent px-0 mt-5 ">
              <Col className="mb-2 mr-2">
                <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0">
                  Online Meetings
                </p>
              </Col>
              <Col className="mr-2">
                <ButtonCustom
                  color="orange"
                  onClick={handleConfigureModal}
                >
                  Configure
                </ButtonCustom>
              </Col>
              <Col className="mb-2">
                <ButtonCustom
                  color="orange"
                  onClick={handleScheduleModal}
                >
                  Schedule New Meeting
                </ButtonCustom>
              </Col>
            </Row>

            <TableCustom
              enableRowSelection={false}
              hidePageSize
              // searchNotFound={
              //   students.length > 0 ? undefined : (
              //     <SearchNotFound isBackgroundWhite text={'searchValue'} />
              //   )
              // }
              columns={columns}
              data={fetchedData?.data}
              pagination={pagination}
              isLoading={isLoading}
              handleTableChange={handleTableChange}
              onChangeSelect={onChangeSelect}
              onChangePagination={(page) => {
                setPagination({ ...pagination, current: Number(page) });
              }}
              onRow={(record) => ({
                onClick: () => {
                  console.log('## row record', record);
                  setRowData(record);
                  handleScheduledModal();
                  // redirectEditStudent(record?.id as number, record?.studentName as string);
                },
              })}
              onLastPage={() => {
                setPagination({
                  ...pagination,
                  current: Math.ceil(Number(pagination.total) / Number(pagination.pageSize)),
                });
              }}
              onFirstPage={() => {
                setPagination({ ...pagination, current: 1 });
              }}
              viewItem={{
                onChange: onChangeLimit,
                value: String(limit),
              }}
            />

            <ScheduleMeetingModal
              visible={visibleScheduleModal}
              onCancel={handleCancelScheduleModal}
              optionsStudent={studentOptions}
              teacherId={teacherId}
              classId={classId}
              onReload={refetchGetMeetingsForUser}
            />
            <ScheduledMeetingModal
              visible={visibleScheduledModal}
              onCancel={handleCancelScheduledModal}
              rowData={rowData}
            />
            <ConfigureModal
              visible={visibleConfigureModal}
              onCancel={handleCancelConfigureModal}
              teacherId={teacherId}
            />
          </Layout>
          :
          <p className='font-bold text-2xl'>You don&apos;t have the permission to access this resource</p>
      }
    </>
  );
};

export default OnlineMeetings;
