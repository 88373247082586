import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';

import style from './multi-select.module.scss';
import './style.css';

interface IProps {
  options: any;
  className: string;
  displayKeyValue: string;
  selectIsLoading?: boolean;
  selectedValue: any[];
  handleChangeSelect: (value: any[]) => void;
  handleSearch: (value: string) => void;
  setSelectedValue: React.Dispatch<React.SetStateAction<any[]>>;
}

const CustomSelect = ({
  options,
  className,
  selectedValue,
  displayKeyValue,
  selectIsLoading,
  handleSearch,
  setSelectedValue,
  handleChangeSelect,
}: IProps) => {
  const handleSelect = (value: any) => {
    handleChangeSelect(value);
  };
  const handleRemove = (value: any) => {
    setSelectedValue([...value]);
  };

  return (
    <>
      <div className={style.main}>
        <label className={style.label}>Invite Students</label>
        <Multiselect
          displayValue={displayKeyValue}
          customArrow={
            <>
              <DownOutlined className={style.img1} />
            </>
          }
          selectedValues={selectedValue}
          onSelect={handleSelect}
          onRemove={handleRemove}
          onSearch={handleSearch}
          options={options}
          loading={selectIsLoading}
          showArrow={true}
          placeholder="Search Students"
          className={`reactMultiSelectWrapper ${className && className}`}
          customCloseIcon={
            <>
              <CloseOutlined className={style.img} />
            </>
          }
          style={{
            chips: {
              background: '#F3E5DB',
              color: '#32302d',
              fontWeight: 700,
              borderRadius: '1px',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              gap: '8px',
            },
            multiselectContainer: {
              color: '#32302d',
            },

            searchBox: {
              minHeight: '3rem',
              width: '100%',
              height: '100%',
              borderRadius: '1rem',
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              fontSize: '0.75rem',
              lineHeight: '1rem',
              color: 'rgb(50 48 45 / 1',
            },
          }}
        />
      </div>
    </>
  );
};
export default CustomSelect;
