import { Empty } from 'antd';
import { useState, useEffect } from 'react';
import { routerUserAccess } from 'helper/function';

const Letters = () => {
  const routerUser = routerUserAccess();
  const [checkRoleUser, setCheckRoleUser] = useState(false);

  useEffect(() => {
    if (routerUser.listRoute.length === 0) setCheckRoleUser(false);
    else setCheckRoleUser(routerUser.listRoute.some((item: string) => location.pathname === item));
  }, [routerUser.listRoute.length]);

  return (
    <>
      {checkRoleUser ? (
        <Empty />
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default Letters;
