import { Breadcrumb, Layout, Spin, Table, notification } from 'antd';
import { getAllAssignmentsByUnits } from 'api/assignment';
import { getUnitsInClass } from 'api/class';
import { IParamsSearch } from 'api/courses';
import PaginationCustom from 'components/Pagination';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import CustomTooltip from 'components/Tooltip';
import { ROUTES, VIEW_ITEMS, WARNING_MESSAGE } from 'constants/index';
import moment from 'moment';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as EditSVG } from 'assets/icons/edit_icon.svg';
import ModuleColumn from 'components/ModuleColumn';
import SecctionColumn from 'components/SecctionColumn';
import StudentColumn from 'components/StudentColumn';
import SelectCustom from 'components/Select';

const { Header } = Layout;

function Assignments() {
  const { classId } = useParams();
  const navigate = useNavigate();
  const { data: dataUnits, isLoading: isLoadingUnitsInClass } = useQuery(
    ['getUnitsInClass', classId],
    () => getUnitsInClass(Number(classId)),
    { enabled: !!classId },
  );

  const [filter, setFilter] = useState<IParamsSearch>({
    page: 1,
    limit: 30,
    sort: 'moduleName',
    order: 'ASC',
    search: JSON.stringify([{ isMarked: '0' }]),
  });

  const {
    data: dataAssignmentsByUnits,
    isLoading: isLoadingAllAssignmentsByUnits,
    mutateAsync: fetchAllAssignmentsByUnits,
  } = useMutation('getAllAssignmentsByUnits', getAllAssignmentsByUnits, {});

  const startPageSize = React.useMemo(() => {
    const startSize = Number(filter.page) * Number(filter?.limit) - (Number(filter?.limit) - 1);
    return startSize;
  }, [filter.page, filter?.limit]);

  const endPageSize = React.useMemo(() => {
    let endSize = Number(filter.page) * Number(filter?.limit);
    endSize =
      dataAssignmentsByUnits?.data?.total && endSize < dataAssignmentsByUnits?.data?.total
        ? endSize
        : (dataAssignmentsByUnits?.data?.total as number);

    return endSize;
  }, [filter.page, filter?.limit, dataAssignmentsByUnits?.data?.total]);

  React.useEffect(() => {
    if (classId && dataUnits?.data) {
      fetchAllAssignmentsByUnits({
        limit: filter.limit,
        page: filter.page,
        filters: filter.search,
        units: dataUnits?.data,
      });
    }
  }, [classId, dataUnits?.data, filter]);

  const [status, setStatus] = useState('0');

  return (
    <Spin
      style={{ paddingTop: 500 }}
      spinning={isLoadingUnitsInClass || isLoadingAllAssignmentsByUnits}
      tip="Loading..."
    >
      <Layout className="bg-transparent flex flex-col gap-y-6">
        <Header className="flex justify-between items-center bg-transparent px-0">
          <Breadcrumb className="text-[1.75rem] leading-9 font-bold mb-0">
            <Breadcrumb.Item
              className="cursor-pointer font-fontFamily"
              onClick={() => navigate(ROUTES.class_management)}
            >
              Class Management
            </Breadcrumb.Item>
            <Breadcrumb.Item className="font-fontFamily text-main-font-color">
              Assignments
            </Breadcrumb.Item>
          </Breadcrumb>
        </Header>
        <div className="flex items-center mb-3 justify-end sm:justify-start ">
          <div className={`flex gap-4 items-center flex-wrap`}>
            <div className="filter-item ">
              <p className="font-fontFamily font-bold mb-0">Filter</p>
              <SelectCustom
                placeholder="All"
                color="transparent"
                options={[
                  { label: 'UNMARKED', value: '0' },
                  { label: 'MARKED', value: '1' },
                  { label: 'ALL', value: 'ALL' },
                ]}
                onChange={(n) => {
                  if (n === 'ALL') {
                    setFilter((s) => ({ ...s, search: '' }));
                  } else {
                    setFilter((s) => ({ ...s, search: JSON.stringify([{ isMarked: n }]) }));
                  }
                  setStatus(n);
                }}
                value={status || '0'}
              />
            </div>

            <div className="filter-item ">
              <p className="font-fontFamily font-bold mb-0">View Item</p>
              <SelectCustom
                color="transparent"
                className="min-w-[72px]"
                options={VIEW_ITEMS}
                value={String(filter.limit) || '5'}
                onChange={(n) => setFilter((s) => ({ ...s, limit: +n }))}
              />
            </div>
          </div>
        </div>
        {dataAssignmentsByUnits?.data?.total ? (
          <>
            <Table
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    navigate(`${record.unitID}/${record.id}`);
                  }, // click row
                };
              }}
              columns={[
                {
                  title: 'Module',
                  dataIndex: 'unitID',
                  key: 'unitID',
                  render: (colValue, rowValue) => {
                    return <ModuleColumn colValue={colValue} rowValue={rowValue} />;
                  },
                },
                {
                  title: 'Secction',
                  dataIndex: 'unitID',
                  key: 'unitID2',
                  render: (colValue, rowValue) => {
                    return <SecctionColumn colValue={colValue} rowValue={rowValue} />;
                  },
                },
                {
                  title: 'Student',
                  dataIndex: 'studentID',
                  key: 'studentID',
                  render: (colValue, rowValue) => {
                    return <StudentColumn colValue={colValue} rowValue={rowValue} />;
                  },
                },
                {
                  title: 'Date & Time Submitted',
                  dataIndex: 'submissionDate',
                  key: 'submissionDate',
                  render: (text: string, recordValue: any) => (
                    <div
                      className={`noti-content ${
                        recordValue.isRead ? 'text-[#AEA8A5]' : 'cursor-pointer'
                      }`}
                    >
                      {moment(text).format('YYYY/MM/DD, h:mm a')}
                    </div>
                  ),
                },
                {
                  title: 'MARKED or UNMARKED',
                  dataIndex: 'isMarked',
                  key: 'isMarked',
                  render: (colValue) => {
                    return colValue ? '✅ MARKED' : '❌ UNMARKED';
                  },
                },
                {
                  title: 'Actions',
                  key: 'actions',
                  render: () => {
                    return (
                      <div className="flex">
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            notification.warning({ message: WARNING_MESSAGE.FEATURE_DEVELOPMENT })
                          }
                        >
                          <CustomTooltip title="Edit">
                            <EditSVG className="icon-hover" />
                          </CustomTooltip>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              dataSource={dataAssignmentsByUnits?.data?.records}
              className="bg-transparent table-component cursor-pointer"
              rowKey="id"
            />
            <div className="flex justify-between items-center my-4 footer-course-sp sm:gap-3">
              <span className="font-fontFamily text-sm text-main-font-color bottom-8">
                {startPageSize} - {endPageSize} of {dataAssignmentsByUnits?.data?.total}
              </span>
              <PaginationCustom
                total={dataAssignmentsByUnits?.data?.total}
                pageSize={Number(filter.limit)}
                onChange={(page2: any) => {
                  setFilter((prev) => ({ ...prev, page: page2 }));
                }}
                current={filter.page}
                onLastPage={() => {
                  setFilter((prev) => ({
                    ...prev,
                    page: Math.ceil(
                      Number(dataAssignmentsByUnits?.data?.total) / Number(filter.limit),
                    ),
                  }));
                }}
                onFirstPage={() => {
                  setFilter((prev) => ({ ...prev, page: 1 }));
                }}
              />
            </div>
          </>
        ) : (
          <div>
            {!isLoadingAllAssignmentsByUnits && !isLoadingUnitsInClass && (
              <SearchNotFound isBackgroundWhite text={'filter.search'} />
            )}
          </div>
        )}
      </Layout>
    </Spin>
  );
}

export default Assignments;
