import { Button, Layout } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PreviewContentCreationComponent from './PreviewContentCreation';
import { routerUserAccess } from 'helper/function';
import { RoleName } from 'enum/role';
import { useEffect, useState } from 'react';
import { ROUTES } from 'constants/constants';

const HQLibraryContentCreationTeacher = () => {
  const history = useNavigate();
  const location = useLocation();
  const [checkRoleUser, setCheckRoleUser] = useState(true);
  const routerUser = routerUserAccess();
  const { topicId, sessionId, moduleId } = useParams();

  const checkPatchUrl = () => {
    let urlParents = [
      ROUTES.content_management,
      ROUTES.hq_library_module,
      ROUTES.hq_library_session,
      ROUTES.hq_library_topic,
      // ROUTES.hq_library,
    ];
    let url = null;
    if (routerUser.userRole === 'Teacher')
      urlParents = [ROUTES.content_management, ROUTES.hq_library];
    else if (routerUser.userRole === 'Admin')
      urlParents = [
        ROUTES.content_management,
        ROUTES.hq_library_module,
        ROUTES.hq_library_session,
        ROUTES.hq_library_topic,
      ];

    for (const item of urlParents) {
      if (location.pathname.includes(item)) {
        url = item;
      }
    }
    return url;
  };

  useEffect(() => {
    const urlParent = checkPatchUrl();
    if (routerUser.listRoute.length === 0) setCheckRoleUser(true);
    else
      setCheckRoleUser(
        routerUser.listRoute.some((item: string) => item.includes(urlParent as string)),
      );
  }, [routerUser.listRoute.length]);

  return (
    <>
      {checkRoleUser ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <PreviewContentCreationComponent
            moduleId={moduleId}
            sessionId={sessionId}
            topicId={topicId}
          />
        </Layout>
      ) : (
        <>
          <p className="font-bold text-2xl">
            You don&apos;t have the permission to access this resource
          </p>
        </>
      )}
    </>
  );
};

export default HQLibraryContentCreationTeacher;
