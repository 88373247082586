import { Layout, TablePaginationConfig, Row, Col, Progress, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getListCategories } from 'api/courses';
import ButtonCustom from 'components/Button';
import ModalCustom from 'components/Modal';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import TableCustom from 'components/SearchBar/Table';
import { ROUTES } from 'constants/constants';
import { useDebounce } from 'hooks';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import style from './style.module.scss';
import { isEmpty } from 'lodash';

import { routerUserAccess } from 'helper/function';
import { IStudentInfo } from 'pages/admin/manage-student';
import { generateCert, getStudentsByClassId, generateCerts } from 'api/class';
import CustomTooltip from 'components/Tooltip';
import FilterCard from 'components/SearchBar/FilterCard';

interface IFields {
  search?: string;
  category?: string;
  leaningMethod?: string;
  programType?: string;
}

const ManageClassCertificates = () => {
  const history = useNavigate();
  const [form] = useForm();
  const location = useLocation();
  const routerUser = routerUserAccess();
  const { id: classId } = useParams();
  const [checkRoleUser, setCheckRoleUser] = useState(true);
  const [messageWarning, setMessageWarning] = useState('');
  const [messageConfirmDelete, setMessageConfirmDelete] = useState<string | null>('');
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [dataDropdown, setDataDropdown] = useState([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchDropdown, setSearchDropdown] = useState<string>('');
  const [selection, setSelection] = useState<React.Key[]>([]);
  const [limit, setLimit] = useState<string>('5');
  const debounceValue = useDebounce(searchDropdown, 300);
  const [categoryId, setCategoryId] = useState<number | string>();
  const [isModalDeleteCategory, setIsModalDeleteCategory] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: Number(limit),
    position: [],
  });
  const [students, setStudents] = useState<IStudentInfo[]>([]);
  console.log('## students', students);
  const [studentId, setStudentId] = useState<number | undefined>();

  const { mutate: mutateGetStudentsByClassId, isLoading: isLoadingStudents } = useMutation('getStudentsByClassId', getStudentsByClassId, {
    onSuccess: ({ data }: { data: any }) => {
      const { students: fetchedStudents } = data;
      console.log('data oagij', data);
      setLimit(String(data?.limit));
      setPagination({
        current: data?.page,
        pageSize: Number(limit),
        total: data?.total,
        position: [],
      });
      console.log('## fetchedStudents', fetchedStudents);
      setStudents(fetchedStudents);
      console.log('mutateGetStudentsByClassId time')
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  const { mutateAsync: mutateGenerateCert, isLoading: isLoadingCreate } = useMutation(
    'generateCert',
    generateCert,
    {
      onSuccess: (data) => {
        notification.success({ message: 'Generate success!' });
        mutateGetStudentsByClassId({
          classId: Number(classId),
          params: {
            limit: Number(limit),
            page: pagination?.current as number,
            search: searchValue,
            sort: 'studentName',
          }
        });
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        notification.error({ message: 'Generate fail!' })
      },
    },
  );

  const { mutateAsync: mutateGenerateCerts } = useMutation(
    'generateCerts',
    generateCerts,
    {
      onSuccess: (data) => {
        notification.success({ message: 'Generate success!' });
        mutateGetStudentsByClassId({
          classId: Number(classId),
          params: {
            limit: Number(limit),
            page: pagination?.current as number,
            search: searchValue,
            sort: 'studentName',
          }
        });
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        notification.error({ message: 'Generate fail!' })
      },
    },
  );

  const handleGenerateCert = (recordStudentId: number) => {
    const payload = {
      classId: Number(classId),
      studentId: recordStudentId
    }
    mutateGenerateCert(payload)
      .then(() => {

      })
      .catch(() => {
        notification.error({ message: 'Generate fail!' })
      });
  }

  const handleGenerateCerts = () => {
    mutateGenerateCerts({classId: Number(classId)})
      .then(() => {

      })
      .catch(() => {
        notification.error({ message: 'Generate fail!' })
      });
  }

  const urlParent = ROUTES.manage_class
  useEffect(() => {
    if (routerUser.listRoute.length === 0) setCheckRoleUser(true)
    else
      setCheckRoleUser(routerUser.listRoute.some((item: string) => urlParent === item))
  }, [routerUser.listRoute.length])



  useEffect(() => {
    console.log('run useEffect');
    mutateGetStudentsByClassId({
      classId: Number(classId),
      params: {
        limit: Number(limit),
        page: pagination?.current as number,
        search: searchValue,
        sort: 'studentName',
      }
    });
  }, [pagination.current, limit]);

  useEffect(() => {
    if (studentId) {
      handleGenerateCert(studentId)
    }
  }, [studentId])


  const searchResult = useMemo(
    () => (
      <>
        {debounceValue ? (
          <div className="bg-white rounded-2xl p-4 min-h-[100px]">
            {dataDropdown?.length > 0 ? (
              dataDropdown?.map((course: { id: number; categoryName: string }) => (
                <div
                  className="py-2 font-fontFamily font-normal cursor-pointer"
                  onClick={() => {
                    history(`${ROUTES.update_category}/${course.id}`);
                  }}
                  key={course.id}
                >
                  {course.categoryName}
                </div>
              ))
            ) : debounceValue ? (
              <div className="text-center font-fontFamily font-normal pt-4 word-break">
                No results found for “{debounceValue}”
              </div>
            ) : null}
          </div>
        ) : (
          <div />
        )}
      </>
    ),
    [debounceValue, dataDropdown],
  ) as ReactElement<string>;

  const onValuesChange = (values: IFields) => {
    const search = values.search || '';
    setSearchDropdown(search);
  };

  const messageWarningDeleteCourse = useMemo(() => {
    return `You are not allowed to delete the selected category as the category that you have selected is tagged to a course.`;
  }, []);

  const handleTableChange = (paginate: TablePaginationConfig) => {
    setPagination({ ...pagination, ...paginate });
  };

  const onChangeSelect = (selectedRowKeys: React.Key[]) => {
    setSelection(selectedRowKeys);
  };

  const onChangeLimit = (value: string) => {
    const total = pagination.total;
    const maxPage = Math.ceil(Number(total) / Number(value));
    setLimit(String(value));
    if (Number(pagination.current) > maxPage) setPagination({ ...pagination, current: maxPage });
    else setPagination(pagination);
  };

  const onChangeAction = (value: string) => {
    if (value === 'selection') {
      setIsModalConfirm(true);
      setMessageConfirmDelete(
        selection?.length > 1
          ? 'Are you sure you want to delete the selected categories? This action cannot be undone.'
          : 'Are you sure you want to delete this category? This action cannot be undone.',
      );
    }
    // else if (value === 'all') {
    //   setMessageWarning(
    //     `Can't delete all categories. There are still courses in these categories!`,
    //   );
    // }
  };

  const errorMessage = useMemo(() => {
    return selection?.length > 1
      ? 'You are not allowed to delete the selected categories as one of the category that you have selected is tagged to a course.'
      : 'You are not allowed to delete the selected category as the category that you have selected is tagged to a course.';
  }, [selection]);

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.studentName}>
              <div className="custom-text-ellipsis">{record.studentName}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: true,
      width: 100,
      align: 'center',
      render: (text: string, record: any) => (
        <div className="flex">
          <div className='cursor-pointer flex items-center'>
            <ButtonCustom
              color="orange"
              className={style.new_group_btn}
              onClick={() => {
                console.log('record.certUrl', record.certUrl);
                if (record.certUrl === '') setStudentId(record.id);
                if (record.certUrl !== '') {
                  window.open(record.certUrl)
                };
              }}
            >
              {record.certUrl === '' ? 'Generate Certificate' : 'Download'}
            </ButtonCustom>
          </div>
        </div>
      ),
    },
  ];

  const renderModalWarning = useCallback(() => {
    return (
      messageWarning && (
        <ModalCustom
          visible={true}
          cancelText="Cancel"
          onCancel={() => {
            setMessageWarning('');
          }}
          title="Warning"
          titleCenter
          content={messageWarning}
        />
      )
    );
  }, [messageWarning]);

  return (
    <>
      {
        checkRoleUser
          ?
          <Layout className="bg-transparent flex flex-col gap-y-6">
            <Row className="flex justify-between items-center bg-transparent px-0 mt-5 ">
              <Col className="mb-2 mr-2">
                <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0">
                  Certificates
                </p>
              </Col>

              {/* TODO: Remove the hidden attribute and modify the handleGenerateCerts function */}
              <Col className="mb-2" hidden>
                <ButtonCustom
                  color="orange"
                  className={style.new_group_btn}
                  onClick={handleGenerateCerts}
                >
                  Generate All Certificates
                </ButtonCustom>
              </Col>
            </Row>

            <TableCustom
              enableRowSelection={false}
              isSearch={searchValue ? true : false}
              hidePageSize
              searchNotFound={
                students.length > 0 ? undefined : (
                  <SearchNotFound isBackgroundWhite text={searchValue} />
                )
              }
              columns={columns}
              data={students}
              pagination={pagination}
              isLoading={isLoadingStudents}
              handleTableChange={handleTableChange}
              onChangeSelect={onChangeSelect}
              onChangePagination={(page) => {
                setPagination({ ...pagination, current: Number(page) });
              }}
              onLastPage={() => {
                setPagination({
                  ...pagination,
                  current: Math.ceil(Number(pagination.total) / Number(pagination.pageSize)),
                });
              }}
              onFirstPage={() => {
                setPagination({ ...pagination, current: 1 });
              }}
              viewItem={{
                onChange: onChangeLimit,
                value: String(limit),
              }}
              action={{
                show: selection.length > 0 ? true : false,
                onSelect: onChangeAction,
                options: [
                  // { value: 'all', label: 'Delete All' },
                  { value: 'selection', label: 'Delete' },
                ],
              }}
            />
            {renderModalWarning()}
          </Layout>
          :
          <p className='font-bold text-2xl'>You don&apos;t have the permission to access this resource</p>
      }
    </>
  );
};

export default ManageClassCertificates;
