import { getAPI, putAPI } from './axios';

export const getAllGlobalSetting = async () => {
  try {
    const data = await getAPI(`api/global-settings/all`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGlobalSettingByKey = async (key: string) => {
  try {
    const data = await getAPI(`api/global-settings/${key}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateGlobalSetting = async (payload: any) => {
  try {
    const data = await putAPI(`api/global-settings/${payload.id}`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
