import React, { useMemo } from 'react';
import style from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { background } from './helper';

interface IProps {
  groupName: string;
  onClick?: () => void;
  groupID: number;
}

const GroupCard = ({ groupName, groupID }: IProps) => {
  const navigate = useNavigate();

  const randomBackgroundColorMemo = useMemo(
    () => background[Math.floor(Math.random() * background.length)],
    [],
  );
  return (
    <>
      <div
        onClick={() =>
          navigate(`/courses/my-group/${groupID}`, {
            state: {
              groupName: groupName,
            },
          })
        }
        className={`${style.cardWrapper} flex justify-center items-center relative border border-red-800 p-4  ${randomBackgroundColorMemo}`}
      >
        <div
          className={style.icon}
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>

        <h1 className="font-bold text-2xl text-[#32302D]">{groupName ?? 'Group 1'}</h1>
      </div>
    </>
  );
};

export default GroupCard;
