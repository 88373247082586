import { Spin } from 'antd';
import { getUnits } from 'api/unit';
import AssignmentsBySecction from 'components/AssignmentsBySecction';
import { useIsInViewport } from 'hooks';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import { useQuery } from 'react-query';
import './style.css';

interface ISecctionByModule {
  sessionName: string;
  completedPercent: number;
  completed: number;
  status: string;
  id: number;
}

function SecctionByModule(props: ISecctionByModule) {
  const { id } = props;
  const ref = useRef<any>(null);
  const isInViewport = useIsInViewport(ref);

  const { data: dataUnits, isLoading } = useQuery({
    queryKey: ['getUnits', id],
    queryFn: () =>
      getUnits({
        filters: JSON.stringify([{ sessionID: id, unitType: 'Assignment' }]),
        limit: 10,
        page: 1,
        search: '',
      }),
    enabled: isInViewport,
  });

  return (
    <div
      style={{ display: dataUnits?.data?.records?.length === 0 ? 'none' : 'block', width: '100%' }}
      ref={ref}
    >
      <Spin className="py-2" spinning={isLoading}>
        <div style={{ minWidth: '100%' }} className="w-full flex flex-col gap-2">
          {!isEmpty(dataUnits?.data?.records) &&
            dataUnits?.data?.records?.map((x: any) => <AssignmentsBySecction key={x.id} {...x} />)}
        </div>
      </Spin>
    </div>
  );
}

export default SecctionByModule;
