import { getSearchParams } from 'constants/index';
import { getAPI, postAPI, putAPI } from './axios';
import { IParamsSearch } from './courses';

export interface ICreateGroup {
  groupName: string;
  classID: number;
  teacherID: number;
  studentIDs: number[];
  groupID?: number;
}

export const getGroupMember = async (params: IParamsSearch) => {
  const paramsOption = getSearchParams(params);

  try {
    const data = await getAPI(`api/groups/members${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const getGroupList = async (params: IParamsSearch) => {
  const paramsOption = getSearchParams(params);

  try {
    const data = await getAPI(`api/groups/all${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const createGroup = async (body: ICreateGroup) => {
  try {
    const data = await postAPI(`api/groups/create`, { ...body });
    return data;
  } catch (error) {
    throw error;
  }
};
export const updateGroup = async (body: ICreateGroup) => {
  try {
    const data = await postAPI(`api/groups/update`, { ...body });
    return data;
  } catch (error) {
    throw error;
  }
};

export const leaveFromGroup = async (body: { userType: string; groupID: number }) => {
  try {
    const data = await postAPI(`api/groups/leave`, { ...body });
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeStudentFromGroup = async (body: { studentID: number; groupID: number }) => {
  try {
    const data = await putAPI(`api/groups/remove-student`, { ...body });
    return data;
  } catch (error) {
    throw error;
  }
};
