import { getAPI, getAPIS3, postAPI, postAPIS3 } from './axios';

export enum ETypeGetListObject {
  CLASS = 'class-library',
  GROUP = 'group-library',
}

export interface IParamsListObject {
  prefix: string;
  fullTextSearch?: string;
  filterConditon?: string;
  type?: ETypeGetListObject;
}

export interface IParamsObject {
  key: string;
}

export interface IPayloadCreateNewFolder {
  folderName: string;
  type?: ETypeGetListObject;
  idPath: number;
}

export interface IParamsSearch {
  limit: number;
  page: number;
  search?: string;
}

export interface IParamsSharedPeople {
  path: string;
  sharerId: number;
}

export interface IRenameFile {
  currentPath: string;
  oldFileName: string;
  newFileName: string;
  type?: ETypeGetListObject;
  idPath: number;
}

export interface IRemoveFile {
  currentPath: string;
  fileName: string;
  type?: ETypeGetListObject;
  idPath: number;
}

export interface IMoveFolder {
  sourcePath: string;
  sourceMove: string;
  type?: ETypeGetListObject;
  idPath: number;
}

export interface IShareForUser {
  userId: number[];
  path: string;
  sharedType: string;
  sharerId: number;
  notify: boolean;
  message: string;
}

export interface IRemoveFolder {
  folderPath: string;
  type?: ETypeGetListObject;
  idPath: number;
}

export const getListObjects = async (params: IParamsListObject) => {
  try {
    const data = await getAPI(`api/own-drive`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getObject = async (params: IParamsObject) => {
  try {
    const data = await getAPIS3(`api/own-drive/object`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const dowloadFile = async (params: IParamsObject) => {
  try {
    const data = await getAPIS3(`api/own-drive/dowload`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createNewFolder = async (payload: IPayloadCreateNewFolder) => {
  try {
    const data = await postAPI(`api/own-drive`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const renameFile = async (payload: IRenameFile) => {
  try {
    const data = await postAPI(`api/own-drive/rename-file`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeFile = async (payload: IRemoveFile) => {
  try {
    const data = await postAPI(`api/own-drive/remove-file`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeFolder = async (payload: IRemoveFolder) => {
  try {
    const data = await postAPIS3(`api/own-drive/remove-folder`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const uploadFileImage = async (formData: FormData) => {
  try {
    const data = await postAPIS3(`api/own-drive/upload-file`, formData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const moveFolder = async (payload: IMoveFolder) => {
  try {
    const data = await postAPIS3(`api/own-drive/move-folder`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const moveFile = async (payload: IMoveFolder) => {
  try {
    const data = await postAPIS3(`api/own-drive/move-file`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const shareForUser = async (payload: IShareForUser) => {
  try {
    const data = await postAPIS3(`api/own-drive/share-for-user`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getListUsersShare = async (params: IParamsSearch) => {
  try {
    const data = await getAPI(`api/own-drive/list-user`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getListSharedPeople = async (params: IParamsSharedPeople) => {
  try {
    const data = await getAPI(`api/own-drive/shared-people`, params);
    return data;
  } catch (error) {
    throw error;
  }
};
