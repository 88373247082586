import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Spin, Typography } from 'antd';

import 'react-quill/dist/quill.snow.css';
import './style.css';
import { getUnitsId } from 'api/unit';

const { Title } = Typography;

import ContentByUnit from 'components/ContentByUnit';
import { useContext } from 'react';
import { AppContext } from 'context';
import { getAllAssignments } from 'api/assignment';
import { getAssignmentAttachmentsByAssignmentID } from 'api/assignment_attachments';

function MyAssignmentDetail() {
  const { id } = useParams();
  const [stateContext]: any = useContext(AppContext);

  const { data: dataUnit, isLoading, refetch: refetchGetUnitsId } = useQuery(['getUnitsId', id], () => getUnitsId(Number(id)), {
    enabled: !!id,
  });

  const { data: dataAssignments, isLoading: isLoadingAssignments, refetch: refetchGetAllAssignments } = useQuery(['getAllAssignments', id], () => getAllAssignments({ limit: 1, page: 1, filters: JSON.stringify([{ unitID: Number(id), studentID: stateContext?.user?.student?.id }]) }), {
    enabled: !!id && !!stateContext?.user?.student?.id,
  });

  const { data: dataAssignmentAttachmentsByAssignmentID, isLoading: isLoadingAssignmentAttachmentsByAssignmentID, refetch: refetchAssignmentAttachmentsByAssignmentID } = useQuery(['getAssignmentAttachmentsByAssignmentID', id], () => getAssignmentAttachmentsByAssignmentID(Number(dataAssignments?.data?.records?.[0]?.id)), {
    enabled: !!id && !!dataAssignments?.data?.records?.[0]?.id
  });




  return (
    <Spin spinning={isLoading || isLoadingAssignments} tip="Loading...">
      <div className="pb-5">
        <Title level={3} className="flex items-center gap-3 flex-wrap">
          <div style={{ opacity: 0.5 }}>My Assignments</div>
          <div style={{ opacity: 0.5 }}>/</div>
          <div style={{ opacity: 0.5 }}>{dataUnit?.data?.session?.sessionName}</div>
          <div style={{ opacity: 1 }}>/</div>
          <div style={{ opacity: 1 }}>{dataUnit?.data?.unitName}</div>
        </Title>
        {!!id && dataAssignments?.data?.records && <ContentByUnit assignmentAttachments={dataAssignmentAttachmentsByAssignmentID?.data} refetchGetUnitsId={refetchGetUnitsId} refetchGetAllAssignments={refetchGetAllAssignments} assignment={dataAssignments?.data?.records?.[0]} stateContext={stateContext} unitId={id} content={dataUnit?.data?.content} />}
      </div>
    </Spin>
  );
}

export default MyAssignmentDetail;
