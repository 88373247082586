import { getSearchParams } from 'constants/index';
import { getAPI, putAPI } from './axios';
import { IParamsSearch } from './courses';

export const getUnits = async (params: IParamsSearch) => {
  const paramsOption = getSearchParams(params);

  try {
    const data = await getAPI(`api/unit/all${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUnitsId = async (id?: number) => {
  try {
    const data = await getAPI(`api/unit/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUnitsId = async (id?: number) => {
  try {
    const data = await putAPI(`api/unit/${id}`, {});
    return data;
  } catch (error) {
    throw error;
  }
};
