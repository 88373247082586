import { getSearchParams } from 'constants/index';
import { deleteAPI, getAPI, postAPI, putAPI } from './axios';
import { IParamsSearch } from './courses';
import { TCreateReplyFormData, TCreateTopicFormData } from 'pages/admin/forum/types';

export const searchForums = async (params: {classId: number, search: IParamsSearch}) => {
  const paramsOption = getSearchParams(params.search);
  try {
    const data = await getAPI(`api/forum/${params.classId}${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchTopics = async (forumId: number) => {
  try {
    const data = await getAPI(`api/forum_topics/${forumId}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export const getTopicDetails = async (params: {forumId: string; topicId: string;}) => {
  const { forumId, topicId } = params;
  try {
    const data = await getAPI(`api/forum/${forumId}/topic/${topicId}`);
    return data
  } catch (error) {
    throw error;
  }
}

export const createNewTopic = async (formData: TCreateTopicFormData) => {
  try {
    const data = await postAPI(`api/forum-topics`, formData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteTopic = async (id: number) => {
  try {
    await deleteAPI(`api/topics/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createNewReply = async (formData: TCreateReplyFormData) => {
  try {
    const data = await putAPI(`api/forum-topics/reply`, formData);
    return data;
  } catch (error) {
    throw error;
  }
}