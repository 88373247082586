import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, Button, Modal, Form, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import CustomInput from 'components/Input';
import { RuleObject } from 'antd/lib/form';
import {
  ContentType,
  IContent,
  ISession,
  ITag,
  IUnit,
  LIST_QUESTION_OPTIONS,
  QuestionType,
  UnitType,
} from '../../index';

const { Option } = Select;
const regexUrl =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g;
interface IProps {
  sessionData: ISession;
  selectedUnit: any;
  handleAddOrEditUnit: (values: any) => void;
  handleCancelAddOrEditUnit: () => void;
  setListUnits: (value: any[]) => void;
  listUnits: any[];
}

const IFrameComponent = (props: IProps) => {
  const [url, setUrl] = useState('');
  const [previewType, setPreviewType] = useState('embed');
  const [form] = Form.useForm();
  const [isClickInputUrl, setIsClickInputUrl] = useState<boolean>(false);
  const [selectedUnitType, setSelectedUnitType] = useState<ContentType>();
  const [contentData, setContentData] = useState<any>(undefined);
  const unitName = Form.useWatch('unitName', form);
  const {
    sessionData,
    selectedUnit,
    handleAddOrEditUnit,
    handleCancelAddOrEditUnit,
    setListUnits,
    listUnits,
  } = props;
  const handlePreview = () => {
    console.log(previewType);
    if (previewType === 'embed') {
      // Display the URL in an iframe
      // You can customize the iframe style and dimensions
      Modal.info({
        title: 'Embedded Preview',
        content: (
          <div>
            <iframe
              src={url}
              title="Embedded Preview"
              width="100%"
              height="300"
            ></iframe>
          </div>
        ),
        width: '80%',
        style: {
          width: '90%',   // Customize the width as needed
          maxWidth: 'none', // Allow the modal to be as wide as content
        },
      });
    } else if (previewType === 'popup') {
      // Open the URL in a new popup window
      window.open(url, '_blank', 'width=600,height=400');
    }
  };

  const handleSubmit = () => {
    // Handle storing the URL in the database
    // You can perform an API call to store the URL
    // or use the appropriate database integration
    // For demonstration purposes, we'll just log it
    console.log('URL:', url);
    console.log('Preview Type:', previewType);
  };

  useEffect(() => {
    if (selectedUnit) {
      form.setFieldsValue(selectedUnit);
      // if (selectedUnit.content && selectedUnit.content[0]) {
      //     setContentData(selectedUnit.content[0]);
      //     setSelectedUnitType(selectedUnit.content[0].contentType || undefined);
      //     setQuestionChoice(selectedUnit.content[0].questionType || undefined);
      //     setIsUploadedFile(selectedUnit.content[0].isUploadedFile)
      //     setFilePath(selectedUnit.content[0].filePath || undefined)
      //     if (!selectedUnit.content[0].isUploadedFile && selectedUnit.content[0].url && selectedUnit.content[0].url !== '') {
      //         setUploadedUrl(selectedUnit.content[0].url);

      //         if (selectedUnit.content[0].contentTitle && selectedUnit.content[0].contentTitle !== '') {
      //             setIsPreviewUrl(true);
      //             setUrlContent(selectedUnit.content[0].contentTitle);
      //         }
      //     }
      // } 
      if (selectedUnit.url && selectedUnit.url !== '') {
        setUrl(selectedUnit.url);

        // if (selectedUnit.content[0].contentType && selectedUnit.content[0].contentType !== '') {
        //   setPreviewType((selectedUnit.content[0].contentType).toLowerCase()); 
        // } 
      } else {
      }
    }
  }, [selectedUnit]);

  const onFinish = useCallback(
    (values: any) => {
      const dataPush: IUnit = { //step 2
        sessionID: sessionData?.id || undefined,
        unitType: UnitType.IFRAME,
        unitName: values.unitName.trim(),
        isUploadedFile: false,
        filePath: url,
        isDisabled: false,
        contentId: undefined,
        content: [
          // {
          //   ...contentData,
          //   contentTitle: "URL",
          //   contentType: ,
          //   unitID: Number(selectedUnit?.id),
          //   tagIds:
          //     selectedUnitType === ContentType.QUESTION
          //       ? values.contentTagIDs?.map((tag: { value: string; label: string }) => {
          //           return {
          //             id: Number(tag.value),
          //             tagName: tag.label,
          //           };
          //         })
          //       : [],
          //   questionType:
          //        undefined,
          //   // isUploadedFile: isUploadedFile,
          //   // filePath: isUploadedFile ? filePath : '',
          //   timeLimit: undefined,
          //   id:  undefined,
          // },
        ],
        order: selectedUnit?.order || undefined,
        url: values.url.trim() || undefined,
        urlContent: previewType,
        id: selectedUnit?.id || undefined,
      };
      handleAddOrEditUnit(dataPush);
      console.log("dataPush.urlContent===>", dataPush.urlContent);
    }, [sessionData, previewType]);
  return (
    <div>
      <Form
        // layout="vertical"
        className="flex flex-wrap gap-x-4 flex-[62%]"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          className={'w-full sm:w-full lg:w-[49%] unit-name'}
          key={'unitName'}
          validateFirst
          name={'unitName'}
          label={<span className="flex align-center">Unit Name</span>}
          rules={[
            { required: true, message: 'Unit Name is required!' },
            {
              validator(_: RuleObject, value: string) {
                if (value?.trim() === '') {
                  return Promise.reject('Unit Name is required!');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <CustomInput classNameCustom="max-w-[476px]"
            type={'string'}
          // placeholder='Unit Name Placeholder'
          />
        </Form.Item>
        <Content className="rounded-3xl bg-white p-8 w-full" title="Add content">
          <p className="text-base font-fontFamily font-bold mb-2 tracking-tight">
            Web address (URL)
          </p>
          <Row className="web-content__upload">
            <Form.Item
              className={'url'}
              key={'url'}
              validateFirst
              name={'url'}
              rules={[
                { required: true, message: 'Url is required!' },
                {
                  validator(_: RuleObject, value: string) {
                    if (value?.trim() === '') {
                      return Promise.reject('Url is required!');
                    } else if (!value?.trim().match(regexUrl)) {
                      return Promise.reject('Invalid url!');
                    }
                    return Promise.resolve();
                  },
                },
                // {
                //     type: "url",
                //     message: "Invalid url."
                // }
              ]}
            >
              <CustomInput
                classNameCustom="input-upload-url custom"
                type={'text'}
                placeholder="Start writing for suggestions or paste a URL 1"
                onChange={(e) => {
                  setIsClickInputUrl(true);
                  setUrl(e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item className="btn-upload custom-width">
              <Select
                style={{ width: 120, margin: '0 10px' }}
                defaultValue="embed"
                onChange={(value) => setPreviewType(value)}
              >
                <Option value="embed">Embed</Option>
                <Option value="popup">Popup</Option>
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item className="btn-upload custom-width">
              <Button
                className="bg-[#FCECD9] btn-load-iframe rounded-xl h-11 min-w-[188px] custom-width font-fontFamily font-bold text-black"
                onClick={handlePreview}
              >
                Preview
              </Button>
              <Button className="bg-[#FCECD9] btn-load-iframe rounded-xl h-11 min-w-[188px] custom-width font-fontFamily font-bold text-black" htmlType="submit" style={{ marginLeft: '10px' }}>
                Save
              </Button>
            </Form.Item>
          </Row>
        </Content>
      </Form>
    </div>
  );
};

export default IFrameComponent;
