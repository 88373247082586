import { Form, Layout, Row, Col, Breadcrumb } from 'antd';
import ButtonCustom from 'components/Button';
import CalendarList from './component/CalendarList';
import CalendarFilter from './component/CalendarFilter';
import EventModified from './component/CalendarModal/EventModified';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ICalendarListFilter, IEvent } from './constants';
import moment from 'moment';
import { Views } from 'react-big-calendar';
import { useMutation } from 'react-query';
import { getAllEvent } from 'api/event';
import EventDelete from './component/CalendarModal/EventDelete';
import { getDaysArray, routerUserAccess } from 'helper/function';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllSessionInClassesByStudent, getClassById, searchClass } from 'api/class';
import { PARAMS_SELECT_SEARCH, ROUTES, TEXT_SELECT_SEARCH } from 'constants/constants';
import { RoleName } from 'enum/role';
import { Header } from 'antd/lib/layout/layout';
import { getCourseDetail } from 'api/courses';
import { ICourseDetail } from '../my-course-detail';

const CalendarTabMyCourse = () => {
  const { courseId } = useParams();
  const [classId, setClassId] = useState<string | undefined>(undefined);
  const [className, setClassName] = useState<string | null>(null);
  const [courseName, setCourseName] = useState<string | null>(null);
  // const [visible, setVisible] = useState(false);
  const routerUser = routerUserAccess();
  // const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [form] = Form.useForm();
  const [eventDetail, setEventDetail] = useState<IEvent>();
  const timeout: any = useRef(null);
  const [eventSearch, setEventSearch] = useState<IEvent[]>([]);
  const [eventSearched, setEventSearched] = useState<IEvent>();
  const [events, setEvents] = useState<IEvent[]>([]);
  const [agendaEvents, setAgendaEvents] = useState<IEvent[]>([]);
  const [dayEvents, setDayEvents] = useState<IEvent[]>([]);
  const [filter, setFilter] = useState<ICalendarListFilter>({
    date: moment(),
    amOrPm: 'AM',
    sort: Views.DAY,
  });
  const [idEventFocused, setIdEventFocused] = useState<{ day?: string; agenda?: string }>({
    day: '',
    agenda: '',
  });
  const navigate = useNavigate();
  const [listSession, setListSession] = useState<any[]>([]);

  const [classOptions, setClassOptions] = useState<
    { label: string; value: string; isDisabled?: boolean }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const { mutate: getCourse } = useMutation('getCourseDetail', getCourseDetail, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      setCourseName(data?.courseName ?? null);
    },
  });

  const dataEvents = (newEvents: IEvent[]) => {
    const result = [];
    try {
      for (const event of newEvents) {
        const startDateTime = new Date(event.startDateTime as string);
        const endDateTime = new Date(event.endDateTime as string);
        const dates = getDaysArray(startDateTime, endDateTime);
        if (dates.length == 1) {
          result.push({
            start: startDateTime,
            end: endDateTime,
            title: event.title,
            resource: {
              ...event,
            },
          });
          continue;
        }

        for (const date of dates) {
          if (startDateTime.toLocaleDateString() == date.toLocaleDateString()) {
            result.push({
              start: moment(event.startDateTime).toDate(),
              end: moment(event.startDateTime).endOf('date').toDate(),
              title: event.title,
              resource: {
                ...event,
              },
            });
          } else if (endDateTime.toLocaleDateString() == date.toLocaleDateString()) {
            result.push({
              start: moment(event.endDateTime).startOf('date').toDate(),
              end: moment(event.endDateTime).toDate(),
              title: event.title,
              resource: {
                ...event,
              },
            });
          } else {
            result.push({
              start: moment(date).startOf('date').toDate(),
              end: moment(date).endOf('date').toDate(),
              title: event.title,
              resource: {
                ...event,
              },
            });
          }
        }
      }
      return result;
    } catch (error) {
      throw error;
    }
  };
  const convertTimeDataSession = (data: any[]) => {
    const listSessions: any = [];
    data.map((elm: any) => {
      const startDateTime = new Date(elm.startTime as string);
      const endDateTime = new Date(elm.endTime as string);
      const dates = getDaysArray(startDateTime, endDateTime);
      // if (dates.length == 1) {
      //   listSessions.push({
      //     start: startDateTime,
      //     end: endDateTime,
      //     title: elm?.session?.sessionName ?? 'Session Name',
      //     resource: {
      //       ...elm,
      //       id: Math.random(),

      //     },
      //     isSession: true,
      //   });
      // }

      for (const date of dates) {
        if (startDateTime.toLocaleDateString() == date.toLocaleDateString()) {
          listSessions.push({
            start: moment(elm.startTime).toDate(),
            end: moment(elm.startTime).endOf('date').toDate(),
            title: elm?.session?.sessionName ?? 'Session Name',
            resource: {
              ...elm,
              id: Math.random(),

            },
            
            isSession: true,
          });
        } else if (endDateTime.toLocaleDateString() == date.toLocaleDateString()) {
          listSessions.push({
            start: moment(elm.endTime).startOf('date').toDate(),
            end: moment(elm.endTime).toDate(),
            title: elm?.session?.sessionName ?? 'Session Name',
            resource: {
              ...elm,
              id: Math.random(),

            },
            isSession: true,
          });
        } else {
          listSessions.push({
            start: moment(date).startOf('date').toDate(),
            end: moment(date).endOf('date').toDate(),
            title: elm?.session?.sessionName ?? 'Session Name',
            resource: {
              ...elm,
              id: Math.random(),

            },
            isSession: true,
          });
        }
      }
    });
    return listSessions;
  };

  const { mutate: getListSession } = useMutation('getListSession', getAllSessionInClassesByStudent, {
    onSuccess: ({ data }: { data: any[] }) => {
      console.log('run to get getListSession ====================>', data);
      setListSession(convertTimeDataSession(data)) 
      if (filter?.id) {
        const date =
          moment(data[0].startTime).format('YYYY-MM-DD') ===
          moment(new Date()).format('YYYY-MM-DD')
            ? moment(data[0].startTime)
            : moment(data[0].startTime).startOf('day');

        //if want to display one event
        // setFilter((prev) => ({ sort: prev.sort, date, id: filter?.id }));
        console.log('run to her===================>', date);

        setFilter((prev) => ({ sort: prev.sort, date, idSearched: filter?.id }));
        setEventSearched(data[0]);
      }
    },
  });

  const { mutate: getListEvent } = useMutation('getListEvent', getAllEvent, {
    onSuccess: ({ data }: { data: IEvent[] }) => {
      console.log('run to get list event', data);

      setEvents(dataEvents(data));

      setAgendaEvents(dataEvents(data));

      console.log('filter?.id========================>', filter?.id);

      if (filter?.id) {
        const date =
          moment(data[0].startDateTime).format('YYYY-MM-DD') ===
          moment(new Date()).format('YYYY-MM-DD')
            ? moment(data[0].startDateTime)
            : moment(data[0].startDateTime).startOf('day');

        //if want to display one event
        // setFilter((prev) => ({ sort: prev.sort, date, id: filter?.id }));
        console.log('run to her===================>', date);

        setFilter((prev) => ({ sort: prev.sort, date, idSearched: filter?.id }));
        setEventSearched(data[0]);
      }
    },
  });

  const { mutate: getClasses } = useMutation('searchClass', searchClass, {
    onSuccess: ({
      data,
    }: {
      data: { listClasses: { id: number; className: string; course: { courseName: string } }[] };
    }) => {
      let newOptions = data.listClasses.map((el) => {
        return {
          label: el.className + ' - ' + el.course.courseName,
          value: el.id.toString(),
          disabled: false,
        };
      });
      newOptions = newOptions.concat([
        { label: TEXT_SELECT_SEARCH.class, value: '', disabled: true },
      ]);
      setClassOptions(newOptions);
    },
  });

  useEffect(() => {
    setIsLoading(true);
  }, []);
  const { mutate: getClass } = useMutation('getClass', getClassById, {
    onSuccess: ({ data }) => {
      setClassName(data.className);
    },
  });

  const getData = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const classIdInParam = params.get('classId');
    const param ={
      search: '',
      classes: classIdInParam ?? '',
    }
    Promise.all([
      getCourse(+courseId!),
      setClassId(classIdInParam ?? undefined),
      getClass(classIdInParam ? +classIdInParam : 0),
      getClasses(PARAMS_SELECT_SEARCH.class),
      getListEvent(param),
      getListSession(param)
    ]);
  };

  useEffect(() => {
    if (isLoading && window) {
      getData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (filter?.search) {
      const param = {
        search: filter.search,
        classes: classId,
      }
      getListEvent(param);
      getListSession({...param,classes:classId ?? ''})
    } else if (filter?.id) {
      console.log('call API ======>', filter?.id);

      // if (filter?.sort === 'day') {
      const callApi =
        filter?.id?.toString() !==
        (filter?.sort === 'day'
          ? idEventFocused.day?.split('_')[0]
          : idEventFocused.agenda?.split('_')[0]);
      console.log('call API ======>', callApi);

      if (callApi) {
        getListEvent({ id: filter?.id });
        getListSession({classes:classId ?? '',id:filter?.id})
      }
    }
  }, [filter?.search, filter?.id, filter?.sort]);

  useEffect(() => {
    const eventsFilter =
      events?.filter(
        (event: IEvent) =>
          moment(event?.start, 'YYYY-MM-DD').format('YYYY-MM-DD') ===
          moment(filter?.date ? filter?.date : new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      ) || [];
    setDayEvents(eventsFilter);
    // }
  }, [filter?.date, filter?.sort, events, eventSearch]);

  const handleChangeFilter = (newFilter?: ICalendarListFilter) => {
    if (eventSearched?.id && newFilter?.sort && newFilter?.sort !== filter.sort) {
      setFilter((prev) => ({
        ...prev,
        ...newFilter,
        date: moment(eventSearched.startDateTime),
        time: moment(eventSearched.startDateTime),
      }));

      return;
    }

    setFilter((prev) => ({ ...prev, ...newFilter }));
  };

  return (
    <>
      {routerUser.userRole === RoleName.STUDENT ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <Row className="flex justify-between items-center bg-transparent px-0 ">
            <Col>
              <Header className="flex justify-between items-center bg-transparent px-0 h-auto">
                <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
                  <Breadcrumb.Item
                    className="cursor-pointer font-fontFamily"
                    onClick={() => navigate(ROUTES.my_course)}
                  >
                    My Course
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font-fontFamily">
                    {`${courseName ?? 'Course Name'} - ${className ?? 'Class Name'}`}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="font-fontFamily text-main-font-color">
                    Calendar
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Header>
            </Col>
          </Row>

          <CalendarList
            events={dayEvents}
            filter={filter}
            onChangeFilter={handleChangeFilter}
            agendarEvents={agendaEvents}
            eventSearched={eventSearched}
            idEventFocused={idEventFocused}
            listSession={listSession}
            onChangeIdEventFocused={(value: { day?: string; agenda?: string }) =>
              setIdEventFocused((prev) => ({ ...prev, ...value }))
            }
          />
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default CalendarTabMyCourse;
