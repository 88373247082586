import { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { Dropdown, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import ButtonCustom from 'components/Button';
import { IMemberList } from 'pages/admin/class-management/component/GroupDetail/helper';

import images from 'assets/images';
import style from './filter.module.scss';

interface IGroupDetailFilter {
  handleReset?: () => void;
  onFinish?: (values: any) => void;
  form?: FormInstance<any>;
  handleChangeSearch?: (value: string) => void;
  searchResults?: IMemberList[];
  keyResult?: string;
  handleRefetching?: () => void;
  redirectToListSession?: (id?: number, moduleName?: string) => void;
}

const GroupDetailFilter = (props: IGroupDetailFilter) => {
  const {
    form,
    keyResult,
    searchResults,
    onFinish,
    handleReset,
    handleRefetching,
    handleChangeSearch,
    redirectToListSession,
  } = props;

  const [valueSearch, setValueSearch] = useState('');

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
    if (handleChangeSearch instanceof Function) {
      handleChangeSearch(event.target.value);
    }
  };

  const onSearch = (moduleId?: number, moduleName?: string) => {
    setValueSearch(moduleName || '');
    form?.setFieldsValue({ search: moduleName });

    if (redirectToListSession instanceof Function) {
      redirectToListSession(moduleId, moduleName);
    }
  };

  const searchResult = useMemo(
    () => (
      <>
        {valueSearch && keyResult ? (
          <div className="bg-white rounded-2xl p-4 min-h-[100px] w-full">
            {searchResults && searchResults?.length > 0 ? (
              searchResults?.map((result: IMemberList) => {
                return (
                  <div
                    className="py-2 font-fontFamily font-normal cursor-pointer text-truncate"
                    key={result.studentID}
                    onClick={() => {
                      handleRefetching?.();
                      onSearch(result?.studentID as number, result.userFullName);
                    }}
                  >
                    {result.userFullName}
                  </div>
                );
              })
            ) : valueSearch ? (
              <div className="text-center font-fontFamily font-normal pt-4 word-break">
                No results found for “{valueSearch}”
              </div>
            ) : null}
          </div>
        ) : (
          <div />
        )}
      </>
    ),
    [searchResults, valueSearch, keyResult],
  ) as ReactElement<string>;

  return (
    <div className="filter-card">
      <div className="filter-content w-full">
        <Form
          form={form}
          className="w-full flex flex-wrap gap-4"
          name="basic"
          initialValues={{ layout: 'inline' }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <div className="flex w-full gap-4 relative  md:flex-wrap sm:flex-wrap lg:flex-wrap">
            <Dropdown
              trigger={['click']}
              overlay={searchResult}
              placement="bottom"
              className="w-[100%] h-auto relative lg:w-full"
              getPopupContainer={(trigger: any) => trigger?.parentNode}
            >
              <div
                className="relative"
                onClick={(e) => e.preventDefault()}
                style={{
                  flex: 1,
                }}
              >
                <Form.Item name="search" className="w-full mb-0">
                  <Input
                    value={valueSearch}
                    placeholder="Search"
                    onChange={onChangeSearch}
                    suffix={<img src={images.search} alt="search" />}
                    className="style_input_custom_login_page rounded-xl"
                  />
                </Form.Item>
              </div>
            </Dropdown>

            <Form.Item className="basis-[15%] mb-0 sm:basis-[30%] lg:basis-[20%] ">
              <ButtonCustom className={`${style.myBtn} h-12 min-w-fit w-full`} htmlType="submit">
                Search
              </ButtonCustom>
            </Form.Item>
            <Form.Item className="mb-0 w-48-class sm:basis-[20%] lg:basis-[20%] w-[10%]">
              <ButtonCustom
                className="h-12 min-w-fit w-full"
                htmlType="button"
                color="outline"
                onClick={() => {
                  if (handleReset instanceof Function) {
                    handleReset();
                  }
                  form?.resetFields();
                  setValueSearch('');
                }}
              >
                Reset
              </ButtonCustom>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default GroupDetailFilter;
