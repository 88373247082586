import { postAPI, getAPI } from './axios';

export const createAssignmentAttachments = async (payload: any) => {
  try {
    const data = await postAPI(`api/assignment-attachments/many`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAssignmentAttachmentsByAssignmentID = async (id: number) => {
  try {
    const data = await getAPI(`api/assignment-attachments/all/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
