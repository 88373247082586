import { Typography } from 'antd';
import { getAllCoursesStudent } from 'api/courses';
import { useQuery } from 'react-query';
import CourseByStudent from 'components/CourseByStudent';
const { Title } = Typography;

const Dashboard = () => {
  const { data: dataAllCoursesStudent } = useQuery(['getAllCoursesStudent'], () =>
    getAllCoursesStudent({ limit: 10, page: 1, search: '' }),
  );

  return (
    <div className="pb-20">
      <Title level={2} className="flex items-center gap-3 flex-wrap">
        <div style={{ opacity: 0.5 }}>My Assignments</div>
        <div style={{ opacity: 0.5 }}>/</div>
        <div>Module - Session</div>
      </Title>
      <div className="pt-4 flex flex-col gap-8">
        {!!dataAllCoursesStudent?.data?.listCourses?.length &&
          dataAllCoursesStudent?.data?.listCourses?.map?.((x: any) => (
            <CourseByStudent
              key={`course-${x.course_id}`}
              classId={x.class_id}
              courseId={x.course_id}
            />
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
