import { Dropdown, Form, Input, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import images from 'assets/images';
import SelectCustom from 'components/Select';
import { ChangeEvent, ReactElement, useMemo, useState } from 'react';

interface IClassFilter {
  onFinish?: (values: any) => void;
  form?: FormInstance<any>;
  handleChangeSearch?: (value: string) => void;
  searchResults?: Array<{ id?: string | number; courseName?: string }>;
  keyResult?: string;
  onChangeLimit?: (value: string) => void;
  limit?: number;
}

const optionsLimit = [
  { label: '3', value: '3' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
];

const ForumSearch = (props: IClassFilter) => {
  const { onFinish, handleChangeSearch, searchResults, keyResult, form, onChangeLimit, limit } = props;
  const [valueSearch, setValueSearch] = useState('');

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
    if (handleChangeSearch instanceof Function) {
      handleChangeSearch(event.target.value);
    }
  };

  const onSearch = (courseId?: number, courseName?: string) => {
    setValueSearch(courseName || '');
    form?.setFieldsValue({ search: courseName });

    if (onFinish instanceof Function) {
      onFinish({ courseId });
    }
  };

  const searchResult = useMemo(
    () => (
      <>
        {valueSearch && keyResult ? (
          <div className="bg-white rounded-2xl p-4 min-h-[100px] w-full">
            {searchResults && searchResults?.length > 0 ? (
              searchResults?.map((result) => (
                <div
                  className="py-2 font-fontFamily font-normal cursor-pointer text-truncate"
                  key={result.id}
                  onClick={() => onSearch(result?.id as number, result.courseName)}
                >
                  {result.courseName}
                </div>
              ))
            ) : valueSearch ? (
              <div className="text-center font-fontFamily font-normal pt-4 word-break">
                No results found for “{valueSearch}”
              </div>
            ) : null}
          </div>
        ) : (
          <div />
        )}
      </>
    ),
    [searchResults, valueSearch, keyResult],
  ) as ReactElement<string>;

  return (
    <div className="filter-card bg-white">
      <div className="filter-content w-full">
        <Form
          form={form}
          name="basic"
          initialValues={{ layout: 'inline' }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row>
            <Col md={12} lg={16}>
              <Dropdown
                trigger={['click']}
                overlay={searchResult}
                placement="bottom"
                className="w-full relative h-auto"
                getPopupContainer={(trigger: any) => trigger?.parentNode}
              >
                <div className="relative" onClick={(e) => e.preventDefault()}>
                  <Form.Item name="search" className="w-full mb-0">
                    <Input
                      value={valueSearch}
                      placeholder="Search"
                      onChange={onChangeSearch}
                      suffix={<img src={images.search} alt="search" />}
                      className="style_input_custom_login_page rounded-xl"
                    />
                  </Form.Item>
                </div>
              </Dropdown>
            </Col>
            <Col md={12} lg={8}>
              <Form.Item>
                <div className="flex justify-between items-center">
                  <p className="text-2xl font-fontFamily leading-9 font-bold mb-0"></p>
                  <div className="flex gap-4 items-center">
                    <p className="font-fontFamily font-bold mb-0 whitespace-nowrap">View Item</p>
                    <SelectCustom
                      color="transparent"
                      value={String(limit)}
                      options={optionsLimit}
                      onChange={onChangeLimit}
                    />
                  </div>
                </div>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ForumSearch;
