import { Form, FormInstance } from 'antd';
import CustomInput from 'components/Input';
import ModalCustom from 'components/Modal';
import UploadFile from 'components/UploadFile';
import { useCallback, useEffect } from 'react';
import { ICardItem } from '..';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: { folderName: string }) => void;
  isKeepOpen: boolean;
  form: FormInstance<any>;

  loading: boolean;
}

const ModalRename = (props: IProps) => {
  const { visible, onCancel, onSubmit, isKeepOpen, form, loading } = props;

  const handleSubmit = useCallback((values: { folderName: string }) => {
    onSubmit(values);
  }, []);

  return (
    <ModalCustom
      onCancel={onCancel}
      onSubmit={form.submit}
      visible={visible}
      title={<span className="text-center">Rename</span>}
      cancelText="Cancel"
      okText={'Rename'}
      isKeepOpen={isKeepOpen}
      loading={loading}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="File name"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                const v = value && value.trim();
                if (!v) {
                  return Promise.reject('File name is required!');
                }
                return Promise.resolve();
              },
            },
          ]}
          name="folderName"
        >
          <CustomInput placeholder="Enter title" />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default ModalRename;
