import React from 'react';
import { MODULES } from './DefaultModule';

interface ILayout3 {
  slot1: string;
  slot2: string;
  slot3: string;
}

function Layout3(props: ILayout3) {
  const { slot1, slot2, slot3 } = props;

  const Slot1 = MODULES?.[slot1] || MODULES.default;
  const Slot2 = MODULES?.[slot2] || MODULES.default;
  const Slot3 = MODULES?.[slot3] || MODULES.default;
  return (
    <div className="flex flex-col gap-5">
      <Slot1 />
      <Slot2 />
      <Slot3 />
    </div>
  );
}

export default React.memo(Layout3);
