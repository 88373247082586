import { getAPI, postAPI, putAPI } from './axios';

export const getDashboardModuleUser = async (usrId: number) => {
  try {
    const data = await getAPI(`api/dashboard-user-module/${usrId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllDasboards = async () => {
  try {
    const data = await getAPI(`api/dashboard/all`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllDashboardModules = async () => {
  try {
    const data = await getAPI(`api/dashboard-module/all`);
    // dashboard-module
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateDashBoardModule = async (params: {
  userId: number,
  data: any,
}) => {
  try {
      const data = await postAPI(`api/dashboard-user-module/${params.userId}`, params.data);
      return data;
  } catch (error) {
      throw error;
  }
};

export const updateDashBoardLayout = async (params: {
  userId: number,
  dashboardId: number,
}) => {
  try {
      const data = await putAPI(`api/dashboard-user-module/${params.userId}/replace/${params.dashboardId}`, {});
      return data;
  } catch (error) {
      throw error;
  }
};
