import { Form, Input, Select } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { Content } from 'antd/lib/layout/layout';
import ButtonCustom from 'components/Button';
import CustomInput from 'components/Input';
import ModalCustom from 'components/Modal';
import { useMutation } from 'react-query';
import {
  ERROR_MESSAGE,
  FIELDS,
  IFieldListForm,
  REGEX_NUMBER,
  ROUTES,
} from 'constants/index';
import { IAdminProfile } from 'constants/types';
import moment from 'moment';
import { codesPhoneNumber } from 'pages/auth/register';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from 'api/student';
import { AppContext } from 'context';

import './custom-student-info.css';

const StudentInformation = ({
  profile,
  mutateGetStudentDetail,
}: {
  profile?: IAdminProfile;
  mutateGetStudentDetail: (value: { id: number }) => void;
}) => {
  const history = useNavigate();
  const [stateContext]: any = useContext(AppContext);
  const teacherId = stateContext?.user?.teacher?.id;
  const { studentId, classId } = useParams();
  const [form] = Form.useForm();
  const [isModalNotice, setIsModalNotice] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState('');
  const { mutateAsync: mutateResetPassword } = useMutation('resetPassword', resetPassword, {});

  const fields = useMemo(
    () => [
      {
        label: 'Student’s Name',
        name: 'studentName',
        type: 'string',
      },
      {
        label: 'Login ID',
        name: 'username',
        type: 'string',
      },
      {
        label: 'Email Address',
        name: 'email',
        type: 'string',
      },
      {
        label: 'Contact Number',
        name: 'contactNumber',
        nameChild: ['mobileCountryCode', 'contactNumber'],
        type: 'phoneNumber',
        options: codesPhoneNumber.map((item) => {
          return { label: `+ ${item.code}`, value: item.code.toString() };
        }),
      },
    ],
    [],
  );

  const renderField = (field: IFieldListForm) => {
    switch (field.type) {
      case FIELDS.STRING:
        return <CustomInput disabled type={field.type} />;
      case FIELDS.PHONE_NUMBER:
        return (
          <Input.Group compact>
            <Form.Item
              noStyle
              name={field?.nameChild ? field?.nameChild[0] : ('' as string)}
              rules={[{ required: true, message: 'Prefix is required!' }]}
            >
              <Select
                options={field.options}
                getPopupContainer={(node) => node}
                className="w-[25%]"
                disabled
              />
            </Form.Item>
            <Form.Item
              noStyle
              name={field?.nameChild ? field?.nameChild[1] : ('' as string)}
              rules={[
                {
                  validator(_: RuleObject, value: string) {
                    const regex = new RegExp(REGEX_NUMBER);
                    if (regex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(ERROR_MESSAGE.CONTACT_NUMBER);
                  },
                },
                {
                  min: 8,
                  message: 'Contact Number should consist of 8 characters',
                },
                {
                  max: 8,
                  message: 'Contact Number should consist of 8 characters',
                },
              ]}
            >
              <Input
                disabled
                className="style_input_custom_login_page w-[75%] rounded-r-2xl"
              />
            </Form.Item>
          </Input.Group>
        );
      default:
        return <CustomInput disabled type={field.type} />;
    }
  }

  const renderFieldList = useCallback(() => {
    return fields?.map((field, index) => (
      <Form.Item
        className={'w-full sm:w-full lg:w-[48%] cus-form-student-if'}
        key={index}
        validateFirst
        name={field.name}
        label={field.label}
      >
        {renderField(field)}
      </Form.Item>
    ));
  }, [fields]);

  const handleBack = () => history(`${ROUTES.class_management}/${classId}${ROUTES.student_list}`);

  const handleResetPassword = () => {
    mutateResetPassword({
      studentID: studentId,
      classID: classId,
      teacherID: String(teacherId),
    }).then((res) => {
      setNewPassword(res?.data);
    })
    setIsModalNotice(true);
  }

  useEffect(() => {
    const userProfile = profile?.user?.userProfile;
    if (userProfile) {
      form.setFieldsValue({
        studentName: userProfile?.firstName + userProfile?.lastName,
        username: profile?.user?.username,
        email: profile?.user?.email,
        ICNumber: userProfile?.ICNumber,
        gender: userProfile?.gender,
        contactNumber: userProfile?.contactNumber,
        dateOfBirth: userProfile?.dateOfBirth ? moment(userProfile?.dateOfBirth) : '',
        nationality: userProfile?.nationality,
        address1: userProfile?.address1,
        address2: userProfile?.address2,
        country: userProfile?.country,
        postalCode: userProfile?.postalCode,
        mobileCountryCode: userProfile?.mobileCountryCode,
        remark: profile?.remark,
      });
    }
  }, [profile]);

  const message = <div>
    <p>Reset Password successfully!</p>
    <p>New Password is: {newPassword}</p>
  </div>;

  return (
    <Content className="rounded-3xl bg-white p-8">
      <div className="flex gap-x-3 custom-student-info">
        <Form
          layout="vertical"
          className="flex flex-wrap gap-x-4 flex-[62%]"
          form={form}
          onFieldsChange={() => {
          }}
          onFinish={() => {
          }}
        >
          {renderFieldList()}
        </Form>

      </div>

      <div className="flex gap-x-3 justify-end">
        <ButtonCustom color="outline" onClick={handleBack}>
          Back
        </ButtonCustom>
        <ButtonCustom
          color="orange"
          onClick={handleResetPassword}
        >
          Reset Password
        </ButtonCustom>
      </div>

      {isModalNotice && (
        <ModalCustom
          visible={true}
          onCancel={() => {
            setIsModalNotice(false);
          }}
          okText="Done"
          onSubmit={() => {
            setIsModalNotice(false);
          }}
          title={"Notice"}
          titleCenter
          content={message}
        />
      )}
    </Content>
  );
};

export default StudentInformation;
