import { Layout, TablePaginationConfig, Row, Col, notification, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import ButtonCustom from 'components/Button';
import ModalCustom from 'components/Modal';
import SearchNotFound from 'components/SearchBar/SearchNotFound';
import TableCustom from 'components/SearchBar/Table';
import CustomTooltip from 'components/Tooltip';
import { ROUTES } from 'constants/constants';
import { useDebounce } from 'hooks';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FilterCard from './components/FilterCard';
import CustomInput from 'components/Input';

import './custom_style.css';
import { routerUserAccess } from 'helper/function';
import { IStudentInfo } from 'pages/admin/manage-student';
import { addStudent, getStudentsByClassId, getStudentByICNumber } from 'api/class';

interface IFields {
  search?: string;
  category?: string;
  leaningMethod?: string;
  programType?: string;
}

const ManageClassStudents = () => {
  const history = useNavigate();
  const [form] = useForm();
  const { id: classId } = useParams();
  const urlParent = ROUTES.manage_class
  const routerUser = routerUserAccess();
  const [checkRoleUser, setCheckRoleUser] = useState(true);
  const [messageWarning, setMessageWarning] = useState('');
  const [messageConfirmDelete, setMessageConfirmDelete] = useState<string | null>('');
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [dataDropdown, setDataDropdown] = useState([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchDropdown, setSearchDropdown] = useState<string>('');
  const [selection, setSelection] = useState<React.Key[]>([]);
  const [limit, setLimit] = useState<string>('5');
  const [order, setOrder] = useState<string>('ASC');
  const debounceValue = useDebounce(searchDropdown, 300);
  const [categoryId, setCategoryId] = useState<number | string>();
  const [isModalDeleteCategory, setIsModalDeleteCategory] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: Number(limit),
    position: [],
  });
  const [students, setStudents] = useState<IStudentInfo[]>([]);
  const [isModalCreateModule, setIsModalCreateModule] = useState<boolean>(false);
  const [topicOptions, setTopicOptions] = useState<any>([]);

  const { mutate: mutateGetStudentsByClassId, isLoading: isLoadingStudents } = useMutation('getStudentsByClassId', getStudentsByClassId, {
    onSuccess: ({ data }: { data: any }) => {
      const { students: fetchedStudents } = data;
      setLimit(String(data?.limit));
      setPagination({
        current: data?.page,
        pageSize: Number(limit),
        total: data?.total,
        position: [],
      });
      setStudents(fetchedStudents);
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  const { mutateAsync: mutateGetStudentByICNumber } = useMutation('getStudentByICNumber', getStudentByICNumber, {
    onSuccess: ({ data }: { data: any }) => {
      notification.success({ message: 'Find Success!' });
      // setTopicOptions(newOptions);
    },
    onError: () => {
      notification.error({ message: 'IC number not found!' });
    },
  });

  useEffect(() => {
    mutateGetStudentsByClassId({
      classId: Number(classId),
      params: {
        limit: Number(limit),
        page: pagination?.current as number,
        search: searchValue,
        sort: 'studentName',
        order: order,
      }
    });
  }, [pagination.current, limit, order, searchValue]);

  useEffect(() => {
    if (routerUser.listRoute.length === 0) setCheckRoleUser(true)
    else
      setCheckRoleUser(routerUser.listRoute.some((item: string) => urlParent === item))
  }, [routerUser.listRoute.length])

  const { mutateAsync: mutateAddStudent, isLoading: isLoadingCreate } = useMutation(
    'addStudent',
    addStudent,
    {
      onSuccess: () => {
        notification.success({ message: 'Save success!' });
        mutateGetStudentsByClassId({
          classId: Number(classId),
          params: {
            limit: Number(limit),
            page: pagination?.current as number,
            search: searchValue,
            sort: 'studentName',
            order: order,
          }
        });
        setIsModalCreateModule(false);
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        notification.error({ message: 'Save fail!' })
      },
    },
  );

  const onValuesChange = (values: IFields) => {
    const search = values.search || '';
    setSearchDropdown(search);
  };

  const handleTableChange = (paginate: TablePaginationConfig) => {
    setPagination({ ...pagination, ...paginate });
  };

  const handleAddStudent = () => {
    setIsModalCreateModule(true);
  };

  const handleAddStudentModalSubmit = (studentId: number) => {
    mutateAddStudent({ classId: Number(classId), studentId: studentId })
      .then(() => {
      })
      .catch((error) => {
        notification.error({ message: 'IC Number is incorrect!' })
      });
  }
  const onFinish = (values: IFields) => {
    const search = values.search || '';
    setSearchValue(search || '');
    mutateGetStudentsByClassId({
      classId: Number(classId),
      params: {
        limit: Number(limit),
        page: pagination?.current as number,
        search: searchValue,
        sort: 'studentName',
        order: order,
      }
    });
  };

  const onChangeSelect = (selectedRowKeys: React.Key[]) => {
    setSelection(selectedRowKeys);
  };

  const onChangeLimit = (value: string) => {
    const total = pagination.total;
    const maxPage = Math.ceil(Number(total) / Number(value));
    setLimit(String(value));
    if (Number(pagination.current) > maxPage) setPagination({ ...pagination, current: maxPage });
    else setPagination(pagination);
  };

  const onFilter = (value: string) => {
    console.log('value', value);
    setOrder(value);
    setPagination({ ...pagination, current: 1 });
  };

  const onChangeAction = (value: string) => {
    if (value === 'selection') {
      setIsModalConfirm(true);
      setMessageConfirmDelete(
        selection?.length > 1
          ? 'Are you sure you want to delete the selected categories? This action cannot be undone.'
          : 'Are you sure you want to delete this category? This action cannot be undone.',
      );
    }
    // else if (value === 'all') {
    //   setMessageWarning(
    //     `Can't delete all categories. There are still courses in these categories!`,
    //   );
    // }
  };

  const errorMessage = useMemo(() => {
    return selection?.length > 1
      ? 'You are not allowed to delete the selected categories as one of the category that you have selected is tagged to a course.'
      : 'You are not allowed to delete the selected category as the category that you have selected is tagged to a course.';
  }, [selection]);

  const handleReset = useCallback(() => {
    setSearchDropdown('');
    setSearchValue('');
    setOrder('ASC');
    setLimit('5');
    setSelection([]);
    form.resetFields();
    mutateGetStudentsByClassId({
      classId: Number(classId),
      params: {
        limit: Number(limit),
        page: pagination?.current as number,
        search: searchValue,
        sort: 'studentName',
        order: order,
      }
    });
  }, [limit]);

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.studentName}>
              <div className="custom-text-ellipsis">{record.studentName}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'IC Number',
      dataIndex: 'icNumber',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.ICNumber}>
              <div className="custom-text-ellipsis">{record.ICNumber}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.userName}>
              <div className="custom-text-ellipsis">{record.userName}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'Contact No',
      dataIndex: 'contactNo',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.contactNo}>
              <div className="custom-text-ellipsis">{record.contactNo}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
  ];

  const renderModalWarning = useCallback(() => {
    return (
      messageWarning && (
        <ModalCustom
          visible={true}
          cancelText="Cancel"
          onCancel={() => {
            setMessageWarning('');
          }}
          title="Warning"
          titleCenter
          content={messageWarning}
        />
      )
    );
  }, [messageWarning]);
  const searchResultRef = useRef<HTMLParagraphElement>(null);

  const modalCreateModule = useCallback(() => {
    const [icNumber, setICNumber] = useState('');
    const handleChange = (event: any) => {
      setICNumber(event.target.value);
    };
    const [studentId, setStudentId] = useState('0000000');
    const handleSearch = () => {
      mutateGetStudentByICNumber(icNumber)
        .then((data: any) => {
          console.log('data ', data);
          const { studentName, id } = data?.data;

          if (null !== searchResultRef.current) {
            searchResultRef.current.innerText = `${icNumber} ${studentName}`;
          }
          setStudentId(id);
        })
        .catch((error) => {
          if (null !== searchResultRef.current) {
            searchResultRef.current.innerText = 'Student name - Empty';
          }
          console.log('error', error);
        });
    };

    return (
      true && (
        // isModalCreateModule && (
        <ModalCustom
          visible={isModalCreateModule}
          onCancel={() => {
            setIsModalCreateModule(false);
            // formCreateModule.resetFields();
          }}
          // isKeepOpen={isKeepOpenCreateModule}
          cancelText="Cancel"
          okText="Add"
          title="Add Student"
          onSubmit={() => {
            handleAddStudentModalSubmit(Number(studentId))
          }}
        >
          <div className="flex flex-col gap-5 mb-0">
            <div className="flex gap-5">
              <CustomInput
                placeholder='Type IC number'
                onChange={handleChange}
              />
              <ButtonCustom
                onClick={handleSearch}
              >
                Search
              </ButtonCustom>
            </div>
            <p ref={searchResultRef}><i>{searchResultRef.current?.innerText}</i></p>
          </div>
        </ModalCustom>
      )
    );
    // }, [topicOptions, topicIdParam]);
  }, [isModalCreateModule,]);

  return (
    <>
      {
        checkRoleUser
          ?
          <Layout className="bg-transparent flex flex-col gap-y-6">
            <Row className="flex justify-between items-center bg-transparent px-0 mt-5 ">
              <Col className="mb-2 mr-2">
                <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0">
                  Students
                </p>
              </Col>

              <Col className="mb-2">
                <ButtonCustom
                  color="orange"
                  onClick={handleAddStudent}
                  disabled
                >
                  Add Student
                </ButtonCustom>
              </Col>
            </Row>

            <FilterCard
              onFinish={onFinish}
              form={form}
              onValuesChange={onValuesChange}
              handleReset={handleReset}
            />

            <TableCustom
              enableRowSelection={false}
              hidePageSize
              searchNotFound={
                students.length > 0 ? undefined : (
                  <SearchNotFound isBackgroundWhite text={'searchValue'} />
                )
              }
              columns={columns}
              data={students}
              pagination={pagination}
              isLoading={isLoadingStudents}
              handleTableChange={handleTableChange}
              onChangeSelect={onChangeSelect}
              onChangePagination={(page) => {
                setPagination({ ...pagination, current: Number(page) });
              }}

              onLastPage={() => {
                setPagination({
                  ...pagination,
                  current: Math.ceil(Number(pagination.total) / Number(pagination.pageSize)),
                });
              }}
              onFirstPage={() => {
                setPagination({ ...pagination, current: 1 });
              }}
              viewItem={{
                onChange: onChangeLimit,
                value: String(limit),
              }}
              filters={{
                show: true,
                options: [
                  { label: 'Student Name (A-Z)', value: 'ASC' },
                  { label: 'Student Name (Z-A)', value: 'DESC' },
                ],
                onChange: onFilter,
                value: order,
              }}

            />
            {renderModalWarning()}
            {modalCreateModule()}
          </Layout>
          :
          <p className='font-bold text-2xl'>You don&apos;t have the permission to access this resource</p>
      }
    </>
  );
};

export default ManageClassStudents;
