import { Button, notification } from 'antd';
import { saveUserUnits } from 'api/content_management';
import { AppContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

function ScormView(props: any) {
  const { filePath, id: unitId } = props;
  const [searchParams] = useSearchParams();

  const [stateContext]: any = useContext(AppContext);

  const [score, setScore] = useState<any>();

  const { mutateAsync: putUserUnits } = useMutation('saveUserUnits', saveUserUnits);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.name === 'andalus-LMSSetValue') {
        setScore(Number(e.data.data?.['cmi.core.score.raw']));
      }
    });
  }, []);

  useEffect(() => {
    if (score !== undefined) {
      const classId = searchParams?.get('classId');
      if (!classId) return;
      putUserUnits({
        classId: Number(classId),
        unitID: Number(unitId),
        data: {
          userID: Number(stateContext?.user?.id),
          score,
        },
      }).then((res) => {
        notification.success({ message: 'Upadte score success!' });
      });
    }
  }, [score]);

  return (
    <>
      <div className="w-full bg-white border-solid border-transparent rounded-2xl min-height-60vh p-8">
        <iframe
          style={{ width: '100%', height: '623px' }}
          src={filePath + `?student_name=${stateContext?.user?.userProfile?.firstName}`}
        ></iframe>
      </div>
      <div className="flex justify-end items-center gap-3 mt-2">
        <Button
          className="w-[120px] h-[44px] rounded-xl font-semibold text-base text-[#32302D]"
          onClick={props.handleExit}
        >
          Exit
        </Button>
        {props.currentIndex > 0 && (
          <Button
            className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
            onClick={() => props?.handleClickBack(true)}
          >
            Back
          </Button>
        )}
        <Button
          className="w-[120px] h-[44px] rounded-xl font-semibold text-base bg-[#ED7635] text-[#FFFFFF]"
          onClick={() => props?.handleClickContinue(true)}
        >
          Continue
        </Button>
      </div>
    </>
  );
}

export default ScormView;
