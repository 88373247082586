import {
  Breadcrumb,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  notification,
} from 'antd';
import { ListObjectsCommandOutput } from '@aws-sdk/client-s3';
import { getListRoles } from 'api/access_control';
import images from 'assets/images';
import ButtonCustom from 'components/Button';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Buffer } from 'buffer';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
const { Header, Content } = Layout;
import './style.css';
import SelectCustom from 'components/Select';
import CardFolder from 'components/CardFolder/CardFolder';
import CardImage from 'components/CardImage';
import ModalCreateNewFolder from './new-folder';
import ModalUploadNewFile from './upload-file';
import ModalUploadFolder from './upload-folder';
import ModalRename from './rename';
import ModalRemove from './remove';
import ModalDowload from './dowload';
import ModalShare from './share';
import { routerUserAccess } from 'helper/function';
import moment from 'moment';
import { AppContext } from 'context';
import Loading from 'components/Loading';
import {
  ETypeGetListObject,
  IParamsListObject,
  createNewFolder,
  dowloadFile,
  getListObjects,
  getListSharedPeople,
  getListUsersShare,
  getObject,
  moveFile,
  moveFolder,
  removeFile,
  removeFolder,
  renameFile,
  shareForUser,
  uploadFileImage,
} from 'api/own_drive';
import { saveAs } from 'file-saver';
import { Base64 } from 'js-base64';
import { ROUTES, SharedWithMeType, formatBytes } from 'constants/index';
import { RoleName } from 'enum/role';
import { checkTeachManagerClass } from 'api/module';
import { getAllGlobalSetting } from 'api/global_setting';
import { keyBy } from 'lodash';
import { getClassById } from 'api/class';

export interface ICardFolder {
  name: string;
  date?: string;
  nameShare?: string;
}

export interface ICardFile {
  name: string;
  date: string;
  nameShare?: string;
  urlFile: string;
}

export interface ICardFolder {
  name: string;
}

export interface ICardUploadFile {
  thumbnailPath: string;
}

export interface IUserShare {
  id: number;
  username: string;
  email: string;
  userProfile: IUserProfileShare;
}

export interface IUserProfileShare {
  firstName: string;
  lastName: string;
  contactNumber: string;
  mobileCountryCode: string;
  profilePhotoDestination: string;
}

export interface ICardItem {
  folderName: string;
}

interface IProps {
  onChangeFolder?: (folderName: string) => void;
  propsListSelected?: string[];
}

const OwnDrive = (props?: IProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formCreateFolder] = Form.useForm();
  const [formRemoveFile] = Form.useForm();
  const [formUploadFile] = Form.useForm();
  const [formUploadFolder] = Form.useForm();
  const [formRenameFolder] = Form.useForm();
  const [formPreview] = Form.useForm();
  const [formShare] = Form.useForm();
  const routerUser = routerUserAccess();
  const paramsQuery = useParams();
  const [stateContext]: any = useContext(AppContext);
  const userInfo = stateContext?.user;
  const keySearch = Form.useWatch('search', form);
  const [isShowModalCreateFolder, setShowModalCreateFolder] = useState<boolean>(false);
  const [listFolder, setListFolder] = useState<ICardFolder[]>([]);
  const [listFile, setListFile] = useState<ICardFile[]>([]);
  const [sortCondtion, setSortCondition] = useState<string>('A-Z');
  const [classId, setClassId] = useState<string | undefined>(undefined);
  const [className, setClassName] = useState<string | null>(null);
  const [groupId, setGroupId] = useState<string | undefined>(undefined);
  const [urlPreview, setUrlPreview] = useState<string>('');
  const [listFolderUpload, setListFolderUpload] = useState<string[]>([]);
  const [folderClick, setFolderClick] = useState<string>();
  const [fileClick, setFileClick] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [listFolderSelected, setListFolderSelected] = useState<string[]>([]);
  const [isShowModalUploadFile, setShowModalUploadFile] = useState<boolean>(false);
  const [isShowModalUploadFolder, setShowModalUploadFolder] = useState<boolean>(false);
  const [isShowModalRename, setShowModalRename] = useState<boolean>(false);
  const [isShowModalRemove, setShowModalRemove] = useState<boolean>(false);
  const [isShowModalDowload, setShowModalDowload] = useState<boolean>(false);
  const [isShowModalShare, setShowModalShare] = useState<boolean>(false);
  const [listUsersShare, setListUsersShare] = useState<IUserShare[]>([]);
  const [listFileUpload, setListFileUpload] = useState<File[]>([]);
  const [MYDRIVE_FILESIZE_IN_MB, setMYDRIVE_FILESIZE_IN_MB] = useState<number>();
  const [MYDRIVE_USER_TRASH, setMYDRIVE_USER_TRASH] = useState<number>();
  const [MYDRIVE_ADMIN_TRASH, setMYDRIVE_ADMIN_TRASH] = useState<number>();
  const [isTeacherManagerClass, setIsTeacherManagerClass] = useState<any>();
  const [searchResults, setSearchResults] = useState<{
    folders: ICardFolder[];
    files: ICardFile[];
  }>();

  const timeout: any = useRef(null);

  const { data: dataGlobalSetting, refetch } = useQuery(['getAllGlobalSetting'], () =>
    getAllGlobalSetting(),
  );

  const { mutate: searchFileAndFolder } = useMutation('searchRole', getListObjects, {
    onSuccess: async ({ data }: { data: ListObjectsCommandOutput }) => {
      const tempListFoler: ICardFolder[] = [];
      if (data?.CommonPrefixes) {
        data.CommonPrefixes.forEach((element) => {
          if (element?.Prefix) {
            tempListFoler.push({
              name: element.Prefix,
            });
          }
        });
      }

      const tempListFile: ICardFile[] = [];

      if (data?.Contents) {
        data?.Contents.forEach((element: any) => {
          tempListFile.push({
            date: moment(element.LastModified).format('YYYY/MM/DD'),
            name: element.Key || '',
            nameShare: `${userInfo?.userProfile?.firstName || ''} ${
              userInfo?.userProfile?.lastName || ''
            }`,
            urlFile: element.fileUrl,
          });
        });
      }

      setSearchResults({ files: tempListFile, folders: tempListFoler });
      setLoadingSearch(false);
    },
  });

  const debounceSetSearchFor = useCallback(
    (value: string, listSelect: string[], userId: number) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        const groupIdQuery = paramsQuery.groupId;

        let classIdQuery = paramsQuery.classId;

        if (!paramsQuery.classId) {
          const search = window.location.search;
          const paramsSearch = new URLSearchParams(search);
          const classIdSearch = paramsSearch.get('classId');
          if (classIdSearch) {
            classIdQuery = classIdSearch;
          }
        }

        const params: IParamsListObject = {
          prefix: '',
        };

        if (groupIdQuery) {
          params.type = ETypeGetListObject.GROUP;
          params.prefix += `${groupIdQuery}`;
        }

        if (classIdQuery && !groupIdQuery) {
          params.type = ETypeGetListObject.CLASS;
          params.prefix += `${classIdQuery}`;
        }

        if (!groupIdQuery && !classIdQuery) {
          params.prefix += `${userId}`;
        }

        if (listSelect?.length > 0) params.prefix += `/${listSelect.join('/')}`;

        if (value) params.fullTextSearch = value;

        searchFileAndFolder(params);
      }, 500);
    },
    [5, timeout],
  );

  const handleChangeSearch = useCallback(
    (value: ChangeEvent<HTMLInputElement>) => {
      const v = value && value.target.value.trim();
      if (v) {
        setLoadingSearch(true);
        debounceSetSearchFor(v, listFolderSelected, routerUser.id);
      }
    },
    [listFolderSelected, routerUser.id],
  );

  const { mutate: muteListObjects } = useMutation('getListObjects', getListObjects, {
    onSuccess: async ({ data }: { data: ListObjectsCommandOutput }) => {
      try {
        if (!isLoading) setLoading(true);
        if (data?.CommonPrefixes) {
          const tempListFoler: ICardFolder[] = [];
          data.CommonPrefixes.forEach((element) => {
            if (element?.Prefix) {
              tempListFoler.push({
                name: element.Prefix,
              });
            }
          });

          setListFolder(tempListFoler);
        } else {
          setListFolder([]);
        }

        if (data?.Contents) {
          const tempListFile: ICardFile[] = [];
          data?.Contents.forEach((element: any) => {
            tempListFile.push({
              date: moment(element.LastModified).format('YYYY/MM/DD'),
              name: element.Key || '',
              nameShare: formatBytes(element.Size, 1),
              urlFile: element.fileUrl,
            });
          });
          setListFile(tempListFile);
        } else {
          setListFile([]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
  });

  const fetchS3Infomation = async (isSearch?: boolean) => {
    setLoading(true);
    const groupIdQuery = paramsQuery.groupId;

    let classIdQuery = paramsQuery.classId;

    if (!paramsQuery.classId) {
      const search = window.location.search;
      const paramsSearch = new URLSearchParams(search);
      const classIdSearch = paramsSearch.get('classId');
      if (classIdSearch) {
        classIdQuery = classIdSearch;
      }
    }

    const params: IParamsListObject = {
      prefix: '',
    };

    if (groupIdQuery) {
      params.type = ETypeGetListObject.GROUP;
      params.prefix += `${groupIdQuery}`;
    }

    if (classIdQuery && !groupIdQuery) {
      params.type = ETypeGetListObject.CLASS;
      params.prefix += `${classIdQuery}`;
    }

    if (!groupIdQuery && !classIdQuery) {
      params.prefix += `${routerUser.id}`;
    }

    if (listFolderSelected?.length > 0) params.prefix += `/${listFolderSelected.join('/')}`;

    if (sortCondtion) params.filterConditon = `${sortCondtion}`;

    if (keySearch && isSearch !== false) params.fullTextSearch = `${keySearch}`;

    muteListObjects(params);
  };

  const { mutate: mutateDowloadFile } = useMutation('uploadFileImage', dowloadFile, {
    onSuccess: async ({ data }: { data: any }) => {
      const buffer = Buffer.from(data);
      const blob = new Blob([buffer]);
      saveAs(blob, `${fileClick}`);
      // downloadBlob(data.fileUrl, `${fileClick}`);
      setFileClick('');
      if (isShowModalDowload) setShowModalDowload(false);
      setLoading(false);
    },
  });

  const { mutate: mutateGetListUsersShare } = useMutation('getListUsersShare', getListUsersShare, {
    onSuccess: async ({
      data,
    }: {
      data: { content: IUserShare[]; total: number; page: number; size: number };
    }) => {
      setListUsersShare([...data.content]);
    },
  });

  const { mutate: mutateGetListSharedPeople } = useMutation(
    'getListSharedPeople',
    getListSharedPeople,
    {
      onSuccess: async ({ data }: { data: number[] }) => {
        formShare.setFieldsValue({ user: data });
      },
    },
  );

  const { mutate: mutatePreviewFile } = useMutation('getObject', getObject, {
    onSuccess: async ({ data }: { data: { fileUrl: string } }) => {
      setUrlPreview(data.fileUrl);
      setShowModalDowload(true);
    },
  });

  const { mutate: moveFolderToNewPath } = useMutation('moveFolder', moveFolder, {
    onSuccess: async () => {
      fetchS3Infomation();
    },
    onError: async (error: { response: { data: { message: string } } }) => {
      setLoading(false);
      notification.error({ message: error.response.data.message });
    },
  });
  const { mutate: moveFileToNewPath } = useMutation('moveFile', moveFile, {
    onSuccess: async () => {
      fetchS3Infomation();
    },
    onError: async (error: { response: { data: { message: string } } }) => {
      setLoading(false);
      notification.error({ message: error.response.data.message });
    },
  });

  const onSearchUser = useCallback(
    (valueSearch: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        mutateGetListUsersShare({ page: 1, limit: 300, search: valueSearch });
      }, 500);
    },
    [5, timeout],
  );

  const handleMoveFolder = (folderName: string) => {
    setLoading(true);
    if (folderClick)
      moveFolderToNewPath({
        sourcePath: `${
          listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''
        }${folderClick}`,
        sourceMove: folderName,
        type: groupId ? ETypeGetListObject.GROUP : classId ? ETypeGetListObject.CLASS : undefined,
        idPath: groupId ? parseInt(groupId) : classId ? parseInt(classId) : routerUser.id,
      });
    if (fileClick)
      moveFileToNewPath({
        sourcePath: `${
          listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''
        }${fileClick}`,
        sourceMove: `${folderName ? `${folderName}/` : ''}${fileClick}`,
        type: groupId ? ETypeGetListObject.GROUP : classId ? ETypeGetListObject.CLASS : undefined,
        idPath: groupId ? parseInt(groupId) : classId ? parseInt(classId) : routerUser.id,
      });
  };

  const handleDowload = () => {
    setLoading(true);
    let key = '';

    if (groupId) key += `${ETypeGetListObject.GROUP}/${groupId}/`;

    if (!groupId && classId) key += `${ETypeGetListObject.CLASS}/${classId}/`;

    if (!groupId && !classId) key += `${routerUser.id}/`;

    if (listFolderSelected?.length > 0) key += `${listFolderSelected.join('/')}/`;

    key += `${fileClick}`;

    mutateDowloadFile({
      key: key,
    });
  };

  const actionButtonOnChange = (value: string) => {
    switch (true) {
      case value === 'NEW_FOLDER':
        formCreateFolder.resetFields();
        setShowModalCreateFolder(true);
        break;
      case value === 'FILE_UPLOAD':
        formUploadFile.resetFields();
        setShowModalUploadFile(true);
        break;
      case value === 'FOLDER_UPLOAD':
        formUploadFolder.resetFields();
        setShowModalUploadFolder(true);
        break;
      case value === 'REMOVE':
        setShowModalRemove(true);
        break;
      case value === 'REMOVE_FILE':
        setShowModalRemove(true);
        break;
      case value === 'DOWNLOAD_FILE':
        handleDowload();
        break;
      case value === 'RENAME_FILE':
        formRenameFolder.setFieldsValue({
          folderName: fileClick?.replace(fileClick?.slice(fileClick?.lastIndexOf('.')), ''),
        });
        setShowModalRename(true);
        break;
      case value === 'SHARE':
        setShowModalShare(true);
        mutateGetListUsersShare({ page: 1, limit: 300 });
        mutateGetListSharedPeople({
          path: `${listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''}${
            fileClick || folderClick
          }`,
          sharerId: routerUser.id,
        });
        break;
      case value.includes('MOVE_TO_'):
        handleMoveFolder(value.replace('MOVE_TO_', ''));
        break;
      default:
        break;
    }
  };

  const rollBackFolder = (index: number) => {
    setLoading(true);
    form.resetFields();
    setListFolderSelected((prevState: string[]) =>
      prevState.filter((_, indexState: number) => indexState <= index),
    );
  };

  const { mutate: mutateUploadFile } = useMutation('uploadFileImage', uploadFileImage, {
    onSuccess: async () => {
      fetchS3Infomation();
      setListFileUpload([]);
      setListFolderUpload([]);
      setShowModalUploadFolder(false);
      setShowModalUploadFile(false);
    },
  });

  const { mutate: mutateShareForUser } = useMutation('shareForUser', shareForUser, {
    onSuccess: async () => {
      formShare.resetFields();
      notification.success({ message: 'Share success' });
      setShowModalShare(false);
      setLoading(false);
    },
  });

  const handleShareForUser = (values: { user: number[]; notify: boolean; message: string }) => {
    setLoading(true);
    mutateShareForUser({
      path: `${listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''}${
        fileClick || folderClick
      }`,
      sharedType: fileClick ? SharedWithMeType.FILE : SharedWithMeType.FOLDER,
      userId: values.user,
      sharerId: routerUser.id,
      message: values.message,
      notify: values.notify,
    });
  };

  const handleUploadFiletoS3 = () => {
    const checkExist = listFileUpload.filter(
      (e) => listFile.map((file) => file.name).indexOf(e.name) !== -1,
    );

    if (checkExist.length > 0) {
      setListFileUpload([]);
      setShowModalUploadFile(false);
      return notification.error({
        message: `File ${checkExist.map((e) => e.name).join(' , ')} is already existed`,
      });
    }

    setLoading(true);
    const fmData = new FormData();
    listFileUpload.forEach((element: File) => {
      fmData.append('file', element, Base64.encode(element.name, true));
    });

    let key = '';

    if (groupId) key += `${ETypeGetListObject.GROUP}/${groupId}`;

    if (!groupId && classId) key += `${ETypeGetListObject.CLASS}/${classId}`;

    if (!groupId && !classId) key += `${routerUser.id}`;

    if (listFolderSelected?.length > 0) key += `/${listFolderSelected.join('/')}`;

    fmData.append('path', key);
    fmData.append('type', 'File');
    mutateUploadFile(fmData);
  };

  const handleUploadFolderToS3 = async () => {
    const checkExist = listFolderUpload.filter(
      (e) => listFolder.map((folder) => folder.name).indexOf(e) !== -1,
    );

    if (checkExist.length > 0) {
      setShowModalUploadFolder(false);
      setListFileUpload([]);
      setListFileUpload([]);
      return notification.error({
        message: `Folder ${checkExist.map((e) => e).join(' , ')} is already existed`,
      });
    }
    setLoading(true);
    const fmData = new FormData();
    listFileUpload.forEach((element: File) => {
      fmData.append('file', element, Base64.encode(element.webkitRelativePath, true));
    });

    let key = '';

    if (groupId) key += `${ETypeGetListObject.GROUP}/${groupId}`;

    if (!groupId && classId) key += `${ETypeGetListObject.CLASS}/${classId}`;

    if (!groupId && !classId) key += `${routerUser.id}`;

    if (listFolderSelected?.length > 0) key += `/${listFolderSelected.join('/')}`;

    fmData.append('path', key);
    fmData.append('type', 'Folder');
    mutateUploadFile(fmData);
  };

  const { mutate: mutateRenameFile } = useMutation('renameFile', renameFile, {
    onSuccess: async () => {
      setShowModalRename(false);
      setFileClick('');
      fetchS3Infomation();
      formRenameFolder.resetFields();
    },
  });

  const handleRenameFile = async (values: { folderName: string }) => {
    if (
      listFile.some(
        (e) =>
          e.name === `${values.folderName}${fileClick?.slice(fileClick?.lastIndexOf('.'))}` &&
          e.name !== fileClick,
      )
    ) {
      return notification.error({
        message: `File ${values.folderName} is already existed`,
      });
    }
    setLoading(true);
    mutateRenameFile({
      newFileName: `${values.folderName}${fileClick?.slice(fileClick?.lastIndexOf('.'))}`,
      oldFileName: fileClick || '',
      currentPath: `${listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}` : ''}`,
      type: groupId ? ETypeGetListObject.GROUP : classId ? ETypeGetListObject.CLASS : undefined,
      idPath: groupId ? parseInt(groupId) : classId ? parseInt(classId) : routerUser.id,
    });
  };

  const { mutate: mutateRemoveFile } = useMutation('renameFile', removeFile, {
    onSuccess: async ({ data }: { data: { fileName: string } }) => {
      setShowModalRemove(false);
      setFileClick('');
      fetchS3Infomation();
      notification.success({
        message: `File ${data.fileName} was ${groupId ? 'deleted' : 'moved to trash drive'}`,
      });
    },
  });

  const { mutate: mutateRemoveFolder } = useMutation('renameFile', removeFolder, {
    onSuccess: async ({ data }: { data: { folderName: string } }) => {
      setShowModalRemove(false);
      setFolderClick('');
      fetchS3Infomation();
      notification.success({
        message: `Folder ${data.folderName} was ${groupId ? 'deleted' : 'moved to trash drive'}`,
      });
    },
  });

  const handleRemoveFileOrFile = async () => {
    setLoading(true);
    if (fileClick)
      mutateRemoveFile({
        fileName: fileClick || '',
        currentPath: `${listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}` : ''}`,
        type: groupId ? ETypeGetListObject.GROUP : classId ? ETypeGetListObject.CLASS : undefined,
        idPath: groupId ? parseInt(groupId) : classId ? parseInt(classId) : routerUser.id,
      });
    if (folderClick) {
      mutateRemoveFolder({
        folderPath: `${
          listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''
        }${folderClick}`,
        type: groupId ? ETypeGetListObject.GROUP : classId ? ETypeGetListObject.CLASS : undefined,
        idPath: groupId ? parseInt(groupId) : classId ? parseInt(classId) : routerUser.id,
      });
    }
  };

  const handleSearch = (value: { search: string }) => {
    if (value.search?.trim()) {
      setLoading(true);
      // setSearchResults(undefined);
      fetchS3Infomation();
    }
  };

  const onUploadFile = (file: File) => {
    setListFileUpload((prevState: File[]) => [...prevState, file]);
  };

  const onUploadFolder = async (file: File) => {
    setListFileUpload((prevState: File[]) => [...prevState, file]);
  };

  const onRemoveFile = (uidFile: string) => {
    setListFileUpload((prevState: File[]) =>
      prevState.filter((element: File, index: number) => `${index}_${element.name}` !== uidFile),
    );
  };

  const onRemoveFolder = (uidFolder: string) => {
    setListFolderUpload((prevState: string[]) =>
      prevState.filter((element: string) => `${element}` !== uidFolder),
    );

    setListFileUpload((prevState: File[]) =>
      prevState.filter((element: File) => !element.webkitRelativePath.includes(`${uidFolder}`)),
    );
  };

  const onClickFolder = (folderName: string) => {
    if (!props?.onChangeFolder) {
      setListFolderSelected((prevState: string[]) => [...prevState, folderName]);
    } else {
      props?.onChangeFolder(folderName);
    }
  };

  const { mutate: mutateCreateNewFolder } = useMutation('createNewFolder', createNewFolder, {
    onSuccess: async () => {
      fetchS3Infomation();
      setShowModalCreateFolder(false);
    },
  });

  const handleCreateNewForder = async (values: { folderName: string }) => {
    if (listFolder.some((e) => e.name === values.folderName)) {
      setShowModalCreateFolder(false);
      return notification.error({
        message: `Folder ${values.folderName} is already existed`,
      });
    }

    setLoading(true);
    mutateCreateNewFolder({
      folderName: `${
        listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''
      }${values.folderName.trim()}`,
      type: groupId ? ETypeGetListObject.GROUP : classId ? ETypeGetListObject.CLASS : undefined,
      idPath: groupId ? parseInt(groupId) : classId ? parseInt(classId) : routerUser.id,
    });
  };

  const handleFilterByName = (value: string) => {
    setSortCondition(value);
  };

  const searchResult = useMemo(
    () => (
      <>
        {keySearch ? (
          <Loading isLoading={isLoadingSearch}>
            <div className="bg-white rounded-2xl p-t-b-0  p-4 min-h-[100px] custom-search-icon">
              {(searchResults?.files && searchResults?.files?.length > 0) ||
              (searchResults?.folders && searchResults?.folders?.length > 0) ? (
                <>
                  {searchResults?.folders.map((element: ICardFolder) => (
                    <CardFolder
                      key={element.name}
                      data={element}
                      onRedirect={() => {
                        form.resetFields();
                        setLoading(true);
                        onClickFolder(element.name);
                      }}
                    />
                  ))}
                  {searchResults?.files.map((element: ICardFile) => (
                    <CardImage
                      key={element.name}
                      data={element}
                      isShowImage={false}
                      onRedirect={() => {
                        form.resetFields();
                        setFileClick(element.name);
                        // setLoading(false);
                        mutatePreviewFile({
                          key: `${groupId ? `${ETypeGetListObject.GROUP}/` : ''}${
                            !groupId && classId ? `${ETypeGetListObject.CLASS}/` : ''
                          }${
                            groupId
                              ? parseInt(groupId)
                              : classId
                              ? parseInt(classId)
                              : routerUser.id
                          }/${
                            listFolderSelected?.length > 0 ? `${listFolderSelected.join('/')}/` : ''
                          }${element.name}`,
                        });
                      }}
                    />
                  ))}
                </>
              ) : keySearch ? (
                <div className="text-center font-fontFamily font-normal pt-4 word-break">
                  No results found for “{keySearch}”
                </div>
              ) : null}
            </div>
          </Loading>
        ) : (
          <div />
        )}
      </>
    ),
    [searchResults, keySearch],
  ) as ReactElement<string>;

  const { mutate: checkTeacherManagerClass } = useMutation(
    'deleteAllDrive',
    checkTeachManagerClass,
    {
      onSuccess: (data) => {
        setIsTeacherManagerClass(data.data);
      },
    },
  );

  const { mutate: getClass } = useMutation('getClass', getClassById, {
    onSuccess: ({ data }) => {
      setClassName(data.className);
    },
  });

  useEffect(() => {
    if (classId) {
      getClass(+parseInt(classId));
    }
  }, [classId]);

  useEffect(() => {
    if (paramsQuery.groupId) setGroupId(paramsQuery.groupId);
    if (paramsQuery.classId) setClassId(paramsQuery.classId);
    else {
      const search = window.location.search;
      const paramsSearch = new URLSearchParams(search);
      const classIdSearch = paramsSearch.get('classId');
      if (classIdSearch) {
        setClassId(classIdSearch);
      }
    }
  }, [paramsQuery]);

  useEffect(() => {
    if (dataGlobalSetting?.data) {
      const _globalSetting = keyBy(dataGlobalSetting?.data, 'settingKey');
      setMYDRIVE_FILESIZE_IN_MB(parseInt(_globalSetting['MYDRIVE_FILESIZE_IN_MB']?.settingValue));
      setMYDRIVE_ADMIN_TRASH(parseInt(_globalSetting['MYDRIVE_ADMIN_TRASH']?.settingValue));
      setMYDRIVE_USER_TRASH(parseInt(_globalSetting['MYDRIVE_USER_TRASH']?.settingValue));
    }
  }, [dataGlobalSetting]);

  useEffect(() => {
    if (routerUser?.id) {
      fetchS3Infomation();
    }
  }, [routerUser?.id, listFolderSelected, sortCondtion]);

  useEffect(() => {
    if (props?.propsListSelected) {
      setListFolderSelected(props.propsListSelected);
    }
  }, [props?.propsListSelected]);

  useEffect(() => {
    if (listFileUpload && isShowModalUploadFolder) {
      const newListFolderUpload: string[] = listFolderUpload;
      listFileUpload.forEach((element: File) => {
        const folderName = element.webkitRelativePath.substring(
          0,
          element.webkitRelativePath.indexOf('/'),
        );

        if (!newListFolderUpload.some((item: string) => item === folderName))
          newListFolderUpload.push(folderName);
      });

      setListFolderUpload([...newListFolderUpload]);
    }
  }, [listFileUpload, isShowModalUploadFolder]);

  const [checkRoleUser, setCheckRoleUser] = useState(true);

  useEffect(() => {
    if (classId) checkTeacherManagerClass(classId as any);
  }, [classId]);

  useEffect(() => {
    if (routerUser.listRoute.length === 0) setCheckRoleUser(true);
    else
      setCheckRoleUser(
        routerUser.listRoute.some((item: string) => location.pathname === item) ||
          (routerUser.userRole === RoleName.TEACHER && isTeacherManagerClass != null) ||
          routerUser.userRole === RoleName.STUDENT,
      );
  }, [routerUser.listRoute.length, isTeacherManagerClass]);

  return (
    <>
      {checkRoleUser ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          {!groupId && (
            <Header className="flex flex-wrap items-center bg-transparent px-0 header-trash">
              <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
                {!!classId ? (
                  <>
                    <Breadcrumb.Item
                      className="cursor-pointer font-fontFamily"
                      onClick={() => navigate(ROUTES.class_management)}
                    >
                      Class Management
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="font-fontFamily">
                      {className ?? 'Class Name'}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      className={`font-fontFamily  ${
                        listFolderSelected?.length > 0 ? 'cursor-pointer' : 'text-main-font-color'
                      }`}
                      onClick={() => {
                        if (listFolderSelected?.length > 0) {
                          setLoading(true);
                          form.resetFields();
                          setListFolderSelected([]);
                        }
                      }}
                    >
                      Own Drive
                    </Breadcrumb.Item>
                  </>
                ) : (
                  <Breadcrumb.Item
                    onClick={() => {
                      if (listFolderSelected?.length > 0) {
                        setLoading(true);
                        form.resetFields();
                        setListFolderSelected([]);
                      }
                    }}
                    className={`font-fontFamily  ${
                      listFolderSelected?.length > 0 ? 'cursor-pointer' : 'text-main-font-color'
                    }`}
                  >
                    Own Drive
                  </Breadcrumb.Item>
                )}

                {!!listFolderSelected?.length &&
                  listFolderSelected.map((element: string, index: number) => (
                    <Breadcrumb.Item
                      onClick={() => {
                        rollBackFolder(index);
                      }}
                      key={index}
                      className={`font-fontFamily ${
                        listFolderSelected?.length - 1 !== index
                          ? 'cursor-pointer'
                          : 'text-main-font-color'
                      }`}
                    >
                      {element}
                    </Breadcrumb.Item>
                  ))}
              </Breadcrumb>
            </Header>
          )}
          <Content className="flex flex-col gap-y-6">
            <div className="filter-card">
              <Form
                className="w-full"
                name="basic"
                autoComplete="off"
                onFinish={handleSearch}
                form={form}
              >
                <Row className="flex gap-4 items-center">
                  {/* <Col className="w-[calc(60%_-_0.67rem)]" xs={24} sm={17} md={17} lg={17} xl={17}> */}
                  <Col className="w-[calc(60%_-_0.67rem)]">
                    <Dropdown
                      trigger={['click']}
                      overlay={searchResult}
                      onOpenChange={(e) => {
                        if (!e) setSearchResults(undefined);
                      }}
                      placement="bottomRight"
                      className="w-full h-full "
                    >
                      <Form.Item name="search" className="flex-1 mb-0">
                        <Input
                          placeholder="Search"
                          suffix={<img src={images.search} alt="search" />}
                          className="style_input_custom_login_page"
                          onChange={(value: ChangeEvent<HTMLInputElement>) =>
                            handleChangeSearch(value)
                          }
                          onFocus={(value: ChangeEvent<HTMLInputElement>) =>
                            handleChangeSearch(value)
                          }
                        />
                      </Form.Item>
                    </Dropdown>
                  </Col>
                  {/* <Col className="w-[calc(20%_-_0.67rem)]" xs={24} sm={4} md={4} lg={4} xl={4}> */}
                  <Col className="w-[calc(20%_-_0.67rem)]">
                    <Form.Item className="mb-0">
                      <ButtonCustom
                        className="h-12 unset-min-width w-full"
                        htmlType="submit"
                        color="orange"
                      >
                        Search
                      </ButtonCustom>
                    </Form.Item>
                  </Col>

                  {/* <Col className="w-[calc(10%_-_0.7rem)]" xs={24} sm={3} md={3} lg={3} xl={3}> */}
                  <Col className="w-[calc(20%_-_0.67rem)]">
                    <Form.Item className="mb-0 ">
                      <ButtonCustom
                        className="h-12 unset-min-width w-full"
                        htmlType="button"
                        color="outline"
                        onClick={async () => {
                          form.resetFields();
                          fetchS3Infomation(false);
                          setSearchResults(undefined);
                        }}
                      >
                        Reset
                      </ButtonCustom>
                    </Form.Item>
                  </Col>
                  {/* <ButtonCustom
                onClick={handleResetData}
                isWidthFitContent
                className="h-12"
                color="outline"
              >
                Reset
              </ButtonCustom> */}
                </Row>
              </Form>
            </div>
            <div className="filter-content w-full">
              <Row className="items-center justify-between">
                <Col>
                  <div className="box-items flex">
                    <div className="item-grid-layout mr-5 point">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.25 0.375H1.25C1.01794 0.375 0.795376 0.467187 0.631282 0.631282C0.467187 0.795376 0.375 1.01794 0.375 1.25V8.25C0.375 8.48206 0.467187 8.70462 0.631282 8.86872C0.795376 9.03281 1.01794 9.125 1.25 9.125H8.25C8.48206 9.125 8.70462 9.03281 8.86872 8.86872C9.03281 8.70462 9.125 8.48206 9.125 8.25V1.25C9.125 1.01794 9.03281 0.795376 8.86872 0.631282C8.70462 0.467187 8.48206 0.375 8.25 0.375ZM7.375 7.375H2.125V2.125H7.375V7.375ZM18.75 0.375H11.75C11.5179 0.375 11.2954 0.467187 11.1313 0.631282C10.9672 0.795376 10.875 1.01794 10.875 1.25V8.25C10.875 8.48206 10.9672 8.70462 11.1313 8.86872C11.2954 9.03281 11.5179 9.125 11.75 9.125H18.75C18.9821 9.125 19.2046 9.03281 19.3687 8.86872C19.5328 8.70462 19.625 8.48206 19.625 8.25V1.25C19.625 1.01794 19.5328 0.795376 19.3687 0.631282C19.2046 0.467187 18.9821 0.375 18.75 0.375ZM17.875 7.375H12.625V2.125H17.875V7.375ZM8.25 10.875H1.25C1.01794 10.875 0.795376 10.9672 0.631282 11.1313C0.467187 11.2954 0.375 11.5179 0.375 11.75V18.75C0.375 18.9821 0.467187 19.2046 0.631282 19.3687C0.795376 19.5328 1.01794 19.625 1.25 19.625H8.25C8.48206 19.625 8.70462 19.5328 8.86872 19.3687C9.03281 19.2046 9.125 18.9821 9.125 18.75V11.75C9.125 11.5179 9.03281 11.2954 8.86872 11.1313C8.70462 10.9672 8.48206 10.875 8.25 10.875ZM7.375 17.875H2.125V12.625H7.375V17.875ZM18.75 10.875H11.75C11.5179 10.875 11.2954 10.9672 11.1313 11.1313C10.9672 11.2954 10.875 11.5179 10.875 11.75V18.75C10.875 18.9821 10.9672 19.2046 11.1313 19.3687C11.2954 19.5328 11.5179 19.625 11.75 19.625H18.75C18.9821 19.625 19.2046 19.5328 19.3687 19.3687C19.5328 19.2046 19.625 18.9821 19.625 18.75V11.75C19.625 11.5179 19.5328 11.2954 19.3687 11.1313C19.2046 10.9672 18.9821 10.875 18.75 10.875ZM17.875 17.875H12.625V12.625H17.875V17.875Z"
                          fill="#ED7635"
                        />
                      </svg>
                    </div>
                    <div className="item-list-layout point">
                      <svg
                        width="22"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.5 0.125H3.125V2.75H0.5V0.125ZM0.5 6.6875H3.125V9.3125H0.5V6.6875ZM0.5 13.25H3.125V15.875H0.5V13.25ZM21.5 2.75V0.125H5.78019V2.75H19.925H21.5ZM5.75 6.6875H21.5V9.3125H5.75V6.6875ZM5.75 13.25H21.5V15.875H5.75V13.25Z"
                          fill="#AEA8A5"
                        />
                      </svg>
                    </div>
                  </div>
                </Col>
                <Col>
                  {/* <div className="flex flex-wrap gap-4"> */}
                  <div className="custom-flex-filter">
                    <div className="mr-6">
                      <Select
                        value={null}
                        getPopupContainer={(triggerNode) => triggerNode}
                        onChange={actionButtonOnChange}
                        placeholder={'Action Button'}
                        allowClear
                        options={[
                          { label: 'New Folder', value: 'NEW_FOLDER' },
                          { label: 'File Upload', value: 'FILE_UPLOAD' },
                          { label: 'Folder Upload', value: 'FOLDER_UPLOAD' },
                        ]}
                        className="text-main-font-color font-fontFamily text-sm min-w-200"
                      />
                    </div>
                    <div className="flex gap-2 items-center m-r-12">
                      <p className="font-fontFamily font-bold mb-0">Filter</p>
                      <SelectCustom
                        placeholder="Select"
                        color="transparent"
                        className="min-w-[144px]"
                        onChange={handleFilterByName}
                        options={[
                          { label: 'Name (A - Z)', value: 'A-Z' },
                          { label: 'Name (Z - A)', value: 'Z-A' },
                        ]}
                        value={sortCondtion || null}
                        // defaultValue={null}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="cus-mt-1_25rem">
              <div className="flex justify-between items-center mt-4 mb-4">
                <div className="text-lg font-semibold text-[#000000]">Folder</div>
              </div>
              <Loading isLoading={isLoading}>
                <Row className="gap-3">
                  {listFolder?.map((element: ICardFolder) => (
                    <React.Fragment key={element.name}>
                      <Dropdown
                        key={element.name}
                        overlay={
                          <Menu
                            className="menu-folder"
                            onClick={(e) => actionButtonOnChange(e.key)}
                          >
                            {!(groupId || classId) && <Menu.Item key={'SHARE'}>Share</Menu.Item>}
                            {(listFolder.length > 1 || listFolderSelected.length > 0) && (
                              <Menu.SubMenu key={'MOVE_TO'} title="Move To">
                                {listFolderSelected?.length > 0 && (
                                  <Menu.Item
                                    key={`MOVE_TO_${
                                      listFolderSelected.length > 0
                                        ? listFolderSelected
                                            .filter(
                                              (_, index: number) =>
                                                index != listFolderSelected.length - 1,
                                            )
                                            .join('/')
                                        : ''
                                    }`}
                                  >
                                    ..
                                  </Menu.Item>
                                )}
                                {listFolder
                                  .filter((e: ICardFolder) => e.name !== element.name)
                                  .map((e: ICardFolder) => (
                                    <Menu.Item
                                      key={`MOVE_TO_${
                                        listFolderSelected?.length > 0
                                          ? `${listFolderSelected.join('/')}/`
                                          : ''
                                      }${e.name}`}
                                    >
                                      {e.name}
                                    </Menu.Item>
                                  ))}
                              </Menu.SubMenu>
                            )}

                            <Menu.Item key={'REMOVE'}>Remove</Menu.Item>
                          </Menu>
                        }
                        onVisibleChange={(visible: boolean) => {
                          if (visible) {
                            setFolderClick(element.name);
                            setFileClick(undefined);
                          }
                        }}
                        trigger={['contextMenu']}
                        className="xl-min:w-[calc(33.33%_-_1rem)] xl:w-[calc(50%_-_0.5rem)] lg:w-[calc(50%_-_0.5rem)] sm:w-full"
                      >
                        <Col
                          key={element.name}
                          onClick={() => {
                            setLoading(true);
                            onClickFolder(element.name);
                          }}
                        >
                          <CardFolder data={element} onRedirect={() => {}} />
                        </Col>
                      </Dropdown>
                    </React.Fragment>
                  ))}
                </Row>
              </Loading>
            </div>
            <div className="cus-mt-1_25rem">
              <div className="flex justify-between items-center mt-4 mb-4">
                <div className="text-lg font-semibold text-[#000000]">Files</div>
              </div>
              <Loading isLoading={isLoading}>
                <Row className="gap-3">
                  {listFile?.map((element: ICardFile) => (
                    <React.Fragment key={element.name}>
                      <Dropdown
                        key={element.name}
                        overlay={
                          <Menu className="" onClick={(e) => actionButtonOnChange(e.key)}>
                            {!(groupId || classId) && <Menu.Item key={'SHARE'}>Share</Menu.Item>}
                            {(listFolder.length > 0 || listFolderSelected.length > 0) && (
                              <Menu.SubMenu key={'MOVE_TO'} title="Move To">
                                {listFolderSelected?.length > 0 && (
                                  <Menu.Item
                                    key={`MOVE_TO_${
                                      listFolderSelected.length > 0
                                        ? listFolderSelected
                                            .filter(
                                              (_, index: number) =>
                                                index != listFolderSelected.length - 1,
                                            )
                                            .join('/')
                                        : ''
                                    }`}
                                  >
                                    ..
                                  </Menu.Item>
                                )}
                                {listFolder
                                  .filter((e: ICardFolder) => e.name !== element.name)
                                  .map((e: ICardFolder) => (
                                    <Menu.Item
                                      key={`MOVE_TO_${
                                        listFolderSelected?.length > 0
                                          ? `${listFolderSelected.join('/')}/`
                                          : ''
                                      }${e.name}`}
                                    >
                                      {e.name}
                                    </Menu.Item>
                                  ))}
                              </Menu.SubMenu>
                            )}
                            <Menu.Item key={'DOWNLOAD_FILE'}>Download</Menu.Item>
                            <Menu.Item key={'REMOVE_FILE'}>Remove</Menu.Item>
                            <Menu.Item key={'RENAME_FILE'}>Rename</Menu.Item>
                          </Menu>
                        }
                        onVisibleChange={(visible: boolean) => {
                          if (visible) {
                            setFileClick(element.name);
                            setFolderClick(undefined);
                          }
                        }}
                        trigger={['contextMenu']}
                        className="xl-min:w-[calc(33.33%_-_1rem)] xl:w-[calc(50%_-_0.5rem)] lg:w-[calc(50%_-_0.5rem)] sm:w-full"
                      >
                        <Col
                          key={element.name}
                          onClick={() => {
                            setFileClick(element.name);
                            mutatePreviewFile({
                              key: `${groupId ? `${ETypeGetListObject.GROUP}/` : ''}${
                                !groupId && classId ? `${ETypeGetListObject.CLASS}/` : ''
                              }${
                                groupId
                                  ? parseInt(groupId)
                                  : classId
                                  ? parseInt(classId)
                                  : routerUser.id
                              }/${
                                listFolderSelected?.length > 0
                                  ? `${listFolderSelected.join('/')}/`
                                  : ''
                              }${element.name}`,
                            });
                          }}
                        >
                          <CardImage data={element} isShowImage={true} />
                        </Col>
                      </Dropdown>
                    </React.Fragment>
                  ))}
                </Row>
              </Loading>
            </div>
          </Content>
          {isShowModalCreateFolder && (
            <ModalCreateNewFolder
              visible={true}
              onCancel={() => {
                setShowModalCreateFolder(false);
              }}
              onSubmit={handleCreateNewForder}
              isKeepOpen={true}
              form={formCreateFolder}
              loading={isLoading}
            />
          )}
          {isShowModalUploadFile && (
            <ModalUploadNewFile
              visible={true}
              onCancel={() => {
                setShowModalUploadFile(false);
                setListFileUpload([]);
              }}
              onSubmit={handleUploadFiletoS3}
              isKeepOpen={true}
              form={formUploadFile}
              listFile={listFileUpload}
              onUploadFile={onUploadFile}
              onRemoveFile={onRemoveFile}
              loading={isLoading}
              limitSize={MYDRIVE_FILESIZE_IN_MB}
            />
          )}
          {isShowModalUploadFolder && (
            <ModalUploadFolder
              visible={true}
              onCancel={() => {
                setShowModalUploadFolder(false);
                setListFileUpload([]);
                setListFileUpload([]);
              }}
              onSubmit={handleUploadFolderToS3}
              isKeepOpen={true}
              form={formUploadFolder}
              listFolder={listFolderUpload}
              onRemoveFolder={onRemoveFolder}
              onUploadFolder={onUploadFolder}
              loading={isLoading}
              limitSize={MYDRIVE_FILESIZE_IN_MB}
            />
          )}
          {isShowModalRename && (
            <ModalRename
              visible={true}
              onCancel={() => {
                setShowModalRename(false);
                formRenameFolder.resetFields();
              }}
              onSubmit={handleRenameFile}
              isKeepOpen={true}
              form={formRenameFolder}
              loading={isLoading}
            />
          )}
          {isShowModalRemove && (
            <ModalRemove
              visible={true}
              onCancel={() => {
                setShowModalRemove(false);
              }}
              fileName={fileClick || folderClick || ''}
              onSubmit={handleRemoveFileOrFile}
              isKeepOpen={true}
              form={formRemoveFile}
              loading={isLoading}
              type={fileClick ? 'File' : 'Folder'}
              timeout={
                routerUser.userRole === RoleName.ADMIN ||
                routerUser.userRole === RoleName.SUPER_ADMIN
                  ? MYDRIVE_ADMIN_TRASH
                  : MYDRIVE_USER_TRASH
              }
              isDelete={!!groupId}
            />
          )}
          {isShowModalDowload && (
            <ModalDowload
              visible={true}
              onCancel={() => {
                setShowModalDowload(false);
              }}
              onSubmit={() => {
                handleDowload();
              }}
              isKeepOpen={true}
              fileUrl={urlPreview}
              fileName={fileClick}
              form={formPreview}
              loading={isLoading}
            />
          )}

          {isShowModalShare && (
            <ModalShare
              visible={true}
              onCancel={() => {
                setShowModalShare(false);
                formShare.resetFields();
              }}
              onSubmit={handleShareForUser}
              isKeepOpen={true}
              form={formShare}
              listUsers={listUsersShare}
              loading={isLoading}
              nameShare={fileClick || folderClick || ''}
              onSearch={onSearchUser}
            />
          )}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default OwnDrive;
