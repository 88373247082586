import { Breadcrumb, Layout, Tabs } from 'antd';
import { ROUTES } from 'constants/constants';
import { AppContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MyInfo from './my-info';
import './style.css';
import { useMutation } from 'react-query';
import { ICourseDetail } from '../available-course-detail';
import { getCourseDetail } from 'api/courses';

const Subscription = () => {
  const [state]: any = useContext(AppContext);
  const history = useNavigate();
  const [activeKey, setActiveKey] = useState('1');
  const { id } = useParams();
  const [dataCourses, setDataCourses] = useState<ICourseDetail>();
  const handleChangeTab = (key: string) => {
    setActiveKey(key);
  };

  const { mutate: getCourse } = useMutation('getCourseDetail', getCourseDetail, {
    onSuccess: ({ data }: { data: ICourseDetail }) => {
      const formData = {
        id: data?.id,
        courseName: data?.courseName,
        learningMethod: data?.learningMethod,
        programType: data?.programType,
        description: data?.description,
        catalogImageUrl: data?.catalogImageUrl,
        startDate: data?.startDate,
        endDate: data?.endDate,
      };
      setDataCourses(formData);
    },
  });

  useEffect(() => {
    if (id) {
      getCourse(Number(id));
    }
  }, [id]);
  return (
    <Layout className="bg-transparent">
      <Breadcrumb className="text-[28px] text-main-font-color font-bold font-fontFamily leading-9">
        <Breadcrumb.Item
          onClick={() => history(ROUTES.available_course)}
          className="!opacity-50 cursor-pointer"
        >
          Available Course
        </Breadcrumb.Item>

        <Breadcrumb.Item
          onClick={() => history(`${ROUTES.available_course}/${id}`)}
          className="!opacity-50 cursor-pointer"
        >
          {dataCourses?.courseName}
        </Breadcrumb.Item>

        <Breadcrumb.Item className="text-main-font-color font-fontFamily">
          Enrolment
        </Breadcrumb.Item>
      </Breadcrumb>

      {state.user?.userRole?.template?.templateName !== 'Student' ? (
        <MyInfo></MyInfo>
      ) : (
        <>
          <MyInfo></MyInfo>
          {/* <Tabs
            className="custom-tab my-profile-tab mt-3"
            onChange={(key) => handleChangeTab(key)}
            activeKey={activeKey}
            size={'small'}
          >
            <Tabs.TabPane tab="Info" key="1" style={{ outline: 'none' }}>
              <MyInfo></MyInfo>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Parent Info" key="2" style={{ outline: 'none' }}>
              <ParentInfo></ParentInfo>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Link Account" key="3" style={{ outline: 'none' }}>
              <LinkAccount></LinkAccount>
            </Tabs.TabPane>
          </Tabs> */}
        </>
      )}
    </Layout>
  );
};

export default Subscription;
