import { getSearchParams } from 'constants/index';
import { deleteAPI, getAPI, postAPI, putAPI } from './axios';
import { IParamsSearch } from './courses';

export interface IClass {
  id?: number;
  className: string;
  capacity: number;
  centreID: number;
  courseID: number;
  startDate: string;
  endDate: string;
  centre?:{
    centreName?:string
  };
  classSessions?: {
    teacherIDs?: number[] | string[];
    sessionID?: string | number;
    centreID?: string | number;
    classroomID?: string | number;
    startTime?: string | undefined;
    endTime?: string | undefined;
  }[];
}

export interface IParamValidationSession {
  sessionID: number;
  teacherIDs: number[];
  classroomID?: number;
  startTime: string;
  endTime: string;
}

export const getStudents = async (id: number) => {
  try {
    const data = await getAPI(`api/classes/${id}/students`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchClass = async (params: IParamsSearch) => {
  const paramsOption = getSearchParams(params);

  try {
    const data = await getAPI(`api/classes${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchClassRoom = async (params: IParamsSearch) => {
  const paramsOption = getSearchParams(params);

  try {
    const data = await getAPI(`api/classrooms${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getClassById = async (classId: number) => {
  try {
    const data = await getAPI(`api/classes/${classId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const studentStartClass = async (param: { classId?: string; moduleId?: string }) => {
  try {
    const data = await getAPI(
      `api/unit/user-units/student/start/${param.classId}/${param.moduleId}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteClass = async (params: { id: number }) => {
  try {
    const data = await deleteAPI(`api/classes/${params.id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteMultipleClass = async (params: { listClassIds: React.Key[] }) => {
  try {
    const data = await deleteAPI(`api/classes`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createMultipleClass = async (body: IClass[]) => {
  try {
    const data = await postAPI(`api/classes/bulk-create`, { classes: body });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateClass = async (body: { data: IClass[]; classId: number }) => {
  try {
    const data = await putAPI(`api/classes/${body.classId}`, body.data[0]);
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkValidationSession = async (params: IParamValidationSession[]) => {
  try {
    const data = await postAPI(`api/classes/check-assigned-sessions`, { classSessions: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllSessionByTeacher = async (params: {
  search?: string;
  classes?: string;
  centre?: string;
  course?: string;
  id?: string;
}) => {
  try {
    const data = await getAPI(`api/classes/session-classSessions`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllSessionByStudent = async (params: {
  search?: string;
  classes?: string;
  centre?: string;
  course?: string;
  id?: string;
}) => {
  try {
    const data = await getAPI(`api/classes/session-classSessions-by-student`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllSessionInClassesByTeacher = async (params: {
  search?: string;
  classes: string;
  id?: string;
}) => {
  try {
    const query = {
      search: params.search,
      id: params.id,
    };
    const data = await getAPI(`api/classes/${params.classes}/session-classSessions`, query);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllSessionInClassesByStudent = async (params: {
  search?: string;
  classes: string;
  id?: string;
}) => {
  try {
    const query = {
      search: params.search,
      id: params.id,
    };
    const data = await getAPI(
      `api/classes/${params.classes}/session-classSessions-by-student`,
      query,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAnnnoucementInClass = async (classId: number) => {
  try {
    const data = await getAPI(`api/classes/${classId}/announcement`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkStudentInClass = async (classId: number) => {
  try {
    const data = await getAPI(`api/classes/check-student?classId=${classId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUnitsInClass = async (classId: number) => {
  try {
    const data = await getAPI(`api/classes/${classId}/units`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStudentsByClassId = async ({classId, params}: any) => {
  try {
    const paramsOption = getSearchParams(params);
    const data = await getAPI(`api/students/class/${classId}${paramsOption ? paramsOption : ''}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addStudent = async (params: { classId: number; studentId: number }) => {
  try {
    const { classId, studentId } = params;
    const data = await putAPI(`api/students/class/${classId}/student/${studentId}`, {});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStudentByICNumber = async (icNumber: string) => {
  try {
    const data = await getAPI(`api/students/user-profile?icNumber=${icNumber}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const generateCert = async (payload: any) => {
  try {
    const data = await postAPI(`api/certificate`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

// certificate/class/12321
export const generateCerts = async (params: { classId: number }) => {
  try {
    const { classId } = params;
    const data = await postAPI(`api/certificate/class/${classId}`, {});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCertByStudentId = async (params: { studentId: number }) => {
  try {
    const { studentId } = params;
    const data = await getAPI(`api/certificate/student/${studentId}`);
    return data;
  } catch (error) {
    throw error;
  }
};