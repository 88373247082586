import { getAPI, postAPI, postAPIS3 } from './axios';

export interface IRenameFile {
  newFileName: string;
  oldPathFile: string;
  sharerId: number;
}

export interface IShareForUser {
  userId: number[];
  path: string;
  sharedType: string;
}

export interface IParamsListFileAndFolder {
  prefix?: string;
  pathFolder?: string;
  fullTextSearch?: string;
  filterConditon?: string;
}

export interface IRemoveFile {
  currentPath: string;
  fileName: string;
  sharerId: number;
}

export interface IMoveFolder {
  sourcePath: string;
  sourceMove: string;
  isRemove: boolean;
  sharerId: number;
}

export const moveFolder = async (payload: IMoveFolder) => {
  try {
    const data = await postAPIS3(`api/shared-with-me/move-folder`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const moveFile = async (payload: IMoveFolder) => {
  try {
    const data = await postAPIS3(`api/shared-with-me/move-file`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getListFileAndFolder = async (params: IParamsListFileAndFolder) => {
  try {
    const data = await getAPI(`api/shared-with-me`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const renameFile = async (payload: IRenameFile) => {
  try {
    const data = await postAPI(`api/shared-with-me/rename-file`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const shareForUser = async (payload: IShareForUser) => {
  try {
    const data = await postAPIS3(`api/shared-with-me/share-for-user`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
