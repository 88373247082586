import { notification, UploadProps } from 'antd';
import Upload, { RcFile, UploadFile as UploadFileProp } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import ButtonCustom from 'components/Button';
import { config } from 'config';
import { ERROR_MESSAGE, TYPES_IMAGE_UPLOAD } from 'constants/index';

const MAX_SIZE = 10485760;

const propsUpload: UploadProps = {
  name: 'file',
  method: 'post',
  action: `${config.BASE_URL}/api/upload-image`,
  headers: {
    authorization: 'authorization-text',
  },
  maxCount: 1,
};

interface IProps {
  disabled?: boolean;
  isUploadMultipleFile?: boolean;
  getFilePath?: (fileUrl: { filePath: string; fileUrl?: string }) => void;
  defaultFileList?: UploadFileProp<any>[];
  isDragAndDrop?:boolean
}

const UploadFileToAswer = (props: IProps) => {
  const { disabled, isUploadMultipleFile, getFilePath, defaultFileList,isDragAndDrop } =
    props;

  const onBeforeUpload = (file: RcFile) => {
    if (file?.size > MAX_SIZE) {
      notification.error({ message: ERROR_MESSAGE.LIMIT_SIZE });
      return Upload.LIST_IGNORE;
    }

    if (file && TYPES_IMAGE_UPLOAD.includes(file?.type) && file?.size <= MAX_SIZE) {
      return true;
    }

    notification.error({ message: ERROR_MESSAGE.UPLOAD_FILE });
    return Upload.LIST_IGNORE;
  };

  const onChange: UploadProps['onChange'] = ({ fileList }) => {
    if (isUploadMultipleFile) {
      return;
    }
    if (getFilePath instanceof Function) {
      getFilePath(fileList[0]?.response?.data);
    }
  };

  return (
    <div style={{ width: isDragAndDrop ?'23vw' : '100%' }}>
      <div className={`rounded-xl box-upload ${isDragAndDrop && 'upload__file__images'}`} >
      <Upload
          onChange={onChange}
          beforeUpload={onBeforeUpload}
          disabled={disabled}
          className={`py-12 `}
          {...propsUpload}
          defaultFileList={defaultFileList}
        >
          <ButtonCustom
            color="outline"
            className="m-auto min-button"
            icon={<UploadIcon className="icon-button mr-2" />}
          >
             &nbsp;Upload Image
          </ButtonCustom>
        </Upload>
        {/* <Dragger
          onChange={onChange}
          beforeUpload={onBeforeUpload}
          className='upload__file__images'
          disabled={disabled}
          {...propsUpload}
          defaultFileList={defaultFileList}
        >
          <div className="flex justify-center items-center">
            {!isDragAndDrop && <p className='mr-5'>Drag & Drop your image here Or</p>}
            <div className="lg:max-w-fit ">
              <ButtonCustom
                color="outline"
                className="m-auto"
                icon={<UploadIcon className="icon-button" />}
              >
                &nbsp;Upload Image
              </ButtonCustom>
            </div>
          </div>
        </Dragger> */}
      </div>
    </div>
  );
};

export default UploadFileToAswer;
