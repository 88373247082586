import { Col, Form, Input, Row, Select } from 'antd';
import { createEvent, updateEvent } from 'api/event';
import ModalCustom from 'components/Modal';
import RangePickerCustom from 'components/RangePicker';
import { CLASS_NAME_FIELD } from 'constants/index';
import { isEmptyObject, routerUserAccess } from 'helper/function';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

const ScheduledMeetingModal = (props: any) => {
  const { visible, onCancel, rowData = {} } = props;
  const [form] = Form.useForm();
  const [isKeepOpen, setIsKeepOpen] = useState(true);
  const [data, setData] = useState({});
  const [duration, setDuration] = useState<[Moment, Moment]>();
  const [loading, setLoading] = useState(false);
  const title = Form.useWatch('title', form);
  const remark = Form.useWatch('remark', form);
  const routerUser = routerUserAccess();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
    setDuration(undefined);
    setIsKeepOpen(true);
    setLoading(false);
  };

  const { mutate: mutateCreateNewEvent } = useMutation('createEvent', createEvent, {
    onSuccess: () => {
      handleCancel();
      // onReload();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const { mutate: mutateUpdateEvent } = useMutation('updateEvent', updateEvent, {
    onSuccess: () => {
      handleCancel();
      // onReload();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const onFinish = useCallback(
    (formValues: {
      title: string;
      remark: string;
      duration: [Moment, Moment];
      guests: string[];
    }) => {
      setLoading(true);
      // mutateCreateNewEvent({
      //   title: title || formValues.title,
      //   remark: remark || formValues.remark,
      //   startDateTime: moment(duration?.[0], 'YYYY-MM-DD HH:mm A').toDate(),
      //   endDateTime: moment(duration?.[1], 'YYYY-MM-DD HH:mm A').toDate(),
      //   classes: formValues.guests,
      // });
      const test = {
        title: title || formValues.title,
        remark: remark || formValues.remark,
        startDateTime: moment(duration?.[0], 'YYYY-MM-DD HH:mm A').toDate(),
        endDateTime: moment(duration?.[1], 'YYYY-MM-DD HH:mm A').toDate(),
        classes: formValues.guests,
      };
      setData(test);
    },
    [title, remark, duration],
  );



  const handleChangeDuration = (newDuration: [Moment, Moment]) => {
    setDuration(newDuration);
  };

  const onFinishFailed = ({ errorFields }: { errorFields: any }) => {
    if (!duration) {
      form.setFields([
        {
          name: 'duration',
          errors: ['Duration is required!'],
        },
      ]);
    }
  };

  return (
    <ModalCustom
      title='Scheduled Online Meeting'
      okText="Start Meeting"
      cancelText="Cancel"
      visible={visible}
      onCancel={handleCancel}
      onSubmit={() => {
        window.open(rowData?.zoomLink)
      }}
      titleCenter
      contentLeft
      styleCancel={{ border: '1px solid #D1CDCB' }}
      className="modal-event-modified-custom"
      loading={loading}
    >
      <Row className='mt-10'>
        <Col span={10}>Start Date Time:</Col>
        <Col span={14}>
          {moment(rowData.startDate).format('YYYY/MM/DD - h:mm a')}
        </Col>
      </Row>
      <Row>
        <Col span={10}>End Date Time:</Col>
        <Col span={14}>
          {moment(rowData.endDate).format('YYYY/MM/DD - h:mm a')}
        </Col>
      </Row>
      <Row>
        <Col span={10}>Student Name:</Col>
        <Col span={14}>{rowData?.studentName}</Col>
      </Row>
      <Row>
        <Col span={10}>Remark:</Col>
        <Col span={14}>{rowData?.remarks}</Col>
      </Row>
      <Row className='mt-10'>
        <Col span={24}><i>Note: The Start Meeting button will only be active 10 mins prior to start of meeting</i></Col>
      </Row>

    </ModalCustom>
  );
};

export default ScheduledMeetingModal;
