import { notification } from 'antd';
import { verifyCert } from 'api/student';
import BackToLogin from 'components/BackToLogin';
import ButtonCustom from 'components/Button';
import HeaderLogin from 'components/HeaderLogin';
import CustomInput from 'components/Input';
import NotiModal from 'components/NotiModal';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';


const VerifiedCertificate = () => {
  const [pin, setPin] = useState('');
  const [certUrl, setCertUrl] = useState('');
  const search = useLocation().search;
  const serialNumber = new URLSearchParams(search).get('serialNumber') || '';

  const { mutateAsync: mutateVerifyCert, isLoading: isLoadingStudents } = useMutation('verifyCert', verifyCert, {
    onSuccess: ({ data }: { data: any }) => {
      const { fileUrl = '' } = data;
      setCertUrl(fileUrl)
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      setCertUrl('')
      notification.error({ message: response.data.message });
    },
  });

  console.log('serialNumber', serialNumber);
  const handleChange = (event: any) => {
    setPin(event.target.value);

    console.log('value is:', event.target.value);
  };
  const handleVerifyPin = () => {
    mutateVerifyCert({ serialNumber: serialNumber, pin: pin })
    .catch(() => {
      notification.error({message: "Certificate is not found"})
    });
  }
  return (
    <div className='flex flex-col'>
      <CustomInput onChange={handleChange} />
      {certUrl !== '' && <a href={certUrl} target='_blank' rel="noreferrer">View Cert</a>}
      <div className='flex justify-center'>
        <ButtonCustom className='mt-5' onClick={handleVerifyPin}>Verify Pin</ButtonCustom>
      </div>
    </div>
  );
};

export default VerifiedCertificate;
