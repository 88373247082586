import { Form, FormInstance, notification } from 'antd';
import ModalCustom from 'components/Modal';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { useCallback, useEffect } from 'react';
import { ICardUploadFile } from '..';
import Dragger from 'antd/lib/upload/Dragger';
import Upload, { RcFile, UploadFile } from 'antd/lib/upload';
import { ERROR_MESSAGE } from 'constants/index';
import ButtonCustom from 'components/Button';
interface IProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isKeepOpen: boolean;
  form: FormInstance<any>;
  listFile: File[];
  onUploadFile: (file: File) => void;
  onRemoveFile: (uidFile: string) => void;
  loading: boolean;
  limitSize?: number;
}

const ModalUploadNewFile = (props: IProps) => {
  const {
    visible,
    onCancel,
    onSubmit,
    isKeepOpen,
    form,
    listFile,
    onUploadFile,
    onRemoveFile,
    loading,
    limitSize,
  } = props;

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [listFile]);

  const onBeforeUpload = (file: RcFile) => {
    if (file.webkitRelativePath && file.webkitRelativePath !== file.name) {
      notification.error({
        message: `File invalid!`,
      });
      return Upload.LIST_IGNORE;
    }

    if (limitSize && file?.size / 1024 / 1024 > limitSize) {
      notification.error({
        message: `The file ${file.name} limit size must be less than ${limitSize}MB`,
      });
      return false;
    }

    return true;
  };

  const onRemoveUpload = (file: UploadFile<any>) => {
    onRemoveFile(file.uid);
  };

  const customRequest = async (options: any) => {
    const { onSuccess, file } = options;
    onSuccess('Ok');
    onUploadFile(file);
  };

  useEffect(() => {
    if (listFile?.length > 0) form.validateFields();
  }, [listFile]);

  return (
    <ModalCustom
      onCancel={onCancel}
      onSubmit={form.submit}
      visible={visible}
      title={'Upload File'}
      cancelText="Cancel"
      okText={'Upload'}
      isKeepOpen={isKeepOpen}
      loading={loading}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Upload File"
          name="thumbnailPath"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                const v = value;
                if (listFile?.length === 0) {
                  return Promise.reject('File upload is required!');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <div className="flex justify-center items-center rounded-xl bg-[#F4F4F4] text-center box-upload">
            <Dragger
              multiple
              beforeUpload={onBeforeUpload}
              customRequest={customRequest}
              onRemove={onRemoveUpload}
              className="py-12"
              fileList={listFile.map((element: File, index: number) => ({
                uid: `${index}_${element.name}`,
                name: element.name as string,
              }))}
            >
              <div className="lg:max-w-fit m-auto">
                <ButtonCustom
                  color="outline"
                  className="m-auto"
                  icon={<UploadIcon className="icon-button" />}
                >
                  &nbsp; Click or drop file
                </ButtonCustom>
              </div>
            </Dragger>
          </div>
        </Form.Item>
      </Form>
    </ModalCustom>
  );
};

export default ModalUploadNewFile;
