import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Layout, Spin } from 'antd';

import { RoleName } from 'enum/role';
import { useMutation } from 'react-query';
import { IParamsSearch } from 'api/courses';
import SelectCustom from 'components/Select';
import { optionsLimit } from './helper';
import GroupCard from './component/GroupCard';

import style from './my-group.module.scss';
import PaginationCustom from 'components/Pagination';
import { routerUserAccess } from 'helper/function';
import { getGroupList } from 'api/teacher_my_groups';
import { IGroupListData } from 'pages/admin/class-management/component/MyGroups/helper';
import SearchNotFound from 'components/SearchBar/SearchNotFound';

const { Header } = Layout;

const StudentMyGroups = () => {
  const [dataList, setDataList] = useState<IGroupListData>();
  const [filter, setFilter] = useState<IParamsSearch>({
    page: 1,
    limit: 5,
    sort: 'createdAt',
    order: 'ASC',
    search: '',
  });

  const routerUser = routerUserAccess();

  const { mutate: getGroupsData, isLoading } = useMutation('getGroupList', getGroupList, {
    onSuccess: ({ data }: { data: IGroupListData }) => {
      setDataList({
        page: data.page,
        limit: data.limit,
        sort: data.sort,
        order: data.order,
        total: data.total,
        groups: data.groups,
        classDetails: data.classDetails,
      });
    },
  });

  const onChangeLimit = useCallback(
    (value: string) => {
      const total = dataList?.total;
      const maxPage = Math.ceil(Number(total) / Number(value));
      if (filter.page > maxPage) setFilter((prev) => ({ ...prev, page: maxPage, limit: +value }));
      else setFilter({ ...filter, limit: +value });
    },
    [filter.page, dataList?.total],
  );

  const startPageSize = useMemo(() => {
    const startSize = Number(filter.page) * Number(filter?.limit) - (Number(filter?.limit) - 1);

    return startSize;
  }, [filter.page, filter?.limit]);

  const endPageSize = useMemo(() => {
    let endSize = Number(filter.page) * Number(filter?.limit);
    endSize = dataList?.total && endSize < dataList?.total ? endSize : (dataList?.total as number);

    return endSize;
  }, [filter.page, filter?.limit, dataList?.total]);

  useEffect(() => {
    getGroupsData({
      ...filter,
      filters: JSON.stringify([{ userType: RoleName.STUDENT }]),
    });
  }, [filter]);

  const MyGroupCardList = useMemo(() => {
    return (
      <div className={style.groupList}>
        {dataList?.groups?.map((ele) => (
          <GroupCard groupName={ele.groupName} groupID={ele.groupID} key={ele.groupID} />
        ))}
      </div>
    );
  }, [dataList]);

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      {routerUser.userRole === RoleName.STUDENT ? (
        <Layout className="bg-transparent flex flex-col gap-y-6">
          <div className="flex">
            <>
              <Header className="flex justify-between items-center bg-transparent px-0 h-auto flex-1">
                <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
                  <Breadcrumb.Item className="font-fontFamily text-main-font-color">
                    My Group
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Header>
            </>
          </div>

          <div className="flex justify-between items-center mt-5">
            <div className="font-bold text-2xl text-[#32302D]">All Groups</div>
            <div className="flex gap-4 items-center flex-wrap">
              <p className="font-fontFamily font-bold mb-0">View Item</p>
              <SelectCustom
                color="transparent"
                value={filter.limit.toString()}
                onChange={onChangeLimit}
                options={optionsLimit}
              />
            </div>
          </div>

          {MyGroupCardList}

          {dataList?.total ? (
            <div className="flex justify-between items-center my-4 footer-course-sp sm:gap-3">
              <span className="font-fontFamily text-sm text-main-font-color bottom-8">
                {startPageSize} - {endPageSize} of {dataList?.total}
              </span>
              <PaginationCustom
                total={dataList?.total}
                pageSize={Number(filter.limit)}
                onChange={(page) => {
                  setFilter((prev) => ({ ...prev, page }));
                }}
                current={filter.page}
                onLastPage={() => {
                  setFilter((prev) => ({
                    ...prev,
                    page: Math.ceil(Number(dataList?.total) / Number(filter.limit)),
                  }));
                }}
                onFirstPage={() => {
                  setFilter((prev) => ({ ...prev, page: 1 }));
                }}
              />
            </div>
          ) : (
            <SearchNotFound isBackgroundWhite text={filter.search} />
          )}
        </Layout>
      ) : (
        <p className="font-bold text-2xl">
          You don&apos;t have the permission to access this resource
        </p>
      )}
    </>
  );
};

export default StudentMyGroups;
