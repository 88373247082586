/**
 *
 * TopicItem
 *
*/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { DATE_TIME_FORMAT, ROUTES } from 'constants/constants';
import { TTopic, TTopicItemProps } from '../../types';

const TopicItem: React.FC<TTopicItemProps> = (props) => {
  const { topics, forumId } = props;
  const navigate = useNavigate();

  return (
    <div className="flex gap-4 flex-wrap p-8">
      {
        topics && topics.length ? topics.map((topic: TTopic) => {
          const { userProfile } = topic.author;
          return (
            <div
              key={topic.id}
              className={`rounded-3xl p-4 shadow-[0px_8px_32px_rgba(0,0,0,0.04)] w-[100%] h-fit border border-solid border-transparent hover:!border-main-button-color cursor-pointer main-search-bar topic`}
              onClick={() => navigate(`${ROUTES.class_forum_student}/${forumId}/topics/${topic.id}`, {
                state: {
                  topicTitle: topic.topicTitle
                }
              })}
            >
              <h2>{topic.topicTitle}</h2>
              <div className="flex">
                <p>
                  {moment.utc(topic.createdAt).format(DATE_TIME_FORMAT)}
                  <span className="ml-1 mr-1">-</span>
                  {userProfile?.firstName ?? ''} {userProfile?.lastName ?? ''}
                  <span className="ml-1 mr-1">-</span>
                  {topic?.replyCount ?? 0} {topic?.replyCount && topic?.replyCount > 1 ? 'Replies' : 'Reply'}
                </p>
              </div>
            </div>
          )
        })
          : null
      }
    </div>
  );
};

export default TopicItem;