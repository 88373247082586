import { Card, Divider, Empty, Spin, Typography } from 'antd';
import { getAnnouncement } from 'api/announcement';
import React from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { isEmpty } from 'lodash';

const { Title } = Typography;
import './styles.css';

function Announcements() {
  const { data, isLoading } = useQuery(['getAnnouncement'], () =>
    getAnnouncement({ page: 1, limit: 10 }),
  );

  return (
    <Card
      bordered={false}
      title={<Title level={4}>Announcements</Title>}
    //   extra={<a href="/announcement">More</a>}
      className="w-full rounded-2xl"
    >
      <Spin spinning={isLoading} tip="Loading...">
        <div className="px-4 w-full flex flex-col gap-1">
          {isEmpty(data?.data?.listAnnouncement) ? (
            <Empty />
          ) : (
            data?.data?.listAnnouncement?.map((x: any) => (
              <div className="text-md flex flex-wrap border-gray-700" key={x.id}>
                <strong dangerouslySetInnerHTML={{ __html: x?.message }}></strong>
                <span>at {moment(x?.updatedAt).format('YYYY/MM/DD, h:mm a')}</span>
                <span>by {x?.author?.username}</span>
                <Divider />
              </div>
            ))
          )}
        </div>
      </Spin>
    </Card>
  );
}

export default React.memo(Announcements);
