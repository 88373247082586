import React, { useContext } from 'react';
import { Spin, Layout, Button, Card, Switch, Input, InputNumber, notification } from 'antd';
import './styles.css';
import { useMutation, useQuery } from 'react-query';
import { getAllGlobalSetting, updateGlobalSetting } from 'api/global_setting';
import { keyBy } from 'lodash';
import { AppContext } from 'context';

const { Header, Content } = Layout;

function GlobalSetting() {
  const [FA2, setFA2] = React.useState<any>();
  const [LOGIN_ENABLED, setLOGIN_ENABLED] = React.useState<any>();
  const [LOGIN_ENABLED_MESSAGE, setLOGIN_ENABLED_MESSAGE] = React.useState<any>();
  const [MAINTENANCE_ENABLED, setMAINTENANCE_ENABLED] = React.useState<any>();
  const [MAINTENANCE_ENABLED_MESSAGE, setMAINTENANCE_ENABLED_MESSAGE] = React.useState<any>();
  const [MYDRIVE_ADMIN_TRASH, setMYDRIVE_ADMIN_TRASH] = React.useState<any>();
  const [MYDRIVE_USER_TRASH, setMYDRIVE_USER_TRASH] = React.useState<any>();
  const [PASSWORD_LENGTH, setPASSWORD_LENGTH] = React.useState<any>();

  const {
    data: dataGlobalSetting,
    isLoading,
    refetch,
  } = useQuery(['getAllGlobalSetting'], () => getAllGlobalSetting());

  const { mutateAsync: mutateUpdateGlobalSetting, isLoading: isUpdating } = useMutation(
    'updateGlobalSetting',
    updateGlobalSetting,
    {},
  );

  const [stateContext]: any = useContext(AppContext);

  const [globalSetting, setGlobalSetting] = React.useState<any>();

  React.useEffect(() => {
    if (dataGlobalSetting?.data) {
      const _globalSetting = keyBy(dataGlobalSetting?.data, 'settingKey');
      setGlobalSetting(_globalSetting);

      setFA2(Number(_globalSetting['2FA']?.settingValue));

      setPASSWORD_LENGTH(_globalSetting['PASSWORD_LENGTH']?.settingValue);

      setLOGIN_ENABLED(Number(_globalSetting['LOGIN_ENABLED']?.settingValue));
      setLOGIN_ENABLED_MESSAGE(_globalSetting['LOGIN_ENABLED_MESSAGE']?.settingValue);

      setMAINTENANCE_ENABLED(Number(_globalSetting['MAINTENANCE_ENABLED']?.settingValue));
      setMAINTENANCE_ENABLED_MESSAGE(_globalSetting['MAINTENANCE_ENABLED_MESSAGE']?.settingValue);

      setMYDRIVE_ADMIN_TRASH(_globalSetting['MYDRIVE_ADMIN_TRASH']?.settingValue);
      setMYDRIVE_USER_TRASH(_globalSetting['MYDRIVE_USER_TRASH']?.settingValue);
    }
  }, [dataGlobalSetting]);

  const handleUpdateByKey = (key: string, value: any) => {
    return mutateUpdateGlobalSetting({
      id: globalSetting?.[key].id,
      settingValue: value,
      lastUpdateUserID: stateContext?.user?.id || 1,
    }).then(() => {
      notification.success({ message: 'Global setting update success!' });
      refetch();
    });
  };

  const handleSaveSetting = () => {
    if (Number(globalSetting?.['2FA'].settingValue) !== +FA2) {
      handleUpdateByKey('2FA', +FA2);
    }
    if (Number(globalSetting?.['PASSWORD_LENGTH'].settingValue) !== +PASSWORD_LENGTH) {
      handleUpdateByKey('PASSWORD_LENGTH', +PASSWORD_LENGTH);
    }

    if (Number(globalSetting?.['LOGIN_ENABLED'].settingValue) !== +LOGIN_ENABLED) {
      handleUpdateByKey('LOGIN_ENABLED', +LOGIN_ENABLED);
    }
    if (globalSetting?.['LOGIN_ENABLED_MESSAGE'].settingValue !== LOGIN_ENABLED_MESSAGE) {
      handleUpdateByKey('LOGIN_ENABLED_MESSAGE', LOGIN_ENABLED_MESSAGE);
    }

    if (Number(globalSetting?.['MAINTENANCE_ENABLED'].settingValue) !== +MAINTENANCE_ENABLED) {
      handleUpdateByKey('MAINTENANCE_ENABLED', +MAINTENANCE_ENABLED);
    }
    if (globalSetting?.['MAINTENANCE_ENABLED_MESSAGE'].settingValue !== MAINTENANCE_ENABLED_MESSAGE) {
      handleUpdateByKey('MAINTENANCE_ENABLED_MESSAGE', MAINTENANCE_ENABLED_MESSAGE);
    }

    if (Number(globalSetting?.['MYDRIVE_ADMIN_TRASH'].settingValue) !== +MYDRIVE_ADMIN_TRASH) {
      handleUpdateByKey('MYDRIVE_ADMIN_TRASH', +MYDRIVE_ADMIN_TRASH);
    }
    if (Number(globalSetting?.['MYDRIVE_USER_TRASH'].settingValue) !== +MYDRIVE_USER_TRASH) {
      handleUpdateByKey('MYDRIVE_USER_TRASH', +MYDRIVE_USER_TRASH);
    }
  };

  return (
    <Spin style={{ paddingTop: 500 }} spinning={false} tip="Loading...">
      <Layout className="bg-transparent flex flex-col gap-y-6">
        <Header className="w-full flex justify-between items-center bg-transparent px-0">
          <div className="w-full text-[1.75rem] leading-9 font-bold mb-0">
            <div className="w-full flex justify-between font-fontFamily text-main-font-color">
              <div> Global Setting</div>
              <div>
                <Button
                  style={{ minWidth: 100 }}
                  className={'styte_button_primary my-0'}
                  size="middle"
                  onClick={handleSaveSetting}
                  loading={isUpdating}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Header>
        <Content className="flex flex-col gap-5">
          <Card title="Security" bordered={false} className="w-full rounded-xl">
            <div className="w-full flex flex-col gap-3 ">
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300 }}>TWO-FACTOR AUTHENTICATION</div>
                <div>
                  <Switch checked={Boolean(FA2)} onChange={setFA2} defaultChecked />
                </div>
              </div>
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300 }}>PASSWORD LENGTH</div>
                <div>
                  <InputNumber
                    value={PASSWORD_LENGTH}
                    onChange={setPASSWORD_LENGTH}
                    defaultValue={0}
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card title="User Login" bordered={false} className="w-full rounded-xl">
            <div className="w-full flex flex-col gap-3 ">
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300, textTransform: 'uppercase' }}>
                  Log-In Enabled (Teacher/Students)
                </div>
                <div>
                  <Switch checked={Boolean(LOGIN_ENABLED)} onChange={setLOGIN_ENABLED} />
                </div>
              </div>
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300, textTransform: 'uppercase' }}>
                  Log-In Disabled Message
                </div>
                <div className="w-full">
                  <Input
                    value={LOGIN_ENABLED_MESSAGE}
                    onChange={(e) => {
                      setLOGIN_ENABLED_MESSAGE(e.target.value);
                    }}
                    style={{ width: '60%' }}
                    placeholder="Enter text message!"
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card title="My Drive" bordered={false} className="w-full rounded-xl">
            <div className="w-full flex flex-col gap-3 ">
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300, textTransform: 'uppercase' }}>
                  User Trash Lifespan (day)
                </div>
                <div>
                  <InputNumber
                    value={Number(MYDRIVE_USER_TRASH)}
                    onChange={setMYDRIVE_USER_TRASH}
                    defaultValue={0}
                  />
                </div>
              </div>
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300, textTransform: 'uppercase' }}>
                  Admin Trash Lifespan (day)
                </div>
                <div>
                  <InputNumber
                    value={Number(MYDRIVE_ADMIN_TRASH)}
                    onChange={setMYDRIVE_ADMIN_TRASH}
                    defaultValue={0}
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card title="Maintenance Message" bordered={false} className="w-full rounded-xl">
            <div className="w-full flex flex-col gap-3 ">
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300, textTransform: 'uppercase' }}>
                  Maintenance Message Enabled (Teacher/Students)
                </div>
                <div>
                  <Switch checked={Boolean(MAINTENANCE_ENABLED)} onChange={setMAINTENANCE_ENABLED} />
                </div>
              </div>
              <div className="w-full flex gap-3 ">
                <div style={{ minWidth: 300, textTransform: 'uppercase' }}>
                  Maintenance Message Disabled Message
                </div>
                <div className="w-full">
                  <Input
                    value={MAINTENANCE_ENABLED_MESSAGE}
                    onChange={(e) => {
                      setMAINTENANCE_ENABLED_MESSAGE(e.target.value);
                    }}
                    style={{ width: '60%' }}
                    placeholder="Enter text message!"
                  />
                </div>
              </div>
            </div>
          </Card>
        </Content>
      </Layout>
    </Spin>
  );
}

export default GlobalSetting;
