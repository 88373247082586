import React from 'react'
import { useQuery } from 'react-query';
import { Skeleton } from 'antd';
import { getStudentDetailById } from 'api/student';

interface IStudentColumn {
    colValue: any;
    rowValue: any
}

function StudentColumn(props: IStudentColumn) {
    const { colValue, rowValue } = props
    const { data, isLoading } = useQuery(['getStudentDetailById', colValue], () =>
        getStudentDetailById({ id: Number(colValue) }), { enabled: !!colValue }
    );

    return isLoading ? <Skeleton.Input active /> : (
        <div>{data?.data?.user?.username}</div>
    )
}

export default React.memo(StudentColumn)