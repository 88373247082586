import React, { useState } from 'react';
import { Dropdown, Form, Input, Layout, Breadcrumb } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import { useQuery } from 'react-query';
import { getCourseDetail, getCourseModules } from 'api/courses';
import { getClassById } from 'api/class';
import { sortBy } from 'lodash';
import ModuleByCourse from 'components/ModuleByCourse';

function MyAssignment() {
  const navigate = useNavigate();
  const { courseId } = useParams();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const classId = params.get('classId');

  const { data: dataCourseDetail } = useQuery(
    ['getCourseDetail', courseId],
    () => getCourseDetail(Number(courseId)),
    {
      enabled: !!courseId,
    },
  );

  console.log('classId', classId);

  const { data: dataClass } = useQuery(
    ['getClassById', classId],
    () => getClassById(Number(classId)),
    {
      enabled: !!classId,
    },
  );

  const { data: dataCourseModules } = useQuery(['getCourseModules', courseId], () =>
    getCourseModules(Number(courseId)),
  );

  return (
    <Layout className="bg-transparent flex flex-col gap-y-6  ">
      <Layout.Header className="flex justify-between items-center bg-transparent px-0 h-auto">
        <Breadcrumb className="custom-font-header text-[1.75rem] leading-9 font-bold mb-0">
          <Breadcrumb.Item
            className="cursor-pointer font-fontFamily"
            onClick={() => navigate(ROUTES.my_course)}
          >
            My Course
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily">
            {`${dataCourseDetail?.data?.courseName ?? 'Course Name'} - ${
              dataClass?.data?.className ?? 'Class '
            }`}
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-fontFamily text-main-font-color">
            My Assignment
          </Breadcrumb.Item>
        </Breadcrumb>
      </Layout.Header>
      <div className="flex flex-col gap-5">
        {!!dataCourseModules?.data?.length &&
          sortBy(dataCourseModules?.data, 'id').map((x: any) => (
            <ModuleByCourse key={`module-${x.id}`} {...x} classId={classId} />
          ))}
      </div>
    </Layout>
  );
}

export default MyAssignment;
