import { Breadcrumb, Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ROUTES } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Guide = () => {
  const history = useNavigate();
  const [userGuideContent, setUserGuideContent] = useState('');

  useEffect(() => {
    fetch('/user-guide-student.html')
      .then((response) => response.text())
      .then((html) => setUserGuideContent(html))
      .catch((error) => console.error('Error fetching user guide:', error));
  }, []);

  return (
    <Layout className="bg-transparent">
      <Breadcrumb className="text-[28px] text-main-font-color font-bold font-fontFamily leading-9">
        <Breadcrumb.Item
          onClick={() => history(ROUTES.guide)}
          className="text-main-font-color font-fontFamily"
        >
          User Guide
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content className="mt-8 p-8 bg-white rounded-3xl shadow[#0000000a]">
        <div dangerouslySetInnerHTML={{ __html: userGuideContent }} />
      </Content>
    </Layout>
  );
};

export default Guide;