import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Dropdown, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import images from 'assets/images';
import ButtonCustom from 'components/Button';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { IEvent } from '../../constants';

import './cus-style-calendar.css';
import { useMutation } from 'react-query';
import { searchCourses } from 'api/courses';
import { searchCentres, searchCentresOfAdmin } from 'api/centres';
import { PARAMS_SELECT_SEARCH, TEXT_SELECT_SEARCH } from 'constants/constants';
import { getAllSessionInClassesByTeacher, searchClass } from 'api/class';
import { routerUserAccess } from 'helper/function';
import { RoleName } from 'enum/role';
import SelectSearch from 'components/SelectSearch';
import { getAllEvent } from 'api/event';

interface ICalendarFilter {
  handleSearchCourses?: (value: string, classes?: string, centre?: string, course?: string) => void;
  handleReset?: (e: boolean) => void;
  onFinish?: (values: any) => void;
  onValuesChange?: (values: any) => void;
  dropdown?: {
    overlay: React.ReactElement;
    visible?: boolean;
  };
  form?: FormInstance<any>;
  handleChangeSearch?: (value: string) => void;
  searchResults?: IEvent[];
  keyResult?: string;
  dependencyFiled?: string;
  fieldNameReset?: string;
  handleSearchDependency?: (val: string | number) => void;
  classId: string;
}

const CalendarFilter = (props: ICalendarFilter) => {
  const {
    handleSearchCourses,
    onFinish,
    onValuesChange,
    handleReset,
    handleChangeSearch,
    searchResults,
    keyResult,
    form,
    dependencyFiled,
    fieldNameReset,
    handleSearchDependency,
    classId,
  } = props;
  const [valueSearch, setValueSearch] = useState('');
  const [optionFilterValue, setOptionFilterValue] = useState<IEvent[]>([]);
  const [optionFilterValueSession,setOptionFilterValureSession] = useState<IEvent[]>([]);

  const { mutate: searchListEvent } = useMutation('getListEvent', getAllEvent, {
    onSuccess: ({ data }: { data: IEvent[] }) => {
      const listEvent = data.map((event) => ({
        start: new Date(event.startDateTime || ''),
        end: new Date(event.endDateTime || ''),
        title: event.title,
        resource: {
          ...event,
        },
      }));
      setOptionFilterValue(listEvent);
    },
  });

  const { mutate: getListSession } = useMutation('getListSession', getAllSessionInClassesByTeacher, {
    onSuccess: ({ data }: { data: any[] }) => {
      console.log('run to get getListSession ====================>', data);
      const listEvent = data.map((event) => ({
        start: new Date(event.startTime || ''),
        end: new Date(event.endTime || ''),
        title: event.session.sessionName,
        resource: {
          ...event,
        },
        isSession:true
      }));
      setOptionFilterValureSession(listEvent) 
    },
  });

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
    const param = {
      search: event.target.value.trim() === '' ? valueSearch : event.target.value.trim(),
      classes: classId ?? undefined,
    }
    searchListEvent(param);
    getListSession(param)
  };

  const dependency = Form.useWatch(dependencyFiled ? dependencyFiled : '', form);
  useEffect(() => {
    if (fieldNameReset) {
      form?.setFieldsValue({ [fieldNameReset]: '' });
      if (handleSearchDependency instanceof Function) {
        handleSearchDependency(dependency?.value);
      }
    }
  }, [dependency, fieldNameReset]);

  const onSearch = (title: string, id: string) => {
    setValueSearch(title);
    form?.setFieldsValue({ search: title });
    if (onFinish instanceof Function) {
      onFinish({ id });
    }
  };

  const searchResult = useMemo(
    () => (
      <>
        {valueSearch && keyResult ? (
          <div className="bg-white rounded-2xl p-4 min-h-[100px] custom-dropdown-search">
            {optionFilterValue && optionFilterValue?.length > 0 || optionFilterValueSession && optionFilterValueSession.length > 0 ? (
              [...optionFilterValue,...optionFilterValueSession]?.map((event: IEvent) => (
                <div
                  className="py-2 font-fontFamily font-normal cursor-pointer text-truncate"
                  key={event?.resource?.id}
                  onClick={() => onSearch(event?.title || '', event?.resource?.id || '')}
                >
                  {/* {event.isSession && 'Session : '}  */}
                  {event.title} 
                </div>
              ))
            ) : valueSearch ? (
              <div className="text-center font-fontFamily font-normal pt-4 word-break">
                No results found for “{valueSearch}”
              </div>
            ) : null}
          </div>
        ) : (
          <div />
        )}
      </>
    ),
    [searchResults, valueSearch, keyResult],
  ) as ReactElement<string>;

  return (
    <div className="filter-card">
      <div className="filter-content w-full">
        <Form
          form={form}
          className="w-full flex flex-wrap gap-4"
          name="basic"
          initialValues={{ layout: 'inline' }}
          autoComplete="off"
        >
          <div className="flex w-full gap-4 relative cus-flex">
            <div className="w-full cus-flex-item">
              <Dropdown
                trigger={['click']}
                overlay={searchResult}
                placement="bottom"
                className="w-full h-full relative cus-width-min-180"
                getPopupContainer={(trigger: any) => trigger?.parentNode}
              >
                <div className="relative" onClick={(e) => e.preventDefault()}>
                  <Form.Item name="search" className="w-full mb-0">
                    <Input
                      value={valueSearch}
                      id="input__option"
                      placeholder="Search"
                      onPaste={(e)=>{
                        const mess = `${e.clipboardData.getData('Text')} `
                        setTimeout(() => {
                          setValueSearch(mess);
                          const param = {
                            search: mess.trim() === '' ? valueSearch : `${mess} `,
                            classes: classId ?? undefined,
                          }
                          searchListEvent(param);
                          getListSession(param)
                        }, 500);
                      }}
                      onChange={onChangeSearch}
                      suffix={<img src={images.search} alt="search" />}
                      onPressEnter={(e) => {
                        document.getElementById('input__option')?.click();
                        handleSearchCourses?.(valueSearch, classId ?? '');
                      }}
                      className="style_input_custom_login_page rounded-xl"
                    />
                  </Form.Item>
                </div>
              </Dropdown>
            </div>
            <div className="custom-width-col-button-search">
              <Form.Item
                className="basis-[15%] mb-0 cus-width-min-180 cus-flex-item"
                id="outSizeClick"
              >
                <ButtonCustom
                  className="h-12"
                  // htmlType="submit"
                  color="orange"
                  onClick={() => {
                    handleSearchCourses?.(valueSearch, classId ?? '');
                  }}
                >
                  Search
                </ButtonCustom>
              </Form.Item>
            </div>
            <div className="custom-width-col-button-search">
              <Form.Item className="mb-0 cus-flex-item">
                <ButtonCustom
                  className="h-12"
                  htmlType="button"
                  color="outline"
                  onClick={() => {
                    form?.resetFields();
                    setValueSearch('');
                    if (handleReset instanceof Function) {
                      handleReset(true);
                    }
                  }}
                  isWidthFitContent
                >
                  Reset
                </ButtonCustom>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CalendarFilter;
