import { Layout, TablePaginationConfig, Row, Col, notification } from 'antd';
import ModalCustom from 'components/Modal';
import TableCustom from 'components/SearchBar/Table';
import CustomTooltip from 'components/Tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { getMeetingsForAdmin } from 'api/online_meetings';
import moment from 'moment';

const OnlineMeetings = () => {
  const [limit, setLimit] = useState<string>('5');
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: Number(limit),
  });

  const [visibleScheduledModal, setVisibleScheduledModal] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [rowData, setRowData] = useState<any>({});

  const { mutate: mutateGetMeetingsForAdmin, isLoading: isLoading } = useMutation('getMeetingsForAdmin', getMeetingsForAdmin, {
    onSuccess: ({ data }: { data: any }) => {
      console.log('data oagij', data);
      setLimit(String(data?.limit));
      setPagination({
        current: data?.page,
        pageSize: Number(limit),
        total: data?.total,
      });
      setMeetings(data?.meetings);
    },
    onError: ({ response }: { response: { data: { message: string } } }) => {
      notification.error({ message: response.data.message });
    },
  });

  useEffect(() => {
    mutateGetMeetingsForAdmin({
      limit: Number(limit),
      page: pagination?.current as number,
    });
  }, [pagination.current, limit]);

  const handleTableChange = (paginate: TablePaginationConfig) => {
    setPagination({ ...pagination, ...paginate });
  };

  const handleScheduledModal = () => {
    setVisibleScheduledModal(true);
  };

  const handleCancelScheduledModal = () => {
    setVisibleScheduledModal(false);
  };

  const onChangeLimit = useCallback((value: string) => {
    const total = pagination.total;
    const maxPage = Math.ceil(Number(total) / Number(value));
    setLimit(String(value));

    if (Number(pagination.current) > maxPage) {
      setPagination({ ...pagination, current: maxPage });
    } else {
      setPagination(pagination);
    }
  }, [pagination.current, pagination.total]);

  const columns = [
    {
      title: 'Start Date Time',
      dataIndex: 'startDate',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.startDate}>
              <div className="custom-text-ellipsis">
                {moment(record.startDate).format('YYYY/MM/DD - h:mm a')}
              </div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'End Date Time',
      dataIndex: 'endDate',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.endDate}>
              <div className="custom-text-ellipsis">
                {moment(record.endDate).format('YYYY/MM/DD - h:mm a')}
              </div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.studentName}>
              <div className="custom-text-ellipsis">{record.studentName}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      title: 'Teacher Name',
      dataIndex: 'teacherName',
      fixed: true,
      render: (text: string, record: any) => {
        return (
          <div>
            <CustomTooltip title={record.teacherName}>
              <div className="custom-text-ellipsis">{record.teacherName}</div>
            </CustomTooltip>
          </div>  
        );
      },
    },
  ];

  console.log('me', meetings);
  return (
    <Layout className="bg-transparent flex flex-col gap-y-6">
      <Row className="flex justify-between items-center bg-transparent px-0 mt-5 ">
        <Col className="mb-2 mr-2">
          <p className="custom-font-header text-[1.75rem] font-fontFamily leading-9 font-bold mb-0">
            Online Meetings
          </p>
        </Col>
      </Row>

      <TableCustom
        enableRowSelection={false}
        hidePageSize
        columns={columns}
        data={meetings || []}
        pagination={pagination}
        isLoading={isLoading}
        handleTableChange={handleTableChange}
        onChangePagination={(page) => {
          setPagination({ ...pagination, current: Number(page) });
        }}
        onRow={(record) => ({
          onClick: () => {
            console.log('## row record', record);
            setRowData(record);
            handleScheduledModal();
            // redirectEditStudent(record?.id as number, record?.studentName as string);
          },
        })}
        onLastPage={() => {
          setPagination({
            ...pagination,
            current: Math.ceil(Number(pagination.total) / Number(pagination.pageSize)),
          });
        }}
        onFirstPage={() => {
          setPagination({ ...pagination, current: 1 });
        }}
        viewItem={{
          onChange: onChangeLimit,
          value: pagination?.pageSize?.toString(),
        }}
      />
      <ModalCustom
        title='Scheduled Online Meeting'
        okText="Start Meeting"
        cancelText="Cancel"
        visible={visibleScheduledModal}
        onCancel={handleCancelScheduledModal}
        onSubmit={() => {
          if (rowData?.zoomLink === '') {
            notification.error({ message: 'You have not created a meeting yet' })
          } else {
            window.open(rowData?.zoomLink)
          }
        }}
        titleCenter
        contentLeft
        styleCancel={{ border: '1px solid #D1CDCB' }}
        className="modal-event-modified-custom"
      >
        <Row className='mt-10'>
          <Col span={10}>Start Date Time:</Col>
          <Col span={14}>
            {moment(rowData.startDate).format('YYYY/MM/DD - h:mm a')}
          </Col>
        </Row>
        <Row>
          <Col span={10}>End Date Time:</Col>
          <Col span={14}>
            {moment(rowData.endDate).format('YYYY/MM/DD - h:mm a')}
          </Col>
        </Row>
        <Row>
          <Col span={10}>Student Name:</Col>
          <Col span={14}>{rowData?.studentName}</Col>
        </Row>
        <Row>
          <Col span={10}>Teacher Name:</Col>
          <Col span={14}>{rowData?.teacherName}</Col>
        </Row>
        <Row>
          <Col span={10}>Remark:</Col>
          <Col span={14}>{rowData?.remarks}</Col>
        </Row>
        <Row className='mt-10'>
          <Col span={24}><i>Note: The Start Meeting button will only be active 10 mins prior to start of meeting</i></Col>
        </Row>

      </ModalCustom>
    </Layout>
  );
};

export default OnlineMeetings;
